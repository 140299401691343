import styled from "styled-components";

interface Props {
    direction: "row" | "column";
}

export const Flex = styled.div<Props>(
    {
        display: "flex",
    },
    ({ direction: flexDirection }) => ({
        flexDirection,
    })
);
