import React, { useState } from "react";
import { Card, Typography } from "antd";
import styled from "styled-components";
import { QTCSpace } from "styles/QTCSpace";
import { QUANTACO_DARK_BLUE } from "Utils/constants";
import { ForecastEventAction } from "./ForecastEventAction";
import { ForecastEventTable } from "./ForecastEventTable";
import { ForecastEventState, SalesAffectedEvent } from "../ForecastModel";
import { toQTCDate } from "Utils/date-utils";
import { ForecastEventEditor } from "./ForecastEventEditor";
import { v4 as uuid } from "uuid";

export const ForecastEvent = () => {
    const [state, setState] = useState<ForecastEventState>(ForecastEventState.IDLE);
    const [editingEvent, setEditingEvent] = useState<SalesAffectedEvent>();

    const [events, setEvents] = useState<SalesAffectedEvent[]>([
        {
            id: uuid(),
            start: toQTCDate("2022-08-18T10:00:00"),
            end: toQTCDate("2022-08-18T15:00:00"),
            name: "Wedding",
            expectedSalesAmount: 10000,
            areaIds: [
                "9baa8bbb-89ab-4cde-b012-3181fd2a0444",
                "88a98aba-89ab-4cde-b012-3181fd2d82b9",
            ],
            classIds: [
                "99a89aab-4567-489a-bcde-f181fd38172f",
                "8999ba9a-cdef-4012-b456-7181fd3b6270",
            ],
            venueId: "bb988889-0123-4456-b89a-b181a29c467f",
        },
    ]);

    const saveEvent = (event: SalesAffectedEvent) => {
        setState(ForecastEventState.SAVING_EVENT);
        if (state === ForecastEventState.CREATING_EVENT) {
            // Save me to the DB
            setEvents((prevEvents) => [
                ...prevEvents,
                {
                    ...event,
                    venueId: "bb988889-0123-4456-b89a-b181a29c467f",
                    id: uuid(),
                },
            ]);
        } else if (state === ForecastEventState.EDITING_EVENT) {
            setEvents((prevEvents) => [
                ...prevEvents.filter(({ id }) => id !== event.id),
                { ...event, venueId: "bb988889-0123-4456-b89a-b181a29c467f" },
            ]);
            // update me in the DB
        }

        setEditingEvent(undefined);
        setState(ForecastEventState.IDLE);
    };

    return (
        <RoundedCard>
            <QTCSpace $fluid direction="vertical" size={"middle"}>
                <Typography.Title level={4} style={{ color: QUANTACO_DARK_BLUE }}>
                    Add events that affect sales
                </Typography.Title>
                <ForecastEventTable
                    onEditEvent={(event) => {
                        setEditingEvent(event);
                        setState(ForecastEventState.EDITING_EVENT);
                    }}
                    events={events}
                />
                <ForecastEventAction
                    onAdd={() => setState(ForecastEventState.CREATING_EVENT)}
                    onApply={() => null}
                />

                {[
                    ForecastEventState.CREATING_EVENT,
                    ForecastEventState.EDITING_EVENT,
                    ForecastEventState.SAVING_EVENT,
                ].includes(state) ? (
                    <ForecastEventEditor
                        onCloseEditor={() => {
                            setState(ForecastEventState.IDLE);
                            setEditingEvent(undefined);
                        }}
                        onSaveEvent={saveEvent}
                        existingEvent={editingEvent}
                        loading={state === ForecastEventState.SAVING_EVENT}
                    />
                ) : null}
            </QTCSpace>
        </RoundedCard>
    );
};

const RoundedCard = styled(Card)`
    border-radius: 15px;
    border: 1px solid #d9d9d9;
`;
