import React, { ReactElement } from "react";
import { Result, Space, Typography } from "antd";
import { RoutePaths } from "../Components/Router/Routes";

export default function SomethingWentWrongPage(): ReactElement {
    document.title = "Salesline - 500";

    const Title = () => (
        <>
            <Space direction={"vertical"}>
                <Typography.Text type={"secondary"} data-testid="SWWP_HEADER_TEXT">
                    Sorry, something went wrong.
                </Typography.Text>
                <Typography.Link href={RoutePaths.LOGIN} data-testid="SWWP_BODY">
                    Click here to go home
                </Typography.Link>
            </Space>
        </>
    );
    return <Result status="500" title="500" subTitle={<Title />} />;
}
