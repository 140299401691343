import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";
import { DraggableTableRow } from "./DraggableTableRow";

const components = {
    body: {
        row: DraggableTableRow,
    },
};

const StyledTable = styled(Table)`
    tr.drop-over-downward td {
        border-bottom: 2px dashed #1890ff;
    }

    tr.drop-over-upward td {
        border-top: 2px dashed #1890ff;
    }
`;

interface Props {
    columns: ColumnsType<any>;
    dataSource: any[];
    onRowMoved: (fromRowIndex: number, toRowIndex: number) => void;
    isLoading: boolean;
    onRowClicked: (rowIndex: number, rowData: any) => void;
}

export const DraggableTable: React.FC<Props> = ({
    columns,
    dataSource,
    onRowMoved,
    isLoading,
    onRowClicked,
}) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <StyledTable
                components={components}
                columns={columns}
                dataSource={dataSource}
                onRow={(record, index) =>
                    ({
                        index,
                        moveRow: onRowMoved,
                        onClick: () => {
                            if (index != undefined) {
                                onRowClicked(index, record);
                            }
                        },
                    } as any)
                }
                loading={isLoading}
            />
        </DndProvider>
    );
};
