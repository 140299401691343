import React, { useMemo } from "react";
import { Skeleton, Typography } from "antd";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import { currencyFormatter } from "Utils/utils";
import { WRANGLR_DARK_BLUE, WRANGLR_LIGHT_BLUE } from "Utils/constants";
import { chain, sumBy } from "lodash";
import { toQTCDate } from "Utils/date-utils";

export interface BaselineRawRow {
    mapped_area: string;
    mapped_class: string;
    relative_timestamp: string;
    base: number;
    forecast?: number;
}

export interface BaselineMappedRow {
    x: string;
    base: number;
    forecast: number;
}

const getWeeklyOptions = (xAxisData: string[]): ChartOptions => {
    return {
        options: {
            chart: {
                id: "baselineChart",
                toolbar: {
                    tools: {
                        download: false,
                        pan: false,
                        zoomin: false,
                        zoomout: false,
                        reset: false,
                        zoom: false,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                type: "datetime",
                tickAmount: 7,
                categories: xAxisData,
                labels: {
                    datetimeUTC: false,
                    formatter: (val) => moment(val).format("DD-MM"),
                },
            },
            colors: [WRANGLR_LIGHT_BLUE, WRANGLR_DARK_BLUE],
            fill: {
                type: "gradient",
                gradient: {
                    opacityFrom: 0.91,
                    opacityTo: 0.1,
                },
            },
            yaxis: {
                tickAmount: 2,
                min: 0,
                labels: {
                    formatter: (num) => {
                        return currencyFormatter(num);
                    },
                },
            },
            grid: {
                show: true,
            },
            stroke: {
                width: 1,
                curve: "straight",
            },
            tooltip: {
                enabled: true,
                fixed: {
                    enabled: false,
                    position: "topRight",
                    offsetX: 0,
                    offsetY: 0,
                },
            },
        },
    };
};

const getMobileOptions = (xAxisData: string[]): ChartOptions => {
    return {
        options: {
            chart: {
                selection: { enabled: false },
                id: "bar-forecast",
                toolbar: {
                    tools: {
                        download: false,
                        pan: false,
                        zoomin: false,
                        zoomout: false,
                        reset: false,
                        zoom: false,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                type: "datetime",
                tickAmount: 6,
                categories: xAxisData,
                labels: {
                    datetimeUTC: false,
                    formatter: (val) => moment(val).format("HH:mm"),
                },
            },
            colors: [WRANGLR_LIGHT_BLUE, WRANGLR_DARK_BLUE],
            fill: {
                type: "gradient",
                gradient: {
                    opacityFrom: 0.91,
                    opacityTo: 0.1,
                },
            },
            yaxis: {
                tickAmount: 2,
                min: 0,
                labels: {
                    formatter: (num) => {
                        return currencyFormatter(num);
                    },
                },
            },
            grid: {
                show: true,
            },
            stroke: {
                width: 1,
                curve: "straight",
            },
            tooltip: {
                enabled: true,
                shared: true,
                intersect: false,
                fixed: {
                    enabled: false,
                    position: "topRight",
                    offsetX: 0,
                    offsetY: 0,
                },
            },
        },
    };
};

interface ChartOptions {
    options?: ApexOptions;
    brushChartOptions?: ApexOptions;
}

interface Props {
    baselineData: BaselineRawRow[];
    loading: boolean;
    isDaily?: boolean;
}

export const ForecastChartMobile: React.FC<Props> = ({
    baselineData,
    loading,
    isDaily,
}) => {
    const series = useMemo(() => {
        const baseLineRows: BaselineMappedRow[] = isDaily
            ? chain(baselineData)
                  .groupBy(({ relative_timestamp }) =>
                      toQTCDate(relative_timestamp).format("YYYY-MM-DD HH")
                  )
                  .map((data, timestamp) => {
                      return {
                          x: moment(timestamp, "YYYY-MM-DD HH").format(
                              "YYYY-MM-DDTHH:mm"
                          ),
                          base: sumBy(data, "base"),
                          forecast: sumBy(data, "forecast"),
                      };
                  })
                  .value()
            : chain(baselineData)
                  .groupBy(({ relative_timestamp }) =>
                      toQTCDate(relative_timestamp).format("YYYY-MM-DD")
                  )
                  .map((data, timestamp) => {
                      return {
                          x: moment(timestamp, "YYYY-MM-DD").format(
                              "YYYY-MM-DDTHH:mm"
                          ),
                          base: sumBy(data, "base"),
                          forecast: sumBy(data, "forecast"),
                      };
                  })
                  .value();
        const series = [
            { name: "Baseline", data: baseLineRows.map(({ base }) => base) },
        ];

        if (baseLineRows.some(({ forecast }) => forecast)) {
            series.push({
                name: "Forecast",
                data: baseLineRows.map(({ forecast }) => forecast),
            });
        }

        return series;
    }, [baselineData]);

    const options = useMemo(() => {
        const xAxis = isDaily
            ? chain(baselineData)
                  .groupBy(({ relative_timestamp }) =>
                      toQTCDate(relative_timestamp).format("YYYY-MM-DD HH")
                  )
                  .map((currentItem, timestamp) => {
                      return moment(timestamp, "YYYY-MM-DD HH").format(
                          "YYYY-MM-DDTHH:mm"
                      );
                  })
                  .value()
            : chain(baselineData)
                  .groupBy(({ relative_timestamp }) =>
                      toQTCDate(relative_timestamp).format("YYYY-MM-DD")
                  )
                  .map((currentItem, timestamp) => {
                      return moment(timestamp, "YYYY-MM-DD").format(
                          "YYYY-MM-DDTHH:mm"
                      );
                  })
                  .value();

        return isDaily
            ? getMobileOptions(xAxis).options
            : getWeeklyOptions(xAxis).options;
    }, [baselineData]);
    if (loading) {
        return (
            <>
                <Skeleton active={true} />
                <Skeleton active={true} />
            </>
        );
    }

    return (
        <>
            {baselineData.length > 0 ? (
                <ReactApexChart
                    options={options}
                    series={series}
                    type={"bar"}
                    width="100%"
                    height={200}
                />
            ) : (
                <Typography.Text type={"secondary"}>
                    Missing forecast data
                </Typography.Text>
            )}
        </>
    );
};
