import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { RoutePaths } from "./Routes";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { getGroupData, GroupData } from "Api/backend";
import jwtDecode from "jwt-decode";
import { Context, Filters, SelectedDates } from "State/store";
import { presetRanges } from "Components/DateFilter";
import { useInitializeFilter } from "Store/filterStore";
import { usePreviewMode } from "Store/viewSettingsStore";
import { useWebviewLogin } from "Hooks/useWebviewLogin";
import { useLogout } from "Components/Mobile/MobileSettingsScreen/MobileSettingsScreen";
import { useAuth } from "@hooks/useAuth";
import CubeJsProvider from "Components/CubeJsProvider";
import { useGroupData } from "@hooks/useGroupData";
import { notification } from "antd";

const useCustomHooks = () => {
    const location = useLocation();
    const isHomePage = location.pathname === "/";
    const isLoading = useWebviewLogin();
    const logout = useLogout();
    return { location, isHomePage, isLoading, logout };
};

/**
 * A Route wrapper component that checks for a valid user session
 */
const AuthCheckRoute = ({
    component: Component,
    ...rest
}: RouteProps): ReactElement => {
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const [{ groupData }, dispatch] = useContext(Context);
    const { setPreviewMode } = usePreviewMode();
    const initializeFilter = useInitializeFilter();
    const { isLoggedIn, isExpired } = useAuth(); // update this line
    const { location, isHomePage, isLoading, logout } = useCustomHooks();
    const {
        groupData: groupDataHook,
        fetchData,
        isLoading: isGroupDataLoading,
        error,
    } = useGroupData();

    useEffect(() => {
        if (error) {
            notification.error({
                message: "Error fetching group data",
                description: "Please try again later",
            });
            console.log({ error });
        }
    }, [error]);

    useEffect(() => {
        if (isLoggedIn) {
            fetchData();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoading) return;
        if (isLoggedIn) {
            handleGroupData(groupData, setPreviewMode, dispatch, initializeFilter)
                .then(() => {
                    setLoaded(true);
                })
                .catch((e) => {
                    console.log("LOG OUT EVENT");
                    console.error({ e });
                    logout();
                });
        } else if (isExpired) {
            logout();
            history.push(RoutePaths.LOGIN);
        } else {
            history.push(RoutePaths.LOGIN);
        }
    }, [isLoggedIn, isLoading, isExpired]);

    return isLoggedIn && loaded ? (
        <CubeJsProvider>
            <Route {...rest} component={Component} />
        </CubeJsProvider>
    ) : (
        <QuantacoLoader size={135} />
    );
};

const handleGroupData = async (
    groupData: GroupData | undefined,
    setPreviewMode: (value: boolean) => void,
    dispatch: any,
    initializeFilter: any
) => {
    if (groupData === undefined) {
        await getGroupData()
            .then(async (groupData: GroupData) => {
                console.log({ groupData });
                let datasetName = "";
                try {
                    datasetName = (jwtDecode(groupData.cubejsToken) as any).user
                        .dataset_name;

                    setPreviewMode(true);
                } catch (e) {
                    console.error(e);
                }

                // Process the retrieved groupData and update the state
                processGroupData(groupData, datasetName, dispatch, initializeFilter);
            })
            .catch((e) => {
                console.log("ISSUE FETCHING GROUP DATA");
                console.error({ e });
                throw e;
                // logout();
            });
    }
};

const processGroupData = (
    groupData: GroupData,
    datasetName: string,
    dispatch: any,
    initializeFilter: any
) => {
    dispatch({
        type: "ADD_GROUP_DATA",
        payload: {
            groupData,
        },
    });

    dispatch({
        type: "UPDATE_FILTERS",
        payload: {
            filters: {
                datasetName,
                mode: "actual",
            } as Filters,
        },
    });

    if (groupData.user && groupData.user.settings?.defaultViewId) {
        const defaultViewId = groupData.user.settings.defaultViewId;
        const view = groupData.views.views.find(
            (view) => view.view_id === defaultViewId
        );
        if (view) {
            const selectedDates: SelectedDates = view.filters.selectedDates;
            let { fromDate, toDate } = selectedDates;
            if (selectedDates.isPresetDateRange) {
                const { presetDateRangeLabel } = selectedDates;
                const selectedRange = presetRanges.find(
                    (range) => range.label === presetDateRangeLabel
                )!;
                const [fromDateObj, toDateObj] = selectedRange.range();
                fromDate = fromDateObj.format("YYYY-MM-DD");
                toDate = toDateObj.format("YYYY-MM-DD");
            }

            const filters = {
                ...view.filters,
                selectedDates: {
                    ...selectedDates,
                    fromDate,
                    toDate,
                },
                selectedViewId: defaultViewId,
            };

            initializeFilter({
                selectedDateRange: {
                    start: fromDate,
                    end: toDate,
                },
                selectedComparison: view.filters.selectedComparison
                    ? (view.filters.selectedComparison as any)
                    : undefined,
            });

            dispatch({
                type: "UPDATE_FILTERS",
                payload: {
                    filters,
                },
            });
        }
    }
};

export default AuthCheckRoute;
