import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MOBILE_VIEWPORT_WIDTH } from "Utils/constants";
import { RoutePaths } from "../Router/Routes";
import { isMobile as isMobileUserAgent } from "react-device-detect";

export const useReturnToHomeWithBreakpoint = ({
    disable,
}: {
    disable?: boolean;
}) => {
    const location = useLocation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        if (disable === true) return;
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const history = useHistory();

    useEffect(() => {
        if (disable === true) return;

        if (location.pathname === "/") return;

        const isMobileWidth = windowWidth <= MOBILE_VIEWPORT_WIDTH;

        if (isMobileUserAgent) {
            history.push(RoutePaths.HOME);
            return;
        }
        if (isMobileWidth) history.push(RoutePaths.HOME);
    }, [windowWidth]);
};

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean | undefined>();
    const [windowWidth, setWindowWidth] = useState(window.window.innerWidth);
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        const isMobileWidth = windowWidth <= MOBILE_VIEWPORT_WIDTH;
        if (isMobileUserAgent) {
            setIsMobile(true);
            return;
        }
        setIsMobile(isMobileWidth);
    }, [windowWidth]);

    return { isMobile };
};
