export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

// Colours
export const WRANGLR_DARK_BLUE = "#00255D";
export const WRANGLR_LIGHT_BLUE = "#286BB5";
export const WRANGLR_LIGHTER_BLUE = "#7ea6d3";
export const QUANTACO_DARK_ORANGE = "#FF6A14";
export const QUANTACO_DARK_BLUE = "#00255D";

export const QUANTACO_CHART_DARK_BLUE = "#2b5c94";
export const QUANTACO_CHART_LIGHT_BLUE = "#7ea6d3";
export const QUANTACO_CHART_DARK_ORANGE = "#f0742d";

export const FORECASTING_TAB_ENABLED = process.env.REACT_APP_FORECASTING_TAB_ENABLED;
export const ERROR_COLOR = "#FF4D4F";
export const WARNING_COLOR = "#FAB121";
export const DARK_GREEN = "#3f8600";
export const DARK_RED = "#cf1322";
export const BLACK = "#000000";
export const APEX_CHARTS_GRAY = "#a4a3a3";
export const ENV: string = process.env.REACT_APP_ENV!;
export const DEFAULT_TIMEZONE = "Australia/Sydney";

export const MOBILE_VIEWPORT_WIDTH = 650;

export enum ComparisonEnums {
    PREVIOUS_PERIOD = "Previous Period",
    SAME_PERIOD_LAST_YEAR = "Same Period Last Year",
    SAME_DAY_LAST_WEEK = "Same Day Last Week",
    ALL_FORECAST = "Forecast",
}
