import { QTCRange } from "Utils/date-utils";

export enum Spread {
    Even = 1,
    Proportional = 2,
}

export interface SalesAffectedEvent extends QTCRange {
    id: string;
    name: string;
    areaIds: string[];
    venueId: string;
    classIds: string[];
    expectedSalesAmount?: number;
    expectedSalesPercent?: number;
    spread?: Spread;
}

export enum ForecastEventState {
    IDLE = "IDLE",
    CREATING_EVENT = "CREATING_EVENT",
    SAVING_EVENT = "SAVING_EVENT",
    EDITING_EVENT = "EDITING_EVENT",
}
