import React, { CSSProperties, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

interface Props {
    className?: string;
    index: string;
    style: CSSProperties;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = "DraggableBodyRow";

export const DraggableTableRow: React.FC<Props> = ({
    className,
    index,
    moveRow,
    style,
    ...restProps
}) => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || ({} as any);
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName:
                    dragIndex < index ? " drop-over-downward" : " drop-over-upward",
            };
        },
        drop: (item: any) => {
            moveRow(item.index, Number(index));
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ""}`}
            style={{ cursor: "move", ...style }}
            key={index}
            {...restProps}
        />
    );
};
