import React, { ReactElement } from "react";
import ContextStore from "./State/store";
import "./App.less";
import Router from "./Components/Router";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import SomethingWentWrongPage from "Pages/SomethingWentWrongPage";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import { TokenErrorModal } from "Components/TokenErrorModal";

// Extend window object for gtag (Google analytics tag)
declare global {
    interface Window {
        gtag: (...args: any[]) => void;
        dataLayer: Record<string, any>;
    }
}

function App(): ReactElement {
    return (
        <Provider store={store}>
            <ErrorBoundary fallback={<SomethingWentWrongPage />}>
                <ContextStore>
                    <div className="App">
                        <BrowserRouter>
                            <Router />
                            <TokenErrorModal />
                        </BrowserRouter>
                    </div>
                </ContextStore>
            </ErrorBoundary>
        </Provider>
    );
}

export default App;
