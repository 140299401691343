import React, { useMemo } from "react";
import { Skeleton, Typography } from "antd";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import { currencyFormatter } from "Utils/utils";
import { WRANGLR_DARK_BLUE, WRANGLR_LIGHT_BLUE } from "Utils/constants";
import { chain, sumBy } from "lodash";

export interface BaselineRawRow {
    mapped_area: string;
    mapped_class: string;
    relative_timestamp: string;
    base: number;
    forecast?: number;
}

export interface BaselineMappedRow {
    x: string;
    base: number;
    forecast: number;
}

const getOptions = (xAxisData: string[]): ChartOptions => {
    return {
        options: {
            chart: {
                id: "baselineChart",
                toolbar: {
                    tools: {
                        download: false,
                        pan: false,
                        zoomin: false,
                        zoomout: false,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                type: "datetime",
                tickAmount: 7,
                categories: xAxisData,
                labels: {
                    datetimeUTC: false,
                    formatter: (val) => moment(val).format("ddd HH:mm (D/M)"),
                },
            },
            colors: [WRANGLR_LIGHT_BLUE, WRANGLR_DARK_BLUE],
            fill: {
                type: "gradient",
                gradient: {
                    opacityFrom: 0.91,
                    opacityTo: 0.1,
                },
            },
            yaxis: {
                tickAmount: 2,
                min: 0,
                labels: {
                    formatter: (num) => {
                        return currencyFormatter(num);
                    },
                },
                forceNiceScale: true,
            },
            grid: {
                show: true,
            },
            stroke: {
                width: 1,
                curve: "straight",
            },
        },
    };
};

const getMobileOptions = (xAxisData: string[]): ChartOptions => {
    return {
        options: {
            chart: {
                selection: { enabled: false },
                id: "bar-forecast",
                toolbar: {
                    tools: {
                        download: false,
                        pan: false,
                        zoomin: false,
                        zoomout: false,
                        reset: false,
                        zoom: false,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                type: "datetime",
                tickAmount: 12,
                categories: xAxisData,
                labels: {
                    datetimeUTC: false,
                    formatter: (val) => moment(val).format("HH:mm"),
                },
            },
            colors: [WRANGLR_LIGHT_BLUE, WRANGLR_DARK_BLUE],
            fill: {
                type: "gradient",
                gradient: {
                    opacityFrom: 0.91,
                    opacityTo: 0.1,
                },
            },
            yaxis: {
                tickAmount: 2,
                min: 0,
                labels: {
                    formatter: (num) => {
                        return currencyFormatter(num);
                    },
                },
                forceNiceScale: true,
            },
            grid: {
                show: true,
            },
            stroke: {
                width: 1,
                curve: "straight",
            },
        },
    };
};

interface ChartOptions {
    options?: ApexOptions;
    brushChartOptions?: ApexOptions;
}

interface Props {
    baselineData: BaselineRawRow[];
    loading: boolean;
}

export const ForecastChart: React.FC<Props> = ({ baselineData, loading }) => {
    const series = useMemo(() => {
        const baseLineRows: BaselineMappedRow[] = chain(baselineData)
            .groupBy("relative_timestamp")
            .map((data, timestamp) => {
                return {
                    x: timestamp,
                    base: sumBy(data, "base"),
                    forecast: sumBy(data, "forecast"),
                };
            })
            .value();

        const series = [
            { name: "Baseline", data: baseLineRows.map(({ base }) => base) },
        ];

        if (baseLineRows.some(({ forecast }) => forecast)) {
            series.push({
                name: "Forecast",
                data: baseLineRows.map(({ forecast }) => forecast),
            });
        }

        return series;
    }, [baselineData]);

    const options = useMemo(() => {
        const xAxis = Object.keys(
            chain(baselineData).groupBy("relative_timestamp").value()
        );

        return getOptions(xAxis).options;
    }, [baselineData]);
    if (loading) {
        return (
            <>
                <Skeleton active={true} />
                <Skeleton active={true} />
            </>
        );
    }

    return (
        <>
            {baselineData.length > 0 ? (
                <ReactApexChart
                    options={options}
                    series={series}
                    type={"area"}
                    width="100%"
                    height={200}
                />
            ) : (
                <Typography.Text type={"secondary"}>
                    Missing forecast data
                </Typography.Text>
            )}
        </>
    );
};
