import React, { Dispatch, ReactElement, useContext, useMemo } from "react";
import { message } from "antd";
import {
    Context,
    Filters,
    initialState,
    SelectedDates,
    State,
} from "../../State/store";
import moment from "moment";
import { presetRanges } from "../DateFilter";
import ViewSelectDesktop from "./ViewSelectDesktop";
import ViewSelectMobile from "./ViewSelectMobile";
import { Action } from "../../State/actions";
import { usePreviewMode } from "Store/viewSettingsStore";
import {
    AggregateFilter,
    ComparisonFilter,
    useResetFilter,
    useSelectedViewId,
    useUpdateFilter,
} from "Store/filterStore";
import { Aggregate } from "Components/AggregateFilter";
import { ComparisonEnums } from "Utils/constants";
import { DEFAULT_DATE_FORMAT, parseToMoment } from "Utils/date-utils";

export interface View {
    view_id: number;
    view_label: string;
    date_created: string;
    filters: Filters;
}

export const EMPTY_FILTERS: Filters = {
    ...initialState.filters,
    selectedVenues: [],
    selectedAreas: [],
    selectedClasses: [],
    selectedDates: {
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
    },
    selectedDays: [],
    selectedHours: [],
    selectedAggregate: null,
    selectedComparison: null,
    mode: "actual",
    selectedViewId: undefined,
};

const calculateDynamicDate = (selectedDates: SelectedDates) => {
    const selectedPreset = presetRanges.find(
        (range) =>
            range.label.toLowerCase() ===
            selectedDates?.presetDateRangeLabel?.toLowerCase()
    )!;
    const [fromDateObj, toDateObj] = selectedPreset.range();
    const fromDate = fromDateObj.format("YYYY-MM-DD");
    const toDate = toDateObj.format("YYYY-MM-DD");
    return { fromDate, toDate, presetDateRangeLabel: selectedPreset.label };
};

interface Props {
    className?: string;
    mode: "desktop" | "mobile";
}

const aggregateViewParser = (
    selectedAggregate: Aggregate | null
): AggregateFilter | undefined => {
    if (selectedAggregate === Aggregate.AVERAGE_DAY)
        return AggregateFilter.AverageDay;

    if (selectedAggregate === Aggregate.AVERAGE_WEEK)
        return AggregateFilter.AverageWeek;

    return undefined;
};

const selectedComparisonParser = (
    selectedComparison: ComparisonEnums | null
): ComparisonFilter | undefined => {
    if (selectedComparison === ComparisonEnums.ALL_FORECAST)
        return ComparisonFilter.Forecast;

    if (selectedComparison === ComparisonEnums.PREVIOUS_PERIOD)
        return ComparisonFilter.PreviousPeriod;
    if (selectedComparison === ComparisonEnums.SAME_DAY_LAST_WEEK)
        return ComparisonFilter.SameDayLastWeek;
    if (selectedComparison === ComparisonEnums.SAME_PERIOD_LAST_YEAR)
        return ComparisonFilter.SamePeriodLastYear;

    return undefined;
};

export default function ViewSelect({ className, mode }: Props): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = useContext(Context);
    const { filters, groupData }: State = state;
    const { views } = state.groupData!.views;

    const { previewMode } = usePreviewMode();
    const { selectedViewId: mappedSelectedViewId } = useSelectedViewId();

    const updateFilter = useUpdateFilter();
    const resetStore = useResetFilter();

    const onSelectView = (view: View) => {
        updateFilter({
            selectedDateRange: {
                start: view.filters.selectedDates.fromDate,
                end: view.filters.selectedDates.toDate,
            },
            selectedDays: view.filters.selectedDays,
            selectedClasses: view.filters.selectedClasses.map(
                (currentClass) => currentClass.primary_id
            ),
            selectedVenues: view.filters.selectedVenues.map(
                (currentVenue) => currentVenue.primary_id
            ),
            selectedAreas: view.filters.selectedAreas.map(
                (currentArea) => currentArea.primary_id
            ),
            selectedAggregate: aggregateViewParser(view.filters.selectedAggregate),
            selectedComparison: selectedComparisonParser(
                view.filters.selectedComparison
            ),
            selectedHours: view.filters.selectedHours,
            selectedViewId: view.view_id,
        });

        message.success("View Applied Successfully", 1);
    };

    const defaultViewId =
        groupData && groupData?.user && groupData.user.settings
            ? groupData.user.settings.defaultViewId
            : null;

    const onClear = () => {
        const payload = {
            filters: { ...EMPTY_FILTERS, datasetName: filters.datasetName },
        };
        previewMode ? resetStore() : dispatch({ type: "REPLACE_FILTERS", payload });
    };

    const preservedViews: View[] = useMemo(() => {
        const now = parseToMoment();
        const todayView: View = {
            view_id: -1,
            view_label: "Today vs Forecast",
            date_created: now.format(DEFAULT_DATE_FORMAT),
            filters: {
                ...EMPTY_FILTERS,
                selectedDates: {
                    fromDate: now.format(DEFAULT_DATE_FORMAT),
                    toDate: now.format(DEFAULT_DATE_FORMAT),
                },
                selectedComparison: ComparisonEnums.ALL_FORECAST,
            },
        };

        return [todayView];
    }, []);

    return (
        <>
            {mode === "desktop" ? (
                <ViewSelectDesktop
                    views={preservedViews.concat(views)}
                    onSelectView={onSelectView}
                    defaultViewId={defaultViewId}
                    onClear={onClear}
                    selectedViewId={mappedSelectedViewId}
                    className={className}
                />
            ) : (
                <ViewSelectMobile
                    className={className}
                    views={preservedViews.concat(views)}
                    onSelectView={onSelectView}
                    selectedViewId={mappedSelectedViewId}
                    defaultViewId={defaultViewId}
                />
            )}
        </>
    );
}
