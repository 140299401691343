import { Query, TimeDimension, TQueryOrderObject } from "@cubejs-client/core";
import { DEFAULT_TIMEZONE } from "./constants";

export const CubeQueryBuilder = (query: Query = {}) => ({
    getResult: (
        { ignoreTimeZone }: { ignoreTimeZone: boolean } = { ignoreTimeZone: false }
    ) =>
        ignoreTimeZone
            ? query
            : {
                  ...query,
                  timezone: DEFAULT_TIMEZONE,
              },
    addMeasures: (measures: string[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  measures: [...(query.measures ?? []), ...measures],
              })
            : CubeQueryBuilder(query),
    addOrder: (order: TQueryOrderObject, condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  order,
              })
            : CubeQueryBuilder(query),
    addFilters: (filters: any, condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  filters: [...(query.filters ?? []), ...filters],
              })
            : CubeQueryBuilder(query),
    addTimeDimensions: (timeDimensions: TimeDimension[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  timeDimensions: [
                      ...(query.timeDimensions ?? []),
                      ...timeDimensions,
                  ],
              })
            : CubeQueryBuilder(query),
    addDimensions: (dimensions: string[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  dimensions: [...(query.dimensions ?? []), ...dimensions],
              })
            : CubeQueryBuilder(query),
    addLimit: (limit: number) =>
        CubeQueryBuilder({
            ...query,
            limit,
        }),
});
