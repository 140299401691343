import { useMemoOne } from "@react-spring/shared";
import { Slider } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { useHoursFilter } from "Store/filterStore";
import { AddHoursToFilterAction } from "../../State/actions";
import { Context, Filters } from "../../State/store";

const formatTimeTo12Hour = (time: number) =>
    time >= 12 ? `${time % 12 || 12}pm` : `${time % 12 || 12}am`;

const formatTimeSliderTooltip = (num: number | undefined) =>
    num ? formatTimeTo12Hour(num % 24) : null;

export default function TimeSlider(): ReactElement {
    const [values, setValues] = useState<[number, number] | undefined>([6, 30]);
    const [state, dispatch] = React.useContext(Context);
    const { selectedHours }: Filters = state.filters;
    const { updateHoursFilter } = useHoursFilter();

    const marks = useMemoOne(() => {
        const localMarks = {};
        for (let i = 6; i < 30; i += 6) {
            const time = i % 24;
            localMarks[i] = formatTimeTo12Hour(time);
        }
        return localMarks;
    }, []);

    const hourMappings = useMemoOne(() => {
        const localHourMappings = {};
        for (let i = 6; i < 30; i++) {
            localHourMappings[i % 24] = i;
        }

        return localHourMappings;
    }, []);

    const onAfterChange = (range: [number, number]) => {
        const [min, max] = range;

        const selectedHours: number[] = [];
        if (range !== [6, 30]) {
            for (let i = min; i <= max; i++) {
                selectedHours.push(i % 24);
            }
        }

        const action: AddHoursToFilterAction = {
            type: "ADD_HOURS_TO_FILTER",
            payload: {
                selectedHours,
            },
        };

        updateHoursFilter(selectedHours);
        dispatch(action);
    };

    useEffect(() => {
        if (selectedHours.length > 0) {
            const mappedHours = selectedHours.map((hour) => hourMappings[hour]);
            setValues([mappedHours[0], mappedHours[mappedHours.length - 1]]);
        } else {
            setValues([6, 30]);
        }
    }, [selectedHours]);

    return (
        <Slider
            marks={marks}
            min={6}
            max={29}
            range={true}
            value={values}
            tipFormatter={formatTimeSliderTooltip}
            onAfterChange={onAfterChange}
            onChange={([a, b]) => setValues([a, b])}
        />
    );
}
