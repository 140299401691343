import React, { ReactElement, useContext } from "react";
import ForecastingDashboardDesktop from "../Components/ForecastingDashboardDesktop";
import { Context } from "State/store";

import BaseDesktopPage from "./BaseDesktopPage";
import { useGtag } from "Utils/firebase/GTag";
import { useReturnWidget } from "Utils/widget";

export default function Forecasting(): ReactElement {
    useReturnWidget();
    document.title = "Salesline - Forecasting";
    const [{ groupData }] = useContext(Context);

    useGtag({
        groupId: groupData?.group.groupId,
        groupName: groupData?.group.groupName,
        userId: groupData?.user.user_id,
        userName: groupData?.user.name,
    });

    return (
        <BaseDesktopPage page={"forecasting"}>
            <ForecastingDashboardDesktop />
        </BaseDesktopPage>
    );
}
