import { ApiOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React from "react";

interface Props {
    onAdd: () => void;
    onApply: () => void;
}

export const ForecastEventAction: React.FC<Props> = ({ onAdd, onApply }) => {
    return (
        <Row gutter={16}>
            <Col>
                <Button onClick={onAdd} type={"primary"} shape={"round"}>
                    Add <PlusOutlined />
                </Button>
            </Col>
            <Col>
                <Button
                    shape={"round"}
                    type={"primary"}
                    onClick={onApply}
                    // loading={toggleCreateBaseline || toggleApplyDriver}
                >
                    Apply <ApiOutlined />
                </Button>
            </Col>
        </Row>
    );
};
