import { BACKEND_API_URL } from "../Utils/constants";
import { getAuthTokens } from "./auth0";
import moment from "moment";
import { Filters, Forecast } from "../State/store";
import { View } from "../Components/ViewSelect";
import { BaselineRawRow } from "../Components/ForecastingChartsDataManager/BaselineChartForAutogen";
import { Nullable } from "types";
import jwtDecode from "jwt-decode";

export interface Venue {
    primary_id: string;
    weather?: any;
}

export type Area = Venue;

export type Class = Venue;

export interface User {
    can_invite_users?: boolean;
    email: string;
    group_id: string;
    name: string;
    first_name: string;
    last_name: string;
    settings: {
        defaultViewId: number;
        mapping_configurator_enabled?: boolean;
    };
    views: View[];
    user_id: string;
    is_active?: boolean;
    is_admin?: boolean;
}

export interface Segments {
    areas: Area[];
    classes: Class[];
    group_id: string;
    venues: Venue[];
}

export interface GroupData {
    cubejsToken: string;
    groupId: string;
    group: {
        bannerAlert: Nullable<string>;
        groupId: string;
        groupName: string;
        historicStartDate: string;
        features: {
            mappingConfiguratorEnabled: boolean;
        };
        is_pilot_forecast?: boolean;
    };
    segments: Segments;
    user: User;
    // TODO Type this
    users: User[];
    views: { group_id?: string; views: View[] };
    forecasts: Forecast[];
}

const errorObject = {
    detail: "Unhandled error occured",
};

// Make a helper to check if an object is a errorObject
export const isErrorObject = (obj: any): obj is typeof errorObject => {
    return obj.detail === "Unhandled error occured";
};

//

export const getGroupData = (onError?: () => void): Promise<GroupData> => {
    return getAuthTokens()
        .then((data) => {
            const access_token = data?.access_token;
            if (access_token === null || access_token === undefined) {
                // Without throwing manually throwing an error the web app just crashes.
                // This is try to the request object being returned but not being handled where it has been returned to.
                throw new Error("No token");
            }

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `bearer ${access_token}`);

            const requestOptions: RequestInit = {
                method: "GET",
                headers: myHeaders,
            };

            const url = `${BACKEND_API_URL}/v2/group/data`;
            return fetch(url, requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (isErrorObject(data)) {
                        if (onError) onError();
                        throw new Error(data.detail);
                    }
                    return data;
                })
                .catch((error) => {
                    throw error;
                });
        })
        .catch((e) => {
            if (onError) {
                onError();
            }
            throw e;
        });
};
export const addView = async (
    viewLabel: string,
    filters: Filters
): Promise<View> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${access_token}`);
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
        view_label: viewLabel,
        date_created: moment().format("YYYY-MM-DD"),
        filters,
    });
    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };
    const url = `${BACKEND_API_URL}/v2/group/views`;
    const r = await fetch(url, requestOptions);
    return await r.json();
};

export const updateView = async (
    viewLabel: string,
    filters: Filters,
    viewIdToOverride?: number
): Promise<View> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${access_token}`);
    myHeaders.append("Content-Type", "application/json");
    const requestBody = {
        view_label: viewLabel,
        filters,
    };
    // If a view_id is supplied to be overridden add to request body.
    if (viewIdToOverride) {
        requestBody["view_id"] = viewIdToOverride;
    }
    const raw = JSON.stringify(requestBody);
    const requestOptions: RequestInit = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };
    const url = `${BACKEND_API_URL}/v2/group/views`;
    const r = await fetch(url, requestOptions);
    return await r.json();
};

export const getViews = async (): Promise<View[]> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${access_token}`);
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    const url = `${BACKEND_API_URL}/v2/group/views`;
    const r = await fetch(url, requestOptions);
    const responseJson = await r.json();
    return responseJson.views;
};

export const deleteView = async (viewId: number): Promise<View> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${access_token}`);
    const raw = JSON.stringify({
        view_id: viewId,
    });
    const requestOptions: RequestInit = {
        method: "DELETE",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };
    const url = `${BACKEND_API_URL}/v2/group/views`;
    const r = await fetch(url, requestOptions);
    return await r.json();
};

export const getCubeJsToken = async (): Promise<string | undefined> => {
    const currentCubeToken = window.localStorage.getItem("cubejsToken");

    try {
        if (currentCubeToken) {
            jwtDecode(currentCubeToken);
        }
    } catch (e) {
        window.localStorage.removeItem("cubejsToken");
    }

    if (window.localStorage.getItem("cubejsToken")) {
        const generationTime = moment(
            window.localStorage.getItem("cubeJsTokenGenerationTime")
        );
        if (moment().diff(generationTime, "hours") <= 23) {
            return new Promise((resolve) => {
                resolve(window.localStorage.getItem("cubejsToken")!);
            });
        }
    }
    try {
        const groupData = await getGroupData();
        if (groupData.cubejsToken) {
            window.localStorage.setItem("cubejsToken", groupData.cubejsToken);
            window.localStorage.setItem(
                "cubeJsTokenGenerationTime",
                moment().format()
            );
            return groupData.cubejsToken;
        }
    } catch (e) {
        console.error({ e });
    }
};

export const clearCubeJsToken = () => {
    window.localStorage.removeItem("cubejsToken");
    window.localStorage.removeItem("cubeJsTokenGenerationTime");
};

export const getForecasts = (): Promise<Forecast[]> => {
    return getAuthTokens().then((data) => {
        const access_token = data?.access_token;
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const requestOptions: RequestInit = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/forecasts`;

        return fetch(url, requestOptions)
            .then((r) => r.json())
            .catch(() => {
                window.location.href = "/something-went-wrong";
            });
    });
};

export const addForecast = (
    venue: string,
    weekPeriod: moment.Moment
): Promise<Forecast> => {
    return getAuthTokens().then((data) => {
        const access_token = data?.access_token;
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const weekStartLabel = moment(weekPeriod).format("DD/MM/YYYY");
        const weekEndLabel = moment(weekPeriod)
            .endOf("isoWeek")
            .format("DD/MM/YYYY");
        const raw = JSON.stringify({
            forecastLabel: `${venue} (${weekStartLabel} - ${weekEndLabel})`,
            configuration: {
                venue,
                weekPeriod: weekPeriod.format("YYYY-MM-DD"),
            },
            drivers: [],
            baseline: [],
            baselineConfiguration: {
                maxPercentile: 1,
                avgSmoothPreceding: 3,
                avgSmoothFollowing: 3,
            },
        });

        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/forecasts`;

        return fetch(url, requestOptions)
            .then((r) => r.json())
            .catch(() => {
                window.location.href = "/something-went-wrong";
            });
    });
};

export const deleteForecast = async (
    forecastId: string
): Promise<Forecast | undefined> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${access_token}`);
    const raw = JSON.stringify({
        forecastId,
    });
    const requestOptions: RequestInit = {
        method: "DELETE",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };
    const url = `${BACKEND_API_URL}/v2/group/forecasts`;
    try {
        const r = await fetch(url, requestOptions);
        return await r.json();
    } catch {
        window.location.href = "/something-went-wrong";
    }
};

export const updateForecast = (forecast: Forecast): Promise<Forecast> => {
    return getAuthTokens().then((data) => {
        const access_token = data?.access_token;
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const raw = JSON.stringify(forecast);

        const requestOptions: RequestInit = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/forecasts`;

        return fetch(url, requestOptions)
            .then((r) => r.json())
            .catch(() => {
                window.location.href = "/something-went-wrong";
            });
    });
};

export const applyForecastWithMode = (
    forecastId: string,
    forecastMode: string,
    previewMode = false
): Promise<BaselineRawRow[]> => {
    return getAuthTokens().then((data) => {
        const access_token = data?.access_token?.toString();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);
        const raw = JSON.stringify({
            forecastId,
            forecastMode,
            // We always use mapped data now
            mappedData: previewMode,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        const url = `${BACKEND_API_URL}/v2/group/forecasts/apply`;

        return fetch(url, requestOptions)
            .then((r) => r.json())
            .catch((error) => {
                console.log("applyForecastWithMode Failed");
                console.log(error);
                // window.location.href = "/something-went-wrong";
            });
    });
};
