import React from "react";
import { Button } from "antd";
import { useGetSalesAndStaffHours } from "Pages/Dashboard/Hooks/useGetSalesAndStaffHours";
import { Parser } from "json2csv";
import { downloadCSV } from "Utils/dom-utils";
import { useListMappingRules } from "Hooks/mappingConfigurator";

const json2csvParser = new Parser({
    header: true,
});

export const DataExportButton = () => {
    const {
        isLoading,
        totalSalesAndStaffHoursByMappingRule,
        totalSalesAndStaffHoursByMappingRuleForComparison,
        averageSalesAndStaffHoursByMappingRule,
    } = useGetSalesAndStaffHours();

    const { mappingRuleById } = useListMappingRules();

    const exportMappedData = () => {
        if (!totalSalesAndStaffHoursByMappingRule) {
            return;
        }
        const csv = json2csvParser.parse(
            Object.entries(totalSalesAndStaffHoursByMappingRule).map(
                ([mappingRuleId, { totalSales, totalStaffHours }]) => {
                    const mappingRule = mappingRuleById[mappingRuleId];
                    if (!mappingRule) {
                        return {
                            Name: mappingRuleId,
                            Segment: mappingRuleId,
                            Sales: totalSales,
                            StaffHours: totalStaffHours,
                            ComparedSales:
                                totalSalesAndStaffHoursByMappingRuleForComparison?.[
                                    mappingRuleId
                                ].totalSales,
                            ComparedStaffHours:
                                totalSalesAndStaffHoursByMappingRuleForComparison?.[
                                    mappingRuleId
                                ].totalStaffHours,
                            AverageSales:
                                averageSalesAndStaffHoursByMappingRule?.[
                                    mappingRuleId
                                ].totalSales,
                            AverageStaffHours:
                                averageSalesAndStaffHoursByMappingRule?.[
                                    mappingRuleId
                                ].totalStaffHours,
                        };
                    }
                    const { segment, segmentName } = mappingRule;
                    return {
                        Name: segmentName,
                        Segment: segment,
                        Sales: totalSales,
                        StaffHours: totalStaffHours,
                        ComparedSales:
                            totalSalesAndStaffHoursByMappingRuleForComparison?.[
                                mappingRuleId
                            ].totalSales,
                        ComparedStaffHours:
                            totalSalesAndStaffHoursByMappingRuleForComparison?.[
                                mappingRuleId
                            ].totalStaffHours,
                        AverageSales:
                            averageSalesAndStaffHoursByMappingRule?.[mappingRuleId]
                                .totalSales,
                        AverageStaffHours:
                            averageSalesAndStaffHoursByMappingRule?.[mappingRuleId]
                                .totalStaffHours,
                    };
                }
            )
        );
        downloadCSV(csv, "mappedData.csv");
    };
    return (
        <Button onClick={exportMappedData} disabled={isLoading}>
            Export Data
        </Button>
    );
};
