import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Context, Filters, Forecast, SelectedDates } from "../../State/store";
import { Cascader, Col, Form, Modal, Row, Select, Slider, Steps } from "antd";
import { SelectOption } from "../DriversCard/DriverModal";
import { useToggle } from "ahooks";
import { UpdateFiltersAction } from "../../State/actions";
import moment from "moment";
import { Venue } from "../../Api/backend";
import { datetimeSort, DAYS_OF_THE_WEEK, stringSort } from "../../Utils/utils";
import styled from "styled-components";
import { CascaderOption } from "antd-mobile/es/components/cascader-view";

interface WeekPeriodVenueMapping {
    [key: string]: string[];
}

interface FormValues {
    weekPeriod: string;
    venues: string[];
    days: number[];
}

const CenteredCol = styled(Col)`
    justify-items: center;
    align-items: center;
`;

const DaySlider = (): ReactElement => {
    function formatter(index: number | undefined) {
        return DAYS_OF_THE_WEEK[index!];
    }

    const marks = {};
    DAYS_OF_THE_WEEK.forEach((day: string, index: number) => (marks[index] = day));
    return (
        <Form.Item name={"days"} initialValue={[0, 6]}>
            <Slider tipFormatter={formatter} range max={6} marks={marks} />
        </Form.Item>
    );
};

const ForecastComparisonSelector = (): ReactElement => {
    const [state, dispatch] = useContext(Context);
    const { forecasts } = state.groupData!;
    const [venueOptions, setVenueOptions] = useState<SelectOption[]>([]);
    const [weekPeriodVenueMappings, setWeekPeriodVenueMappings] =
        useState<WeekPeriodVenueMapping>({});
    const [visible, { toggle: toggleModalVisible }] = useToggle();
    const [selectedWeekPeriod, setSelectedWeekPeriod] = useState<string>();
    const [venuesSelected, setVenuesSelected] = useState(false);

    const WeekCascadedSelection = (): ReactElement => {
        const [mappedWeekOptions, setMappedWeekOptions] = useState<CascaderOption[]>(
            []
        );

        const displayRender = (labels, selectedOptions) => {
            if (!selectedOptions.length) {
                return "";
            }
            return labels.map((label, i) => {
                const option = selectedOptions[i];
                if (i === labels.length - 1) {
                    return <span key={option.value}>{label}</span>;
                }
            });
        };

        useEffect(() => {
            setMappedWeekOptions(
                forecasts
                    .reduce((res: CascaderOption[], forecast: Forecast) => {
                        const weekPeriodDateObj = moment(
                            forecast.configuration.weekPeriod
                        );

                        if (
                            !moment().isBetween(
                                weekPeriodDateObj,
                                weekPeriodDateObj.clone().add(1, "week"),
                                "day",
                                "[]"
                            ) &&
                            !weekPeriodDateObj.isBefore(moment())
                        ) {
                            return res;
                        }

                        const monthLabel = weekPeriodDateObj
                            .clone()
                            .format("MMMM YYYY");
                        const weekLabel =
                            weekPeriodDateObj.clone().format("[Week] W (DD/MM") +
                            weekPeriodDateObj
                                .clone()
                                .add(1, "week")
                                .format(" - DD/MM)");

                        const existingRow = res.find(
                            (row) => row.value === monthLabel
                        );

                        if (!existingRow) {
                            return [
                                ...res,
                                {
                                    value: monthLabel,
                                    label: monthLabel,
                                    children: [
                                        {
                                            value: forecast.configuration.weekPeriod,
                                            label: weekLabel,
                                        },
                                    ],
                                },
                            ];
                        } else {
                            const existingChild = existingRow.children!.find(
                                (child) =>
                                    child.value === forecast.configuration.weekPeriod
                            );
                            if (!existingChild) {
                                existingRow.children!.push({
                                    value: forecast.configuration.weekPeriod,
                                    label: weekLabel,
                                });
                                existingRow.children = existingRow.children!.sort(
                                    (a, b) =>
                                        stringSort(
                                            a.label as string,
                                            b.label as string
                                        )
                                );
                            }
                        }
                        return res;
                    }, [])
                    .sort((a, b) =>
                        datetimeSort(a.value as string, b.value as string)
                    )
            );
        }, []);

        return (
            <Form.Item
                name={"weekPeriod"}
                rules={[
                    {
                        required: true,
                        message: "Required.",
                    },
                ]}
            >
                <Cascader
                    options={mappedWeekOptions}
                    onChange={([, weekPeriod]) =>
                        setSelectedWeekPeriod(weekPeriod as string)
                    }
                    displayRender={displayRender}
                />
            </Form.Item>
        );
    };

    const [form] = Form.useForm<FormValues>();

    useEffect(() => {
        const mappings: WeekPeriodVenueMapping = {};
        forecasts.map((forecast: Forecast) => {
            const { weekPeriod, venue } = forecast.configuration;
            if (!mappings[weekPeriod]) {
                mappings[weekPeriod] = [];
            }
            mappings[weekPeriod].push(venue);
        });
        setWeekPeriodVenueMappings(mappings);
    }, []);

    useEffect(() => {
        if (selectedWeekPeriod) {
            const venues: string[] = weekPeriodVenueMappings[selectedWeekPeriod]
                .map((venue) => venue)
                .sort();
            setVenueOptions(
                venues.map((venue) => ({
                    key: venue,
                    value: venue,
                }))
            );
            form.setFields([
                {
                    name: "venues",
                    value: venues,
                },
            ]);
            setVenuesSelected(venues.length > 0);
        }
        form.validateFields();
        return () => setVenuesSelected(false);
    }, [selectedWeekPeriod]);

    const handleModalClose = () => {
        toggleModalVisible();
        form.resetFields();
        setSelectedWeekPeriod(undefined);
    };

    const onOk = () => {
        form.validateFields().then(({ weekPeriod, venues, days }: FormValues) => {
            weekPeriod = weekPeriod[1];
            days = days ? days : [0, 6];
            const selectedDates: SelectedDates = {
                fromDate: moment(weekPeriod)
                    .add(days[0], "day")
                    .format("YYYY-MM-DD"),
                toDate: moment(weekPeriod).add(days[1], "day").format("YYYY-MM-DD"),
            };
            const selectedVenues: Venue[] = venues.map((primary_id) => ({
                primary_id,
            }));
            const updateFiltersAction: UpdateFiltersAction = {
                type: "UPDATE_FILTERS",
                payload: {
                    filters: {
                        ...state.filters,
                        selectedComparison: null,
                        selectedDates,
                        selectedVenues,
                        mode: "forecast",
                    } as Filters,
                },
            };
            dispatch(updateFiltersAction);
            handleModalClose();
        });
    };

    return (
        <>
            <Modal
                title={"Forecast Comparison Selection"}
                visible={visible}
                onCancel={handleModalClose}
                onOk={onOk}
                width={750}
                bodyStyle={{ paddingBottom: 0 }}
            >
                <Row gutter={[0, 32]}>
                    <Col span={24}>
                        <Steps size={"small"}>
                            <Steps.Step
                                title="Select a Week Period"
                                status={selectedWeekPeriod ? "finish" : "process"}
                            />
                            <Steps.Step
                                title="Select Venues"
                                status={venuesSelected ? "finish" : "process"}
                            />
                            <Steps.Step
                                title="Select Days (optional)"
                                status={selectedWeekPeriod ? "finish" : "process"}
                            />
                        </Steps>
                    </Col>
                    <Col span={24}>
                        <Form form={form}>
                            <Row
                                gutter={[16, 0]}
                                justify={selectedWeekPeriod ? "center" : undefined}
                            >
                                <Col span={12}>
                                    <WeekCascadedSelection />
                                </Col>
                                {selectedWeekPeriod && (
                                    <>
                                        <Col span={12}>
                                            <Form.Item
                                                name={"venues"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required.",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    options={venueOptions}
                                                    placeholder={"Venues"}
                                                    mode={"multiple"}
                                                    onChange={(values: string[]) =>
                                                        setVenuesSelected(
                                                            values.length > 0
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <CenteredCol span={22}>
                                            <DaySlider />
                                        </CenteredCol>
                                    </>
                                )}
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            {/*  TEMPORARILY DISABLED.
                 SEE WGL-544 FOR CONTEXT.
            <Button block onClick={() => toggleModalVisible()}>
                Compare against forecast
                 </Button> */}
        </>
    );
};

export default ForecastComparisonSelector;
