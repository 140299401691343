import { MappingRule } from "Api/mappingConfigurator";
import {
    useCreateMappingRule,
    useGetMappingRuleById,
    useListIntegrationSources,
    useListSegmentsByIntegrationSource,
    useUpdateMappingRule,
} from "Hooks/mappingConfigurator";
import { chain, cloneDeep, isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Utils as QbUtils } from "react-awesome-query-builder";
import AntdConfig from "react-awesome-query-builder/lib/config/antd";
import {
    getFieldSettings,
    parseTreeLogic,
    validateMappingObject,
} from "Utils/mapping-configurator-utils";
import { MultiSelectWidget } from "./AntDesignComponents/MultiSelectWidget";
import { MappedSegmentTypes } from "./MappingConfiguratorEditorPage";
import { hasDuplicateValues } from "./HasDupllicateCheck";
import { Modal } from "antd";
import { openNotificationWithIcon } from "Components/Notification";

const defaultConfig = cloneDeep(AntdConfig);

// Override of Select component
// defaultConfig.widgets.select.factory = (props: any) => {
//     return React.cloneElement(<SelectWidget {...props} />, {});
// };

// Override for Multi-Select component
defaultConfig.widgets.multiselect.factory = (props: any) => {
    return React.cloneElement(<MultiSelectWidget {...props} />, {});
};

const {
    is_null,
    is_not_null,
    not_equal,
    select_not_equals,
    multiselect_not_equals,
    ...defaultOperators
} = AntdConfig.operators;

export const useMappingConfiguratorEditorPage = (id: string) => {
    const { data: existingMappingRule, isFetching: fetchingExistingMappingRule } =
        useGetMappingRuleById(id);
    const { data: integrationSources } = useListIntegrationSources();
    const { mutate: createMappingRule, isLoading: creatingMappingRule } =
        useCreateMappingRule();
    const { mutate: updateMappingRule, isLoading: updatingMappingRule } =
        useUpdateMappingRule();
    const [mappingRule, setMappingRule] = useState<Partial<MappingRule>>({
        id,
    });

    const { data: segments } = useListSegmentsByIntegrationSource(
        integrationSources?.map(({ integrationName }) => integrationName).join(",")
    );

    // console.log({ oldSegMents });

    // const segments = useMemo(() => {
    //     if (!oldSegMents) return [];
    //     return oldSegMents.map((segment) => {
    //         const updatedSegmentName = segment.segmentName.replace(/ - 49/g, "");
    //
    //         return {
    //             ...segment,
    //             segmentName: updatedSegmentName,
    //         };
    //     });
    // }, [oldSegMents]);

    const [parsedMappingRules, setParsedMappingRules] =
        useState<MappedSegmentTypes>();

    const venueNameById = useMemo(
        () =>
            segments
                ? chain(segments)
                      .filter(({ segmentType }) => segmentType === "venue")
                      .keyBy(
                          ({ integrationName, segmentId }) =>
                              `${integrationName}_${segmentId}`
                      )
                      .mapValues("segmentName")
                      .value()
                : undefined,
        [segments]
    );

    const fieldSettings = useMemo(
        () =>
            segments && venueNameById
                ? getFieldSettings({
                      segments,
                      venueNameById,
                      knownMappedRules: parsedMappingRules,
                      selectedSegment: mappingRule["segment"],
                  })
                : undefined,
        [venueNameById, segments, parsedMappingRules, mappingRule]
    );

    if (hasDuplicateValues(fieldSettings ?? {})) {
        openNotificationWithIcon({
            title: "Duplicate values found in field settings",
            type: "error",
        });
    }

    const config: any = useMemo(
        () =>
            fieldSettings
                ? {
                      ...defaultConfig,
                      operators: defaultOperators,
                      // We hide the Not operator
                      settings: { ...defaultConfig.settings, showNot: false },
                      fields: { ...fieldSettings },
                  }
                : undefined,
        [fieldSettings, defaultConfig]
    );

    const [ruleBuilder, setRuleBuilder] = useState(
        config
            ? QbUtils.checkTree(QbUtils.loadTree({ id, type: "group" }), config)
            : QbUtils.loadTree({ id, type: "group" })
    );

    const mappingRuleDirty = useMemo(
        () =>
            ruleBuilder
                ? !isEqual(existingMappingRule, mappingRule) ||
                  !isEqual(QbUtils.getTree(ruleBuilder), existingMappingRule?.rules)
                : false,
        [existingMappingRule, mappingRule, ruleBuilder]
    );

    const mappingRuleValid =
        ruleBuilder && config
            ? Boolean(mappingRule.segmentName) &&
              Boolean(mappingRule.segment) &&
              validateMappingObject({ tree: ruleBuilder, config })
            : false;

    const mappingRuleParsingResult = useMemo(() => {
        if (!mappingRuleValid || !ruleBuilder) {
            return;
        }

        return parseTreeLogic(ruleBuilder, config);
    }, [mappingRuleValid, ruleBuilder]);

    const saveMappingRule = () => {
        if (!mappingRuleValid || !ruleBuilder) {
            return;
        }

        if (!mappingRuleParsingResult) {
            return;
        }
        const completedMappingRule: MappingRule = {
            id: mappingRule.id!,
            segment: mappingRule.segment!,
            segmentName: mappingRule.segmentName!,
            rules: QbUtils.getTree(ruleBuilder),
            result: mappingRuleParsingResult,
            exclude: mappingRule.exclude,
        };
        if (existingMappingRule) {
            updateMappingRule(completedMappingRule);
        } else {
            createMappingRule(completedMappingRule);
        }
    };

    useEffect(() => {
        if (existingMappingRule) {
            setMappingRule(existingMappingRule);
        }
    }, [existingMappingRule, setMappingRule]);

    useEffect(() => {
        if (existingMappingRule?.rules && config) {
            setRuleBuilder(QbUtils.loadTree(existingMappingRule.rules));
        }
    }, [config, existingMappingRule?.rules, setRuleBuilder]);

    return {
        fetchingExistingMappingRule,
        creatingMappingRule,
        updatingMappingRule,
        config,
        mappingRule,
        setMappingRule,
        ruleBuilder,
        setRuleBuilder,
        mappingRuleDirty,
        saveMappingRule,
        mappingRuleValid,
        segments,
        venueNameById,
        mappingRuleParsingResult,
        parsedMappingRules,
        setParsedMappingRules,
    };
};
