import React, { useMemo } from "react";
import { Spin } from "antd";
import { SeriesRow } from "Api/Cube/types";
import { getGranularityForDateRange } from "Api/Cube/utils";
import { useGetSalesSeries } from "Hooks/useGetSalesAndStaffHours";
import {
    useDateRangeFilter,
    useComparisonFilter,
    useAggregateFilter,
    AggregateFilter,
} from "Store/filterStore";
import { DATE_TIME_FORMAT, formatForDisplay, parseToMoment } from "Utils/date-utils";
import {
    formatTooltipTimestampBasedOnGranularity,
    getCumulativeTransactionsLineGraphOptions,
} from "Utils/utils";
import Charts from "./ApexChartHandler";

interface Props {
    height: number;
}

const cumSumSeriesRows = (seriesRow: SeriesRow[]) =>
    seriesRow.reduce<Array<SeriesRow>>((result, seriesRow: SeriesRow, i) => {
        const { x, value = 0 } = seriesRow;

        if (!result.length) return [{ x, value }];

        const previousValue = result[i - 1].value ?? 0;
        result.push({ x, value: previousValue + value });

        return result;
    }, []);

export const CumulativeSalesChart: React.FC<Props> = ({ height }) => {
    const { selectedDateRange } = useDateRangeFilter();
    const {
        salesSeries,
        salesSeriesForComparison,
        averageSaleSeries,
        forecastSaleSeries,
        isLoading,
    } = useGetSalesSeries();

    const { selectedComparison } = useComparisonFilter();
    const { selectedAggregate } = useAggregateFilter();

    const cumulativeSalesSeries = useMemo(
        () =>
            salesSeries
                ? { ...salesSeries, series: cumSumSeriesRows(salesSeries.series) }
                : undefined,
        [salesSeries]
    );

    const cumulativeAverageSalesSeries = useMemo(
        () =>
            averageSaleSeries
                ? {
                      ...averageSaleSeries,
                      series: cumSumSeriesRows(averageSaleSeries.series),
                  }
                : undefined,
        [averageSaleSeries]
    );

    const cumulativeSalesSeriesForComparison = useMemo(
        () =>
            salesSeriesForComparison
                ? {
                      ...salesSeriesForComparison,
                      series: cumSumSeriesRows(salesSeriesForComparison.series),
                  }
                : forecastSaleSeries
                ? {
                      ...forecastSaleSeries,
                      series: cumSumSeriesRows(forecastSaleSeries.series),
                  }
                : undefined,
        [forecastSaleSeries, salesSeriesForComparison]
    );

    const chartOptions = useMemo(() => {
        if (!cumulativeSalesSeries && !averageSaleSeries) {
            return {};
        }

        const series =
            averageSaleSeries?.series.map(({ x }) => x) ??
            cumulativeSalesSeries?.series.map(({ x }) => x) ??
            [];

        const chartOptions = getCumulativeTransactionsLineGraphOptions(
            series,
            getGranularityForDateRange({
                start: parseToMoment(selectedDateRange.start)
                    .add(6, "hours")
                    .format(DATE_TIME_FORMAT),
                end: parseToMoment(selectedDateRange.end)
                    .add(1, "day")
                    .add(5, "hours")
                    .add(45, "minutes")
                    .format(DATE_TIME_FORMAT),
            })
        );

        if (averageSaleSeries) {
            const displayFormat =
                selectedAggregate === AggregateFilter.AverageWeek
                    ? "ddd, HH:mm"
                    : "HH:mm";

            chartOptions.xaxis = {
                type: "datetime",
                categories: averageSaleSeries.series.map((d) => d.x),
                labels: {
                    datetimeUTC: false,
                    formatter: (val) => formatForDisplay(val, displayFormat),
                },
            };
            chartOptions.tooltip = {
                x: {
                    formatter: (val) =>
                        formatTooltipTimestampBasedOnGranularity(
                            selectedAggregate === AggregateFilter.AverageWeek
                                ? "hour"
                                : "minute",
                            val,
                            true,
                            selectedDateRange.end
                        ),
                },
            };
        }

        return chartOptions;
    }, [
        averageSaleSeries,
        cumulativeSalesSeries,
        selectedAggregate,
        selectedDateRange,
    ]);

    const series = useMemo(() => {
        const comparisonLabel = selectedComparison ?? "";
        const currentPeriodSeries = {
            name: "Current Period",
            data:
                cumulativeAverageSalesSeries?.series.map(({ value = 0 }) => value) ??
                cumulativeSalesSeries?.series.map(({ value = 0 }) => value) ??
                [],
        };

        const xs = cumulativeSalesSeries?.series.map(({ x }) => x.split("T")[1]);
        let comparedSeries =
            cumulativeSalesSeriesForComparison && cumulativeSalesSeries
                ? {
                      name: comparisonLabel,
                      data:
                          cumulativeSalesSeriesForComparison?.series
                              .filter(({ x }) => xs!.includes(x.split("T")[1]))
                              .map(({ value = 0 }) => value) ?? [],
                  }
                : undefined;

        if (comparedSeries) {
            // In scenario where we are provided with a forecast of length greater than the current Actual data we trim the forecast back so that it isn't showing the future
            if (comparedSeries.data.length !== currentPeriodSeries.data.length) {
                const lengthDiff =
                    comparedSeries.data.length - currentPeriodSeries.data.length;

                comparedSeries = {
                    ...comparedSeries,
                    data: comparedSeries.data.filter(
                        (x, i) => lengthDiff + i < comparedSeries!.data.length
                    ),
                };
            }
        }

        const result = [currentPeriodSeries];
        if (comparedSeries) {
            result.push(comparedSeries);
        }

        return result;
    }, [
        cumulativeAverageSalesSeries,
        cumulativeSalesSeries,
        cumulativeSalesSeriesForComparison,
        selectedComparison,
    ]);

    return (
        <div style={{ height }}>
            <Spin style={{ height }} spinning={isLoading}>
                {!isLoading && (
                    <Charts
                        options={chartOptions}
                        series={series}
                        height={height}
                        type={"area"}
                    />
                )}
            </Spin>
        </div>
    );
};
