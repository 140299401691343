import React, { ReactElement } from "react";
import { Tabs } from "antd-mobile";
import CumulativeTransactionsLineGraph from "../HomeDashboardDesktop/CumulativeTransactionsLineGraph";
import SalesOverTimeLineGraph from "../HomeDashboardDesktop/SalesOverTimeLineGraph";
import { usePreviewMode } from "Store/viewSettingsStore";
import { SalesOverTimeLineChart } from "Pages/Dashboard/Components/Charts/SalesOverTimeChart";
import { CumulativeSalesChart } from "Pages/Dashboard/Components/Charts/CummulativeSalesChart";

export default function TabulatedGraphsMobile(): ReactElement {
    return (
        <Tabs>
            <Tabs.Tab title="Cumulative Sales" key="1">
                <CumulativeSalesChart height={200} />
            </Tabs.Tab>
            <Tabs.Tab title="Sales over Time" key="2">
                <SalesOverTimeLineChart height={200} />
            </Tabs.Tab>
        </Tabs>
    );
}
