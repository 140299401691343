import { Button, Modal, Result } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RoutePaths } from "./Router/Routes";
import { clearAuthTokens } from "Api/auth/auth0API";

export const TokenErrorModal = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const router = useHistory();
    const location = useLocation();

    const handleGoback = () => {
        router.push(RoutePaths.LOGIN);
        setVisible(false);
    };

    const handleTokenExpire = () => {
        if (location.pathname !== RoutePaths.LOGIN) {
            clearAuthTokens();
        }
    };
    useEffect(() => {
        window.addEventListener("token-expire", handleTokenExpire);
        return () => window.removeEventListener("token_expire", handleTokenExpire);
    }, []);

    useEffect(() => {
        if (location.pathname === RoutePaths.LOGIN) setVisible(false);
    }, [location]);

    return (
        <Modal
            title=""
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
            destroyOnClose
            style={{ top: 30, borderRadius: "12px" }}
            bodyStyle={{ padding: "0px 24px 20px 24px" }}
            zIndex={100000}
            cancelButtonProps={{ style: { display: "none" } }}
            footer={null}
            width={"80%"}
        >
            <Result
                title="Session expired, please log in again."
                extra={
                    <Button type="primary" key="console" onClick={handleGoback}>
                        {location.pathname !== RoutePaths.LOGIN
                            ? "Go back to Login"
                            : "Continue"}
                    </Button>
                }
            />
        </Modal>
    );
};
