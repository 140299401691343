import { BACKEND_API_URL } from "../Utils/constants";
import { getAuthTokens } from "./auth0";
import { GroupData, isErrorObject } from "./backend";

class GroupDataManager {
    private static instance: GroupDataManager;
    private subscribers: Array<(data: GroupData) => void> = [];

    private constructor() {
        console.log("GroupDataManager constructor");
    }

    public static getInstance(): GroupDataManager {
        if (!GroupDataManager.instance) {
            GroupDataManager.instance = new GroupDataManager();
        }

        return GroupDataManager.instance;
    }

    public subscribe(callback: (data: GroupData) => void): void {
        this.subscribers.push(callback);
    }

    public unsubscribe(callback: (data: GroupData) => void): void {
        this.subscribers = this.subscribers.filter((sub) => sub !== callback);
    }

    public fetchGroupData(onError?: () => void): Promise<GroupData> {
        return getAuthTokens()
            .then((data) => {
                const access_token = data?.access_token;
                if (access_token === null) {
                    throw new Error("No token");
                }

                const myHeaders = new Headers();
                myHeaders.append("Authorization", `bearer ${access_token}`);

                const requestOptions: RequestInit = {
                    method: "GET",
                    headers: myHeaders,
                };

                const url = `${BACKEND_API_URL}/v2/group/data`;
                return fetch(url, requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then((data) => {
                        if (isErrorObject(data)) {
                            if (onError) onError();
                            return [];
                        }

                        this.subscribers.forEach((callback) => callback(data));
                        return data;
                    })
                    .catch((error) => {
                        throw error;
                    });
            })
            .catch((e) => {
                if (onError) {
                    onError();
                }
                throw e;
            });
    }
}

export const GroupDataInstance = GroupDataManager.getInstance();
