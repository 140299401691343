import DateFilter from "../DateFilter";
import { Col, Divider, Row, Select, Typography } from "antd";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { WRANGLR_DARK_BLUE } from "../../Utils/constants";
import ViewSelect from "../ViewSelect";
import ComparisonFilter, { ComparisonFilterPreview } from "../ComparisonFilter";
import TimeSlider from "../TimeSlider";
import CheckboxManager from "./CheckboxManager";
import ViewsButtonGroup from "../ViewsButtonGroup";
import DeleteViewButton from "../ViewSelect/DeleteViewButton";
import ForecastComparisonSelector from "../ComparisonFilter/ForecastComparisonSelector";
import AggregateFilter from "Components/AggregateFilter";
import { usePreviewMode } from "Store/viewSettingsStore";
export const ReducedMarginDivider = styled(Divider)`
    margin: 12px 0 !important;
`;

export const SideNavFilterTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
    width: 100%;
`;

export const FullWidthSelect = styled(Select)`
    width: 100%;
`;

export const FullWidthViewSelect = styled(ViewSelect)`
    width: 100%;
`;

export default function SideNavFilters(): ReactElement {
    const { previewMode } = usePreviewMode();
    return (
        <>
            <Row gutter={4}>
                <SideNavFilterTitle level={5}>Views</SideNavFilterTitle>
                <Col span={20}>
                    <FullWidthViewSelect mode={"desktop"} />
                </Col>
                <Col span={4}>
                    <DeleteViewButton />
                </Col>
            </Row>
            <ReducedMarginDivider />
            <Row>
                <SideNavFilterTitle level={5}>Dates</SideNavFilterTitle>
                <DateFilter />
            </Row>
            <ReducedMarginDivider />
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <SideNavFilterTitle level={5}>Comparisons</SideNavFilterTitle>
                    {previewMode ? (
                        <ComparisonFilterPreview />
                    ) : (
                        <ComparisonFilter />
                    )}
                </Col>
                <Col span={24}>
                    <ForecastComparisonSelector />
                </Col>
            </Row>
            <ReducedMarginDivider />
            <Row style={{ marginTop: "10px" }}>
                <Col span={24}>
                    <SideNavFilterTitle level={5}>Aggregate</SideNavFilterTitle>
                    <AggregateFilter />
                </Col>
            </Row>
            <ReducedMarginDivider />
            <Row>
                <Col span={24}>
                    <SideNavFilterTitle level={5}>Time</SideNavFilterTitle>
                    <TimeSlider />
                </Col>
            </Row>
            <ReducedMarginDivider />
            <CheckboxManager />
            <Row>
                <Col span={24}>
                    <ViewsButtonGroup />
                </Col>
            </Row>
        </>
    );
}
