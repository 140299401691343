import { Segments } from "Api/backend";
import {
    CubeQueryBuilder,
    getFilterSet,
    getMeasures,
    getOrdering,
    getCubeName,
    getTimeFilter,
} from "Api/Cube/utils";
import cloneDeep from "lodash/cloneDeep";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { Context, Filters } from "State/store";
import {
    DateRange,
    DATE_TIME_FORMAT,
    DEFAULT_DATE_FORMAT,
    parseToMoment,
} from "Utils/date-utils";
import { useGetLastCutOffTime } from "./useGetLastCutOffTime";
import { CubeContext } from "@cubejs-client/react";

export const SALE_AND_STAFF_DATA_QUERY_KEY = "SALE_AND_STAFF_DATA_QUERY_KEY";

interface TotalSalesAndStaff {
    name: string;
    totalSales: number;
    totalStaff: number;
}

type SalesAndStaffDataBySegment = {
    [name: string]: TotalSalesAndStaff;
};

export interface GetSaleAndStaffDataByDatesParams {
    breakdownBySegment?: "Venue" | "Area" | "Class";
    dateRange?: DateRange<string>;
    enabled?: boolean;
    useForecastData?: boolean;
    useExactTime?: boolean;
}

export interface GetSaleAndStaffDataByDatesResponse {
    isLoading?: boolean;
    totalSalesAndStaff?: {
        totalSales: number;
        totalStaff: number;
    };
    totalSalesAndStaffBySegment?: {
        Area: SalesAndStaffDataBySegment;
        Venue: SalesAndStaffDataBySegment;
        Class: SalesAndStaffDataBySegment;
    };
}

const getFilterConditions = (filters: Filters) => {
    const selectedVenues = filters.selectedVenues.map(
        ({ primary_id }) => primary_id
    );
    const selectedClasses = filters.selectedClasses.map(
        ({ primary_id }) => primary_id
    );
    const selectedAreas = filters.selectedAreas.map(({ primary_id }) => primary_id);

    const includeAll = Boolean(
        !selectedAreas.length && !selectedClasses.length && !selectedVenues.length
    );

    const mustHaveVenues = Boolean(
        selectedVenues.length && !selectedAreas.length && !selectedClasses.length
    );
    const mustHaveAreas = Boolean(
        !selectedVenues.length && selectedAreas.length && !selectedClasses.length
    );
    const mustHaveAreasAndClasses = Boolean(
        !selectedVenues.length && selectedAreas.length && selectedClasses.length
    );
    const mustHaveVenuesAndAreas = Boolean(
        selectedVenues.length && selectedAreas.length && !selectedClasses.length
    );
    const mustHaveVenuesAndAreasAndClasses = Boolean(
        selectedVenues.length && selectedAreas.length && selectedClasses.length
    );

    const mustHaveClasses = Boolean(
        !selectedVenues.length && !selectedAreas.length && selectedClasses.length
    );

    const mustHaveVenuesAndClasses = Boolean(
        selectedVenues.length && !selectedAreas.length && selectedClasses.length
    );

    return {
        includeAll,
        mustHaveVenues,
        mustHaveAreas,
        mustHaveAreasAndClasses,
        mustHaveVenuesAndAreas,
        mustHaveVenuesAndAreasAndClasses,
        mustHaveClasses,
        mustHaveVenuesAndClasses,
        selectedVenues,
        selectedClasses,
        selectedAreas,
    };
};

const getInitialSaleAndStaffDataBySegment = ({
    areas,
    venues,
    classes,
}: Segments) => ({
    Area: areas.reduce<SalesAndStaffDataBySegment>((result, { primary_id }) => {
        result[primary_id] = {
            name: primary_id,
            totalSales: 0,
            totalStaff: 0,
        };
        return result;
    }, {}),
    Venue: venues.reduce<SalesAndStaffDataBySegment>((result, { primary_id }) => {
        result[primary_id] = {
            name: primary_id,
            totalSales: 0,
            totalStaff: 0,
        };
        return result;
    }, {}),
    Class: classes.reduce<SalesAndStaffDataBySegment>((result, { primary_id }) => {
        result[primary_id] = {
            name: primary_id,
            totalSales: 0,
            totalStaff: 0,
        };
        return result;
    }, {}),
});

const getQuery = (params: {
    filters: Filters;
    dateRange?: DateRange<string>;
    useForecastData?: boolean;
    cutOffTime?: { hours: number; minutes: number };
    cubeName?: string;
}) => {
    const {
        filters,
        dateRange,
        useForecastData,
        cutOffTime,
        cubeName = "actuals",
    } = params;

    const {
        selectedDates: { fromDate, toDate },
        datasetName,
    } = filters;

    let start = dateRange?.start ?? fromDate;
    let end = dateRange?.end ?? toDate;

    if (cutOffTime) {
        start = parseToMoment(start).hours(6).format(DATE_TIME_FORMAT);
        end = parseToMoment(end)
            .hours(cutOffTime.hours)
            .minutes(cutOffTime.minutes)
            .format(DATE_TIME_FORMAT);
    }

    const mappedClassKey = `${cubeName}.mappedClass`;
    const mappedVenueKey = `${cubeName}.mappedVenue`;
    const mappedAreaKey = `${cubeName}.mappedArea`;

    return CubeQueryBuilder()
        .addMeasures(
            getMeasures({
                filters,
                ignoreGranularityForActiveStaff: true,
                addActiveStaff: true,
                salesType: useForecastData ? "forecast" : "actual",
                cubeName,
            })
        )
        .addOrder(getOrdering({ ...filters, cubeName }))
        .addFilters(
            getFilterSet({
                cubeName,
                filters: {
                    ...filters,
                    selectedAreas: [],
                    selectedClasses: [],
                    selectedVenues: [],
                },
            })
        )
        .addFilters([
            getTimeFilter({
                start,
                end,
                cubeName,
                useExactDateAndTime: Boolean(cutOffTime),
            }),
        ])
        .addDimensions([mappedClassKey, mappedVenueKey, mappedAreaKey])
        .getResult();
};

export const useGetTotalSalesAndStaffHoursByDates = ({
    breakdownBySegment,
    dateRange,
    enabled = true,
    useForecastData,
}: GetSaleAndStaffDataByDatesParams = {}) => {
    const [{ filters, groupData }] = useContext(Context);
    const { cubejsApi } = useContext(CubeContext);

    if (!groupData) {
        throw new Error("No Group Data, this should be impossible");
    }

    const initialSalesAndStaffBySegment = getInitialSaleAndStaffDataBySegment(
        groupData.segments
    );

    const {
        selectedDates: { fromDate, toDate },
    } = filters;
    const today = parseToMoment();

    const cubeName = getCubeName(
        fromDate,
        toDate,
        today.format(DEFAULT_DATE_FORMAT)
    );

    const mappedClassKey = `${cubeName}.mappedClass`;
    const mappedVenueKey = `${cubeName}.mappedVenue`;
    const mappedAreaKey = `${cubeName}.mappedArea`;
    const activeStaffKey = `${cubeName}.activeStaffHourly`;
    const transactionTotalKey = `${cubeName}.transactionTotal`;
    const forecastTransactionTotalKey = `forecasts.forecastTransactionTotal`;

    const { isLoading: calculatingCutOffTime, cutOffTime } = useGetLastCutOffTime();

    const query = getQuery({
        filters,
        dateRange,
        useForecastData,
        cutOffTime,
        cubeName,
    });

    const { isLoading, data, refetch, isFetching } = useQuery(
        [SALE_AND_STAFF_DATA_QUERY_KEY, query],
        () => cubejsApi.load(query),
        {
            select: (result) =>
                result
                    .rawData()
                    .filter(
                        (d) =>
                            d[mappedAreaKey] &&
                            d[mappedClassKey] &&
                            d[mappedVenueKey]
                    )
                    .map((d) => ({
                        transactionTotal:
                            d[transactionTotalKey] ?? d[forecastTransactionTotalKey],
                        activeStaffHourly: d[activeStaffKey],
                        area: d[mappedAreaKey],
                        class: d[mappedClassKey],
                        venue: d[mappedVenueKey],
                    })),
            enabled: enabled || !calculatingCutOffTime,
        }
    );

    const filterConditions = useMemo(() => getFilterConditions(filters), [filters]);

    const totalSalesAndStaff = useMemo(() => {
        if (!data || !enabled) return undefined;

        const {
            includeAll,
            mustHaveVenues,
            mustHaveAreas,
            mustHaveAreasAndClasses,
            mustHaveClasses,
            mustHaveVenuesAndAreas,
            mustHaveVenuesAndAreasAndClasses,
            mustHaveVenuesAndClasses,
            selectedAreas,
            selectedClasses,
            selectedVenues,
        } = filterConditions;

        return data.reduce(
            (result, d) => {
                const haveVenueInFilter =
                    mustHaveVenues && selectedVenues.includes(d.venue);

                const haveAreaInFilter =
                    mustHaveAreas && selectedAreas.includes(d.area);

                const haveClassInFilter =
                    mustHaveClasses && selectedClasses.includes(d.class);

                const haveVenueAndAreaInFilter =
                    mustHaveVenuesAndAreas &&
                    selectedVenues.includes(d.venue) &&
                    selectedAreas.includes(d.area);

                const haveAreaAndClassInFilter =
                    mustHaveAreasAndClasses &&
                    selectedAreas.includes(d.area) &&
                    selectedClasses.includes(d.class);

                const haveVenueAndClassInFilter =
                    mustHaveVenuesAndClasses &&
                    selectedVenues.includes(d.venue) &&
                    selectedClasses.includes(d.class);

                const haveVenueAndAreaAndClassInFilter =
                    mustHaveVenuesAndAreasAndClasses &&
                    selectedVenues.includes(d.venue) &&
                    selectedAreas.includes(d.area) &&
                    selectedClasses.includes(d.class);

                if (
                    includeAll ||
                    haveVenueInFilter ||
                    haveVenueAndAreaInFilter ||
                    haveVenueAndAreaAndClassInFilter ||
                    haveAreaInFilter ||
                    haveAreaAndClassInFilter ||
                    haveClassInFilter ||
                    haveVenueAndClassInFilter
                ) {
                    result.totalSales += d.transactionTotal;
                    result.totalStaff += d.activeStaffHourly;
                    return result;
                }

                return result;
            },
            {
                totalSales: 0,
                totalStaff: 0,
            }
        );
    }, [data, enabled, filterConditions]);

    const totalSalesAndStaffBySegment = useMemo(() => {
        if (!data || !breakdownBySegment || !enabled) return undefined;

        const {
            includeAll,
            mustHaveVenues,
            mustHaveAreas,
            mustHaveAreasAndClasses,
            mustHaveClasses,
            mustHaveVenuesAndAreas,
            mustHaveVenuesAndAreasAndClasses,
            mustHaveVenuesAndClasses,
            selectedAreas,
            selectedClasses,
            selectedVenues,
        } = filterConditions;

        return data.reduce((result, d) => {
            if (includeAll) {
                if (
                    !result["Venue"][d.venue] ||
                    !result["Area"][d.area] ||
                    !result["Class"][d.class]
                ) {
                    return result;
                }
                result["Venue"][d.venue].totalSales += d.transactionTotal;
                result["Venue"][d.venue].totalStaff += d.activeStaffHourly;
                result["Area"][d.area].totalSales += d.transactionTotal;
                result["Area"][d.area].totalStaff += d.activeStaffHourly;
                result["Class"][d.class].totalSales += d.transactionTotal;
                result["Class"][d.class].totalStaff += d.activeStaffHourly;
                return result;
            }

            if (breakdownBySegment === "Venue") {
                if (
                    (mustHaveVenuesAndAreasAndClasses &&
                        selectedAreas.includes(d.area) &&
                        selectedClasses.includes(d.class)) ||
                    mustHaveVenues ||
                    (mustHaveAreas && selectedAreas.includes(d.area)) ||
                    (mustHaveClasses && selectedClasses.includes(d.class)) ||
                    (mustHaveVenuesAndAreas && selectedAreas.includes(d.area)) ||
                    (mustHaveVenuesAndClasses &&
                        selectedClasses.includes(d.class)) ||
                    (mustHaveAreasAndClasses &&
                        selectedAreas.includes(d.area) &&
                        selectedClasses.includes(d.class))
                ) {
                    if (!result["Venue"][d.venue]) {
                        return result;
                    }

                    result["Venue"][d.venue].totalSales += d.transactionTotal;
                    result["Venue"][d.venue].totalStaff += d.activeStaffHourly;
                    return result;
                }
            } else if (breakdownBySegment === "Area") {
                if (
                    (mustHaveVenuesAndAreasAndClasses &&
                        selectedVenues.includes(d.venue) &&
                        selectedClasses.includes(d.class)) ||
                    (mustHaveVenues && selectedVenues.includes(d.venue)) ||
                    mustHaveAreas ||
                    (mustHaveClasses && selectedClasses.includes(d.class)) ||
                    (mustHaveVenuesAndAreas && selectedVenues.includes(d.venue)) ||
                    (mustHaveVenuesAndClasses &&
                        selectedClasses.includes(d.class) &&
                        selectedVenues.includes(d.venue)) ||
                    (mustHaveAreasAndClasses && selectedClasses.includes(d.class))
                ) {
                    if (!result["Area"][d.area]) {
                        return result;
                    }
                    result["Area"][d.area].totalSales += d.transactionTotal;
                    result["Area"][d.area].totalStaff += d.activeStaffHourly;
                    return result;
                }
            } else if (breakdownBySegment === "Class") {
                if (
                    (mustHaveVenuesAndAreasAndClasses &&
                        selectedVenues.includes(d.venue) &&
                        selectedAreas.includes(d.area)) ||
                    (mustHaveVenues && selectedVenues.includes(d.venue)) ||
                    (mustHaveAreas && selectedAreas.includes(d.area)) ||
                    mustHaveClasses ||
                    (mustHaveVenuesAndAreas &&
                        selectedVenues.includes(d.venue) &&
                        selectedAreas.includes(d.area)) ||
                    (mustHaveVenuesAndClasses && selectedVenues.includes(d.venue)) ||
                    (mustHaveAreasAndClasses && selectedAreas.includes(d.area))
                ) {
                    if (!result["Class"][d.class]) {
                        return result;
                    }
                    result["Class"][d.class].totalSales += d.transactionTotal;
                    result["Class"][d.class].totalStaff += d.activeStaffHourly;
                    return result;
                }
            }

            return result;
        }, cloneDeep(initialSalesAndStaffBySegment));
    }, [data, breakdownBySegment, enabled, filterConditions]);

    return {
        isLoading: isLoading || calculatingCutOffTime,
        totalSalesAndStaff,
        totalSalesAndStaffBySegment,
        refetch,
        isFetching,
    };
};
