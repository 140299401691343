import { Select } from "antd";
import omit from "lodash/omit";
import React, { useEffect, useState } from "react";
import { ListValue } from "./MultiSelectWidget";

const SELECT_WIDTH_OFFSET_RIGHT = 48;
const DEFAULT_FONT_SIZE = "14px";
const DEFAULT_FONT_FAMILY = "'Helvetica Neue', Helvetica, Arial, sans-serif";

export const calcTextWidth = function (
    str: string,
    fontFamily = DEFAULT_FONT_FAMILY,
    fontSize = DEFAULT_FONT_SIZE
) {
    let div = document.createElement("div");
    div.innerHTML = str;
    const css = {
        position: "absolute",
        float: "left",
        "white-space": "nowrap",
        visibility: "hidden",
        "font-size": fontSize,
        "font-family": fontFamily,
    };
    for (const k in css) {
        div.style[k] = css[k];
    }
    div = document.body.appendChild(div);
    const w = div.offsetWidth;
    document.body.removeChild(div);
    return w;
};

const isObject = (v: any) => typeof v == "object" && v !== null && !Array.isArray(v);

const listValue = (v: any, title?: string) =>
    isObject(v) ? v : { value: v, title: title !== undefined ? title : v };

export const mapListValues = (
    listValues: any[] | any,
    mapFn: ({ title, value, disabled, count, warning }: ListValue) => any
) => {
    const ret: any[] = [];
    if (Array.isArray(listValues)) {
        for (const v of listValues) {
            const lv = mapFn(listValue(v));
            if (lv != null) ret.push(lv);
        }
    } else {
        for (const value in listValues) {
            const lv = mapFn(listValue(value, listValues[value]));
            if (lv != null) ret.push(lv);
        }
    }
    return ret;
};

const Option = Select.Option;

const SelectWidget = ({
    setValue,
    config,
    field,
    value, //key in listValues
    customProps,
    fieldDefinition,
    readonly,
    placeholder,
    // from fieldSettings:
    listValues,
}: {
    setValue: (value: boolean) => void;
    config: any;
    field: string;
    value: string | number; //key in listValues
    customProps: any;
    fieldDefinition: any;
    readonly: boolean;
    placeholder: any;
    // from fieldSettings:
    listValues: any[];
}) => {
    const [options, setOptions] = useState<any>(undefined);
    const [optionsMaxWidth, setOptionsMaxWidth] = useState<number>(0);

    useEffect(() => {
        let tempOptionsMaxWidth = 0;
        mapListValues(listValues, ({ title, value }) => {
            tempOptionsMaxWidth = Math.max(optionsMaxWidth, calcTextWidth(title));
        });
        setOptionsMaxWidth(tempOptionsMaxWidth);
        setOptions(
            mapListValues(listValues, ({ title, value }) => {
                return (
                    <Option key={value + ""} value={value + ""}>
                        {title}
                    </Option>
                );
            })
        );
    }, []);
    const handleChange = (val) => {
        setValue(val);
    };

    const filterOption = (input, option) => {
        const dataForFilter = option.children || option.value;
        return dataForFilter.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const { renderSize } = config.settings;
    const placeholderWidth = calcTextWidth(placeholder);
    const dropdownWidth = optionsMaxWidth + SELECT_WIDTH_OFFSET_RIGHT;
    const width = value
        ? dropdownWidth
        : placeholderWidth + SELECT_WIDTH_OFFSET_RIGHT;
    const aValue = value != undefined ? value + "" : undefined;
    const customSelectProps = omit(customProps, [""]);

    return (
        <Select
            disabled={readonly}
            style={{ width }}
            key={"widget-select"}
            dropdownMatchSelectWidth={false}
            placeholder={placeholder}
            size={renderSize}
            value={aValue}
            onChange={handleChange}
            filterOption={filterOption}
            {...customSelectProps}
        >
            {options}
        </Select>
    );
};

export { SelectWidget };
