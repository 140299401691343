import { CubeQueryBuilder, getFilterSet, getTimeFilter } from "Api/Cube/utils";
import { useContext, useMemo } from "react";
import { Context } from "State/store";
import { Aggregate } from "Components/AggregateFilter";
import { DEFAULT_DATE_FORMAT, parseToMoment } from "Utils/date-utils";
import { useCubeQuery } from "@cubejs-client/react";

export const TOTAL_SALES_BY_DATE_QUERY_KEY = "SalesByDate";

export const useGetMedianSalesDates = () => {
    const [{ filters }] = useContext(Context);

    const {
        selectedDates: { fromDate: start, toDate: end },
        datasetName,
        selectedAggregate,
    } = filters;

    const transactionTotalKey = `actuals.transactionTotal`;
    const localisedPeriodKey = `actuals.period`;

    const medianAggregationSelected =
        selectedAggregate === Aggregate.MEDIAN_DAY ||
        selectedAggregate === Aggregate.MEDIAN_WEEK;

    const timeDimensions: any = [
        {
            dimension: localisedPeriodKey,
            granularity: selectedAggregate === Aggregate.MEDIAN_DAY ? "day" : "week",
            dateRange: [
                start,
                parseToMoment(end).add(1, "day").format(DEFAULT_DATE_FORMAT),
            ],
        },
    ];

    const query = CubeQueryBuilder()
        .addMeasures([transactionTotalKey])
        .addTimeDimensions(timeDimensions)
        .addFilters(getFilterSet({ filters, cubeName: "actuals" }))
        .addFilters([getTimeFilter({ start, end, cubeName: "actuals" })])
        .addOrder({
            [localisedPeriodKey]: "asc",
        })
        .getResult();

    const { resultSet, isLoading, error, progress, refetch } = useCubeQuery<any>(
        query,
        { skip: !medianAggregationSelected }
    );

    // const { isLoading, data } = useQuery(
    //     [TOTAL_SALES_BY_DATE_QUERY_KEY, query],
    //     () => cubejsApi.load(query),
    //     {
    //         enabled: medianAggregationSelected,
    //     }
    // );

    const periodsWithMedianSales = useMemo(() => {
        if (!resultSet) {
            return undefined;
        }

        const rawData = resultSet.rawData();

        if (rawData.length === 1 || start === end) {
            return { start, end };
        }

        // Because the last date is part of the second last date, i.e 6AM -> 6AM
        const mergedData =
            selectedAggregate === Aggregate.MEDIAN_DAY
                ? rawData.reduce<any[]>((result, d, i) => {
                      if (i === rawData.length - 1) {
                          return result;
                      }

                      if (i === rawData.length - 2) {
                          return [
                              ...result,
                              {
                                  ...d,
                                  [transactionTotalKey]:
                                      d[transactionTotalKey] +
                                      rawData[i + 1][transactionTotalKey],
                              },
                          ];
                      }

                      return [...result, d];
                  }, [])
                : rawData;

        mergedData.sort((a, b) => a[transactionTotalKey] - b[transactionTotalKey]);

        const startMoment = parseToMoment(
            mergedData[Math.floor(mergedData.length / 2)][localisedPeriodKey]
        );

        const endMoment =
            selectedAggregate === Aggregate.MEDIAN_DAY
                ? startMoment.clone()
                : startMoment.clone().add(6, "days");

        return {
            start: startMoment.format(DEFAULT_DATE_FORMAT),
            end: endMoment.format(DEFAULT_DATE_FORMAT),
        };
    }, [resultSet, filters]);

    return {
        isLoading,
        periodsWithMedianSales,
    };
};
