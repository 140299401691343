import cubejs, { CubejsApi, HttpTransport } from "@cubejs-client/core";
import { getCubeJsToken } from "../../Api/backend";
import React, { ReactElement, useEffect, useState } from "react";
import { CubeProvider } from "@cubejs-client/react";
import { EnvUtils } from "Utils/env-utils";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { useLocation } from "react-router-dom";
import { useAuth } from "@hooks/useAuth";

interface Props {
    children: ReactElement;
}

export default function CubeJsProvider(props: Props): ReactElement {
    const [cubejsApi, setCubejsApi] = useState<CubejsApi | undefined>();
    const [isCubeJsLoading, setIsCubeJsLoading] = useState(true);
    const location = useLocation();
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        // If the user was to navigate from an un-protected screen (e.g. Login page) to a protected screen (e.g. Home page) this will ensure that the CubeJs instance is initialised.
        if (cubejsApi === undefined)
            getCubeJsToken()
                .then((token) => {
                    if (token !== undefined && token !== null)
                        setCubejsApi(
                            cubejs({
                                apiUrl: EnvUtils.isTesting
                                    ? "http://localhost:4000/cubejs-api/v1"
                                    : (process.env
                                          .REACT_APP_CUBEJS_BACKEND_API_URL as string),

                                transport: new HttpTransport({
                                    method: "POST",
                                    apiUrl: EnvUtils.isTesting
                                        ? "http://localhost:4000/cubejs-api/v1"
                                        : (process.env
                                              .REACT_APP_CUBEJS_BACKEND_API_URL as string),

                                    authorization: token ?? "",
                                }),
                            })
                        );

                    setIsCubeJsLoading(false);
                })
                .catch((tokenError) => {
                    console.error({ tokenError });
                });
    }, [location, isLoggedIn]);

    if (isCubeJsLoading) return <QuantacoLoader size={135} />;

    if (!cubejsApi) {
        // console.log("CUBE JS UNAVAILABLE: NO TOKEN");
        return props.children;
    }

    return <CubeProvider cubejsApi={cubejsApi}>{props.children}</CubeProvider>;
}
