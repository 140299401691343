import { Row, Col, Button } from "antd";
import { RoundedCard } from "Components/HomeDashboardDesktop";
import React from "react";
import { TitleWithQuantacoColor } from "styles/TitleWithQuantacoColor";

interface Props {
    message: string;
    onCreate?: () => void;
}

export const LoadingCard: React.FC<Props> = ({ message, onCreate }) => {
    return (
        <RoundedCard style={{ minHeight: 300 }} bodyStyle={{ height: "270px" }}>
            <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
                <Col>
                    <TitleWithQuantacoColor level={4}>
                        {message}
                        {onCreate ? (
                            <Button type="link" onClick={onCreate}>
                                Click here to create
                            </Button>
                        ) : null}
                    </TitleWithQuantacoColor>
                </Col>
            </Row>
        </RoundedCard>
    );
};
