import React, { ReactElement, useState } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DriverModal } from "./DriverModal";

export const DriverModalButton = (): ReactElement => {
    const [modalVisible, setModalVisible] = useState(false);
    const onButtonClick = () => setModalVisible(true);

    return (
        <>
            {modalVisible && (
                <DriverModal visible={modalVisible} setVisible={setModalVisible} />
            )}
            <Button onClick={onButtonClick} type={"primary"} shape={"round"}>
                Add <PlusOutlined />
            </Button>
        </>
    );
};
