import { getUserToken, isAuthenticated } from "./auth/auth";
import { refreshAuthToken } from "./auth/backend-auth";
import { AuthTokens, AuthTokensInLocalstorage } from "./auth/types";

/**  Gets the current access-token/id-token.
 *   Automatically refreshes the token in the events of expiry
 */
export const getAuthTokens = async (): Promise<AuthTokensInLocalstorage | null> => {
    const access_token = getUserToken(AuthTokens.accessToken);
    const autheticated = isAuthenticated();
    const email = getUserToken(AuthTokens.email);

    if (access_token && autheticated && email) {
        return Promise.resolve({ access_token, email });
    } else {
        // TODO refresh Token
        return await refreshAuthToken()
            .then((data) => {
                if (data) {
                    //TO BE TESTED
                    return data;
                }
                return null;
            })
            .catch((error) => error);
    }
};

export const getRefreshToken = () => {
    const refreshToken = getUserToken(AuthTokens.refreshToken);
    return refreshToken ? refreshToken : null;
};
