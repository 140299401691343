import React, { useContext, useEffect } from "react";
import { HomeDashboardDesktop } from "../Components/HomeDashboardDesktop";
import BaseDesktopPage from "./BaseDesktopPage";
import { Context } from "State/store";
import { WENT_TO_HOME_PAGE } from "usage-events";
import { useIsMobile } from "Components/RedirectMobileWrapper/RedirectMobileWrapper";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { MobileHomeScreen } from "Components/Mobile/MobileHomeScreen/MobileHomeScreen";
import { useGtag } from "Utils/firebase/GTag";
import { useReturnWidget } from "Utils/widget";

const HomePage = () => {
    useReturnWidget();
    document.title = "Salesline - Home";
    const { isMobile } = useIsMobile();
    const [{ groupData }] = useContext(Context);

    useGtag({
        groupId: groupData?.group.groupId,
        groupName: groupData?.group.groupName,
        userId: groupData?.user.user_id,
        userName: groupData?.user.name,
    });

    return (
        <>
            {isMobile === undefined ? (
                <QuantacoLoader size={135} />
            ) : isMobile === true ? (
                <MobileHomeScreen />
            ) : (
                <BaseDesktopPage page={"home"}>
                    <HomeDashboardDesktop />
                </BaseDesktopPage>
            )}
        </>
    );
};

export default HomePage;
