import React, { useCallback, useMemo } from "react";
import { Context, Forecast } from "../State/store";
import { useParams } from "react-router-dom";
import { UpdateForecastAction } from "../State/actions";
import { applyForecastWithMode } from "../Api/backend";
import { ForecastPageParams } from "../Components/ForecastingDashboardDesktop";
import { BaselineRawRow } from "../Components/ForecastingChartsDataManager/BaselineChartForAutogen";

interface UseCurrentForecast {
    forecast: Forecast;
    setCurrentForecast: (forecast: Forecast) => void;
    getBaselineData: (previewMode: boolean) => Promise<BaselineRawRow[]>;
    getDriversData: (previewMode: boolean) => Promise<BaselineRawRow[]>;
    getAutogenData: (previewMode: boolean) => Promise<BaselineRawRow[]>;
    getAutogenDriversData: (previewMode: boolean) => Promise<BaselineRawRow[]>;
}

const useCurrentForecast = (): UseCurrentForecast => {
    // Initialize the state
    const [state, dispatch] = React.useContext(Context);
    if (!state.groupData) {
        throw new Error("Group Data not found, this should be impossible");
    }
    const forecasts = useMemo(() => state.groupData?.forecasts, [state]);
    const { id } = useParams<ForecastPageParams>();
    const forecast: Forecast = useMemo(
        () => forecasts!.find((forecast) => forecast.forecastId === id)!,
        [forecasts]
    );
    const setCurrentForecast = useCallback((forecast: Forecast) => {
        const updateForecastAction: UpdateForecastAction = {
            type: "UPDATE_FORECAST",
            payload: {
                forecast,
            },
        };
        dispatch(updateForecastAction);
    }, []);

    const getBaselineData = useCallback((previewMode: boolean) => {
        return applyForecastWithMode(forecast.forecastId, "baseline", previewMode);
    }, []);

    const getDriversData = useCallback((previewMode: boolean) => {
        return applyForecastWithMode(forecast.forecastId, "drivers", previewMode);
    }, []);

    const getAutogenData = useCallback((previewMode: boolean) => {
        return applyForecastWithMode(forecast.forecastId, "autogen", previewMode);
    }, []);
    const getAutogenDriversData = useCallback((previewMode: boolean) => {
        return applyForecastWithMode(
            forecast.forecastId,
            "drivers_autogen",
            previewMode
        );
    }, []);
    return {
        forecast,
        setCurrentForecast,
        getBaselineData,
        getDriversData,
        getAutogenData,
        getAutogenDriversData,
    };
};

export default useCurrentForecast;
