import { useContext } from "react";
import { Context, Filters } from "State/store";
import { ComparisonEnums } from "Utils/constants";
import {
    parseToMoment,
    shiftToPreviousPeriod,
    shiftToSameDayLastWeek,
    shiftToSamePeriodLastYear,
} from "Utils/date-utils";
import {
    GetSaleAndStaffDataByDatesParams,
    useGetTotalSalesAndStaffHoursByDates,
} from "./useGetTotalSalesAndStaffHoursByDates";

const getShiftedPeriods = (filters: Filters) => {
    const {
        selectedDates: { fromDate, toDate },
        selectedComparison,
    } = filters;

    const start = parseToMoment(fromDate);
    const end = parseToMoment(toDate);

    switch (selectedComparison) {
        case ComparisonEnums.PREVIOUS_PERIOD:
            return shiftToPreviousPeriod({
                start,
                end,
            });
        case ComparisonEnums.SAME_DAY_LAST_WEEK:
            return shiftToSameDayLastWeek({
                start,
                end,
            });
        case ComparisonEnums.SAME_PERIOD_LAST_YEAR:
            return shiftToSamePeriodLastYear({
                start,
                end,
            });
        default:
            return undefined;
    }
};

export const useGetTotalSalesAndStaffHoursForComparison = (
    params: GetSaleAndStaffDataByDatesParams = {}
) => {
    const [{ filters, groupData }] = useContext(Context);
    if (!groupData) {
        throw new Error("No Group Data, this should be impossible");
    }
    const { selectedComparison, selectedAggregate } = filters;
    const shiftedPeriods = getShiftedPeriods(filters);
    const forecastComparisonSelected =
        selectedComparison === ComparisonEnums.ALL_FORECAST;
    return useGetTotalSalesAndStaffHoursByDates({
        ...params,
        enabled:
            (shiftedPeriods && !selectedAggregate) || forecastComparisonSelected,
        dateRange: shiftedPeriods,
        useForecastData: forecastComparisonSelected,
    });
};
