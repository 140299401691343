import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { Statistic } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import { Flex } from "../../styles/Flex";
import { BLACK, DARK_GREEN, DARK_RED } from "../../Utils/constants";
import { currencyFormatter, isNumber } from "../../Utils/utils";
import { RowValue } from "./SegmentTable.logic";

interface Props {
    displayChange: boolean;
    roundNumberWhenFormatting?: boolean;
    rowValue: RowValue;
}

const getColour = (value: number): string => {
    if (value === 0) return BLACK;

    return value > 0 ? DARK_GREEN : DARK_RED;
};

const StyledStatistic = styled(Statistic)`
    font-size: inherit;
    .ant-statistic-content-prefix {
        margin-right: 0;
        margin-left: 4px;
    }
`;

function percentageFormatter(
    num: number,
    fractionDigits = 1,
    absoluteValue = false
): string {
    if (absoluteValue) num = Math.abs(num); // For instances where we colour the value instead of having a minus symbol

    const figure = Math.round(num * 10 ** fractionDigits) / 10 ** fractionDigits;
    return figure + "%";
}

export const SegmentTableRow: FC<Props> = ({
    displayChange,
    roundNumberWhenFormatting = false,
    rowValue: { total, change },
}) => {
    const formattedTotal = isNumber(parseFloat(total.toString()))
        ? `${currencyFormatter(
              parseFloat(total.toString()),
              roundNumberWhenFormatting ? 0 : undefined
          )} `
        : "$0";

    if (!displayChange) {
        return <>{formattedTotal}</>;
    }

    return (
        <Flex direction="row">
            {formattedTotal}

            {change && isNumber(change) ? (
                <StyledStatistic
                    value={percentageFormatter(change, 0, true)}
                    prefix={change >= 0 ? <CaretUpFilled /> : <CaretDownFilled />}
                    valueStyle={{
                        color: getColour(change),
                        fontSize: "inherit",
                    }}
                />
            ) : null}
        </Flex>
    );
};
