import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    SearchOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Popover, Select, Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import styled from "styled-components";
import {
    CustomizedFormProps,
    FieldData,
    SetEditFieldsType,
} from "../../../@types/UserTable";
import { User } from "../../../Api/backend";
import { View } from "../../ViewSelect";

export const CheckCrossIconHelper = (booleanFlag: boolean): ReactElement =>
    booleanFlag ? <CheckOutlined /> : <CloseOutlined />;

const ButtonWithMargin = styled(Button)`
    margin: 4px;
`;

export const TableActions = ({
    users,
    setUserToEditID,
    setEditFields,
    setIsEditUserModalVisable,
    user_id,
    setDeleteUserId,
    setIsDeleteModalVisible,
}: {
    users: User[];
    setUserToEditID: Dispatch<SetStateAction<string | null>>;
    setEditFields: (input: SetEditFieldsType) => void;
    setIsEditUserModalVisable: Dispatch<SetStateAction<boolean>>;
    user_id: string;
    setDeleteUserId: Dispatch<SetStateAction<string | null>>;
    setIsDeleteModalVisible: Dispatch<SetStateAction<boolean>>;
}): ReactElement => {
    const { md } = useBreakpoint();

    const onCreateClick = () => {
        const USER_TO_EDIT =
            users[
                users.findIndex((user) => {
                    if (user.user_id === user_id) {
                        return true;
                    }
                    return false;
                })
            ];
        if (USER_TO_EDIT) {
            setUserToEditID(user_id);

            setEditFields({
                firstName: USER_TO_EDIT.first_name,
                lastName: USER_TO_EDIT.last_name,
                isActive: USER_TO_EDIT.is_active ? 1 : 0,
                defaultViewID: USER_TO_EDIT.settings.defaultViewId,
                isAdmin: USER_TO_EDIT.is_admin,
            });
            setIsEditUserModalVisable(true);
        }
    };
    const onDeleteClick = () => {
        setIsDeleteModalVisible(true);
        setDeleteUserId(user_id);
    };

    return (
        <>
            <PopoverWrapper content="Edit user profile" md={md}>
                <ButtonWithMargin
                    type="primary"
                    shape="round"
                    size="large"
                    onClick={onCreateClick}
                >
                    <UserOutlined />
                </ButtonWithMargin>
            </PopoverWrapper>
            <PopoverWrapper content="Delete user" md={md}>
                <ButtonWithMargin
                    type="primary"
                    shape="round"
                    danger
                    size="large"
                    onClick={onDeleteClick}
                >
                    <DeleteOutlined />
                </ButtonWithMargin>
            </PopoverWrapper>
        </>
    );
};

/**
 * Popover helper function to disable the popover effect on mobile.
 *
 *
 * @param children Children component(s)
 * @param {string} content content for text for popover
 * @param {boolean | undefined} md boolean flag for whether size is above "md" breakpoint (from false we can infer the screen is mobile/very small)
 * @returns
 */
const PopoverWrapper = ({
    children,
    content,
    md,
}: {
    children: ReactElement;
    content: string;
    md: boolean | undefined;
}) => {
    if (md) return <Popover content={content}>{children}</Popover>;
    return <>{children}</>;
};

/**
 * Helper function that changes the colour of the Icon based on if the column has a filter applied.
 *
 * @param {boolean} filtered
 * @returns {ReactElement}
 */
const FilterIcon = (filtered: boolean | undefined) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
);

export const getColumnSearchProps = (dataIndex: string) => ({
    // eslint-disable-next-line react/display-name
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }: {
        setSelectedKeys: (input: any) => any;
        // TODO DETERMINE THIS TYPE
        selectedKeys: any;
        confirm: () => void;
        clearFilters: () => void;
    }): ReactElement => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(confirm)}
                style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(confirm)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered) => FilterIcon(filtered),
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
            : "",
});

const handleSearch = (confirm: () => void) => {
    confirm();
};

const handleReset = (clearFilters: () => void) => {
    clearFilters();
};

export const UserForm: React.FC<CustomizedFormProps> = ({
    onChange,
    fields,
    views,
    adminChecked,
    onAdminCheckedChange,
    createUserFlag,
    editUserFlag,
}: {
    onChange: (input: FieldData[]) => void;
    fields: FieldData[];
    views: View[];
    adminChecked: boolean;
    onAdminCheckedChange: (e: any) => void;
    createUserFlag: boolean;
    editUserFlag: boolean;
}) => (
    <Form
        name="create_user_form"
        fields={fields}
        layout="vertical"
        onFieldsChange={(_, allFields) => {
            onChange(allFields);
        }}
    >
        <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: "First Name is required!" }]}
        >
            <Input placeholder="Please enter your first name" />
        </Form.Item>
        <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: "Last Name is required!" }]}
        >
            <Input placeholder="Please enter your last name" />
        </Form.Item>
        <Form.Item
            name="email"
            label="Email"
            hidden={editUserFlag}
            rules={[
                {
                    required: true,
                    message: "A valid email is required!",
                    type: "email",
                },
            ]}
        >
            <Input placeholder="Please enter the user's email" />
        </Form.Item>
        <Form.Item
            name="defaultView"
            label="Default View"
            rules={[{ required: false }]}
        >
            <Select placeholder="Select the user's default view">
                {views.map((view) => (
                    <Select.Option value={view.view_id} key={view.view_id}>
                        {view.view_label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item
            name="isActive"
            label=" Is Active"
            rules={[{ required: false }]}
            hidden={createUserFlag}
        >
            <Select>
                {[
                    { id: 1, value: "Yes" },
                    { id: 0, value: "No" },
                ].map((item) => (
                    <Select.Option value={item.id} key={item.id}>
                        {item.value}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item>
            <Checkbox checked={adminChecked} onChange={onAdminCheckedChange}>
                Admin
            </Checkbox>
        </Form.Item>
    </Form>
);
