import React, { ReactElement, useEffect, useState } from "react";
import { Context, State } from "../../State/store";
import styled from "styled-components";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";

const StyledSider = styled(Layout.Sider)`
    background: white;
    border-right: 1px solid #d9d9d9;
`;

interface Props {
    page: string;
    children: ReactElement | ReactElement[];
}

const BaseSideNavigation = ({ page, children }: Props): ReactElement => {
    const [state] = React.useContext(Context);
    const { groupData }: State = state;
    const location = useLocation<{ prevPath?: string }>();
    const navigatingFromHome = location.state?.prevPath === "/" ? true : false;
    const [isCollapsed, setIsCollapsed] = useState(!navigatingFromHome);

    useEffect(() => {
        setIsCollapsed(page !== "home");
    }, [page]);

    return (
        <>
            {groupData && (
                <StyledSider width={300} collapsed={isCollapsed} collapsedWidth={67}>
                    {children}
                </StyledSider>
            )}
        </>
    );
};

export default BaseSideNavigation;
