// Represents an item in a list of field settings
interface FieldSettingsItem {
    title: string;
    value: string;
}

// Represents the settings for a field
interface FieldSettings {
    listValues: FieldSettingsItem[];
}

// Represents a single field
interface Field {
    label: string;
    type: string;
    fieldSettings: FieldSettings;
}

// Represents a collection of fields
interface Fields {
    [key: string]: Field;
}

// Checks if there are any duplicate values in the list of field settings for any of the fields
export function hasDuplicateValues(fields: Fields | {}): boolean {
    // Return false if the fields object is empty
    if (Object.keys(fields).length === 0) return false;
    for (const field of Object.values(fields)) {
        const values: string[] = [];
        // Iterate through each item in the list of field settings
        for (const item of field.fieldSettings.listValues) {
            // If the value is already in the list of values, it's a duplicate
            if (values.includes(item.value)) {
                return true;
            }
            // Add the value to the list of seen values
            values.push(item.value);
        }
    }
    // No duplicates found
    return false;
}

// Finds all duplicate values in the list of field settings for each field
export function findDuplicateValues(fields: Fields): Record<string, string[]> {
    const duplicates: Record<string, string[]> = {};
    // Iterate through each field
    for (const [fieldName, field] of Object.entries(fields)) {
        const values: string[] = [];
        const fieldDuplicates: string[] = [];
        // Iterate through each item in the list of field settings for the field
        for (const item of field.fieldSettings.listValues) {
            // If the value is already in the list of seen values, it's a duplicate for this field
            if (values.includes(item.value)) {
                fieldDuplicates.push(item.value);
            } else {
                // Add the value to the list of seen values
                values.push(item.value);
            }
        }
        // If there are any duplicates for the field, add them to the duplicates object
        if (fieldDuplicates.length > 0) {
            duplicates[fieldName] = fieldDuplicates;
        }
    }
    // Return the object containing all the field names with their respective duplicate values
    return duplicates;
}
