import { Tabs } from "antd";

import React, { ReactElement, useState } from "react";
import { SalesOverTimeLineChart } from "Pages/Dashboard/Components/Charts/SalesOverTimeChart";
import { CumulativeSalesChart } from "Pages/Dashboard/Components/Charts/CummulativeSalesChart";

interface Props {
    height: number;
}

export default function TabulatedGraphs({ height }: Props): ReactElement {
    const [activeTab, setActiveTab] = useState("1");

    return (
        <Tabs defaultActiveKey={"1"} onChange={(e) => setActiveTab(e)}>
            <Tabs.TabPane tab="Sales over Time" key="1">
                {activeTab === "1" ? (
                    <SalesOverTimeLineChart height={height} />
                ) : null}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Cumulative Sales" key="2">
                {activeTab === "2" ? <CumulativeSalesChart height={height} /> : null}
            </Tabs.TabPane>
        </Tabs>
    );
}
