import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import { BaselineMappedRow } from "./BaselineChartForAutogen";
import { ColumnType } from "antd/es/table";
import { Skeleton, Table, Typography } from "antd";
import "../HomeDashboardDesktop/segmentTable.scss";
import { currencyFormatter, stringSort } from "../../Utils/utils";
import { FilteredSegments } from "./index";
import { useDebounceEffect } from "ahooks";
import { WRANGLR_DARK_BLUE } from "Utils/constants";

const onHeaderCell = (): { style: CSSProperties } => ({
    style: {
        color: WRANGLR_DARK_BLUE,
        fontWeight: "bold" as const,
    },
});

export interface ForecastingSegmentTableRecord {
    [segment: string]: string | number;
}

const getBaseKey = (
    mode: "baseline" | "drivers" | "drivers_autogen" | "autogen"
): string => (mode === "baseline" || mode === "drivers" ? "baseline" : "autogen");

const getForecastKey = (
    mode: "baseline" | "drivers" | "drivers_autogen" | "autogen"
): string =>
    mode === "baseline"
        ? "autogen"
        : mode === "autogen"
        ? "baseline"
        : mode === "drivers"
        ? "baseline drivers"
        : mode === "drivers_autogen"
        ? "autogen drivers"
        : "";

const getColumns = (
    mode: "baseline" | "drivers" | "drivers_autogen" | "autogen"
): ColumnType<ForecastingSegmentTableRecord>[] => {
    const columns: ColumnType<ForecastingSegmentTableRecord>[] = [];
    const baseKey = getBaseKey(mode);
    const forecastKey = getForecastKey(mode);
    columns.push({
        key: "segment",
        title: `Name`,
        dataIndex: "segment",
        className: "segment-table-column",
        sortDirections: ["descend", "ascend"],
        onHeaderCell,
        sorter: (
            a: ForecastingSegmentTableRecord,
            b: ForecastingSegmentTableRecord
        ) => stringSort(a.segment as string, b.segment as string),
    });
    columns.push({
        key: baseKey,
        title: baseKey.charAt(0).toUpperCase() + baseKey.slice(1),
        dataIndex: baseKey,
        className: "segment-table-column",
        render: (val) => currencyFormatter(val, 1),
        sortDirections: ["descend", "ascend"],
        sorter: (
            a: ForecastingSegmentTableRecord,
            b: ForecastingSegmentTableRecord
        ) => (a[baseKey] as number) - (b[baseKey] as number),
        onHeaderCell,
    });
    columns.push({
        key: forecastKey,
        title: forecastKey.charAt(0).toUpperCase() + forecastKey.slice(1),
        dataIndex: forecastKey,
        className: "segment-table-column",
        render: (val) => currencyFormatter(val, 1),
        sortDirections: ["descend", "ascend"],
        sorter: (
            a: ForecastingSegmentTableRecord,
            b: ForecastingSegmentTableRecord
        ) => (a[forecastKey] as number) - (b[forecastKey] as number),
        onHeaderCell,
    });
    return columns;
};

interface Props {
    mappedData: BaselineMappedRow[];
    loading: boolean;
    mode: "baseline" | "drivers" | "drivers_autogen" | "autogen";
    setFilteredSegments: React.Dispatch<React.SetStateAction<FilteredSegments>>;
    segmentType: "days" | "areas" | "classes";
}

const SegmentTableForAutogen = ({
    mappedData,
    loading,
    mode,
    setFilteredSegments,
    segmentType,
}: Props): ReactElement => {
    const [columns, setColumns] = useState<
        ColumnType<ForecastingSegmentTableRecord>[]
    >([]);
    const [dataSource, setDataSource] = useState<ForecastingSegmentTableRecord[]>(
        []
    );
    const [inclusionList, setInclusionList] = useState<string[]>([]);

    useEffect(() => {
        setColumns(getColumns(mode));
    }, [mode]);

    useEffect(() => {
        setDataSource(
            mappedData.map(({ x, base, forecast }: BaselineMappedRow) => ({
                segment: x,
                key: x,
                [getBaseKey(mode)]: base,
                [getForecastKey(mode)]: forecast,
            }))
        );
    }, [mappedData, mode]);

    useDebounceEffect(
        () => {
            setFilteredSegments((excludedSegments: FilteredSegments) => {
                const newExcludedSegments = { ...excludedSegments };
                newExcludedSegments[segmentType] = inclusionList;
                return newExcludedSegments;
            });
        },
        [inclusionList],
        {
            wait: 500,
        }
    );

    const onRowClick = (record: ForecastingSegmentTableRecord) => () => {
        //Remove segment from exclusion list if it exists, otherwise, add it in
        if (inclusionList.includes(record.segment as string)) {
            setInclusionList((excludedList: string[]) =>
                excludedList.filter((item: string) => item !== record.segment)
            );
        } else {
            setInclusionList((excludedList: string[]) =>
                excludedList.concat(record.segment as string)
            );
        }
    };

    const setRowClassName = (record: ForecastingSegmentTableRecord) => {
        if (inclusionList.find((item) => item === record.segment) !== undefined) {
            return "selected-row";
        }
        return "";
    };

    const getSummary = () => {
        let totalBase = 0;
        let totalForecast = 0;

        mappedData.forEach(({ base, forecast }) => {
            totalBase += base as number;
            totalForecast += forecast as number;
        });

        return (
            <>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                        <Typography.Text strong>Totals</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                        <Typography.Text strong>
                            {currencyFormatter(totalBase)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                        <Typography.Text strong>
                            {currencyFormatter(totalForecast)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </>
        );
    };

    if (loading) {
        return <Skeleton active paragraph={{ rows: 6 }} />;
    }

    return (
        <Table
            columns={columns}
            className={"segment-table"}
            dataSource={dataSource}
            pagination={{ pageSize: 7, size: "small", hideOnSinglePage: true }}
            tableLayout={"auto"}
            showSorterTooltip={false}
            size={"small"}
            onRow={(record: ForecastingSegmentTableRecord) => ({
                onClick: onRowClick(record),
            })}
            rowClassName={setRowClassName}
            summary={getSummary}
        />
    );
};

export default SegmentTableForAutogen;
