export const downloadCSV = (data: any, fileName: string) => {
    const isMac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
    if (!isMac) {
        const BOM = "\ufeff";
        data = BOM + data;
    }
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const blob = new Blob([data], { type: "text/plain;charset=utf-8;" });
    const anchor = document.createElement("a");

    const target = document.createAttribute("target");
    target.value = "_blank";
    anchor.attributes.setNamedItem(target);

    const download = document.createAttribute("download");
    download.value = fileName;
    anchor.attributes.setNamedItem(download);
    const href = document.createAttribute("href");

    // @ts-ignore: TS reckons this will always be defined
    if (window.navigator.msSaveBlob) {
        // IE
        // used 'any' after typescript upgrade - prob a better way of doing this..
        (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else if (navigator.userAgent.search("Firefox") !== -1) {
        // Firefox
        anchor.style.cssText = "display:none;";

        href.value = "data:attachment/csv;charset=utf-8," + encodeURIComponent(data);
        anchor.attributes.setNamedItem(href);

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    } else if (isSafari) {
        window.open("data:attachment/csv;charset=utf-8," + encodeURI(data));
    } else {
        // Chrome
        href.value = URL.createObjectURL(blob);
        anchor.attributes.setNamedItem(href);
        anchor.click();
        anchor.remove();
    }
};
