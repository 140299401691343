import { useEffect, useState } from "react";

export const useBegoneWidget = () => {
    const [hasBeenHidden, setHasBeenHidden] = useState(false);

    useEffect(() => {
        if (hasBeenHidden) return;
        setTimeout(() => {
            const widget = document.getElementById("jsd-widget");
            if (widget) {
                if (widget.style.display !== "none") {
                    widget.style.display = "none";
                    setHasBeenHidden(true);
                }
            }
        }, 500);
    });
};

export const useReturnWidget = () => {
    const [hasBeenReturned, setHasBeenReturned] = useState(false);

    useEffect(() => {
        if (hasBeenReturned === true) return;
        setTimeout(() => {
            const widget = document.getElementById("jsd-widget");
            if (widget) {
                if (widget.style.display !== "initial") {
                    widget.style.display = "initial";
                    setHasBeenReturned(true);
                }
            }
        }, 500);
    });
};
