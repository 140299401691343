import React, {
    Dispatch,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import { Context, State } from "../../State/store";
import { WRANGLR_LIGHT_BLUE, WRANGLR_DARK_BLUE } from "../../Utils/constants";
import { getGroupData, GroupData } from "../../Api/backend";
import { Card, Col, Row, Typography } from "antd";
import styled from "styled-components";
import TotalSalesMetric from "../HomeDashboardDesktop/TotalSalesMetric";
import { WranglrDarkBlueTitle } from "../HomeDashboardDesktop";
import ManHrMetric from "../HomeDashboardDesktop/ManHrMetric";
import jwtDecode from "jwt-decode";
import { TabulatedTables } from "./TabulatedTables";
import TabulatedGraphsMobile from "./TabulatedGraphsMobile";
import { Action } from "../../State/actions";
import { formatForDisplay } from "Utils/date-utils";
import { ArrowRightOutlined } from "@ant-design/icons";
import { usePreviewMode } from "Store/viewSettingsStore";
import { TotalSalesMetricPreview } from "Pages/Dashboard/Components/TotalSalesMetric/TotalSalesMetric";
import { ManHrMetricPreview } from "Pages/Dashboard/Components/ManHrMetric/ManHrMetric";
import { useDateRangeFilter } from "Store/filterStore";

const RoundedCard = styled(Card)`
    border-radius: 15px;
`;

const CenteredWranglrDarkBlueTitle = styled(WranglrDarkBlueTitle)`
    text-align: center;
`;

interface ElementTitleRowProps {
    title: string;
}

const ElementTitleRow = ({ title }: ElementTitleRowProps): ReactElement => (
    <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
            <Typography.Title level={4} style={{ color: WRANGLR_DARK_BLUE }}>
                {title}
            </Typography.Title>
        </Col>
    </Row>
);

interface MetricsCardProps {
    children: ReactElement | ReactElement[];
    style?: React.CSSProperties;
}

const MetricsCard = ({ children, style }: MetricsCardProps): ReactElement => (
    <RoundedCard
        style={{ borderRadius: "15px", ...style }}
        bodyStyle={{ padding: "5px" }}
    >
        {children}
    </RoundedCard>
);

const StyledWarningBanner = styled.div`
    display: flex;
    width: 100%;
    background-color: ${WRANGLR_LIGHT_BLUE};
    color: #fff;
    margin-bottom: 8px;
    border-radius: 2px;
    padding: 8px;
    text-align: center;
    align-content: center;
    justify-content: center;
`;

const StyledClippedXOverflow = styled.div`
    overflow-x: clip;
`;

export default function HomeDashboardMobile(): ReactElement {
    const [{ groupData }, dispatch]: [State, Dispatch<Action>] = useContext(Context);
    const [loading, setLoading] = useState(true);
    const { selectedDateRange } = useDateRangeFilter();

    const startDate = selectedDateRange.start;
    const endDate = selectedDateRange.end;

    useEffect(() => {
        getGroupData().then((groupData: GroupData) => {
            const datasetName = (jwtDecode(groupData.cubejsToken) as any).user
                .dataset_name;
            const payload = {
                groupData,
            };
            dispatch({ type: "ADD_GROUP_DATA", payload });
            const datasetNamePayload = {
                datasetName,
            };
            dispatch({
                type: "ADD_DATASET_NAME_TO_FILTER",
                payload: datasetNamePayload,
            });
            setLoading(false);
        });
    }, []);

    const LoadedDashboardCards = (
        <>
            {!loading && (
                <>
                    {groupData?.group?.bannerAlert && (
                        <StyledWarningBanner>
                            {groupData?.group.bannerAlert}
                        </StyledWarningBanner>
                    )}
                    {/* Metrics Row */}
                    <Col span={24}>
                        <MetricsCard
                            style={{ textAlign: "center", marginBottom: "8px" }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                }}
                            >
                                <div>
                                    {formatForDisplay(startDate, "DD-MM-YYYY")}
                                </div>
                                <ArrowRightOutlined />
                                <div>{formatForDisplay(endDate, "DD-MM-YYYY")}</div>
                            </div>
                        </MetricsCard>
                    </Col>
                    <Row gutter={8}>
                        <Col span={12}>
                            <MetricsCard>
                                <CenteredWranglrDarkBlueTitle level={5}>
                                    Sales
                                </CenteredWranglrDarkBlueTitle>
                                <TotalSalesMetricPreview level={4} />
                            </MetricsCard>
                        </Col>
                        <Col span={12}>
                            <MetricsCard>
                                <CenteredWranglrDarkBlueTitle level={5}>
                                    /StaffHr
                                </CenteredWranglrDarkBlueTitle>
                                <ManHrMetricPreview level={4} />
                            </MetricsCard>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "12px" }}>
                        <Col span={24}>
                            <MetricsCard>
                                <TabulatedGraphsMobile />
                            </MetricsCard>
                        </Col>
                    </Row>
                    <ElementTitleRow title={"Segments"} />
                    <Row style={{ paddingBottom: "65px" }}>
                        <Col span={24}>
                            <MetricsCard>
                                <TabulatedTables />
                            </MetricsCard>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );

    return <StyledClippedXOverflow>{LoadedDashboardCards}</StyledClippedXOverflow>;
}
