import { Context, State } from "../../State/store";
import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
} from "react";
import CheckboxWithDrawers from "./index";
import { Action, AddClassToFilterAction } from "../../State/actions";
import { drawerType } from "../SideNavigation/CheckboxManager";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { usePreviewMode } from "Store/viewSettingsStore";
import { useClassesFilter } from "Store/filterStore";
import { chain } from "lodash";

interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}
export default function ClassesCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = React.useContext(Context);
    const { previewMode } = usePreviewMode();
    const { data: mappingRule } = useListMappingRules();
    const { selectedClasses: selectedClassIds, updateClassesFilter } =
        useClassesFilter();
    const classes = previewMode
        ? mappingRule
              ?.filter(({ segment, exclude }) => segment === "class" && !exclude)
              .map(({ segmentName }) => segmentName) ?? []
        : state.groupData!.segments.classes.map((c) => c.primary_id);

    const classNameById = chain(mappingRule)
        .keyBy("id")
        .mapValues("segmentName")
        .value();

    const selectedClasses = previewMode
        ? selectedClassIds?.map((classId) => classNameById[classId] ?? classId) ?? []
        : state.filters.selectedClasses.map((c) => c.primary_id);

    const dispatchAction = useCallback(
        (selectedClasses: string[]) => {
            const action: AddClassToFilterAction = {
                type: "ADD_CLASS_TO_FILTER",
                payload: {
                    selectedClasses: selectedClasses.map((c) => ({
                        primary_id: c,
                    })),
                },
            };
            if (mappingRule) {
                updateClassesFilter(
                    mappingRule
                        .filter(
                            ({ segmentName, segment }) =>
                                segment === "class" &&
                                selectedClasses.includes(segmentName)
                        )
                        .map(({ id }) => id)
                );
            }
            dispatch(action);
        },
        [dispatch, mappingRule, updateClassesFilter]
    );

    useEffect(() => {
        dispatchAction([]);
    }, [previewMode]);

    return (
        <>
            <CheckboxWithDrawers
                options={classes.sort()}
                dispatchAction={dispatchAction}
                selectedOptions={selectedClasses}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Classes"}
                title={"Classes"}
            />
        </>
    );
}
