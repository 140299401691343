export enum RoutePaths {
    HOME = "/",
    FORECASTING_LANDING = "/forecasting",
    FORECAST = "/forecast",
    FORECASTING_CREATION = "/forecasting/:id",
    LOGIN = "/login",
    USERS = "/users",
    PROFILE = "/profile",
    SET_PASSWORD = "/set-password",
    MAPPING = "/mapping",
    MAPPING_EDITION = "/mapping/:id",
    SOMETHING_WENT_WRONG = "/something-went-wrong",
    PAGE_NOT_FOUND = "/page-unavailable",
}
