import { ColumnType } from "antd/lib/table";
import React, { CSSProperties, useContext, useMemo } from "react";
import { SEGMENT_TYPE } from "Utils/types";
import { WRANGLR_DARK_BLUE } from "Utils/constants";
import { calculatePercentageDifference, stringSort } from "Utils/utils";
import { SegmentTableRow } from "./SegmentTableRow";
import { UpdateFiltersAction } from "State/actions";
import { Context } from "State/store";
import { useGetAverageSalesAndStaffHoursByDates } from "Hooks/useGetAverageSalesAndStaffHoursByDates";
import { useGetMedianSalesAndStaff } from "Hooks/useGetMedianSalesAndStaff";
import { useGetTotalSalesAndStaffHoursByDates } from "Hooks/useGetTotalSalesAndStaffHoursByDates";
import { useGetTotalSalesAndStaffHoursForComparison } from "Hooks/useGetTotalSalesAndStaffHoursForComparison";
import { Aggregate } from "Components/AggregateFilter";
export interface SegmentTableProps {
    segment: SEGMENT_TYPE;
    omitTitle?: boolean;
}
export interface SegmentTableRowRecord {
    segment: string;
    sales: RowValue;
    staffHours: RowValue;
}

export interface RowValue {
    total: number | string;
    change?: number;
}

const columnClassName = "segment-table-column";

const onHeaderCell = (): { style: CSSProperties } => ({
    style: {
        color: WRANGLR_DARK_BLUE,
        fontWeight: "bold" as const,
    },
});

const getColumns = ({
    comparisonSelected,
    isForecast = false,
}: {
    comparisonSelected: boolean;
    isForecast: boolean;
}): ColumnType<SegmentTableRowRecord>[] => [
    {
        title: `Name`,
        dataIndex: "segment",
        key: "segment",
        className: columnClassName,
        sortDirections: ["descend", "ascend"],
        sorter: {
            compare: (a, b) => stringSort(a.segment, b.segment),
            multiple: 1,
        },
        onHeaderCell,
    },
    {
        title: "Sales",
        dataIndex: "sales",
        key: "sales",
        className: columnClassName,
        sortDirections: ["ascend", "descend"],
        defaultSortOrder: "descend",
        sorter: {
            compare: (a, b) =>
                parseInt(a.sales.total.toString()) -
                parseInt(b.sales.total.toString()),
            multiple: 5,
        },
        render: (rowValues: RowValue) => (
            <SegmentTableRow
                rowValue={rowValues}
                displayChange={comparisonSelected || isForecast}
            />
        ),
        onHeaderCell,
    },
    {
        title: "/StaffHr",
        dataIndex: "staffHours",
        key: "staffHours",
        className: columnClassName,
        sortDirections: ["descend", "ascend"],
        sorter: {
            compare: (a, b) =>
                // It is safe to assume "manHour" is a number in this context.
                parseInt(a.staffHours.total.toString()) -
                parseInt(b.staffHours.total.toString()),
            multiple: 4,
        },
        render: (rowValue: RowValue) => (
            <SegmentTableRow
                rowValue={rowValue}
                displayChange={comparisonSelected && !isForecast}
                roundNumberWhenFormatting
            />
        ),
        onHeaderCell,
    },
];

const SEGMENT_TO_FILTER_NAME_MAPPER = {
    Venue: "selectedVenues",
    Area: "selectedAreas",
    Class: "selectedClasses",
};

const getComparisonFigures = (
    salesCurrentPeriod,
    manHrCurrentPeriod,
    salesPreviousPeriod,
    manHrPreviousPeriod
) => {
    const salesChange = calculatePercentageDifference(
        salesCurrentPeriod,
        salesPreviousPeriod
    );
    const manHourChange = calculatePercentageDifference(
        manHrCurrentPeriod,
        manHrPreviousPeriod
    );

    return {
        salesChange: salesChange === Number.POSITIVE_INFINITY ? 100 : salesChange,
        manHourChange:
            manHourChange === Number.POSITIVE_INFINITY ? 100 : manHourChange,
    };
};

export const useSegmentTable = ({ segment }: SegmentTableProps) => {
    const [{ filters }, dispatch] = useContext(Context);

    const segmentFilterName = SEGMENT_TO_FILTER_NAME_MAPPER[segment];
    const segmentFilters = filters[segmentFilterName];
    const isForecast = filters.mode === "forecast";

    const columns = useMemo(
        () =>
            getColumns({
                comparisonSelected: !!filters.selectedComparison,
                isForecast,
            }),
        [filters.selectedComparison, isForecast]
    );

    const medianAggregateSelected =
        filters.selectedAggregate === Aggregate.MEDIAN_DAY ||
        filters.selectedAggregate === Aggregate.MEDIAN_WEEK;

    const {
        averageSalesAndStaffBySegment: averageData,
        isLoading: gettingAverageData,
    } = useGetAverageSalesAndStaffHoursByDates({
        breakdownBySegment: segment,
    });

    const { totalSalesAndStaffBySegment: data, isLoading: gettingData } =
        useGetTotalSalesAndStaffHoursByDates({
            breakdownBySegment: segment,
            enabled: !medianAggregateSelected,
        });

    const {
        totalSalesAndStaffBySegment: comparisonData,
        isLoading: gettingComparisonData,
    } = useGetTotalSalesAndStaffHoursForComparison({
        breakdownBySegment: segment,
    });

    const {
        isLoading: gettingMedianData,
        medianSalesAndStaffInPeriodsBySegment: medianData,
    } = useGetMedianSalesAndStaff({
        breakdownBySegment: segment,
    });

    const dataSource = useMemo(() => {
        if (medianData) {
            const medianSegmentData = medianData[segment];
            return Object.values(medianSegmentData)
                .sort((a, b) => b.totalSales - a.totalSales)
                .map(({ name, totalSales, totalStaff }) => ({
                    key: name,
                    segment: name,
                    sales: { total: totalSales },
                    staffHours: {
                        total: totalSales / totalStaff,
                    },
                }));
        }
        if (averageData) {
            const averageSegmentData = averageData[segment];
            return Object.values(averageSegmentData)
                .sort((a, b) => b.averageSales - a.averageSales)
                .map(({ name, averageSales, averageStaff }) => ({
                    key: name,
                    segment: name,
                    sales: { total: averageSales },
                    staffHours: {
                        total: averageSales / averageStaff,
                    },
                }));
        }

        if (data) {
            const segmentData = data[segment];
            return Object.values(segmentData)
                .sort((a, b) => b.totalSales - a.totalSales)
                .map(({ name, totalSales, totalStaff }) => {
                    const segmentComparisonData = comparisonData?.[segment][name];

                    const { salesChange, manHourChange } = segmentComparisonData
                        ? getComparisonFigures(
                              totalSales,
                              totalSales / totalStaff,
                              segmentComparisonData.totalSales,
                              segmentComparisonData.totalSales /
                                  segmentComparisonData.totalStaff
                          )
                        : { salesChange: undefined, manHourChange: undefined };
                    return {
                        key: name,
                        segment: name,
                        sales: { total: totalSales, change: salesChange },
                        staffHours: {
                            total: totalSales / totalStaff,
                            change: !isForecast ? manHourChange : undefined,
                        },
                    };
                });
        }

        return [];
    }, [medianData, averageData, data, segment, comparisonData, isForecast]);

    const onRowClick = (record: SegmentTableRowRecord) => () => {
        const primary_id = record.segment;
        const selectedSegment = segmentFilters.find(
            (segment) => segment.primary_id === primary_id
        );
        const action: UpdateFiltersAction = {
            type: "UPDATE_FILTERS",
            payload: {
                filters: {},
            },
        };

        // If our selected row doesn't exist, add it to state. Otherwise unselect it from state
        let newSelectedSegmentsFromState;
        if (selectedSegment === undefined) {
            newSelectedSegmentsFromState = segmentFilters.concat({
                primary_id,
            });
            action.payload.filters[segmentFilterName] = newSelectedSegmentsFromState;
            dispatch(action);
        } else {
            newSelectedSegmentsFromState = segmentFilters.filter(
                (row) => row.primary_id !== primary_id
            );
            action.payload.filters[segmentFilterName] = newSelectedSegmentsFromState;
            dispatch(action);
        }
    };

    const getRowClassName = (record: SegmentTableRowRecord) => {
        return segmentFilters.find(
            (segment) => segment.primary_id === record.segment
        )
            ? "selected-row"
            : "";
    };

    return {
        columns,
        dataSource,
        getRowClassName,
        onRowClick,
        isLoading: gettingData || gettingMedianData || gettingAverageData,
        isLoadingCompareData: gettingComparisonData,
    };
};
