import React, {
    Dispatch,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import { Context, State } from "../../State/store";
import {
    Action,
    ToggleApplyAutogenDriverForecasts,
    ToggleApplyDriverForecasts,
    ToggleCreateForecastAutogen,
    ToggleCreateForecastBaseline,
} from "../../State/actions";
import { RoundedCard, WranglrDarkBlueTitle } from "../HomeDashboardDesktop";
import { Col } from "antd";
import moment from "moment";
import InfoTooltip from "../InfoTooltip";
import {
    BASELINE_AND_FORECAST_CARD_TOOLTIP_TEXT,
    BASELINE_AND_AUTOGEN_CARD_TOOLTIP_TEXT,
    AUTOGEN_AND_FORECAST_CARD_TOOLTIP_TEXT,
} from "../ForecastingDashboardDesktop/constants";
import { usePreviewMode } from "Store/viewSettingsStore";
import { useAppDispatch } from "Redux/TypedReduxFunctions";
import { setCurrentBaseline } from "Redux/StateSlices/CurrentForecast";
import useCurrentForecast from "Hooks/useCurrentForecast";
import BaselineChartForAutogen, { BaselineRawRow } from "./BaselineChartForAutogen";
import BaselineChart from "./BaselineChart";
import SegmentTablesGroupForAutogen from "./SegmentTablesGroupForAutogen";
import SegmentTablesGroup from "./SegmentTablesGroup";

export const filterBaselineDataIfRequired =
    (
        filteredSegments: FilteredSegments,
        filterAreas: boolean,
        filterClasses: boolean,
        filterDays: boolean
    ) =>
    (row: BaselineRawRow): boolean => {
        const { days, areas, classes } = filteredSegments;
        return (
            (!filterAreas ||
                areas.length === 0 ||
                areas.includes(row.mapped_area) ||
                row.mapped_area === "") &&
            (!filterClasses ||
                classes.length === 0 ||
                classes.includes(row.mapped_class) ||
                row.mapped_class === "") &&
            (!filterDays ||
                days.length === 0 ||
                days.includes(moment(row.relative_timestamp).format("dddd")))
        );
    };

export interface FilteredSegments {
    days: string[];
    areas: string[];
    classes: string[];
}
const getTitle = (isAutogen?: boolean) => (mode: string) => {
    if (isAutogen) {
        return mode === "baseline"
            ? "Baseline & Autogen"
            : mode === "autogen"
            ? "Autogen & Baseline"
            : mode === "drivers"
            ? "Baseline & Baseline Drivers"
            : mode === "drivers_autogen"
            ? "Autogen & Autogen Drivers"
            : "";
    } else {
        return mode === "baseline" ? "Baseline" : "Baseline & drivers";
    }
};

const getInfoTooltip = (isAutogen?: boolean) => (mode: string) => {
    if (isAutogen) {
        return mode === "baseline" || mode === "autogen"
            ? BASELINE_AND_AUTOGEN_CARD_TOOLTIP_TEXT
            : mode === "drivers"
            ? BASELINE_AND_FORECAST_CARD_TOOLTIP_TEXT
            : mode === "drivers_autogen"
            ? AUTOGEN_AND_FORECAST_CARD_TOOLTIP_TEXT
            : "";
    } else {
        return BASELINE_AND_FORECAST_CARD_TOOLTIP_TEXT;
    }
};

const ForecastingChartsDataManager = (): ReactElement => {
    const reduxDispatch = useAppDispatch();

    const [state, dispatch]: [State, Dispatch<Action>] = useContext(Context);
    const {
        toggleCreateBaseline,
        toggleApplyDriver,
        toggleCreateAutogen,
        toggleApplyAutogenDriver,
    } = state.forecastPage!;
    const {
        getBaselineData,
        getDriversData,
        getAutogenData,
        getAutogenDriversData,
    } = useCurrentForecast();
    const [loading, setLoading] = useState<boolean>(false);
    const [mode, setMode] = useState<
        "baseline" | "drivers" | "autogen" | "drivers_autogen"
    >("autogen");
    const [baselineData, setBaselineData] = useState<BaselineRawRow[]>([]);
    const [forecastData, setForecastData] = useState<BaselineRawRow[]>([]);
    const [originalForecastData, setOriginalForecastData] = useState<
        BaselineRawRow[]
    >([]);
    const [originalBaselineData, setOriginalBaselineData] = useState<
        BaselineRawRow[]
    >([]);
    const [filteredSegments, setFilteredSegments] = useState<FilteredSegments>({
        days: [],
        areas: [],
        classes: [],
    });
    const { previewMode } = usePreviewMode();
    const isAutogenSupport = state.groupData?.group.is_pilot_forecast;

    useEffect(() => {
        if (toggleApplyAutogenDriver) {
            setMode("drivers_autogen");
            setLoading(true);
            getAutogenDriversData(previewMode)
                .then((data: BaselineRawRow[]) => {
                    setBaselineData(data.length ? data : []);

                    setOriginalBaselineData(data.length ? data : []);
                    setLoading(false);
                    const action: ToggleApplyAutogenDriverForecasts = {
                        type: "TOGGLE_APPLY_AUTOGEN_DRIVER_FORECASTS",
                    };
                    dispatch(action);
                })
                .catch(() => {
                    setLoading(false);
                    const action: ToggleApplyAutogenDriverForecasts = {
                        type: "TOGGLE_APPLY_AUTOGEN_DRIVER_FORECASTS",
                    };
                    dispatch(action);
                });
        }
    }, [toggleApplyAutogenDriver]);

    useEffect(() => {
        async function getAutogenAndBaseline() {
            try {
                const baselineData: BaselineRawRow[] = await getAutogenData(
                    previewMode
                );
                setBaselineData(baselineData.length ? baselineData : []);
                setOriginalBaselineData(baselineData.length ? baselineData : []);
            } catch (error) {
                return;
            }
            try {
                const autogenData: BaselineRawRow[] = await getBaselineData(
                    previewMode
                );
                setForecastData(autogenData.length ? autogenData : []);

                setOriginalForecastData(autogenData.length ? autogenData : []);
                setLoading(false);
                const action: ToggleCreateForecastAutogen = {
                    type: "TOGGLE_CREATE_FORECAST_AUTOGEN",
                };
                dispatch(action);
            } catch (error) {
                setLoading(false);
                const action: ToggleCreateForecastAutogen = {
                    type: "TOGGLE_CREATE_FORECAST_AUTOGEN",
                };
                dispatch(action);
            }
        }
        if (toggleCreateAutogen) {
            setMode("autogen");
            setLoading(true);
            getAutogenAndBaseline();
        }
    }, [toggleCreateAutogen]);

    useEffect(() => {
        async function getBaselineAndAutogen(isAutogen?: boolean) {
            try {
                const baselineData: BaselineRawRow[] = await getBaselineData(
                    previewMode
                );
                setBaselineData(baselineData.length ? baselineData : []);
                setOriginalBaselineData(baselineData.length ? baselineData : []);
                if (!isAutogen) {
                    setLoading(false);
                    const action: ToggleCreateForecastBaseline = {
                        type: "TOGGLE_CREATE_FORECAST_BASELINE",
                    };
                    dispatch(action);
                }
            } catch (error) {
                if (!isAutogen) {
                    setLoading(false);
                    const action: ToggleCreateForecastBaseline = {
                        type: "TOGGLE_CREATE_FORECAST_BASELINE",
                    };
                    dispatch(action);
                }
                return;
            }
            if (isAutogen) {
                try {
                    const autogenData: BaselineRawRow[] = await getAutogenData(
                        previewMode
                    );

                    setForecastData(autogenData.length ? autogenData : []);

                    setOriginalForecastData(autogenData.length ? autogenData : []);
                    setLoading(false);
                    const action: ToggleCreateForecastBaseline = {
                        type: "TOGGLE_CREATE_FORECAST_BASELINE",
                    };
                    dispatch(action);
                } catch (error) {
                    setLoading(false);
                    const action: ToggleCreateForecastBaseline = {
                        type: "TOGGLE_CREATE_FORECAST_BASELINE",
                    };
                    dispatch(action);
                }
            }
        }
        if (toggleCreateBaseline) {
            setMode("baseline");
            setLoading(true);
            getBaselineAndAutogen(isAutogenSupport);
        }
    }, [toggleCreateBaseline]);

    useEffect(() => {
        if (toggleApplyDriver) {
            setMode("drivers");
            setLoading(true);
            getDriversData(previewMode)
                .then((data: BaselineRawRow[]) => {
                    setBaselineData(data.length ? data : []);
                    setOriginalBaselineData(data.length ? data : []);
                    setLoading(false);
                    const action: ToggleApplyDriverForecasts = {
                        type: "TOGGLE_APPLY_DRIVER_FORECASTS",
                    };
                    dispatch(action);
                })
                .catch(() => {
                    setLoading(false);
                    const action: ToggleApplyDriverForecasts = {
                        type: "TOGGLE_APPLY_DRIVER_FORECASTS",
                    };
                    dispatch(action);
                });
        }
    }, [toggleApplyDriver]);

    useEffect(() => {
        if (originalBaselineData.length > 0) {
            setBaselineData(
                originalBaselineData.filter(
                    filterBaselineDataIfRequired(filteredSegments, true, true, true)
                )
            );
        }
        if (originalForecastData.length > 0) {
            setForecastData(
                originalForecastData.filter(
                    filterBaselineDataIfRequired(filteredSegments, true, true, true)
                )
            );
        }
    }, [filteredSegments]);

    useEffect(() => {
        // We only allow forecasting on mapped data
        previewMode === true &&
            reduxDispatch(setCurrentBaseline({ baseline: baselineData }));
    }, [baselineData]);

    return (
        <>
            <Col span={24}>
                <RoundedCard>
                    <WranglrDarkBlueTitle level={4}>
                        {getTitle(isAutogenSupport)(mode)}{" "}
                        <InfoTooltip
                            tooltipText={getInfoTooltip(isAutogenSupport)(mode)}
                        />
                    </WranglrDarkBlueTitle>
                    {isAutogenSupport ? (
                        <BaselineChartForAutogen
                            baselineData={baselineData}
                            forecastData={forecastData}
                            loading={loading}
                            mode={mode}
                            filteredSegments={filteredSegments}
                        />
                    ) : (
                        <BaselineChart
                            baselineData={baselineData}
                            loading={loading}
                            mode={mode as "baseline" | "drivers"}
                            filteredSegments={filteredSegments}
                        />
                    )}
                </RoundedCard>
            </Col>

            <Col span={24}>
                {isAutogenSupport ? (
                    <SegmentTablesGroupForAutogen
                        baselineData={originalBaselineData}
                        forecastData={originalForecastData}
                        loading={loading}
                        mode={mode}
                        setFilteredSegments={setFilteredSegments}
                        filteredSegments={filteredSegments}
                    />
                ) : (
                    <SegmentTablesGroup
                        baselineData={originalBaselineData}
                        loading={loading}
                        mode={mode as "baseline" | "drivers"}
                        setFilteredSegments={setFilteredSegments}
                        filteredSegments={filteredSegments}
                    />
                )}
            </Col>
        </>
    );
};

export default ForecastingChartsDataManager;
