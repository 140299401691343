import React, { FC } from "react";
import styled, { CSSProperties, keyframes } from "styled-components";
import { Flex } from "styles/Flex";

interface Props {
    size?: number;
    styles?: CSSProperties;
}

const PulseKeyFrame = keyframes`
     0% {
       -moz-box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
       box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
     }
     50% {
       -moz-box-shadow: 0 0 0 20px rgba(255, 106, 20, 0.3);
       box-shadow: 0 0 0 20px rgba(255, 106, 20, 0.3);
     }
     100% {
       -moz-box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
       box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
     }
`;

const PulseElement = styled.div`
    margin: 100px;
    display: block;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background: rgb(255, 106, 20);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(255, 106, 20, 0.3);
    animation: ${PulseKeyFrame} 1.5s infinite;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: auto;
`;

const LogoText = styled.div`
    font-family: "Red Hat Display";
    font-weight: bolder;
    color: #fff;
`;

export const QuantacoLoader: FC<Props> = ({ size, styles }) => {
    return (
        <Flex style={{ justifyContent: "center" }} direction="row">
            <PulseElement
                style={{
                    height: size,
                    width: size,
                    ...styles,
                }}
            >
                <LogoText style={{ fontSize: `${(size ?? 400) / 1.5}px` }}>
                    Q
                </LogoText>
            </PulseElement>
        </Flex>
    );
};
