import { Card, Col, Row } from "antd";
import React from "react";
import styled from "styled-components";

export const Container = styled(Card)`
    border-radius: 15px;
    border: 1px solid #d9d9d9;
`;

export const MappingConfiguratorLayout: React.FC = ({ children }) => {
    return (
        <>
            <Row justify={"center"} align={"middle"}>
                <Col span={20} style={{ maxWidth: "850px", marginTop: 30 }}>
                    <Container
                        style={{ minHeight: 300 }}
                        bodyStyle={{ minHeight: "270px" }}
                    >
                        {children}
                    </Container>
                </Col>
            </Row>
        </>
    );
};
