import { config } from "./config";
export const enum AuthTokens {
    accessToken = "access_token",
    idToken = "id_token",
    refreshToken = "refresh_token",
    userId = "user_id",
    expiresIn = "expires_in",
    email = "email",
}
export interface AuthTokensInLocalstorage {
    access_token?: string;
    email?: string;
    id_token?: string;
    refresh_token?: string;
    expires_in?: string;
    user_id?: string;
}
export interface AccessIdToken {
    last_name?: string;
    email?: string;
    email_verified?: string;
    iss?: string;
    sub?: string;
    aud?: string[] | string;
    exp?: number;
    nbf?: number;
    iat?: number;
    jti?: string;
}
export interface Auth0Result {
    accessToken?: string;
    access_token?: string;
    expiresIn?: number;
    expires_in?: number;
    idToken?: string;
    id_token?: string;
    refreshToken?: string;
    appState?: any;
    scope?: string;
    token_type?: string;
}

export const BASE_URL_AUTH = `https://${config.auth0Domain}`;
