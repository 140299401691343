import { MappingRule } from "Api/mappingConfigurator";
import { Moment } from "moment";
import { ComparisonFilter } from "Store/filterStore";
import { Maybe } from "types";
import { ComparisonEnums } from "Utils/constants";
import { DateRange, DATE_TIME_FORMAT, parseToMoment, Time } from "Utils/date-utils";
import { TotalSalesAndStaffHours } from "./dashboard-model";

// Delete me after moving all clients to the new dashboard
export const comparisonEnumsToComparisonFilter = {
    [ComparisonEnums.ALL_FORECAST]: ComparisonFilter.Forecast,
    [ComparisonEnums.PREVIOUS_PERIOD]: ComparisonFilter.PreviousPeriod,
    [ComparisonEnums.SAME_DAY_LAST_WEEK]: ComparisonFilter.SameDayLastWeek,
    [ComparisonEnums.SAME_PERIOD_LAST_YEAR]: ComparisonFilter.SamePeriodLastYear,
};

export const CubeName = {
    default: "actualsUnmapped",
    noPreAgg: "actualsUnmappedNoPreAgg",
    forecast: "forecastsUnmapped",
};

export const QueryKey = {
    salesAndStaffHours: "SALES_AND_STAFF_HOURS",
    forecastSales: "FORECAST_SALES",
};

export const getDefaultTotalSalesAndStaffHours = (): TotalSalesAndStaffHours => ({
    totalSales: 0,
    totalStaffHours: 0,
});

const getSamePeriodLastYear = ({ start, end }: DateRange<Moment>) => {
    return {
        start: start.subtract(1, "year").format(DATE_TIME_FORMAT),
        end: end.subtract(1, "year").format(DATE_TIME_FORMAT),
    };
};

const getSameDayLastWeek = ({ start, end }: DateRange<Moment>) => {
    const daysDifference = end
        .clone()
        .endOf("day")
        .diff(start.clone().startOf("day"), "days");

    return daysDifference > 1
        ? undefined
        : {
              start: start.subtract(7, "day").format(DATE_TIME_FORMAT),
              end: end.subtract(7, "day").format(DATE_TIME_FORMAT),
          };
};

const getPreviousPeriod = ({ start, end }: DateRange<Moment>) => {
    const daysDifference = end
        .clone()
        .endOf("day")
        .diff(start.clone().startOf("day"), "days");

    return {
        start: start.subtract(daysDifference || 1, "day").format(DATE_TIME_FORMAT),
        end: end.subtract(daysDifference || 1, "day").format(DATE_TIME_FORMAT),
    };
};

export const getComparisonPeriod = (params: {
    start: string;
    end: string;
    selectedComparison?: ComparisonFilter;
    cutOffTime?: Time;
}): Maybe<DateRange<string>> => {
    const { start, end, selectedComparison, cutOffTime } = params;
    if (!selectedComparison) {
        return;
    }

    const originalPeriod = {
        start: parseToMoment(start).add(6, "hours"),
        end: parseToMoment(end)
            .add(cutOffTime ? 0 : 1, "day")
            .add(cutOffTime?.hours ?? 5, "hours")
            .add(cutOffTime?.minutes ?? 45, "minutes"),
    };

    switch (selectedComparison) {
        case ComparisonFilter.SamePeriodLastYear:
            return getSamePeriodLastYear(originalPeriod);
        case ComparisonFilter.SameDayLastWeek:
            return getSameDayLastWeek(originalPeriod);
        case ComparisonFilter.PreviousPeriod:
            return getPreviousPeriod(originalPeriod);
        default:
            //throw new Error(`Unsupported comparison filter ${selectedComparison}`);
            return;
    }
};

export const mergeMappingRuleResults = (mappingRules: MappingRule[]) =>
    mappingRules
        .flatMap(({ result }) => result)
        .reduce((result, { areaIds, classIds, venueIds }) => {
            if (areaIds) {
                result["areaIds"] = result["areaIds"]
                    ? [...result["areaIds"], ...areaIds]
                    : areaIds;
            }
            if (classIds) {
                result["classIds"] = result["classIds"]
                    ? [...result["classIds"], ...classIds]
                    : classIds;
            }
            if (venueIds) {
                result["venueIds"] = result["venueIds"]
                    ? [...result["venueIds"], ...venueIds]
                    : venueIds;
            }

            return result;
        }, {});
