import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";
import { Auth } from "Api/auth/auth";

export const useWebviewLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { search } = useLocation();
    const values: { accessToken?: string; refreshToken?: string } =
        queryString.parse(search);

    const auth = useMemo(() => Auth.getInstance(), []);

    useEffect(() => {
        const applySession = async () => {
            if (values.accessToken && values.refreshToken) {
                await auth.setSession(values.accessToken, values.refreshToken);
            } else if (values.accessToken && !values.refreshToken) {
                await auth.setSession(values.accessToken);
            }
            setIsLoading(false);
        };
        applySession();
    }, [auth, values]);
    return isLoading;
};
