import React, { ReactElement, useEffect, useState } from "react";
import { ColumnType } from "antd/es/table";
import moment from "moment";
{
    /* Temporarily disabled function see WGL-508 */
}
//import { deleteForecast, getForecasts } from "../../Api/backend";
import { Button, Col, Row, Table, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ForecastConfiguration, Forecast } from "../../State/store";
import { RoundedCard, WranglrDarkBlueTitle } from "../HomeDashboardDesktop";
import { useHistory } from "react-router-dom";
import { CreateNewForecastButton } from "./LandingPage";
import { datetimeSort, stringSort } from "../../Utils/utils";
import _ from "lodash";

interface ForecastRow {
    key: string;
    venue: string;
    weekPeriod: moment.Moment;
    actions: ReactElement;
}

interface Props {
    forecasts: Forecast[];
}

interface ActionsElementProps {
    forecastId: string;
}

export const ForecastsTable = ({ forecasts }: Props): ReactElement => {
    //* Temporarily disabled function see WGL-508 */
    // const [, dispatch] = React.useContext(Context);

    const [rows, setRows] = useState<ForecastRow[]>([]);

    //* Temporarily disabled function see WGL-508 */
    // const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    // const [deleteModalForecastId, setDeleteModalForecastId] = useState<
    //     string | undefined
    // >(undefined);
    const history = useHistory();

    const uniqueVenues = _.uniq(
        forecasts.map((forecast) => forecast.configuration.venue!)
    );

    const columns: ColumnType<ForecastRow>[] = [
        {
            key: "venue",
            title: "Venue",
            dataIndex: "venue",
            filters: uniqueVenues.map((venue) => ({
                text: venue,
                value: venue,
            })),
            onFilter: (value, record) => record.venue === value,
            sorter: (a, b) => stringSort(a.venue, b.venue),
        },
        {
            key: "weekPeriod",
            title: "Week Period",
            dataIndex: "weekPeriod",
            render: (dateObj: moment.Moment) =>
                dateObj.format("DD/MM/YYYY") +
                " - " +
                dateObj.clone().endOf("isoWeek").format("DD/MM/YYYY"),
            sorter: (a, b) => datetimeSort(a.weekPeriod, b.weekPeriod),
            defaultSortOrder: "descend",
        },
        {
            key: "actions",
            // Was "Actions" renamed to "Edit" when "Delete" functionality was disabled.
            title: "Edit",
            width: "5vw",
            dataIndex: "actions",
        },
    ];

    //* Temporarily disabled function see WGL-508 *
    //const DeleteForecastModal = () => {
    //    const [confirmLoading, setConfirmLoading] = useState(false);
    //    const onOk = () => {
    //        setConfirmLoading(true);
    //        deleteForecast(deleteModalForecastId!)
    //            .then(() => {
    //                getForecasts().then((forecasts) => {
    //                    const action: UpdateGroupData = {
    //                        type: "UPDATE_GROUP_DATA",
    //                        payload: {
    //                            groupData: {
    //                                forecasts,
    //                            },
    //                        },
    //                    };
    //                    dispatch(action);
    //                });
    //            })
    //            .then(() => {
    //                setConfirmLoading(false);
    //                setDeleteModalVisible(false);
    //                setDeleteModalForecastId(undefined);
    //            });
    //    };
    //    return (
    //        <Modal
    //            title={"Delete Forecast"}
    //            onCancel={() => setDeleteModalVisible(false)}
    //            onOk={onOk}
    //            confirmLoading={confirmLoading}
    //            visible={deleteModalVisible}
    //        >
    //            <Typography.Text>
    //                Are you sure you want to delete this forecast?
    //            </Typography.Text>
    //        </Modal>
    //    );
    //};

    const ActionsElement = ({ forecastId }: ActionsElementProps): ReactElement => {
        return (
            <Row justify={"center"} gutter={8}>
                <Col>
                    <Tooltip title={"edit"}>
                        <Button
                            type={"primary"}
                            onClick={() => {
                                history.push(`/forecasting/${forecastId}`);
                            }}
                            icon={<EditOutlined />}
                            shape={"circle"}
                        />
                    </Tooltip>
                </Col>
                {/* Temporarily disabled function see WGL-508 */}
                {/* <Col>
                    <Tooltip title={"delete"}>
                        <Button
                            onClick={() => {
                                setDeleteModalForecastId(forecastId);
                                setDeleteModalVisible(true);
                            }}
                            icon={<DeleteOutlined />}
                            shape={"circle"}
                        />
                    </Tooltip>
                        </Col> */}
            </Row>
        );
    };

    useEffect(() => {
        setRows(
            forecasts.map((forecast: Forecast): ForecastRow => {
                const config: ForecastConfiguration = forecast.configuration;
                return {
                    key: forecast.forecastId,
                    venue: config.venue!,
                    weekPeriod: moment(config.weekPeriod),
                    actions: (
                        <div key={forecast.forecastId}>
                            <ActionsElement
                                key={forecast.forecastId}
                                forecastId={forecast.forecastId}
                            />
                        </div>
                    ),
                };
            })
        );
    }, [forecasts]);

    return (
        <RoundedCard style={{ minHeight: 300 }} bodyStyle={{ minHeight: "270px" }}>
            {/* Temporarily disabled function see WGL-508 */}
            {/* <DeleteForecastModal /> */}
            <Row style={{ height: "100%" }}>
                <Col flex={"auto"}>
                    <WranglrDarkBlueTitle level={3}>Forecasts</WranglrDarkBlueTitle>
                    <Table
                        columns={columns}
                        dataSource={rows}
                        pagination={{ pageSize: 7, hideOnSinglePage: true }}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }} justify={"end"}>
                <Col span={8}>
                    <CreateNewForecastButton />
                </Col>
            </Row>
        </RoundedCard>
    );
};
