import React from "react";
import { Button, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { MomentFormat } from "Utils/date-utils";
import { prependDollarSymbol } from "Utils/number-utils";
import { SalesAffectedEvent } from "../ForecastModel";
import { EditOutlined } from "@ant-design/icons";
import { displayTwoTimePeriods } from "Utils/utils";

interface Props {
    events: SalesAffectedEvent[];
    onEditEvent: (event: SalesAffectedEvent) => void;
}

export const ForecastEventTableMobile: React.FC<Props> = ({
    events,
    onEditEvent,
}) => {
    const columns: ColumnsType<SalesAffectedEvent> = [
        {
            key: "name",
            title: "Name",
            dataIndex: "name",
        },
        {
            key: "period",
            title: "Period",
            render: (_, { start, end }) => {
                return (
                    <>
                        <div>
                            {displayTwoTimePeriods(start.format(), end.format())}
                        </div>
                    </>
                );
            },
        },

        {
            key: "expectedSales",
            title: "Expected Sales",
            render: (_, { expectedSalesAmount, expectedSalesPercent }) =>
                expectedSalesAmount
                    ? prependDollarSymbol(expectedSalesAmount)
                    : `${expectedSalesPercent}%`,
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={events}
            pagination={{ pageSize: 5, hideOnSinglePage: true }}
            onRow={(record: SalesAffectedEvent) => ({
                onClick: () => onEditEvent(record),
            })}
        />
    );
};
