export const baseVsForecast = [
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T06:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T06:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T06:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T06:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T07:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T07:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T07:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T07:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T08:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T08:15:00",
        base: 0.1111111111,
        forecast: 0.1195513262,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T08:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T08:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T08:30:00",
        base: 0.1111111111,
        forecast: 0.1195513262,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T08:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T08:45:00",
        base: 0.746031746,
        forecast: 0.8027017617,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:00:00",
        base: 13.1170634921,
        forecast: 14.1134610288,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T09:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:15:00",
        base: 0.4140873016,
        forecast: 0.4455421747,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T09:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:15:00",
        base: 18.2698412698,
        forecast: 19.6576537821,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T09:15:00",
        base: 0.630952381,
        forecast: 0.6788807453,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:15:00",
        base: 0.2741269841,
        forecast: 0.2949502005,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T09:30:00",
        base: 1.2658730159,
        forecast: 1.3620311808,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:30:00",
        base: 0.3138095238,
        forecast: 0.3376471028,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:30:00",
        base: 1.2076190476,
        forecast: 1.2993521284,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T09:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:30:00",
        base: 20.9781746032,
        forecast: 22.5717173586,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T09:45:00",
        base: 1.4722222222,
        forecast: 1.5840550724,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:45:00",
        base: 0.3733333333,
        forecast: 0.4016924561,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:45:00",
        base: 4.1589285714,
        forecast: 4.4748488372,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T09:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T09:45:00",
        base: 24.496031746,
        forecast: 26.3567977404,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T10:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:00:00",
        base: 0.3733333333,
        forecast: 0.4016924561,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T10:00:00",
        base: 1.6468253968,
        forecast: 1.7719214421,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:00:00",
        base: 30.6944444444,
        forecast: 33.0260538671,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T10:00:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:00:00",
        base: 7.5548809524,
        forecast: 8.1287643353,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:15:00",
        base: 0.4927777778,
        forecast: 0.5302101318,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T10:15:00",
        base: 3.1111111111,
        forecast: 3.347437134,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T10:15:00",
        base: 0.0952380952,
        forecast: 0.1024725653,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T10:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:15:00",
        base: 11.4468650794,
        forecast: 12.3163911113,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T10:15:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:15:00",
        base: 37.2242063492,
        forecast: 40.0518291274,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T10:30:00",
        base: 3.6666666667,
        forecast: 3.9451937651,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:30:00",
        base: 15.7763095238,
        forecast: 16.9747085373,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:30:00",
        base: 43.380952381,
        forecast: 46.6762535069,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:30:00",
        base: 1.0231349206,
        forecast: 1.1008542299,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T10:30:00",
        base: 0.1825396825,
        forecast: 0.1964057502,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T10:30:00",
        base: 0.1904761905,
        forecast: 0.2049451307,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T10:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T10:45:00",
        base: 4.7222222222,
        forecast: 5.0809313642,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:45:00",
        base: 22.4397619048,
        forecast: 24.1443296612,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T10:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T10:45:00",
        base: 0.1904761905,
        forecast: 0.2049451307,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T10:45:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:45:00",
        base: 45.8392857143,
        forecast: 49.3213265994,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T10:45:00",
        base: 0.3702380952,
        forecast: 0.3983620977,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T10:45:00",
        base: 1.8742460317,
        forecast: 2.0166173888,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T11:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:00:00",
        base: 29.3926190476,
        forecast: 31.6253392929,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:00:00",
        base: 2.9076984127,
        forecast: 3.1285728133,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:00:00",
        base: 0.5357142857,
        forecast: 0.57640818,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:00:00",
        base: 0.9408730159,
        forecast: 1.0123435516,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T11:00:00",
        base: 4.2579365079,
        forecast: 4.5813776082,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:00:00",
        base: 55.994047619,
        forecast: 60.2474638775,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:00:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T11:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:15:00",
        base: 5.3672222222,
        forecast: 5.7749268129,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:15:00",
        base: 33.9551587302,
        forecast: 36.5344583226,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:15:00",
        base: 60.1031746032,
        forecast: 64.6687281024,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:15:00",
        base: 2.6674603175,
        forecast: 2.8700857672,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:15:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T11:15:00",
        base: 4.0535714286,
        forecast: 4.3614885618,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:15:00",
        base: 1.2988095238,
        forecast: 1.3974696097,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T11:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:30:00",
        base: 36.4292063492,
        forecast: 39.1964393884,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T11:30:00",
        base: 3.8472222222,
        forecast: 4.1394646702,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:30:00",
        base: 8.7751349206,
        forecast: 9.4417111574,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:30:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:30:00",
        base: 64.876984127,
        forecast: 69.8051654394,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:30:00",
        base: 6.5817460317,
        forecast: 7.0817082022,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:30:00",
        base: 1.6313492063,
        forecast: 1.7552696503,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:45:00",
        base: 2.221031746,
        forecast: 2.3897456173,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T11:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T11:45:00",
        base: 4.9623015873,
        forecast: 5.3392476226,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:45:00",
        base: 13.2402539683,
        forecast: 14.2460092921,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:45:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T11:45:00",
        base: 11.7932539683,
        forecast: 12.6890923708,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:45:00",
        base: 66.3670634921,
        forecast: 71.4084341178,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T11:45:00",
        base: 38.1191269841,
        forecast: 41.0147296663,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:00:00",
        base: 17.2428571429,
        forecast: 18.5526579526,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:00:00",
        base: 76.7876984127,
        forecast: 82.6206406408,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T12:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T12:00:00",
        base: 3.498015873,
        forecast: 3.7637319307,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:00:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:00:00",
        base: 2.6392857143,
        forecast: 2.8397709667,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:00:00",
        base: 17.1653730159,
        forecast: 18.4692879813,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:00:00",
        base: 40.1606349206,
        forecast: 43.2113144979,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:15:00",
        base: 25.4476190476,
        forecast: 27.3806694556,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:15:00",
        base: 3.2186507937,
        forecast: 3.4631457391,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:15:00",
        base: 22.0189047619,
        forecast: 23.691503395,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:15:00",
        base: 76.4107142857,
        forecast: 82.2150200697,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:15:00",
        base: 0.0873015873,
        forecast: 0.0939331849,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:15:00",
        base: 43.359484127,
        forecast: 46.6531544828,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T12:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T12:15:00",
        base: 3.4781746032,
        forecast: 3.7423834796,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:30:00",
        base: 32.0257936508,
        forecast: 34.4585349366,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T12:30:00",
        base: 3.248015873,
        forecast: 3.4947414467,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:30:00",
        base: 4.3305555556,
        forecast: 4.6595129393,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:30:00",
        base: 26.4754920635,
        forecast: 28.4866216957,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T12:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:30:00",
        base: 0.0515873016,
        forecast: 0.0555059729,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:30:00",
        base: 87.9166666667,
        forecast: 94.5949868683,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:30:00",
        base: 42.7477380952,
        forecast: 45.9949390381,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:45:00",
        base: 6.0865079365,
        forecast: 6.5488508625,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T12:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:45:00",
        base: 37.9765873016,
        forecast: 40.8613623935,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:45:00",
        base: 83.7222222222,
        forecast: 90.0819243037,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:45:00",
        base: 31.6273174603,
        forecast: 34.0297897233,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T12:45:00",
        base: 0.196031746,
        forecast: 0.210922697,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T12:45:00",
        base: 3.0813492063,
        forecast: 3.3154144574,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T12:45:00",
        base: 40.691547619,
        forecast: 43.7825563527,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:00:00",
        base: 0.196031746,
        forecast: 0.210922697,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:00:00",
        base: 34.698547619,
        forecast: 37.3343164706,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T13:00:00",
        base: 2.8571428571,
        forecast: 3.0741769598,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:00:00",
        base: 6.1912698413,
        forecast: 6.6615706844,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T13:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:00:00",
        base: 41.1328571429,
        forecast: 44.2573886023,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:00:00",
        base: 40.8448412698,
        forecast: 43.947494486,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:00:00",
        base: 84.1686507937,
        forecast: 90.5622644537,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T13:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:15:00",
        base: 41.4742063492,
        forecast: 44.6246673552,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:15:00",
        base: 7.0551587302,
        forecast: 7.5910822457,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T13:15:00",
        base: 3.2599206349,
        forecast: 3.5075505174,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:15:00",
        base: 41.2336507937,
        forecast: 44.3658387339,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:15:00",
        base: 0.7198412698,
        forecast: 0.7745218063,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:15:00",
        base: 36.8000793651,
        forecast: 39.5954846365,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:15:00",
        base: 112.5138888889,
        forecast: 121.0606617094,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:30:00",
        base: 41.1242063492,
        forecast: 44.2480806776,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:30:00",
        base: 0.7198412698,
        forecast: 0.7745218063,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:30:00",
        base: 41.2400793651,
        forecast: 44.3727556321,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:30:00",
        base: 6.9067460317,
        forecast: 7.4313958314,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T13:30:00",
        base: 2.6845238095,
        forecast: 2.8884454352,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:30:00",
        base: 118.9484126984,
        forecast: 127.9839644044,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:30:00",
        base: 38.1288888889,
        forecast: 41.0252331043,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T13:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T13:45:00",
        base: 3.0694444444,
        forecast: 3.3026053867,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T13:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:45:00",
        base: 0.9420634921,
        forecast: 1.0136244587,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:45:00",
        base: 40.2023809524,
        forecast: 43.2562316391,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:45:00",
        base: 116.1051587302,
        forecast: 124.9247313603,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T13:45:00",
        base: 20.7285714286,
        forecast: 22.3031538436,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:45:00",
        base: 39.6119047619,
        forecast: 42.620901734,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T13:45:00",
        base: 49.1895238095,
        forecast: 52.9260552662,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:00:00",
        base: 135.8988095238,
        forecast: 146.2219461876,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T14:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:00:00",
        base: 39.8767460317,
        forecast: 42.9058608594,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T14:00:00",
        base: 2.7400793651,
        forecast: 2.9482210983,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:00:00",
        base: 47.4362301587,
        forecast: 51.0395780354,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:00:00",
        base: 20.5511904762,
        forecast: 22.1122986907,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:00:00",
        base: 33.555952381,
        forecast: 36.1049274862,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:00:00",
        base: 0.9420634921,
        forecast: 1.0136244587,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:15:00",
        base: 39.3002777778,
        forecast: 42.2856029609,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:15:00",
        base: 29.8507936508,
        forecast: 32.118317726,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T14:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:15:00",
        base: 45.8146428571,
        forecast: 49.2948118231,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:15:00",
        base: 122.3492063492,
        forecast: 131.6430889246,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T14:15:00",
        base: 2.505952381,
        forecast: 2.6963093752,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:15:00",
        base: 20.0678571429,
        forecast: 21.5922504217,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:15:00",
        base: 0.9420634921,
        forecast: 1.0136244587,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:30:00",
        base: 26.8030385488,
        forecast: 28.8390492461,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:30:00",
        base: 45.4586904762,
        forecast: 48.9118206102,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:30:00",
        base: 128.3630952381,
        forecast: 138.1138044561,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T14:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:30:00",
        base: 40.5492857143,
        forecast: 43.6294879583,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:30:00",
        base: 17.7849206349,
        forecast: 19.1358976369,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:30:00",
        base: 1.0476190476,
        forecast: 1.1271982186,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T14:30:00",
        base: 3.1369047619,
        forecast: 3.3751901205,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T14:45:00",
        base: 3.5257936508,
        forecast: 3.7936197622,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:45:00",
        base: 45.2948809524,
        forecast: 48.7355677978,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:45:00",
        base: 22.7593877551,
        forecast: 24.4882349099,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:45:00",
        base: 40.6008730159,
        forecast: 43.6849939311,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T14:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T14:45:00",
        base: 136.9920634921,
        forecast: 147.3982458438,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:45:00",
        base: 17.7412698413,
        forecast: 19.0889310445,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T14:45:00",
        base: 1.1071428571,
        forecast: 1.1912435719,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T15:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:00:00",
        base: 0.6785714286,
        forecast: 0.730117028,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:00:00",
        base: 121.223015873,
        forecast: 130.4313508396,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:00:00",
        base: 42.1376530612,
        forecast: 45.3385107639,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:00:00",
        base: 20.0129591837,
        forecast: 21.5331823072,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T15:00:00",
        base: 3.123015873,
        forecast: 3.3602462047,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:00:00",
        base: 46.0872222222,
        forecast: 49.5880968445,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:00:00",
        base: 17.3436507937,
        forecast: 18.6611080843,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:15:00",
        base: 46.1212698413,
        forecast: 49.6247307866,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:15:00",
        base: 124.7686507937,
        forecast: 134.246319053,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T15:15:00",
        base: 3.0138888889,
        forecast: 3.2428297236,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:15:00",
        base: 45.2283673469,
        forecast: 48.6640016902,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:15:00",
        base: 0.6785714286,
        forecast: 0.730117028,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:15:00",
        base: 17.6099206349,
        forecast: 18.9476042982,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T15:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:15:00",
        base: 16.9018480726,
        forecast: 18.1857451732,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T15:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:30:00",
        base: 0.4563492063,
        forecast: 0.4910143755,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T15:30:00",
        base: 3.744047619,
        forecast: 4.0284527245,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:30:00",
        base: 36.9351190476,
        forecast: 39.7407821948,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:30:00",
        base: 3.7321428571,
        forecast: 4.0156436538,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:30:00",
        base: 137.2311507937,
        forecast: 147.6554946797,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:30:00",
        base: 14.8145464853,
        forecast: 15.9398881164,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:30:00",
        base: 48.6509863946,
        forecast: 52.3466095066,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:45:00",
        base: 0.4563492063,
        forecast: 0.4910143755,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T15:45:00",
        base: 4.4742063492,
        forecast: 4.8140757253,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:45:00",
        base: 3.4615079365,
        forecast: 3.7244507806,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:45:00",
        base: 149.7827380952,
        forecast: 161.1605248518,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:45:00",
        base: 34.8507142857,
        forecast: 37.4980420119,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T15:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T15:45:00",
        base: 15.2181179138,
        forecast: 16.374115612,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T15:45:00",
        base: 53.3491609977,
        forecast: 57.4016665481,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:00:00",
        base: 163.748015873,
        forecast: 176.1866321656,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:00:00",
        base: 58.945946712,
        forecast: 63.4235949403,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:00:00",
        base: 14.4169274376,
        forecast: 15.5120651562,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:00:00",
        base: 0.4563492063,
        forecast: 0.4910143755,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:00:00",
        base: 2.9638888889,
        forecast: 3.1890316268,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T16:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:00:00",
        base: 34.5281349206,
        forecast: 37.1509588937,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T16:00:00",
        base: 4.4742063492,
        forecast: 4.8140757253,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:15:00",
        base: 0.4841269841,
        forecast: 0.5209022071,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:15:00",
        base: 32.7882142857,
        forecast: 35.278870519,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:15:00",
        base: 61.7544274376,
        forecast: 66.4454132989,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:15:00",
        base: 13.6134920635,
        forecast: 14.6475992756,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:15:00",
        base: 3.5170634921,
        forecast: 3.7842264438,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:15:00",
        base: 174.0018650794,
        forecast: 187.2193836085,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T16:15:00",
        base: 3.8432539683,
        forecast: 4.13519498,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T16:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:30:00",
        base: 3.3948412698,
        forecast: 3.6527199849,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:30:00",
        base: 31.4634126984,
        forecast: 33.8534344384,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:30:00",
        base: 14.3388888889,
        forecast: 15.4280986482,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T16:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T16:30:00",
        base: 3.8353174603,
        forecast: 4.1266555996,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:30:00",
        base: 65.1305385488,
        forecast: 70.0779803459,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:30:00",
        base: 179.3828174603,
        forecast: 193.0090835496,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:30:00",
        base: 0.5873015873,
        forecast: 0.6319141529,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T16:45:00",
        base: 4.6825396825,
        forecast: 5.038234462,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:45:00",
        base: 2.596031746,
        forecast: 2.7932313432,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:45:00",
        base: 68.1924886621,
        forecast: 73.3725221177,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T16:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:45:00",
        base: 0.7103174603,
        forecast: 0.7642745497,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T16:45:00",
        base: 15.9412698413,
        forecast: 17.1521995598,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:45:00",
        base: 189.1502777778,
        forecast: 203.518499062,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T16:45:00",
        base: 27.8336904762,
        forecast: 29.9479914892,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:00:00",
        base: 70.0327267574,
        forecast: 75.3525482612,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T17:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:00:00",
        base: 2.2162698413,
        forecast: 2.384621989,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:00:00",
        base: 0.9484126984,
        forecast: 1.0204559631,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:00:00",
        base: 25.4180555556,
        forecast: 27.3488602635,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T17:00:00",
        base: 4.501984127,
        forecast: 4.8439635569,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:00:00",
        base: 198.628452381,
        forecast: 213.7166541572,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:00:00",
        base: 18.0031746032,
        forecast: 19.3707305992,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:15:00",
        base: 19.7324943311,
        forecast: 21.2314128014,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T17:15:00",
        base: 3.8154761905,
        forecast: 4.1053071485,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:15:00",
        base: 73.5843537415,
        forecast: 79.1739637068,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:15:00",
        base: 1.9674603175,
        forecast: 2.1169124121,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:15:00",
        base: 201.9665873016,
        forecast: 217.3083602689,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T17:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:15:00",
        base: 24.2117857143,
        forecast: 26.0509598298,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:15:00",
        base: 1.1468253968,
        forecast: 1.2339404742,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T17:30:00",
        base: 3.4742063492,
        forecast: 3.7381137894,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T17:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:30:00",
        base: 191.823531746,
        forecast: 206.3948185769,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:30:00",
        base: 2.2206349206,
        forecast: 2.3893186482,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:30:00",
        base: 75.7696712018,
        forecast: 81.5252821121,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:30:00",
        base: 1.0952380952,
        forecast: 1.1784345013,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:30:00",
        base: 23.8971428571,
        forecast: 25.7124160921,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:30:00",
        base: 26.6221768707,
        forecast: 28.6444489649,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:45:00",
        base: 184.8135147392,
        forecast: 198.8523071073,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:45:00",
        base: 22.0827777778,
        forecast: 23.7602283288,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:45:00",
        base: 3.0194444444,
        forecast: 3.2488072899,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:45:00",
        base: 33.9682086168,
        forecast: 36.5484995038,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T17:45:00",
        base: 1.0952380952,
        forecast: 1.1784345013,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T17:45:00",
        base: 77.7803854875,
        forecast: 83.6887341476,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T17:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T17:45:00",
        base: 4.7162698413,
        forecast: 5.0745268288,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:00:00",
        base: 22.3380555556,
        forecast: 24.0348975008,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:00:00",
        base: 79.7764285714,
        forecast: 85.8364005284,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:00:00",
        base: 2.5674603175,
        forecast: 2.7624895736,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:00:00",
        base: 0.8174603175,
        forecast: 0.8795561857,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:00:00",
        base: 40.0572959184,
        forecast: 43.100125665,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T18:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:00:00",
        base: 177.0789909297,
        forecast: 190.5302538956,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T18:00:00",
        base: 5.6011904762,
        forecast: 6.0266677483,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:15:00",
        base: 48.0333730159,
        forecast: 51.68208102,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T18:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:15:00",
        base: 2.6111111111,
        forecast: 2.8094561661,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:15:00",
        base: 19.5136111111,
        forecast: 20.9959027884,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:15:00",
        base: 170.1147052154,
        forecast: 183.036947556,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:15:00",
        base: 0.7103174603,
        forecast: 0.7642745497,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:15:00",
        base: 81.6523412698,
        forecast: 87.854811187,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T18:15:00",
        base: 6.0297619048,
        forecast: 6.4877942923,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 2.6547619048,
        forecast: 2.8564227585,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 18.5577777778,
        forecast: 19.9674625046,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 56.3663095238,
        forecast: 60.6480035172,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 0.7341269841,
        forecast: 0.7898926911,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 81.0941269841,
        forecast: 87.2541938635,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 6.0793650794,
        forecast: 6.5411654201,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 2.1785714286,
        forecast: 2.3440599319,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 153.1420861678,
        forecast: 164.7750555075,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 0.9007936508,
        forecast: 0.9692196804,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T18:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 6.3293650794,
        forecast: 6.8101559041,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 58.8163095238,
        forecast: 63.2841102603,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 0.5992063492,
        forecast: 0.6447232235,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 78.5154365079,
        forecast: 84.4796210665,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 139.6104988662,
        forecast: 150.2155826381,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 16.3948809524,
        forecast: 17.6402678491,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 8.3253968254,
        forecast: 8.9578100857,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 2.2793650794,
        forecast: 2.4525100635,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T18:45:00",
        base: 2.2658730159,
        forecast: 2.4379931168,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 3.7182539683,
        forecast: 4.000699738,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 117.1100623583,
        forecast: 126.0059694134,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 0.4365079365,
        forecast: 0.4696659244,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 14.0868650794,
        forecast: 15.1569306222,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 63.0634637188,
        forecast: 67.8538865102,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 12.0952380952,
        forecast: 13.0140157966,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 6.1150793651,
        forecast: 6.5795926321,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 73.1048356009,
        forecast: 78.65802044,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 2.1658730159,
        forecast: 2.3303969232,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T19:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 59.3217970522,
        forecast: 63.8279955999,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 6.255952381,
        forecast: 6.731166635,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 1.6464285714,
        forecast: 1.7714944731,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 15.2658730159,
        forecast: 16.425498284,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 6.0079365079,
        forecast: 6.4643109961,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 0.4365079365,
        forecast: 0.4696659244,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 65.8661451247,
        forecast: 70.8694650215,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 11.4595238095,
        forecast: 12.3300114231,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:15:00",
        base: 94.5626417234,
        forecast: 101.7458030566,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 59.9357482993,
        forecast: 64.4885837723,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 0.4365079365,
        forecast: 0.4696659244,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 79.1041269841,
        forecast: 85.1130296109,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 0.528968254,
        forecast: 0.5691497066,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 9.4430952381,
        forecast: 10.1604110337,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 57.1698129252,
        forecast: 61.5125425925,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 5.0178571429,
        forecast: 5.3990232857,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 19.0793650794,
        forecast: 20.5286705874,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:30:00",
        base: 7.3095238095,
        forecast: 7.8647693889,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 50.6954081633,
        forecast: 54.5463295108,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 0.5158730159,
        forecast: 0.5550597289,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 4.6765873016,
        forecast: 5.0318299266,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 9.3293650794,
        forecast: 10.0380417119,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 50.4938435374,
        forecast: 54.3294536457,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 23.7023809524,
        forecast: 25.502859696,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 6.0962301587,
        forecast: 6.5593116035,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 62.5510714286,
        forecast: 67.3025719096,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T19:45:00",
        base: 0.3841269841,
        forecast: 0.4133060135,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 42.5693310658,
        forecast: 45.8029798653,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 25.8888888889,
        forecast: 27.8554590083,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 0.0436507937,
        forecast: 0.0469665924,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 52.3963095238,
        forecast: 56.3764346315,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 0.5158730159,
        forecast: 0.5550597289,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 4.1726190476,
        forecast: 4.4895792684,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 11.0337301587,
        forecast: 11.8718736623,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 43.0416213152,
        forecast: 46.3111461964,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:00:00",
        base: 4.6119047619,
        forecast: 4.962233976,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:15:00",
        base: 0.2738095238,
        forecast: 0.2946086253,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:15:00",
        base: 30.8526643991,
        forecast: 33.1962925159,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:15:00",
        base: 39.3308333333,
        forecast: 42.3184795756,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T20:15:00",
        base: 3.4424603175,
        forecast: 3.7039562676,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:15:00",
        base: 10.2420634921,
        forecast: 11.020070463,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T20:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:15:00",
        base: 35.6476133787,
        forecast: 38.3554751027,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:15:00",
        base: 24.8412698413,
        forecast: 26.7282607897,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:15:00",
        base: 2.878968254,
        forecast: 3.0976602561,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 9.8353174603,
        forecast: 10.5824272152,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 0.9253968254,
        forecast: 0.9956917598,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 21.7738095238,
        forecast: 23.4277902481,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 0.1706349206,
        forecast: 0.1835966795,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 25.604484127,
        forecast: 27.5494503101,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 2.7678571429,
        forecast: 2.9781089298,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 30.5795975057,
        forecast: 32.9024829326,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 5.5734920635,
        forecast: 5.9968653106,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:30:00",
        base: 24.5903628118,
        forecast: 26.4582943765,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 0.9253968254,
        forecast: 0.9956917598,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 8.5853174603,
        forecast: 9.2374747953,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 2.5535714286,
        forecast: 2.7475456579,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 4.3909920635,
        forecast: 4.7245403213,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 18.1971428571,
        forecast: 19.5794330572,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 24.2820238095,
        forecast: 26.1265333467,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 18.623015873,
        forecast: 20.0376562118,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 16.2011904762,
        forecast: 17.4318642693,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T20:45:00",
        base: 0.1349206349,
        forecast: 0.1451694675,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 0.1349206349,
        forecast: 0.1451694675,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 8.5079365079,
        forecast: 9.154215836,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 20.8928571429,
        forecast: 22.4799190188,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 0.9253968254,
        forecast: 0.9956917598,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 12.1276984127,
        forecast: 13.0489418627,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 12.7738095238,
        forecast: 13.7441328246,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 1.8174603175,
        forecast: 1.9555181217,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 3.6423809524,
        forecast: 3.919063261,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:00:00",
        base: 26.0845238095,
        forecast: 28.0659547363,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 0.1349206349,
        forecast: 0.1451694675,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 1.2222222222,
        forecast: 1.3150645884,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 17.0793650794,
        forecast: 18.3767467155,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 5.5079365079,
        forecast: 5.9263300281,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 7.8452380952,
        forecast: 8.4411775689,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 0.9253968254,
        forecast: 0.9956917598,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 3.3656349206,
        forecast: 3.6212950649,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 7.2063492063,
        forecast: 7.7537574431,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:15:00",
        base: 20.2276587302,
        forecast: 21.7641908469,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 0.6785714286,
        forecast: 0.730117028,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 4.751984127,
        forecast: 5.1129540408,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 5.1865079365,
        forecast: 5.5804851202,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 3.2423412698,
        forecast: 3.4886357897,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 17.4228968254,
        forecast: 18.746373798,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 0.0555555556,
        forecast: 0.0597756631,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 0.9253968254,
        forecast: 0.9956917598,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 3.4484126984,
        forecast: 3.7103608029,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:30:00",
        base: 12.4563492063,
        forecast: 13.4025576068,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:45:00",
        base: 14.1042460317,
        forecast: 15.1756318653,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-27T21:45:00",
        base: 0.1666666667,
        forecast: 0.1793269893,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:45:00",
        base: 0.9253968254,
        forecast: 0.9956917598,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:45:00",
        base: 10.2698412698,
        forecast: 11.0499582945,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:45:00",
        base: 3.4821428571,
        forecast: 3.7466531698,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:45:00",
        base: 0.5972222222,
        forecast: 0.6425883784,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T21:45:00",
        base: 3.2423412698,
        forecast: 3.4886357897,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T21:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T21:45:00",
        base: 1.9166666667,
        forecast: 2.0622603772,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T22:00:00",
        base: 11.8887698413,
        forecast: 12.7918638144,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T22:00:00",
        base: 0.9253968254,
        forecast: 0.9956917598,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T22:00:00",
        base: 3.373015873,
        forecast: 3.6292366887,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T22:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T22:00:00",
        base: 3.2423412698,
        forecast: 3.4886357897,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T22:00:00",
        base: 1.9007936508,
        forecast: 2.0451816163,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T22:00:00",
        base: 9.1388888889,
        forecast: 9.8330965813,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T22:15:00",
        base: 8.8105952381,
        forecast: 9.4798651092,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T22:15:00",
        base: 2.4146825397,
        forecast: 2.5981065001,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T22:15:00",
        base: 6.0595238095,
        forecast: 6.519816969,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T22:15:00",
        base: 1.9007936508,
        forecast: 2.0451816163,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T22:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T22:30:00",
        base: 8.0169444444,
        forecast: 8.6259270648,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T22:30:00",
        base: 1.7777777778,
        forecast: 1.9128212195,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-27T22:30:00",
        base: 5.4404761905,
        forecast: 5.8537452944,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T22:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T22:30:00",
        base: 2.2123015873,
        forecast: 2.3803522988,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T22:45:00",
        base: 0.9209126984,
        forecast: 0.9908670098,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T22:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T23:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T23:00:00",
        base: 0.3694444444,
        forecast: 0.3975081597,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T23:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-27T23:15:00",
        base: 0.1031746032,
        forecast: 0.1110119458,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T23:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-27T23:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T00:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T00:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T00:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T00:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T01:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T01:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T01:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T01:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T02:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T02:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T02:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T02:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T03:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T03:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T03:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T03:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T04:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T04:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T04:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T04:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T05:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T05:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T05:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T05:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T06:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T06:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T06:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T06:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T07:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T07:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T07:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T07:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T07:45:00",
        base: 0.119047619,
        forecast: 0.1280907067,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T08:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T08:00:00",
        base: 4.8134920635,
        forecast: 5.1791342393,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T08:15:00",
        base: 9.248015873,
        forecast: 9.9505130624,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T08:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T08:30:00",
        base: 0.4206349206,
        forecast: 0.4525871635,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T08:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T08:30:00",
        base: 10.7281746032,
        forecast: 11.5431075152,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T08:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T08:45:00",
        base: 15.2738095238,
        forecast: 16.4340376645,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T08:45:00",
        base: 1.0555555556,
        forecast: 1.1357375991,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T09:00:00",
        base: 1.0555555556,
        forecast: 1.1357375991,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T09:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T09:00:00",
        base: 30.8174603175,
        forecast: 33.158414264,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T09:00:00",
        base: 12.6388888889,
        forecast: 13.5989633571,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T09:15:00",
        base: 12.6388888889,
        forecast: 13.5989633571,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T09:15:00",
        base: 1.9047619048,
        forecast: 2.0494513066,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T09:15:00",
        base: 36.2928571429,
        forecast: 39.0497328323,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T09:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T09:30:00",
        base: 12.6388888889,
        forecast: 13.5989633571,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T09:30:00",
        base: 2.1111111111,
        forecast: 2.2714751981,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T09:30:00",
        base: 45.7452380952,
        forecast: 49.2201349411,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T09:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T09:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T09:45:00",
        base: 12.6388888889,
        forecast: 13.5989633571,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T09:45:00",
        base: 2.75,
        forecast: 2.9588953238,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T09:45:00",
        base: 48.8761904762,
        forecast: 52.5889205263,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T10:00:00",
        base: 12.6388888889,
        forecast: 13.5989633571,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T10:00:00",
        base: 62.7234126984,
        forecast: 67.488004556,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T10:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T10:00:00",
        base: 3.1785714286,
        forecast: 3.4200218678,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T10:15:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T10:15:00",
        base: 12.6388888889,
        forecast: 13.5989633571,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T10:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T10:15:00",
        base: 2.7579365079,
        forecast: 2.9674347043,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T10:15:00",
        base: 72.4297619048,
        forecast: 77.931666839,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T10:30:00",
        base: 12.6388888889,
        forecast: 13.5989633571,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T10:30:00",
        base: 2.3333333333,
        forecast: 2.5105778505,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T10:30:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T10:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T10:30:00",
        base: 73.4634920635,
        forecast: 79.0439211418,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T10:45:00",
        base: 3.0555555556,
        forecast: 3.2876614709,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T10:45:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T10:45:00",
        base: 101.7598015873,
        forecast: 109.4896731171,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T10:45:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T10:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T11:00:00",
        base: 2.4126984127,
        forecast: 2.595971655,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:00:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:00:00",
        base: 0.2369047619,
        forecast: 0.2549005063,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T11:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T11:00:00",
        base: 0.0873015873,
        forecast: 0.0939331849,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:00:00",
        base: 102.6732936508,
        forecast: 110.4725558062,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T11:00:00",
        base: 0.1825396825,
        forecast: 0.1964057502,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:15:00",
        base: 125.024484127,
        forecast: 134.5215859816,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:15:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T11:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:15:00",
        base: 3.571031746,
        forecast: 3.8422942308,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T11:15:00",
        base: 0.0873015873,
        forecast: 0.0939331849,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T11:15:00",
        base: 3.6964285714,
        forecast: 3.9772164418,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T11:15:00",
        base: 2.5833333333,
        forecast: 2.7795683345,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T11:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:30:00",
        base: 6.4341269841,
        forecast: 6.9228757259,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:30:00",
        base: 129.2903571429,
        forecast: 139.1115029702,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T11:30:00",
        base: 10.9702380952,
        forecast: 11.8035586187,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T11:30:00",
        base: 4.0396825397,
        forecast: 4.346544646,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:30:00",
        base: 0.0396825397,
        forecast: 0.0426969022,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T11:30:00",
        base: 0.1468253968,
        forecast: 0.1579785382,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:45:00",
        base: 11.3099206349,
        forecast: 12.1690441017,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T11:45:00",
        base: 4.0396825397,
        forecast: 4.346544646,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:45:00",
        base: 127.8042460317,
        forecast: 137.5125039821,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T11:45:00",
        base: 19.1329365079,
        forecast: 20.5863114054,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:45:00",
        base: 0.0297619048,
        forecast: 0.0320226767,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T11:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T11:45:00",
        base: 0.3452380952,
        forecast: 0.3714630493,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T11:45:00",
        base: 0.1944444444,
        forecast: 0.2092148209,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 4.4206349206,
        forecast: 4.7564349073,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 25.6964285714,
        forecast: 27.6483790325,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 0.253968254,
        forecast: 0.2732601742,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 0.6865079365,
        forecast: 0.7386564084,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 1.246031746,
        forecast: 1.3406827297,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 0.3452380952,
        forecast: 0.3714630493,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 132.393531746,
        forecast: 142.4504007238,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 0.1666666667,
        forecast: 0.1793269893,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:00:00",
        base: 15.0214285714,
        forecast: 16.1624853663,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 5.8611111111,
        forecast: 6.3063324579,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 0.253968254,
        forecast: 0.2732601742,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 0.6865079365,
        forecast: 0.7386564084,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 1.246031746,
        forecast: 1.3406827297,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 0.1666666667,
        forecast: 0.1793269893,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 136.7248809524,
        forecast: 147.1107676011,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 0.3452380952,
        forecast: 0.3714630493,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 31.5218253968,
        forecast: 33.9162842784,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:15:00",
        base: 19.6892857143,
        forecast: 21.1849219745,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 0.6865079365,
        forecast: 0.7386564084,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 0.253968254,
        forecast: 0.2732601742,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 36.128968254,
        forecast: 38.8733946262,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 0.0297619048,
        forecast: 0.0320226767,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 6.9662698413,
        forecast: 7.4954411847,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 1.246031746,
        forecast: 1.3406827297,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 0.1666666667,
        forecast: 0.1793269893,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 23.7916666667,
        forecast: 25.598927726,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:30:00",
        base: 104.498015873,
        forecast: 112.435887461,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 0.1666666667,
        forecast: 0.1793269893,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 0.253968254,
        forecast: 0.2732601742,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 42.8452380952,
        forecast: 46.0998453269,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 0.7698412698,
        forecast: 0.8283199031,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 7.3511904762,
        forecast: 7.9096011362,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 0.0297619048,
        forecast: 0.0320226767,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 0.2579365079,
        forecast: 0.2775298644,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 29.8355952381,
        forecast: 32.1019648124,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 117.9015873016,
        forecast: 126.8576201238,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T12:45:00",
        base: 1.623015873,
        forecast: 1.7463033008,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 106.2329365079,
        forecast: 114.302596026,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 6.9841269841,
        forecast: 7.5146547907,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 1.6726190476,
        forecast: 1.7996744286,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 0.0297619048,
        forecast: 0.0320226767,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 43.9107142857,
        forecast: 47.2462571515,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 3.7777777778,
        forecast: 4.0647450913,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 0.369047619,
        forecast: 0.3970811906,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 0.4087301587,
        forecast: 0.4397780929,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 0.4424603175,
        forecast: 0.4760704598,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:00:00",
        base: 30.5169444444,
        forecast: 32.8350706235,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 1.6726190476,
        forecast: 1.7996744286,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 0.369047619,
        forecast: 0.3970811906,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 40.0376984127,
        forecast: 43.0790394948,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 30.6158673469,
        forecast: 32.9415079012,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 156.6277777778,
        forecast: 168.5255270003,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 4.8888888889,
        forecast: 5.2602583535,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 0.0297619048,
        forecast: 0.0320226767,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 0.4226190476,
        forecast: 0.4547220086,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 3.7777777778,
        forecast: 4.0647450913,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:15:00",
        base: 0.3492063492,
        forecast: 0.3757327395,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 30.1115022676,
        forecast: 32.398830274,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 36.5337301587,
        forecast: 39.3089030288,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 0.0297619048,
        forecast: 0.0320226767,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 151.7130952381,
        forecast: 163.2375156603,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 1.3839285714,
        forecast: 1.4890544649,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 0.369047619,
        forecast: 0.3970811906,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 0.6468253968,
        forecast: 0.6959595062,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 5.4920634921,
        forecast: 5.9092512672,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 4.7976190476,
        forecast: 5.1620554784,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:30:00",
        base: 2.2281746032,
        forecast: 2.3974310597,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 0.369047619,
        forecast: 0.3970811906,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 4.5833333333,
        forecast: 4.9314922064,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 33.3988095238,
        forecast: 35.9358477534,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 1.3541666667,
        forecast: 1.4570317883,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 0.4801587302,
        forecast: 0.5166325169,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 145.2706349206,
        forecast: 156.3056735849,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 0.0297619048,
        forecast: 0.0320226767,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 3.6884920635,
        forecast: 3.9686770613,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 9.3055555556,
        forecast: 10.0124235706,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T13:45:00",
        base: 30.4682482993,
        forecast: 32.7826754249,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 3.5456349206,
        forecast: 3.8149682134,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 1.808531746,
        forecast: 1.9459113187,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 0.369047619,
        forecast: 0.3970811906,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 1.1150793651,
        forecast: 1.1997829524,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 30.3583276644,
        forecast: 32.6644050058,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 29.9781746032,
        forecast: 32.2553747821,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 151.5146825397,
        forecast: 163.0240311492,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 10.503968254,
        forecast: 11.3018700176,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:00:00",
        base: 4.2400793651,
        forecast: 4.5621640022,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 30.7682482993,
        forecast: 33.1054640057,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 1.2936507937,
        forecast: 1.3919190124,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 2.130952381,
        forecast: 2.2928236492,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 157.5305555556,
        forecast: 169.4968815258,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 1.808531746,
        forecast: 1.9459113187,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 5.9821428571,
        forecast: 6.4365580097,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 27.3591269841,
        forecast: 29.4373792356,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 0.369047619,
        forecast: 0.3970811906,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:15:00",
        base: 13.2063492063,
        forecast: 14.2095290588,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 1.503968254,
        forecast: 1.6182125941,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 163.1111111111,
        forecast: 175.501346885,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 30.7301587302,
        forecast: 33.0644810791,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 2.3353174603,
        forecast: 2.5127126956,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 18.9817460317,
        forecast: 20.4236362079,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 1.9513888889,
        forecast: 2.0996201667,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 21.880952381,
        forecast: 23.5430718841,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 0.369047619,
        forecast: 0.3970811906,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:30:00",
        base: 29.9017006803,
        forecast: 32.1730917519,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 1.3928571429,
        forecast: 1.4986612679,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 163.6091269841,
        forecast: 176.0371930078,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 18.9126984127,
        forecast: 20.349343598,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 37.4920634921,
        forecast: 40.3400332174,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 0.3650793651,
        forecast: 0.3928115004,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 2.9404761905,
        forecast: 3.1638404545,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 1.8700396825,
        forecast: 2.0120915171,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 21.8805555556,
        forecast: 23.5426449151,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T14:45:00",
        base: 30.7687641723,
        forecast: 33.1060190654,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 26.1365079365,
        forecast: 28.1218876782,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 2.1121031746,
        forecast: 2.2725426207,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 3.7063492063,
        forecast: 3.9878906673,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 0.5277777778,
        forecast: 0.5678687995,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 16.2777777778,
        forecast: 17.5142692906,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 33.6591269841,
        forecast: 36.215939432,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 2.6904761905,
        forecast: 2.8948499705,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 130.1468253968,
        forecast: 140.0330302108,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:00:00",
        base: 49.1587301587,
        forecast: 52.8929224701,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 0.4722222222,
        forecast: 0.5080931364,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 36.066547619,
        forecast: 38.806232399,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 39.3440759637,
        forecast: 42.3327281418,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 3.3507936508,
        forecast: 3.6053264235,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 2.5595238095,
        forecast: 2.7539501932,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 138.375,
        forecast: 148.8862328861,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 65.4083333333,
        forecast: 70.3768769602,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 13.2142857143,
        forecast: 14.2180684393,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 4.7579365079,
        forecast: 5.1193585762,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T15:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 64.8329365079,
        forecast: 69.757771878,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 10.9603174603,
        forecast: 11.7928843932,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 0.5277777778,
        forecast: 0.5678687995,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 2.746031746,
        forecast: 2.9546256336,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 166.2896825397,
        forecast: 178.9213687528,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 39.0431349206,
        forecast: 42.0089270345,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 43.7190759637,
        forecast: 47.0400616116,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 6.5170634921,
        forecast: 7.0121122516,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T15:30:00",
        base: 4.8154761905,
        forecast: 5.1812690844,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 173.3591269841,
        forecast: 186.5278218833,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 41.9748809524,
        forecast: 45.1633741705,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 9.3571428571,
        forecast: 10.0679295435,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 47.5028061224,
        forecast: 51.1112112382,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 9.6972222222,
        forecast: 10.4338419955,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 2.1507936508,
        forecast: 2.3141721003,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 65.3726190476,
        forecast: 70.3384497482,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 4.75,
        forecast: 5.1108191957,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T15:45:00",
        base: 0.5992063492,
        forecast: 0.6447232235,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 196.4742063492,
        forecast: 211.3987674264,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 14.7400793651,
        forecast: 15.8597643296,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 62.6702380952,
        forecast: 67.430790707,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 2.5704365079,
        forecast: 2.7656918413,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 0.8134920635,
        forecast: 0.8752864955,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 46.3916950113,
        forecast: 49.9156979761,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 46.7780555556,
        forecast: 50.3314072152,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 8.8531746032,
        forecast: 9.5256788853,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T16:00:00",
        base: 4.6329365079,
        forecast: 4.9848633342,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 201.4111111111,
        forecast: 216.7106890316,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 3.6973072562,
        forecast: 3.9781618732,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 54.1313095238,
        forecast: 58.2432285904,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 8.9186507937,
        forecast: 9.5961287739,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 20.1833333333,
        forecast: 21.7164984071,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 33.3087585034,
        forecast: 35.8389562832,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 4.2361111111,
        forecast: 4.557894312,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 44.7694444444,
        forecast: 48.1702181155,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:15:00",
        base: 0.8134920635,
        forecast: 0.8752864955,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 35.8527777778,
        forecast: 38.5762241867,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 29.5071712018,
        forecast: 31.7485930505,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 6.9274659864,
        forecast: 7.4536897139,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 5.0218253968,
        forecast: 5.4032929759,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 0.8531746032,
        forecast: 0.9179833977,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 61.7749603175,
        forecast: 66.467505896,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 27.296031746,
        forecast: 29.369491161,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 215.0642857143,
        forecast: 231.4009852094,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:30:00",
        base: 9.2043650794,
        forecast: 9.9035464699,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 227.3023809524,
        forecast: 244.568709854,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 24.1861111111,
        forecast: 26.023334934,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 0.8015873016,
        forecast: 0.8624774248,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 35.2311904762,
        forecast: 37.9074199103,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 9.9107142857,
        forecast: 10.6635513294,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 25.2512188209,
        forecast: 27.1693502874,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 8.6854024943,
        forecast: 9.3451624822,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 4.8075396825,
        forecast: 5.1727297039,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T16:45:00",
        base: 69.6086904762,
        forecast: 74.8963013632,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 75.2520634921,
        forecast: 80.9683559186,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 248.4611111111,
        forecast: 267.3346981177,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 4.4404761905,
        forecast: 4.7777833584,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 47.0952777778,
        forecast: 50.6727262516,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 9.5257936508,
        forecast: 10.2493913779,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 0.8571428571,
        forecast: 0.922253088,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 16.9592120181,
        forecast: 18.2474665951,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 11.4583333333,
        forecast: 12.328730516,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:00:00",
        base: 6.2222222222,
        forecast: 6.6948742681,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 60.5974603175,
        forecast: 65.2005607164,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 1.0158730159,
        forecast: 1.0930406968,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 10.8551587302,
        forecast: 11.6797376023,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 5.0853174603,
        forecast: 5.4716080195,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 5.9047619048,
        forecast: 6.3532990503,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 1.7380952381,
        forecast: 1.8701243172,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 59.3510770975,
        forecast: 63.8594998141,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 85.2969047619,
        forecast: 91.7762227776,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:15:00",
        base: 242.2830725624,
        forecast: 260.6873638005,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 125.3986961451,
        forecast: 134.9242238691,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 1.4900793651,
        forecast: 1.6032686784,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 11.4821428571,
        forecast: 12.3543486573,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 1.0396825397,
        forecast: 1.1186588382,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 5.7341269841,
        forecast: 6.1697023708,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 143.9514285714,
        forecast: 154.8862577675,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 1.1944444444,
        forecast: 1.2851767568,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 258.1199206349,
        forecast: 277.7272095119,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:30:00",
        base: 93.2957142857,
        forecast: 100.3826373581,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 100.3679365079,
        forecast: 107.9920792717,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 8.8948412698,
        forecast: 9.5705106326,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 0.8611111111,
        forecast: 0.9265227782,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 6.4107142857,
        forecast: 6.8976845536,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 140.3004818594,
        forecast: 150.9579780752,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 3.1865079365,
        forecast: 3.4285612483,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 242.1881746032,
        forecast: 260.5852572086,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 13.7757936508,
        forecast: 14.8222296057,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T17:45:00",
        base: 155.6544047619,
        forecast: 167.4782146857,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 0.9642857143,
        forecast: 1.0375347239,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 6.5992063492,
        forecast: 7.1004948392,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 155.691468254,
        forecast: 167.5180935924,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 233.5786507937,
        forecast: 251.321737303,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 9.751984127,
        forecast: 10.4927637206,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 14.5198412698,
        forecast: 15.6227965223,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 26.9623015873,
        forecast: 29.0104102134,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 100.6492063492,
        forecast: 108.2947149146,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:00:00",
        base: 168.0712698413,
        forecast: 180.8382888749,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 103.4214285714,
        forecast: 111.2775205037,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 5.9563492063,
        forecast: 6.4088050232,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 218.3822222222,
        forecast: 234.9709585978,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 21.9753968254,
        forecast: 23.6446905114,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 1.0714285714,
        forecast: 1.1528163599,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 175.1495181406,
        forecast: 188.4542146181,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 15.5396825397,
        forecast: 16.7201069093,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 178.9895238095,
        forecast: 192.5859145517,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:15:00",
        base: 48.1051587302,
        forecast: 51.7593197162,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 18.9563492063,
        forecast: 20.3963101905,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 100.8904761905,
        forecast: 108.5543120801,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 1.0158730159,
        forecast: 1.0930406968,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 35.403968254,
        forecast: 38.0933222226,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 6.1746031746,
        forecast: 6.6436379854,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 202.0111904762,
        forecast: 217.356351587,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 186.7230952381,
        forecast: 200.9069430377,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 78.123015873,
        forecast: 84.0573914005,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:30:00",
        base: 192.7090419501,
        forecast: 207.3475938504,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 0.7976190476,
        forecast: 0.8582077346,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 184.7570238095,
        forecast: 198.7915250172,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 118.1984126984,
        forecast: 127.1769929524,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 99.9491496599,
        forecast: 107.5414805639,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 201.9074546485,
        forecast: 217.244735785,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 20.9124716553,
        forecast: 22.5010234876,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 171.7742857143,
        forecast: 184.8225930024,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 6.251984127,
        forecast: 6.7268969448,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 51.9952380952,
        forecast: 55.9448970408,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T18:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 150.989739229,
        forecast: 162.4592121284,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 91.6920068027,
        forecast: 98.65710915,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 5.9404761905,
        forecast: 6.3917262623,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 180.7179705215,
        forecast: 194.4456574221,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 182.6147619048,
        forecast: 196.4865327509,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 0.6388888889,
        forecast: 0.6874201257,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 170.1805555556,
        forecast: 183.1078000155,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 20.829138322,
        forecast: 22.411359993,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:00:00",
        base: 82.4496031746,
        forecast: 88.7126346495,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 108.6672222222,
        forecast: 116.9217947958,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 126.0993197279,
        forecast: 135.6780681755,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 83.1209750567,
        forecast: 89.4350052395,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 0.5436507937,
        forecast: 0.5849475604,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 6.496031746,
        forecast: 6.9894828934,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 127.9332482993,
        forecast: 137.6513055116,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 21.6624716553,
        forecast: 23.3079949396,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 215.1408730159,
        forecast: 231.4833902307,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:15:00",
        base: 111.2674603175,
        forecast: 119.7195520107,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 144.7416666667,
        forecast: 155.7365238783,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 6.5674603175,
        forecast: 7.0663373174,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 127.1347222222,
        forecast: 136.7921218478,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 107.838962585,
        forecast: 116.0306189531,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 107.860430839,
        forecast: 116.0537179772,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 265.4305555556,
        forecast: 285.5931744141,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 19.2656462585,
        forecast: 20.7291020455,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 72.3495464853,
        forecast: 77.8453581009,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 0.5079365079,
        forecast: 0.5465203484,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 0.0833333333,
        forecast: 0.0896634947,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T19:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 292.4551587302,
        forecast: 314.6706187639,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 101.487414966,
        forecast: 109.1965954807,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 0.5119047619,
        forecast: 0.5507900386,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 98.8590022676,
        forecast: 106.3685234652,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 64.2967687075,
        forecast: 69.1808757334,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 160.6801587302,
        forecast: 172.885734655,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 0.0833333333,
        forecast: 0.0896634947,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 18.3450113379,
        forecast: 19.738533914,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 6.4920634921,
        forecast: 6.9852132032,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T19:45:00",
        base: 120.3986111111,
        forecast: 129.544322696,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 85.9337188209,
        forecast: 92.4614104653,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 0.2182539683,
        forecast: 0.2348329622,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 0.0833333333,
        forecast: 0.0896634947,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 5.9047619048,
        forecast: 6.3532990503,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 102.3254818594,
        forecast: 110.0983235578,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 188.505952381,
        forecast: 202.8252294606,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 51.9598639456,
        forecast: 55.9068358022,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 87.9552721088,
        forecast: 94.6365248546,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 339.7567460317,
        forecast: 365.5653262101,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:00:00",
        base: 16.2140589569,
        forecast: 17.4457102648,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 204.771031746,
        forecast: 220.3258357426,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 43.4447845805,
        forecast: 46.7449345239,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 82.6191326531,
        forecast: 88.8950419153,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 79.7947505669,
        forecast: 85.8561142981,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 0.0833333333,
        forecast: 0.0896634947,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 5.1349206349,
        forecast: 5.5249791473,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 11.4164399093,
        forecast: 12.2836547864,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 0.1626984127,
        forecast: 0.1750572991,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 368.598015873,
        forecast: 396.5974347436,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:15:00",
        base: 64.0147959184,
        forecast: 68.8774837453,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 4.0357142857,
        forecast: 4.3422749558,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 35.2591269841,
        forecast: 37.9374785295,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 73.4336394558,
        forecast: 79.0118008722,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 389.6892857143,
        forecast: 419.2908382735,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 0.1626984127,
        forecast: 0.1750572991,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 8.25,
        forecast: 8.8766859715,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 69.2342120181,
        forecast: 74.4933767965,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 0.0833333333,
        forecast: 0.0896634947,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 229.3220238095,
        forecast: 246.7417686925,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:30:00",
        base: 52.9838435374,
        forecast: 57.0085988662,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 5.8293650794,
        forecast: 6.2721749361,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 0.1071428571,
        forecast: 0.115281636,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 51.5953231293,
        forecast: 55.5146037598,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 2.6587301587,
        forecast: 2.8606924487,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 42.996031746,
        forecast: 46.2620935553,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 233.0847222222,
        forecast: 250.790288961,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 64.1487188209,
        forecast: 69.0215796908,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 29.0841269841,
        forecast: 31.2934135751,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 388.4968253968,
        forecast: 418.0077963618,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T20:45:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 0.1071428571,
        forecast: 0.115281636,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 25.1123015873,
        forecast: 27.0198806319,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 413.5853174603,
        forecast: 445.0020588523,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 3.8214285714,
        forecast: 4.1117116838,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 28.8253968254,
        forecast: 31.0150297726,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 56.2827664399,
        forecast: 60.5581143389,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 38.2927437642,
        forecast: 41.2015347131,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 251.1732142857,
        forecast: 270.2528178999,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 1.7261904762,
        forecast: 1.8573152466,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T21:00:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 42.0165816327,
        forecast: 45.2082425152,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 22.251984127,
        forecast: 23.9422879198,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 2.4404761905,
        forecast: 2.6258594865,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 23.5912698413,
        forecast: 25.3833083698,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 0.6825396825,
        forecast: 0.7343867182,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 412.1011904762,
        forecast: 443.4051947093,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 21.6896825397,
        forecast: 23.3372728154,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 0.0238095238,
        forecast: 0.0256181413,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 0.1071428571,
        forecast: 0.115281636,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:15:00",
        base: 256.466468254,
        forecast: 275.9481576871,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 41.8981292517,
        forecast: 45.0807922621,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 6.9246031746,
        forecast: 7.4506094374,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 409.5023809524,
        forecast: 440.6089745829,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 261.768452381,
        forecast: 281.6528907927,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 22.7658730159,
        forecast: 24.4952128036,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 0.1706349206,
        forecast: 0.1835966795,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 1.130952381,
        forecast: 1.2168617133,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:30:00",
        base: 19.0722222222,
        forecast: 20.520985145,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:45:00",
        base: 248.0871031746,
        forecast: 266.9322798143,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:45:00",
        base: 23.2936507937,
        forecast: 25.0630816031,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:45:00",
        base: 1.248015873,
        forecast: 1.3428175748,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T21:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:45:00",
        base: 17.3515873016,
        forecast: 18.6696474647,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T21:45:00",
        base: 39.2432539683,
        forecast: 42.2242475124,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T21:45:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:45:00",
        base: 369.3714285714,
        forecast: 397.4295973678,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T21:45:00",
        base: 0.3452380952,
        forecast: 0.3714630493,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:00:00",
        base: 325.7388888889,
        forecast: 350.4826455009,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:00:00",
        base: 240.3946428571,
        forecast: 258.655485319,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:00:00",
        base: 22.628968254,
        forecast: 24.3479084909,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:00:00",
        base: 0.380952381,
        forecast: 0.4098902613,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T22:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T22:00:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:00:00",
        base: 16.0583333333,
        forecast: 17.2781554214,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:00:00",
        base: 0.2976190476,
        forecast: 0.3202267666,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:00:00",
        base: 31.0859126984,
        forecast: 33.4472588075,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:15:00",
        base: 204.3158730159,
        forecast: 219.8361022741,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:15:00",
        base: 0.380952381,
        forecast: 0.4098902613,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:15:00",
        base: 12.7630952381,
        forecast: 13.732604661,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:15:00",
        base: 270.6873015873,
        forecast: 291.2492330511,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:15:00",
        base: 34.3392857143,
        forecast: 36.9477643361,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:15:00",
        base: 44.3926587302,
        forecast: 47.764811029,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T22:15:00",
        base: 0.0535714286,
        forecast: 0.057640818,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T22:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:15:00",
        base: 0.2976190476,
        forecast: 0.3202267666,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:30:00",
        base: 169.9797619048,
        forecast: 182.8917536902,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:30:00",
        base: 0.380952381,
        forecast: 0.4098902613,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T22:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:30:00",
        base: 10.1055555556,
        forecast: 10.8731931193,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:30:00",
        base: 31.880952381,
        forecast: 34.3026912435,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:30:00",
        base: 219.8976190476,
        forecast: 236.6014678998,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:30:00",
        base: 40.3517857143,
        forecast: 43.4169854759,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T22:30:00",
        base: 0.0535714286,
        forecast: 0.057640818,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T22:45:00",
        base: 0.0535714286,
        forecast: 0.057640818,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T22:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:45:00",
        base: 0.126984127,
        forecast: 0.1366300871,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:45:00",
        base: 35.1767857143,
        forecast: 37.8488824574,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:45:00",
        base: 148.3587301587,
        forecast: 159.6283465157,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:45:00",
        base: 122.628968254,
        forecast: 131.9441020853,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T22:45:00",
        base: 7.1599206349,
        forecast: 7.7038020675,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T22:45:00",
        base: 31.880952381,
        forecast: 34.3026912435,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:00:00",
        base: 33.5253968254,
        forecast: 36.0720508715,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:00:00",
        base: 31.880952381,
        forecast: 34.3026912435,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:00:00",
        base: 0.126984127,
        forecast: 0.1366300871,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:00:00",
        base: 4.9662698413,
        forecast: 5.3435173128,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:00:00",
        base: 81.2384920635,
        forecast: 87.4095251937,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T23:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T23:00:00",
        base: 0.0297619048,
        forecast: 0.0320226767,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:00:00",
        base: 92.1484126984,
        forecast: 99.1481845211,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:15:00",
        base: 2.5876984127,
        forecast: 2.7842649938,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T23:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:15:00",
        base: 0.126984127,
        forecast: 0.1366300871,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:15:00",
        base: 49.6551587302,
        forecast: 53.4270607169,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:15:00",
        base: 24.6208333333,
        forecast: 26.4910794979,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:15:00",
        base: 53.4325396825,
        forecast: 57.4913788392,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:15:00",
        base: 28.1111111111,
        forecast: 30.2464855326,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T23:15:00",
        base: 0.0178571429,
        forecast: 0.019213606,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T23:30:00",
        base: 0.0178571429,
        forecast: 0.019213606,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:30:00",
        base: 22.0628968254,
        forecast: 23.7388371808,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T23:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:30:00",
        base: 21.3416666667,
        forecast: 22.9628209829,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:30:00",
        base: 27.0646825397,
        forecast: 29.1205682211,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:30:00",
        base: 22.876984127,
        forecast: 24.6147641298,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:30:00",
        base: 1.3468253968,
        forecast: 1.4491328613,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:30:00",
        base: 0.126984127,
        forecast: 0.1366300871,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-28T23:45:00",
        base: 0.0178571429,
        forecast: 0.019213606,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:45:00",
        base: 22.6329365079,
        forecast: 24.3521781812,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-28T23:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-28T23:45:00",
        base: 6.1150793651,
        forecast: 6.5795926321,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:45:00",
        base: 20.4484126984,
        forecast: 22.001713714,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-28T23:45:00",
        base: 5.0634920635,
        forecast: 5.4481247233,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T00:00:00",
        base: 6.6884920635,
        forecast: 7.1965628692,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T00:00:00",
        base: 1.25,
        forecast: 1.3449524199,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T00:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T00:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T00:15:00",
        base: 5.3968253968,
        forecast: 5.8067787019,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T00:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T00:30:00",
        base: 5.3968253968,
        forecast: 5.8067787019,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T00:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T00:45:00",
        base: 5.3968253968,
        forecast: 5.8067787019,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T01:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T01:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T01:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T01:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T02:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T02:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T02:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T02:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T03:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T03:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T03:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T03:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T04:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T04:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T04:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T04:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T05:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T05:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T05:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T05:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T06:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T06:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T06:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T06:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T07:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T07:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T07:30:00",
        base: 0.0238095238,
        forecast: 0.0256181413,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T07:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T07:45:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T07:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T08:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T08:00:00",
        base: 0.3134920635,
        forecast: 0.3373055275,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T08:15:00",
        base: 3.2182539683,
        forecast: 3.46271877,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T08:15:00",
        base: 0.380952381,
        forecast: 0.4098902613,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T08:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T08:30:00",
        base: 0.380952381,
        forecast: 0.4098902613,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T08:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T08:30:00",
        base: 7.2857142857,
        forecast: 7.8391512476,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T08:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T08:45:00",
        base: 12.4067460317,
        forecast: 13.3491864791,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T08:45:00",
        base: 0.8055555556,
        forecast: 0.8667471151,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T09:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T09:00:00",
        base: 0.9761904762,
        forecast: 1.0503437946,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T09:00:00",
        base: 19.0634920635,
        forecast: 20.5115918265,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T09:15:00",
        base: 23.969047619,
        forecast: 25.7897828789,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T09:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T09:15:00",
        base: 0.0595238095,
        forecast: 0.0640453533,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T09:15:00",
        base: 1.9682539683,
        forecast: 2.1177663501,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T09:30:00",
        base: 28.6079365079,
        forecast: 30.7810507484,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T09:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T09:30:00",
        base: 0.0595238095,
        forecast: 0.0640453533,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T09:30:00",
        base: 1.9682539683,
        forecast: 2.1177663501,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T09:45:00",
        base: 1.9781746032,
        forecast: 2.1284405757,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T09:45:00",
        base: 0.0595238095,
        forecast: 0.0640453533,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T09:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T09:45:00",
        base: 36.7948412698,
        forecast: 39.5898486454,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T10:00:00",
        base: 4.2638888889,
        forecast: 4.5877821435,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T10:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T10:00:00",
        base: 0.1666666667,
        forecast: 0.1793269893,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T10:00:00",
        base: 44.0408730159,
        forecast: 47.3863029908,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T10:15:00",
        base: 4.6884920635,
        forecast: 5.0446389973,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T10:15:00",
        base: 0.1666666667,
        forecast: 0.1793269893,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T10:15:00",
        base: 48.280952381,
        forecast: 51.948466993,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T10:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T10:30:00",
        base: 0.0305555556,
        forecast: 0.0328766147,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T10:30:00",
        base: 4.2638888889,
        forecast: 4.5877821435,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T10:30:00",
        base: 0.2857142857,
        forecast: 0.307417696,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T10:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T10:30:00",
        base: 54.905952381,
        forecast: 59.0767148186,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T10:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T10:45:00",
        base: 60.6321428571,
        forecast: 65.237877809,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T10:45:00",
        base: 0.3373015873,
        forecast: 0.3629236689,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T10:45:00",
        base: 4.7083333333,
        forecast: 5.0659874484,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T10:45:00",
        base: 0.0742063492,
        forecast: 0.0798432072,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:00:00",
        base: 0.123015873,
        forecast: 0.1323603969,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T11:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:00:00",
        base: 72.5579365079,
        forecast: 78.0695778331,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:00:00",
        base: 0.2615079365,
        forecast: 0.2813725856,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:00:00",
        base: 0.3333333333,
        forecast: 0.3586539786,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T11:00:00",
        base: 5.3988095238,
        forecast: 5.808913547,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:15:00",
        base: 91.7464285714,
        forecast: 98.7156649016,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:15:00",
        base: 0.6428571429,
        forecast: 0.691689816,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:15:00",
        base: 0.1773809524,
        forecast: 0.1908551529,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T11:15:00",
        base: 5.6130952381,
        forecast: 6.039476819,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:15:00",
        base: 4.0238095238,
        forecast: 4.3294658851,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:15:00",
        base: 2.9234126984,
        forecast: 3.1454807865,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:15:00",
        base: 0.3333333333,
        forecast: 0.3586539786,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T11:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:30:00",
        base: 6.0777777778,
        forecast: 6.539457544,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:30:00",
        base: 0.6428571429,
        forecast: 0.691689816,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T11:30:00",
        base: 5.7222222222,
        forecast: 6.1568933001,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:30:00",
        base: 106.5694444444,
        forecast: 114.6646657568,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T11:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:30:00",
        base: 0.804047619,
        forecast: 0.8651246328,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:30:00",
        base: 8.4682539683,
        forecast: 9.1115189337,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:30:00",
        base: 0.380952381,
        forecast: 0.4098902613,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:45:00",
        base: 109.878968254,
        forecast: 118.225587402,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T11:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:45:00",
        base: 1.1584920635,
        forecast: 1.2464933634,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T11:45:00",
        base: 9.9634920635,
        forecast: 10.7203382094,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:45:00",
        base: 14.4761904762,
        forecast: 15.5758299298,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T11:45:00",
        base: 7.1329365079,
        forecast: 7.674768174,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:45:00",
        base: 1.7107142857,
        forecast: 1.8406634547,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T11:45:00",
        base: 0.6111111111,
        forecast: 0.6575322942,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 0.0277777778,
        forecast: 0.0298878316,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 1.4284126984,
        forecast: 1.5369176923,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 6.7083333333,
        forecast: 7.2179113203,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 2.8146825397,
        forecast: 3.0284912745,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 0.2579365079,
        forecast: 0.2775298644,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 114.4146825397,
        forecast: 123.1058433257,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 14.2198412698,
        forecast: 15.3000079415,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 0.6111111111,
        forecast: 0.6575322942,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:00:00",
        base: 22.3492063492,
        forecast: 24.0468953303,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 1.7802777778,
        forecast: 1.9155111243,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 7.3075396825,
        forecast: 7.8626345438,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 0.5753968254,
        forecast: 0.6191050822,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 3.6591269841,
        forecast: 3.9370813537,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 112.0178571429,
        forecast: 120.5269504316,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 0.4920634921,
        forecast: 0.5294415875,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 18.5079365079,
        forecast: 19.9138351954,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 1.3253968254,
        forecast: 1.4260765341,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:15:00",
        base: 28.9563492063,
        forecast: 31.1559295499,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 34.6904761905,
        forecast: 37.3256319207,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 1.3253968254,
        forecast: 1.4260765341,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 4.1702380952,
        forecast: 4.4870174543,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 0.5753968254,
        forecast: 0.6191050822,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 0.4404761905,
        forecast: 0.4739356146,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 110.5995238095,
        forecast: 119.0008777525,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 7.8472222222,
        forecast: 8.443312414,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 23.2218253968,
        forecast: 24.9858002101,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:30:00",
        base: 2.2812301587,
        forecast: 2.4545168179,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 0.5753968254,
        forecast: 0.6191050822,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 28.4142857143,
        forecast: 30.5726898656,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 10.7467063492,
        forecast: 11.5630469685,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 7.1884920635,
        forecast: 7.7345438371,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 1.3253968254,
        forecast: 1.4260765341,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 4.4015873016,
        forecast: 4.7359403942,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 109.5955555556,
        forecast: 117.9206461263,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 0.3849206349,
        forecast: 0.4141599515,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T12:45:00",
        base: 40.0317460317,
        forecast: 43.0726349595,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 31.5265873016,
        forecast: 33.9214079067,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 2.7638888889,
        forecast: 2.9738392396,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 9.244047619,
        forecast: 9.9462433721,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 42.6785714286,
        forecast: 45.9205183376,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 3.4246031746,
        forecast: 3.6847426616,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 100.9430952381,
        forecast: 108.6109281725,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 0.4365079365,
        forecast: 0.4696659244,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 10.9215079365,
        forecast: 11.7511268228,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:00:00",
        base: 4.7376984127,
        forecast: 5.097583156,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 6.9424603175,
        forecast: 7.4698230434,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 32.0583333333,
        forecast: 34.4935463964,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 0.4682539683,
        forecast: 0.5038234462,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 5.2964285714,
        forecast: 5.6987555393,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 92.6006349206,
        forecast: 99.6347584188,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 9.7202380952,
        forecast: 10.4586061988,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 10.5722222222,
        forecast: 11.3753086894,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 41.8193310658,
        forecast: 44.9960084134,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:15:00",
        base: 9.1607142857,
        forecast: 9.8565798775,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 7.0694444444,
        forecast: 7.6064531305,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 38.2439342404,
        forecast: 41.1490175233,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 5.0031746032,
        forecast: 5.3832254319,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 10.6693650794,
        forecast: 11.4798307061,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 32.3396825397,
        forecast: 34.7962674332,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 5.8492063492,
        forecast: 6.2935233872,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 0.130952381,
        forecast: 0.1408997773,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 92.9796031746,
        forecast: 100.042513835,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:30:00",
        base: 9.998015873,
        forecast: 10.7574845143,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 7.2380952381,
        forecast: 7.7879149649,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 32.4766666667,
        forecast: 34.9436571397,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 4.5579365079,
        forecast: 4.904166189,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 0.130952381,
        forecast: 0.1408997773,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 14.3432539683,
        forecast: 15.4327953074,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 112.5113492063,
        forecast: 121.0579291077,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 10.7288095238,
        forecast: 11.5437906656,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 9.4003968254,
        forecast: 10.1144691669,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T13:45:00",
        base: 33.4264739229,
        forecast: 35.9656135938,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 11.423531746,
        forecast: 12.2912853328,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 10.7297619048,
        forecast: 11.5448153913,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 7.0674603175,
        forecast: 7.6043182854,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 14.7202380952,
        forecast: 15.8384158785,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 32.3445238095,
        forecast: 34.8014764553,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 4.1738095238,
        forecast: 4.4908601755,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 0.130952381,
        forecast: 0.1408997773,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 116.8780952381,
        forecast: 125.7563816217,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:00:00",
        base: 29.0217120181,
        forecast: 31.2262574474,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 6.126984127,
        forecast: 6.5924017028,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 0.246031746,
        forecast: 0.2647207938,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 149.6974603175,
        forecast: 161.068769209,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 25.8232993197,
        forecast: 27.7848871285,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 33.5528571429,
        forecast: 36.1015971279,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 4.4325396825,
        forecast: 4.769243978,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 11.455515873,
        forecast: 12.325699036,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 12.5948412698,
        forecast: 13.5515697956,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:15:00",
        base: 17.2619047619,
        forecast: 18.5731524657,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 33.1211111111,
        forecast: 35.6370548317,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 23.6784580499,
        forecast: 25.4771195635,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 8.3956349206,
        forecast: 9.0333836027,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 21.0908730159,
        forecast: 22.6929765609,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 0.246031746,
        forecast: 0.2647207938,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 149.005,
        forecast: 160.3237082652,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 11.8586507937,
        forecast: 12.7594568656,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 5.3134920635,
        forecast: 5.7171152073,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:30:00",
        base: 26.4682539683,
        forecast: 28.4788337807,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 0.1944444444,
        forecast: 0.2092148209,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 4.2341269841,
        forecast: 4.5557594669,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 12.8242857143,
        forecast: 13.7984432842,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 7.5515873016,
        forecast: 8.1252204925,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 18.8094104308,
        forecast: 20.2382096611,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 19.1146825397,
        forecast: 20.5666708303,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 159.5503174603,
        forecast: 171.670068455,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 27.0456349206,
        forecast: 29.100073708,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T14:45:00",
        base: 33.9342063492,
        forecast: 36.5119143582,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 23.2043650794,
        forecast: 24.9670135731,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 13.4263492063,
        forecast: 14.4462406847,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 4.25,
        forecast: 4.5728382278,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 167.1973696145,
        forecast: 179.8980054951,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 38.1754761905,
        forecast: 41.0753592675,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 0.1944444444,
        forecast: 0.2092148209,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 16.3031746032,
        forecast: 17.5415953081,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 7.230952381,
        forecast: 7.7802295225,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:00:00",
        base: 17.248015873,
        forecast: 18.5582085499,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 40.5786507937,
        forecast: 43.6610836659,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 178.7926077098,
        forecast: 192.3740403237,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 19.8293253968,
        forecast: 21.3355993424,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 16.0456349206,
        forecast: 17.2644924126,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 0.2976190476,
        forecast: 0.3202267666,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 5.4325396825,
        forecast: 5.8452059139,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 22.3670634921,
        forecast: 24.0661089363,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 14.2277777778,
        forecast: 15.3085473219,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:15:00",
        base: 16.1761904762,
        forecast: 17.4049652209,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 14.2281746032,
        forecast: 15.308974291,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 23.4670634921,
        forecast: 25.2496670658,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 43.3095238095,
        forecast: 46.5993990829,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 5.2301587302,
        forecast: 5.6274517126,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 17.9142857143,
        forecast: 19.2750895382,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 0.4285714286,
        forecast: 0.461126544,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 169.0862585034,
        forecast: 181.9303780407,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 20.8313492063,
        forecast: 22.4137388204,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:30:00",
        base: 16.876984127,
        forecast: 18.1589925142,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 48.7996031746,
        forecast: 52.506515505,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 5.1428571429,
        forecast: 5.5335185277,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 182.2254648526,
        forecast: 196.067663941,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 18.8654195011,
        forecast: 20.2984732888,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 0.5277777778,
        forecast: 0.5678687995,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 28.121031746,
        forecast: 30.2571597582,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 21.7678571429,
        forecast: 23.4213857128,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 13.5535714286,
        forecast: 14.5831269532,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T15:45:00",
        base: 24.4186507937,
        forecast: 26.2735387811,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 21.876984127,
        forecast: 23.5388021939,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 52.3833333333,
        forecast: 56.3624727445,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 32.1690873016,
        forecast: 34.6127134505,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 19.9027777778,
        forecast: 21.4146313084,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 19.5479591837,
        forecast: 21.032860007,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 4.9384920635,
        forecast: 5.3136294813,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 13.6488095238,
        forecast: 14.6855995186,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 0.4126984127,
        forecast: 0.4440477831,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:00:00",
        base: 173.5149092971,
        forecast: 186.6954377223,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 18.5876417234,
        forecast: 19.9995949733,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 11.6825396825,
        forecast: 12.5699680136,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 192.4826870748,
        forecast: 207.1040446206,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 15.2896825397,
        forecast: 16.4511164254,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 59.7793650794,
        forecast: 64.3203213803,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 13.0734126984,
        forecast: 14.0664944364,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 0.5793650794,
        forecast: 0.6233747724,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 6.1488095238,
        forecast: 6.615884999,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:15:00",
        base: 29.6677380952,
        forecast: 31.921356916,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 206.6820634921,
        forecast: 222.3820331597,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 0.630952381,
        forecast: 0.6788807453,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 12.5277777778,
        forecast: 13.4794120308,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 69.5071428571,
        forecast: 74.7870399905,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 12.3591269841,
        forecast: 13.2979501964,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 21.9908163265,
        forecast: 23.6612813077,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 34.5067460317,
        forecast: 37.1279452634,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 6.3154761905,
        forecast: 6.7952119883,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:30:00",
        base: 13.2738095238,
        forecast: 14.2821137926,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 0.6031746032,
        forecast: 0.6489929137,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 224.5989002268,
        forecast: 241.6598674987,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 12.1111111111,
        forecast: 13.0310945575,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 10.7182539683,
        forecast: 11.5324332896,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 36.5503401361,
        forecast: 39.3267747322,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 11.6527777778,
        forecast: 12.5379453369,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 75.6198412698,
        forecast: 81.3640708084,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 6.3472222222,
        forecast: 6.8293695101,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T16:45:00",
        base: 43.1192063492,
        forecast: 46.3946247398,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 10.7182539683,
        forecast: 11.5324332896,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 44.4134353741,
        forecast: 47.7871659071,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 5.3313492063,
        forecast: 5.7363288133,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 11.6527777778,
        forecast: 12.5379453369,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 55.6938095238,
        forecast: 59.9244191153,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 0.5,
        forecast: 0.537980968,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 230.3032653061,
        forecast: 247.7975471928,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 84.9182539683,
        forecast: 91.3688089366,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:00:00",
        base: 12.9325396825,
        forecast: 13.9149204335,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 9.1646825397,
        forecast: 9.8608495677,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 95.9150793651,
        forecast: 103.2009744798,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 55.958276644,
        forecast: 60.2089756699,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 13.503968254,
        forecast: 14.5297558255,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 9.2876984127,
        forecast: 9.9932099646,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 0.4722222222,
        forecast: 0.5080931364,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 5.7420634921,
        forecast: 6.1782417512,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 65.2961507937,
        forecast: 70.2561728176,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:15:00",
        base: 247.1564399093,
        forecast: 265.9309215657,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 14.8888888889,
        forecast: 16.0198777129,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 103.7793650794,
        forecast: 111.6626465618,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 6.2579365079,
        forecast: 6.7333014801,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 73.8494501134,
        forecast: 79.4591973123,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 0.5753968254,
        forecast: 0.6191050822,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 247.4625907029,
        forecast: 266.2603281663,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 9.6150793651,
        forecast: 10.3454594079,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 70.8134920635,
        forecast: 76.1926220115,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:30:00",
        base: 3.8333333333,
        forecast: 4.1245207544,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 113.3130952381,
        forecast: 121.9205773201,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 6.8869047619,
        forecast: 7.4100473803,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 0.7420634921,
        forecast: 0.7984320715,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 7.2896825397,
        forecast: 7.8434209378,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 15.5873015873,
        forecast: 16.771343192,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 258.7582256236,
        forecast: 278.4140013832,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 22.3472222222,
        forecast: 24.0447604852,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 88.3090929705,
        forecast: 95.017222634,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T17:45:00",
        base: 88.1610827664,
        forecast: 94.8579692882,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 116.4527777778,
        forecast: 125.2987562237,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 34.2797619048,
        forecast: 36.8837189827,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 108.6152494331,
        forecast: 116.865874053,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 6.4781746032,
        forecast: 6.9702692874,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 0.5753968254,
        forecast: 0.6191050822,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 15.746031746,
        forecast: 16.9421308009,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 100.4723866213,
        forecast: 108.1044636179,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 255.5245748299,
        forecast: 274.9347162151,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:00:00",
        base: 12.3726190476,
        forecast: 13.3124671432,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 15.875,
        forecast: 17.0808957331,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 235.7355952381,
        forecast: 253.6425274231,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 0.6150793651,
        forecast: 0.6618019844,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 127.9630668934,
        forecast: 137.6833891839,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 7.5476190476,
        forecast: 8.1209508022,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 22.7424603175,
        forecast: 24.4700216313,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 57.2619047619,
        forecast: 61.6116299034,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 112.8568310658,
        forecast: 121.4296544379,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 115.5799603175,
        forecast: 124.3596378594,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T18:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 213.6901587302,
        forecast: 229.9224768793,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 19.2380952381,
        forecast: 20.6994581962,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 40.7662698413,
        forecast: 43.8629546196,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 119.9530612245,
        forecast: 129.0649279774,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 136.4688492063,
        forecast: 146.835287188,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 7.0753968254,
        forecast: 7.6128576658,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 95.4007936508,
        forecast: 102.647622627,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 0.6349206349,
        forecast: 0.6831504355,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:30:00",
        base: 116.7767857143,
        forecast: 125.6473764304,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 112.6617063492,
        forecast: 121.2197076702,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 144.9246882086,
        forecast: 155.9334480909,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 21.3611111111,
        forecast: 22.983742465,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 0.6865079365,
        forecast: 0.7386564084,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 119.6964285714,
        forecast: 128.7888010112,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 54.2007936508,
        forecast: 58.3179908662,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 203.4877777778,
        forecast: 218.9451033186,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 115.2199263039,
        forecast: 123.9722549651,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T18:45:00",
        base: 8.9087301587,
        forecast: 9.5854545484,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 179.4685034014,
        forecast: 193.1012783606,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 117.1061961451,
        forecast: 126.0018095153,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 104.8597222222,
        forecast: 112.8250697247,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 146.4919501134,
        forecast: 157.6197622441,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 22.5643424036,
        forecast: 24.2783735359,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 0.5833333333,
        forecast: 0.6276444626,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 163.5694444444,
        forecast: 175.9944961056,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 8.7757936508,
        forecast: 9.4424199259,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:00:00",
        base: 79.9174603175,
        forecast: 85.9881453188,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 142.9752834467,
        forecast: 153.8359627694,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 128.6432539683,
        forecast: 138.4152445857,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 241.2071428571,
        forecast: 259.5297043919,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 162.9937018141,
        forecast: 175.3750189504,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 95.2570238095,
        forecast: 102.4929317503,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 8.4265873016,
        forecast: 9.0666871864,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 0.380952381,
        forecast: 0.4098902613,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 115.5784126984,
        forecast: 124.3579726802,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 21.4929138322,
        forecast: 23.125557176,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T19:15:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 19.2349773243,
        forecast: 20.6961034396,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 126.2798129252,
        forecast: 135.8722719855,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 81.2566269841,
        forecast: 87.4290376781,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 10.3253968254,
        forecast: 11.1097339576,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 0.2142857143,
        forecast: 0.230563272,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 158.280952381,
        forecast: 170.3042799468,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 133.0086451247,
        forecast: 143.1122393056,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 290.0880952381,
        forecast: 312.1237485465,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:30:00",
        base: 105.2498412698,
        forecast: 113.2448229704,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 119.062414966,
        forecast: 128.1066265049,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 334.9194444444,
        forecast: 360.3605738295,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 0.0079365079,
        forecast: 0.0085393804,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 0.25,
        forecast: 0.268990484,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 0.0773809524,
        forecast: 0.0832589593,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 71.051468254,
        forecast: 76.4486753342,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 9.8214285714,
        forecast: 10.5674832994,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 97.4275,
        forecast: 104.8282815141,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 106.3651303855,
        forecast: 114.4448316064,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 18.6714852608,
        forecast: 20.0898074281,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T19:45:00",
        base: 195.3507936508,
        forecast: 210.1900181246,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 386.6317460317,
        forecast: 416.0010419574,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 235.5861111111,
        forecast: 253.4816881925,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 61.785,
        forecast: 66.4783082123,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 0.0773809524,
        forecast: 0.0832589593,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 97.1052891156,
        forecast: 104.4815948672,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 7.9861111111,
        forecast: 8.5927515718,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 83.6338492063,
        forecast: 89.9868383025,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 16.8123582766,
        forecast: 18.0894575591,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 0.1587301587,
        forecast: 0.1707876089,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 99.7598356009,
        forecast: 107.3377858425,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:00:00",
        base: 0.0079365079,
        forecast: 0.0085393804,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 7.9920634921,
        forecast: 8.5991561071,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 78.9392176871,
        forecast: 84.9355934844,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 71.3397619048,
        forecast: 76.7588683288,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 78.5111961451,
        forecast: 84.4750585975,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 0.0833333333,
        forecast: 0.0896634947,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 12.3421201814,
        forecast: 13.279651524,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 430.6257936508,
        forecast: 463.3369626036,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 272.0408730159,
        forecast: 292.7056243859,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 50.7413492063,
        forecast: 54.5957603244,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 0.0873015873,
        forecast: 0.0939331849,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:15:00",
        base: 0.0079365079,
        forecast: 0.0085393804,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 0.0079365079,
        forecast: 0.0085393804,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 304.8420634921,
        forecast: 327.9984567919,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 5.7857142857,
        forecast: 6.2252083437,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 43.9429365079,
        forecast: 47.2809270361,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 61.358015873,
        forecast: 66.0188895444,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 56.5398809524,
        forecast: 60.8347597675,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 8.1675170068,
        forecast: 8.7879374105,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 483.7273809524,
        forecast: 520.4722492783,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 58.0027097506,
        forecast: 62.4087078732,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:30:00",
        base: 0.119047619,
        forecast: 0.1280907067,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 42.5001587302,
        forecast: 45.7285530652,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 38.0719047619,
        forecast: 40.9639203527,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 5.1547619048,
        forecast: 5.5463275984,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 4.3214285714,
        forecast: 4.6496926518,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 0.0079365079,
        forecast: 0.0085393804,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 334.1801587302,
        forecast: 359.5651305411,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 49.0233333333,
        forecast: 52.7472406397,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 530.9404761905,
        forecast: 571.2717426325,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 43.0555555556,
        forecast: 46.3261389087,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T20:45:00",
        base: 0.119047619,
        forecast: 0.1280907067,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 0.0833333333,
        forecast: 0.0896634947,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 330.3492063492,
        forecast: 355.4431716008,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 33.523015873,
        forecast: 36.0694890574,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 523.2293650794,
        forecast: 562.9748805932,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 3.3134920635,
        forecast: 3.5651913354,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 31.8136507937,
        forecast: 34.2302772974,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 43.7631349206,
        forecast: 47.0874673722,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 3.8134920635,
        forecast: 4.1031723033,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 40.4444444444,
        forecast: 43.5166827426,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:00:00",
        base: 0.0079365079,
        forecast: 0.0085393804,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 41.0753968254,
        forecast: 44.1955634879,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 39.9419444444,
        forecast: 42.9760118698,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 2.2420634921,
        forecast: 2.4123749754,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 0.0079365079,
        forecast: 0.0085393804,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 1.2757936508,
        forecast: 1.3727054064,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 31.226984127,
        forecast: 33.5990462949,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 357.2785714286,
        forecast: 384.4181433853,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 531.4496031746,
        forecast: 571.819543888,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 22.1584920635,
        forecast: 23.8416940182,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:15:00",
        base: 0.0833333333,
        forecast: 0.0896634947,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 29.3007936508,
        forecast: 31.5265386612,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 341.4579365079,
        forecast: 367.3957424083,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 503.7611111111,
        forecast: 542.027780364,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 0.7678571429,
        forecast: 0.826185058,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 43.3531746032,
        forecast: 46.6463656753,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 0.0416666667,
        forecast: 0.0448317473,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 11.1803174603,
        forecast: 12.0295960191,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 1.2936507937,
        forecast: 1.3919190124,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:30:00",
        base: 34.5163265306,
        forecast: 37.1382535155,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 39.7698412698,
        forecast: 42.7908354049,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 26.1698412698,
        forecast: 28.157753076,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 0.1765873016,
        forecast: 0.1900012149,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 0.0535714286,
        forecast: 0.057640818,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 469.9158730159,
        forecast: 505.6115924606,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 0.2619047619,
        forecast: 0.2817995547,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 321.0226190476,
        forecast: 345.4081186721,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 0.8095238095,
        forecast: 0.8710168053,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T21:45:00",
        base: 33.6195011338,
        forecast: 36.1733035254,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:00:00",
        base: 278.4583900227,
        forecast: 299.6106284084,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:00:00",
        base: 41.2329931973,
        forecast: 44.3651311853,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T22:00:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:00:00",
        base: 24.0791666667,
        forecast: 25.9082667826,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T22:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:00:00",
        base: 44.3083333333,
        forecast: 47.6740801118,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:00:00",
        base: 405.0810090703,
        forecast: 435.8517467331,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:00:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:15:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:15:00",
        base: 20.1967063492,
        forecast: 21.7308872632,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:15:00",
        base: 338.1599773243,
        forecast: 363.8472638603,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:15:00",
        base: 240.7266439909,
        forecast: 259.0127059016,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T22:15:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:15:00",
        base: 44.093707483,
        forecast: 47.4431508663,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T22:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:15:00",
        base: 47.1535714286,
        forecast: 50.7354480009,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:30:00",
        base: 45.3194444444,
        forecast: 48.7619971803,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:30:00",
        base: 246.0837868481,
        forecast: 264.7767877013,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:30:00",
        base: 16.2613888889,
        forecast: 17.49663547,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:30:00",
        base: 185.8941043084,
        forecast: 200.0149803521,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:30:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T22:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T22:30:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:30:00",
        base: 46.5520408163,
        forecast: 50.0882239588,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:45:00",
        base: 12.8721031746,
        forecast: 13.8498930514,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:45:00",
        base: 173.2861678005,
        forecast: 186.4493205788,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:45:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T22:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T22:45:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T22:45:00",
        base: 38.5337698413,
        forecast: 41.4608695976,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:45:00",
        base: 41.2091439909,
        forecast: 44.339470347,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T22:45:00",
        base: 142.1064058957,
        forecast: 152.9010835975,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:00:00",
        base: 10.0851984127,
        forecast: 10.8512896085,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:00:00",
        base: 82.0829931973,
        forecast: 88.3181762686,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:00:00",
        base: 36.3182709751,
        forecast: 39.0770771484,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T23:00:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:00:00",
        base: 29.069484127,
        forecast: 31.2776584181,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T23:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:00:00",
        base: 103.4528344671,
        forecast: 111.3113120521,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:00:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:15:00",
        base: 54.0841836735,
        forecast: 58.1925229692,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-29T23:15:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:15:00",
        base: 31.0356746032,
        forecast: 33.3932045293,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:15:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T23:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:15:00",
        base: 71.7583900227,
        forecast: 77.209296249,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:15:00",
        base: 17.0496428571,
        forecast: 18.3447667357,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:15:00",
        base: 6.7328174603,
        forecast: 7.244255309,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:30:00",
        base: 27.1848809524,
        forecast: 29.2498971379,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:30:00",
        base: 3.6879761905,
        forecast: 3.9681220016,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:30:00",
        base: 23.7556122449,
        forecast: 25.5601345405,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:30:00",
        base: 30.9091836735,
        forecast: 33.2571051037,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:30:00",
        base: 17.0496428571,
        forecast: 18.3447667357,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T23:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-29T23:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:45:00",
        base: 15.2773412698,
        forecast: 16.4378376888,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:45:00",
        base: 10.4563888889,
        forecast: 11.2506764319,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:45:00",
        base: 1.5853968254,
        forecast: 1.7058266375,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-29T23:45:00",
        base: 11.8412698413,
        forecast: 12.7407556224,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-29T23:45:00",
        base: 13.6111111111,
        forecast: 14.6450374614,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T00:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T00:00:00",
        base: 5.4921031746,
        forecast: 5.9092939641,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T00:00:00",
        base: 7.7023412698,
        forecast: 8.287426024,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T00:00:00",
        base: 19.3412698413,
        forecast: 20.810470142,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T00:00:00",
        base: 0.5126984127,
        forecast: 0.5516439767,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T00:00:00",
        base: 15.7936507937,
        forecast: 16.9933670835,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T00:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T00:15:00",
        base: 2.1229761905,
        forecast: 2.2842415719,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T00:15:00",
        base: 18.6944444444,
        forecast: 20.1145106358,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T00:15:00",
        base: 3.3532142857,
        forecast: 3.6079309345,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T00:15:00",
        base: 15,
        forecast: 16.1394290391,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T00:30:00",
        base: 16.2063492063,
        forecast: 17.4374148666,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T00:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T00:30:00",
        base: 12.4563492063,
        forecast: 13.4025576068,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T00:45:00",
        base: 12.4563492063,
        forecast: 13.4025576068,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T00:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T00:45:00",
        base: 16.2063492063,
        forecast: 17.4374148666,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T01:00:00",
        base: 12.4563492063,
        forecast: 13.4025576068,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T01:00:00",
        base: 16.2063492063,
        forecast: 17.4374148666,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T01:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T01:15:00",
        base: 12.4563492063,
        forecast: 13.4025576068,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T01:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T01:15:00",
        base: 16.2063492063,
        forecast: 17.4374148666,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T01:30:00",
        base: 16.2063492063,
        forecast: 17.4374148666,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T01:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T01:30:00",
        base: 12.4563492063,
        forecast: 13.4025576068,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T01:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T02:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T02:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T02:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T02:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T03:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T03:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T03:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T03:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T04:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T04:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T04:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T04:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T05:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T05:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T05:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T05:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T06:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T06:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T06:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T06:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T07:00:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T07:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T07:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T07:15:00",
        base: 0.4007936508,
        forecast: 0.4312387124,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T07:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T07:30:00",
        base: 0.4007936508,
        forecast: 0.4312387124,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T07:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T07:45:00",
        base: 0.4007936508,
        forecast: 0.4312387124,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T08:00:00",
        base: 1.7619047619,
        forecast: 1.8957424586,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T08:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T08:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T08:15:00",
        base: 5.5496031746,
        forecast: 5.9711617755,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T08:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T08:30:00",
        base: 19.0912698413,
        forecast: 20.541479658,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T08:30:00",
        base: 0.6408730159,
        forecast: 0.6895549709,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T08:45:00",
        base: 25.8224489796,
        forecast: 27.7839721949,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T08:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T08:45:00",
        base: 1.0694444444,
        forecast: 1.1506815148,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T09:00:00",
        base: 28.6041950113,
        forecast: 30.7770250405,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T09:00:00",
        base: 1.4900793651,
        forecast: 1.6032686784,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T09:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T09:15:00",
        base: 0.0396825397,
        forecast: 0.0426969022,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T09:15:00",
        base: 35.9811791383,
        forecast: 38.7143791632,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T09:15:00",
        base: 1.7003968254,
        forecast: 1.8295622601,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T09:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T09:30:00",
        base: 0.0396825397,
        forecast: 0.0426969022,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T09:30:00",
        base: 41.7141156463,
        forecast: 44.8828006269,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T09:30:00",
        base: 1.9146825397,
        forecast: 2.0601255321,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T09:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T09:45:00",
        base: 2.0853174603,
        forecast: 2.2437222117,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T09:45:00",
        base: 0.0396825397,
        forecast: 0.0426969022,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T09:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T09:45:00",
        base: 45.5414965986,
        forecast: 49.000916846,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T10:00:00",
        base: 0.130952381,
        forecast: 0.1408997773,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T10:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T10:00:00",
        base: 2.0853174603,
        forecast: 2.2437222117,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T10:00:00",
        base: 46.4403061224,
        forecast: 49.9680016813,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T10:15:00",
        base: 0.1865079365,
        forecast: 0.2006754404,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T10:15:00",
        base: 41.9859410431,
        forecast: 45.1752744071,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T10:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T10:15:00",
        base: 2.0396825397,
        forecast: 2.1946207741,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T10:30:00",
        base: 0.2738095238,
        forecast: 0.2946086253,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T10:30:00",
        base: 2.4047619048,
        forecast: 2.5874322745,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T10:30:00",
        base: 49.3698412698,
        forecast: 53.1200699899,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T10:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T10:45:00",
        base: 0.3253968254,
        forecast: 0.3501145982,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T10:45:00",
        base: 2.1984126984,
        forecast: 2.365408383,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T10:45:00",
        base: 62.226984127,
        forecast: 66.9538663092,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T10:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:00:00",
        base: 0.0305555556,
        forecast: 0.0328766147,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T11:00:00",
        base: 2.1944444444,
        forecast: 2.3611386928,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T11:00:00",
        base: 0.2857142857,
        forecast: 0.307417696,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T11:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:00:00",
        base: 66.9333333333,
        forecast: 72.0177189125,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:15:00",
        base: 0.0305555556,
        forecast: 0.0328766147,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T11:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:15:00",
        base: 3.496031746,
        forecast: 3.7615970856,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:15:00",
        base: 71.6607142857,
        forecast: 77.1042008739,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T11:15:00",
        base: 4.5198412698,
        forecast: 4.8631771629,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T11:15:00",
        base: 2.4880952381,
        forecast: 2.6770957692,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T11:15:00",
        base: 0.2857142857,
        forecast: 0.307417696,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:30:00",
        base: 0.0472222222,
        forecast: 0.0508093136,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T11:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:30:00",
        base: 83.248015873,
        forecast: 89.5716963222,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T11:30:00",
        base: 9.2202380952,
        forecast: 9.9206252308,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T11:30:00",
        base: 0.4007936508,
        forecast: 0.4312387124,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:30:00",
        base: 8.1218253968,
        forecast: 8.7387749774,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T11:30:00",
        base: 2.746031746,
        forecast: 2.9546256336,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T11:45:00",
        base: 3.5515873016,
        forecast: 3.8213727487,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T11:45:00",
        base: 0.3888888889,
        forecast: 0.4184296418,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:45:00",
        base: 98.4682539683,
        forecast: 105.9480931686,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:45:00",
        base: 12.746031746,
        forecast: 13.7142449931,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T11:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:45:00",
        base: 0.7896825397,
        forecast: 0.8496683542,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T11:45:00",
        base: 15.5297619048,
        forecast: 16.7094326838,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T11:45:00",
        base: 0.6662698413,
        forecast: 0.7168809883,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:00:00",
        base: 0.3333333333,
        forecast: 0.3586539786,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:00:00",
        base: 23.5138888889,
        forecast: 25.3000494104,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T12:00:00",
        base: 4.6071428571,
        forecast: 4.9571103477,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:00:00",
        base: 108.3908730159,
        forecast: 116.6244535687,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:00:00",
        base: 0.0952380952,
        forecast: 0.1024725653,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:00:00",
        base: 17.8388888889,
        forecast: 19.193965424,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:00:00",
        base: 0.7896825397,
        forecast: 0.8496683542,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T12:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:00:00",
        base: 0.8138888889,
        forecast: 0.8757134645,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:15:00",
        base: 1.1936507937,
        forecast: 1.2843228188,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:15:00",
        base: 0.7103174603,
        forecast: 0.7642745497,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:15:00",
        base: 31.6488095238,
        forecast: 34.0529143655,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T12:15:00",
        base: 4.2341269841,
        forecast: 4.5557594669,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:15:00",
        base: 119.7361111111,
        forecast: 128.8314979134,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:15:00",
        base: 0.246031746,
        forecast: 0.2647207938,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:15:00",
        base: 22.0785714286,
        forecast: 23.7557024571,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:15:00",
        base: 0.7896825397,
        forecast: 0.8496683542,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T12:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:30:00",
        base: 2.2222222222,
        forecast: 2.3910265243,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:30:00",
        base: 124.5694444444,
        forecast: 134.0319806038,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:30:00",
        base: 0.2976190476,
        forecast: 0.3202267666,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:30:00",
        base: 28.3396825397,
        forecast: 30.4924196894,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:30:00",
        base: 39.3756802721,
        forecast: 42.3667331747,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:30:00",
        base: 1.0833333333,
        forecast: 1.1656254306,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T12:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T12:30:00",
        base: 4.0198412698,
        forecast: 4.3251961949,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:30:00",
        base: 0.7896825397,
        forecast: 0.8496683542,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:45:00",
        base: 1.1825396825,
        forecast: 1.2723676862,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:45:00",
        base: 130.2261904762,
        forecast: 140.1184240153,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:45:00",
        base: 0.3531746032,
        forecast: 0.3800024298,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T12:45:00",
        base: 4.3353174603,
        forecast: 4.6646365675,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:45:00",
        base: 2.6349206349,
        forecast: 2.8350743074,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T12:45:00",
        base: 46.2810090703,
        forecast: 49.7966041167,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T12:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:45:00",
        base: 0.7896825397,
        forecast: 0.8496683542,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T12:45:00",
        base: 32.4543650794,
        forecast: 34.9196614806,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:00:00",
        base: 3.0738095238,
        forecast: 3.307302046,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:00:00",
        base: 0.7896825397,
        forecast: 0.8496683542,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T13:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T13:00:00",
        base: 5.7162698413,
        forecast: 6.1504887648,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:00:00",
        base: 49.8941043084,
        forecast: 53.6841570638,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:00:00",
        base: 1.619047619,
        forecast: 1.7420336106,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:00:00",
        base: 140.0416666667,
        forecast: 150.6795027794,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:00:00",
        base: 0.3531746032,
        forecast: 0.3800024298,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:00:00",
        base: 35.0464285714,
        forecast: 37.7086231336,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T13:15:00",
        base: 6.1448412698,
        forecast: 6.6116153088,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:15:00",
        base: 49.6460884354,
        forecast: 53.4173014249,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:15:00",
        base: 3.2261904762,
        forecast: 3.4712581505,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:15:00",
        base: 143.880952381,
        forecast: 154.8104280692,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:15:00",
        base: 3.5186507937,
        forecast: 3.7859343198,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:15:00",
        base: 0.7896825397,
        forecast: 0.8496683542,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T13:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:15:00",
        base: 34.3786507937,
        forecast: 36.9901196631,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:15:00",
        base: 0.2976190476,
        forecast: 0.3202267666,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 149.0238095238,
        forecast: 160.3439465969,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 3.5369047619,
        forecast: 3.8055748949,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 0.2182539683,
        forecast: 0.2348329622,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 47.187755102,
        forecast: 50.7722283324,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 5.9464285714,
        forecast: 6.3981307977,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 34.0786507937,
        forecast: 36.6673310823,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 1.626984127,
        forecast: 1.750572991,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 0.0992063492,
        forecast: 0.1067422555,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:30:00",
        base: 3.7182539683,
        forecast: 4.000699738,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 2.5595238095,
        forecast: 2.7539501932,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 4.7242063492,
        forecast: 5.0830662093,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 41.3266439909,
        forecast: 44.4658958745,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 155.755952381,
        forecast: 167.5874760585,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 3.6996031746,
        forecast: 3.980632194,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 0.7718253968,
        forecast: 0.8304547482,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 3.7579365079,
        forecast: 4.0433966402,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 0.2182539683,
        forecast: 0.2348329622,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T13:45:00",
        base: 33.5024603175,
        forecast: 36.047372062,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 1.126984127,
        forecast: 1.212592023,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 148.8928571429,
        forecast: 160.2030468196,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 3.1547619048,
        forecast: 3.3944037265,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 4.9158730159,
        forecast: 5.289292247,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 0.7579365079,
        forecast: 0.8155108324,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 6.3908730159,
        forecast: 6.8763361025,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 5.2579365079,
        forecast: 5.6573395441,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 34.9575963719,
        forecast: 37.6130430682,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:00:00",
        base: 33.3512698413,
        forecast: 35.8846968646,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 3.8373015873,
        forecast: 4.1287904447,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 5.3849206349,
        forecast: 5.7939696313,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 1.7222222222,
        forecast: 1.8530455563,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 33.5147619048,
        forecast: 36.0606081017,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 5.3404761905,
        forecast: 5.7461491008,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 0.7976190476,
        forecast: 0.8582077346,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 145.7738095238,
        forecast: 156.8470703051,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 6.3908730159,
        forecast: 6.8763361025,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:15:00",
        base: 30.9112811791,
        forecast: 33.25936194,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 26.9702380952,
        forecast: 29.0189495938,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 151.501984127,
        forecast: 163.0103681405,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 35.9416269841,
        forecast: 38.6718225508,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 0.9563492063,
        forecast: 1.0289953435,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 2.1666666667,
        forecast: 2.3312508612,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 6.4563492063,
        forecast: 6.9467859912,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 5.6468253968,
        forecast: 6.0757691859,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 5.9226190476,
        forecast: 6.3725126563,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:30:00",
        base: 4.8253968254,
        forecast: 5.1919433099,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 3.4523809524,
        forecast: 3.7146304931,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 21.0595238095,
        forecast: 22.6592460081,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 6.425,
        forecast: 6.9130554384,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 151.5535714286,
        forecast: 163.0658741134,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 1.0119047619,
        forecast: 1.0887710066,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 7.7777777778,
        forecast: 8.3685928351,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 34.8332936508,
        forecast: 37.4792980718,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 5.3611111111,
        forecast: 5.7683514899,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T14:45:00",
        base: 5.619047619,
        forecast: 6.0458813543,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 3.5119047619,
        forecast: 3.7786758465,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 18.5793650794,
        forecast: 19.9906896194,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 6.780952381,
        forecast: 7.2960466513,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 4.0119047619,
        forecast: 4.3166568144,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 39.5859920635,
        forecast: 42.5930206569,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 152.7994047619,
        forecast: 164.4063433586,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 7.7777777778,
        forecast: 8.3685928351,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 5.0515873016,
        forecast: 5.4353156526,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:00:00",
        base: 1.0476190476,
        forecast: 1.1271982186,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 9.0924603175,
        forecast: 9.7831412057,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 20.8253968254,
        forecast: 22.407334285,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 4.619047619,
        forecast: 4.9699194184,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 15.7043650794,
        forecast: 16.8972990536,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 155.9501984127,
        forecast: 167.7964773949,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 10.475,
        forecast: 11.270701279,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 3.9523809524,
        forecast: 4.2526114611,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 43.9113888889,
        forecast: 47.2469829988,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:15:00",
        base: 1.1825396825,
        forecast: 1.2723676862,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 43.0746031746,
        forecast: 46.3466334217,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 4.6666666667,
        forecast: 5.0211557011,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 171.3886904762,
        forecast: 184.4077072035,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 1.2936507937,
        forecast: 1.3919190124,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 48.1070238095,
        forecast: 51.7613264706,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 35.1071428571,
        forecast: 37.773949394,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 14.8472222222,
        forecast: 15.9750459656,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 11.7087301587,
        forecast: 12.598147969,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T15:30:00",
        base: 5.5634920635,
        forecast: 5.9861056912,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 53.7292460317,
        forecast: 57.8106235771,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 189.6783730159,
        forecast: 204.0867094367,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 0.8095238095,
        forecast: 0.8710168053,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 60.3801587302,
        forecast: 64.9667524799,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 5.246031746,
        forecast: 5.6445304735,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 7.2777777778,
        forecast: 7.8306118671,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 14.2835034014,
        forecast: 15.3685059718,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 14.1408730159,
        forecast: 15.2150411061,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T15:45:00",
        base: 70.25,
        forecast: 75.586326,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 20.2434240363,
        forecast: 21.7811537162,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 11.4801587302,
        forecast: 12.3522138122,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 1.0436507937,
        forecast: 1.1229285284,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 18.7896825397,
        forecast: 20.2169832012,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 83.2003968254,
        forecast: 89.5204600395,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 78.798015873,
        forecast: 84.7836657072,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 6.7142857143,
        forecast: 7.2243158556,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 207.269484127,
        forecast: 223.0140754032,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:00:00",
        base: 57.6858730159,
        forecast: 62.0678036067,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 78.3535714286,
        forecast: 84.3054604024,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 231.7954365079,
        forecast: 249.4030666079,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 67.7423412698,
        forecast: 72.888180658,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 26.4160430839,
        forecast: 28.4226568565,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 1.0833333333,
        forecast: 1.1656254306,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 7.4841269841,
        forecast: 8.0526357587,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 29.0317460317,
        forecast: 31.2370536641,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 82.2123015873,
        forecast: 88.4573071742,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:15:00",
        base: 10.2936507937,
        forecast: 11.0755764359,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 80.288968254,
        forecast: 86.3878737174,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 77.0678571429,
        forecast: 82.9220807704,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 32.9563492063,
        forecast: 35.4597772937,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 1.0634920635,
        forecast: 1.1442769795,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 30.9057256236,
        forecast: 33.2533843737,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 7.1051587302,
        forecast: 7.6448803425,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 252.549047619,
        forecast: 271.7331621968,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 79.2599206349,
        forecast: 85.2806576491,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:30:00",
        base: 9.9880952381,
        forecast: 10.7468102888,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 6.7678571429,
        forecast: 7.2819566736,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 79.2599206349,
        forecast: 85.2806576491,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 9.9563492063,
        forecast: 10.712652767,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 1.2896825397,
        forecast: 1.3876493221,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 40.6553287982,
        forecast: 43.7435862801,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 77.0083333333,
        forecast: 82.8580354171,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 93.2858560091,
        forecast: 100.3720302276,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 39.6825396825,
        forecast: 42.69690222,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T16:45:00",
        base: 277.7341666667,
        forecast: 298.8313916443,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 70.1761904762,
        forecast: 75.5069097619,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 62.0172335601,
        forecast: 66.7281826832,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 11.2341269841,
        forecast: 12.0874930185,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 104.9271258503,
        forecast: 112.8975934629,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 64.5853174603,
        forecast: 69.4913432081,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 70.0634920635,
        forecast: 75.3856505596,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 299.9238095238,
        forecast: 322.7066027307,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 1.2103174603,
        forecast: 1.3022555177,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T17:00:00",
        base: 7.1408730159,
        forecast: 7.6833075545,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 37.396031746,
        forecast: 40.2367067141,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 12.5317460317,
        forecast: 13.4836817211,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 50.7400793651,
        forecast: 54.5943940236,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 6.8015873016,
        forecast: 7.3182490405,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 78.8914399093,
        forecast: 84.8841864142,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 119.172244898,
        forecast: 128.2247993311,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 1.3611111111,
        forecast: 1.4645037461,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 99.3430272109,
        forecast: 106.8893158803,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:15:00",
        base: 310.343452381,
        forecast: 333.9177418311,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 6.4464285714,
        forecast: 6.9361117656,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 309.1906746032,
        forecast: 332.6773968216,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 132.2770068027,
        forecast: 142.3250243202,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 20.1321428571,
        forecast: 21.6614194033,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 16.2043650794,
        forecast: 17.4352800215,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 95.4548752834,
        forecast: 102.7058124052,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 1.5595238095,
        forecast: 1.6779882572,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 115.7492630385,
        forecast: 124.541801143,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:30:00",
        base: 15.1388888889,
        forecast: 16.2888681969,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 112.445521542,
        forecast: 120.9871010464,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 25.0456349206,
        forecast: 26.9481498361,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 6.5198412698,
        forecast: 7.0151010347,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 4.9523809524,
        forecast: 5.3285733971,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 19.0615079365,
        forecast: 20.5094569814,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 1.2619047619,
        forecast: 1.3577614906,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 143.4761734694,
        forecast: 154.3749013679,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 314.6412301587,
        forecast: 338.5419871292,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T17:45:00",
        base: 129.0270408163,
        forecast: 138.8281846258,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 6.6726190476,
        forecast: 7.1794841083,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 21.7638888889,
        forecast: 23.4171160225,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 43.5615079365,
        forecast: 46.870524412,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 130.2931405896,
        forecast: 140.1904597889,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 150.8048185941,
        forecast: 162.2602445641,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 148.575776644,
        forecast: 159.8618802721,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 297.4581122449,
        forecast: 320.053606313,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 15.4138888889,
        forecast: 16.5847577293,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:00:00",
        base: 1.0079365079,
        forecast: 1.0845013164,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 6.8531746032,
        forecast: 7.3737550134,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 152.9747845805,
        forecast: 164.5950453677,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 25.1408730159,
        forecast: 27.0506224015,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 283.0609297052,
        forecast: 304.5627859155,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 72.1515873016,
        forecast: 77.6323615544,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 181.6857709751,
        forecast: 195.4869738717,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 152.0741723356,
        forecast: 163.6260208732,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 0.9722222222,
        forecast: 1.0460741044,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:15:00",
        base: 27.023015873,
        forecast: 29.0757364738,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 261.4791836735,
        forecast: 281.3416486742,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 27.505952381,
        forecast: 29.5953577738,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 148.7051587302,
        forecast: 160.0010904721,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 41.1416666667,
        forecast: 44.2668673146,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 205.697675737,
        forecast: 221.322869405,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 107.0603174603,
        forecast: 115.1928264373,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 165.0848866213,
        forecast: 177.625054204,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 0.8134920635,
        forecast: 0.8752864955,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T18:30:00",
        base: 7.4543650794,
        forecast: 8.020613082,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 0.8492063492,
        forecast: 0.9137137075,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 232.7000566893,
        forecast: 250.3764034896,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 7.2797619048,
        forecast: 7.8327467123,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 66.5718253968,
        forecast: 71.6287501332,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 156.0301587302,
        forecast: 167.8825116528,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 142.3043650794,
        forecast: 153.114080144,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 207.8445011338,
        forecast: 223.6327718151,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 164.1204818594,
        forecast: 176.5873913894,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T18:45:00",
        base: 29.5376984127,
        forecast: 31.7814391674,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 31.2063492063,
        forecast: 33.5768439058,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 100.9972222222,
        forecast: 108.6691667471,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 6.4226190476,
        forecast: 6.9104936243,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 215.8178571429,
        forecast: 232.2117993826,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 169.6339739229,
        forecast: 182.5196989838,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 0.6666666667,
        forecast: 0.7173079573,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 200.21680839,
        forecast: 215.4256647637,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 211.6672902494,
        forecast: 227.7459473926,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:00:00",
        base: 133.8151984127,
        forecast: 143.9800599428,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 143.9496031746,
        forecast: 154.88429371,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 0.5198412698,
        forecast: 0.5593294191,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 209.7213718821,
        forecast: 225.6522132989,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 182.470776644,
        forecast: 196.3316100909,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 5.8571428571,
        forecast: 6.3020627677,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 121.0021031746,
        forecast: 130.193657185,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 167.159654195,
        forecast: 179.8574251393,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 286.6126984127,
        forecast: 308.38435385,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 29.5357142857,
        forecast: 31.7793043223,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T19:15:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 154.6156179138,
        forecast: 166.3605195776,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 4.7361111111,
        forecast: 5.09587528,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 158.5317063492,
        forecast: 170.5740816719,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 0.4404761905,
        forecast: 0.4739356146,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 199.297760771,
        forecast: 214.4368045083,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 109.1191269841,
        forecast: 117.4080271183,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 317.1567460317,
        forecast: 341.2485864578,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 168.378968254,
        forecast: 181.1693606547,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:30:00",
        base: 27.871031746,
        forecast: 29.9881692742,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 381.3611111111,
        forecast: 410.3300394046,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 94.3687301587,
        forecast: 101.5371615941,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 4.4662698413,
        forecast: 4.8055363449,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 147.9004988662,
        forecast: 159.1353070871,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 132.3461961451,
        forecast: 142.399469419,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 25.4067460317,
        forecast: 27.3366916463,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 177.8652210884,
        forecast: 191.3762076193,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 208.9321428571,
        forecast: 224.8030329093,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 0.5119047619,
        forecast: 0.5507900386,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T19:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 412.2027777778,
        forecast: 443.514498779,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 77.6167460317,
        forecast: 83.5126643219,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 149.0199829932,
        forecast: 160.3398293956,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 4.8412698413,
        forecast: 5.2090220708,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 0.5119047619,
        forecast: 0.5507900386,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 131.0631179138,
        forecast: 141.0189260813,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 228.8833333333,
        forecast: 246.2697544385,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 126.4655612245,
        forecast: 136.0721300853,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:00:00",
        base: 21.9781746032,
        forecast: 23.6476792945,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 4.2142857143,
        forecast: 4.5344110158,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 18.6011904762,
        forecast: 20.0141729156,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 101.2592120181,
        forecast: 108.9510577951,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 440.648015873,
        forecast: 474.1204922283,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 0.3492063492,
        forecast: 0.3757327395,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 120.487244898,
        forecast: 129.6396892769,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 118.3368707483,
        forecast: 127.3259685438,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 253.7686507937,
        forecast: 273.0454087896,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 67.7442460317,
        forecast: 72.8902301093,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T20:15:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 90.4515306122,
        forecast: 97.3224039866,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 59.4553571429,
        forecast: 63.9717011736,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 0.2579365079,
        forecast: 0.2775298644,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 450.0234126984,
        forecast: 484.2080623468,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 14.3154761905,
        forecast: 15.4029074759,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 81.3943310658,
        forecast: 87.5772020283,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 117.7834183673,
        forecast: 126.7304748485,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 3.8412698413,
        forecast: 4.1330601349,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:30:00",
        base: 259.644047619,
        forecast: 279.3671121323,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 60.9126984127,
        forecast: 65.5397449077,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 3.6646825397,
        forecast: 3.94305892,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 0.1785714286,
        forecast: 0.19213606,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 455.725,
        forecast: 490.3427532577,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 10.0833333333,
        forecast: 10.8492828541,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 101.2604024943,
        forecast: 108.9523387022,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 62.2773809524,
        forecast: 67.008091375,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 0.0416666667,
        forecast: 0.0448317473,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 50.391468254,
        forecast: 54.2193017376,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T20:45:00",
        base: 270.5801587302,
        forecast: 291.1339514151,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 42.1388888889,
        forecast: 45.3398404674,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 293.5388888889,
        forecast: 315.8366711635,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 88.5039342404,
        forecast: 95.2268644239,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 483.0630952381,
        forecast: 519.7575031351,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 8.369047619,
        forecast: 9.0047766782,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 44.2773809524,
        forecast: 47.640776528,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 2.8154761905,
        forecast: 3.0293452125,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:00:00",
        base: 46.0307539683,
        forecast: 49.5273391526,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 318.401984127,
        forecast: 342.5884152494,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 5.4801587302,
        forecast: 5.8964421966,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 503.3702380952,
        forecast: 541.6072158772,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 31.3488095238,
        forecast: 33.7301257848,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 77.4043310658,
        forecast: 83.2841139039,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 40.2861111111,
        forecast: 43.3463221027,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 28.5823412698,
        forecast: 30.7535112465,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:15:00",
        base: 2.3948412698,
        forecast: 2.576758049,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 0.0535714286,
        forecast: 0.057640818,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 22.8071428571,
        forecast: 24.5396175819,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 39.373015873,
        forecast: 42.3638663827,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 483.4087301587,
        forecast: 520.1293931535,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 315.4091269841,
        forecast: 339.368214884,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 3.4642857143,
        forecast: 3.7274395638,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 72.40611678,
        forecast: 77.9062255848,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 1.1547619048,
        forecast: 1.2424798546,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:30:00",
        base: 53.183531746,
        forecast: 57.2234557778,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 0.1845238095,
        forecast: 0.1985405953,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 1.5992063492,
        forecast: 1.7206851595,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 305.4924603175,
        forecast: 328.6982590192,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 47.0128968254,
        forecast: 50.5840874826,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 0.0535714286,
        forecast: 0.057640818,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 2.003968254,
        forecast: 2.1561935621,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 37.8662698413,
        forecast: 40.7426650054,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 63.9473866213,
        forecast: 68.8049539076,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T21:45:00",
        base: 460.4253968254,
        forecast: 495.4002013257,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:00:00",
        base: 36.1567460317,
        forecast: 38.9032824577,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:00:00",
        base: 1.8531746032,
        forecast: 1.9939453337,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:00:00",
        base: 285.9527777778,
        forecast: 307.6743043661,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:00:00",
        base: 413.0277777778,
        forecast: 444.4021673761,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:00:00",
        base: 1.746031746,
        forecast: 1.8786636977,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T22:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T22:00:00",
        base: 0.0535714286,
        forecast: 0.057640818,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:00:00",
        base: 44.8075396825,
        forecast: 48.2112071417,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:00:00",
        base: 59.6974433107,
        forecast: 64.2321766754,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:15:00",
        base: 356.3055555556,
        forecast: 383.3712153429,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:15:00",
        base: 48.2996031746,
        forecast: 51.968534537,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:15:00",
        base: 37.5151870748,
        forecast: 40.3649133123,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T22:15:00",
        base: 0.0178571429,
        forecast: 0.019213606,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:15:00",
        base: 33.1476190476,
        forecast: 35.6655763624,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:15:00",
        base: 2.3412698413,
        forecast: 2.519117231,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T22:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:15:00",
        base: 258.2345238095,
        forecast: 277.8505181655,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:30:00",
        base: 217.1428571429,
        forecast: 233.6374489477,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:30:00",
        base: 2.3412698413,
        forecast: 2.519117231,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T22:30:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:30:00",
        base: 52.8630952381,
        forecast: 56.8786782923,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:30:00",
        base: 34.7790759637,
        forecast: 37.4209619042,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T22:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:30:00",
        base: 308.8123015873,
        forecast: 332.270281859,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:30:00",
        base: 29.094047619,
        forecast: 31.3040878006,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:45:00",
        base: 71.950345805,
        forecast: 77.4158333641,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:45:00",
        base: 96.0566269841,
        forecast: 103.35327433,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T22:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:45:00",
        base: 2.0595238095,
        forecast: 2.2159692252,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T22:45:00",
        base: 209.476984127,
        forecast: 225.3892613768,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:45:00",
        base: 24.1607142857,
        forecast: 25.9960089166,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T22:45:00",
        base: 150.8349206349,
        forecast: 162.2926332142,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T22:45:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:00:00",
        base: 136.1515873016,
        forecast: 146.4939254548,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-06-30T23:00:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:00:00",
        base: 97.7091269841,
        forecast: 105.1313014292,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:00:00",
        base: 1.6706349206,
        forecast: 1.7975395835,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:00:00",
        base: 67.4070918367,
        forecast: 72.527465029,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:00:00",
        base: 20.2079365079,
        forecast: 21.7429704865,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T23:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:00:00",
        base: 95.0883730159,
        forecast: 102.3114699159,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:15:00",
        base: 73.3928571429,
        forecast: 78.9679206558,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:15:00",
        base: 49.6873015873,
        forecast: 53.4616452077,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:15:00",
        base: 57.9908786848,
        forecast: 62.3959780968,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:15:00",
        base: 14.1738095238,
        forecast: 15.2504795349,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T23:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:15:00",
        base: 1.6706349206,
        forecast: 1.7975395835,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:15:00",
        base: 59.5387698413,
        forecast: 64.0614500621,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:30:00",
        base: 56.0750056689,
        forecast: 60.3345716576,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:30:00",
        base: 59.3403571429,
        forecast: 63.847965551,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:30:00",
        base: 36.9444444444,
        forecast: 39.7508159668,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T23:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:30:00",
        base: 1.6706349206,
        forecast: 1.7975395835,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:30:00",
        base: 28.0436507937,
        forecast: 30.1739007989,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:30:00",
        base: 10.5071428571,
        forecast: 11.3052857698,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:45:00",
        base: 59.3403571429,
        forecast: 63.847965551,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:45:00",
        base: 0.4880952381,
        forecast: 0.5251718973,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-06-30T23:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:45:00",
        base: 8.5793650794,
        forecast: 9.23107026,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-06-30T23:45:00",
        base: 20.9880952381,
        forecast: 22.5823915841,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:45:00",
        base: 4.6511904762,
        forecast: 5.0045039092,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-06-30T23:45:00",
        base: 49.4734183673,
        forecast: 53.2315150043,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T00:00:00",
        base: 19.3650793651,
        forecast: 20.8360882833,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T00:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T00:00:00",
        base: 2.0591269841,
        forecast: 2.2155422562,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T00:00:00",
        base: 4.7619047619,
        forecast: 5.1236282664,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T00:00:00",
        base: 48.8628174603,
        forecast: 52.5745316702,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T00:00:00",
        base: 52.9236904762,
        forecast: 56.943876462,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T00:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T00:15:00",
        base: 0.7083333333,
        forecast: 0.7621397046,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T00:15:00",
        base: 42.8076587302,
        forecast: 46.0594113605,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T00:15:00",
        base: 43.9475,
        forecast: 47.2858371799,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T00:30:00",
        base: 0.1329365079,
        forecast: 0.1430346224,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T00:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T00:30:00",
        base: 0.3412698413,
        forecast: 0.3671933591,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T00:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T01:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T01:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T01:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T01:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T02:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T02:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T02:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T02:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T03:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T03:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T03:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T03:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T04:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T04:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T04:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T04:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T05:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T05:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T05:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T05:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T06:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T06:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T06:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T06:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T07:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T07:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T07:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T07:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T07:45:00",
        base: 5.9285714286,
        forecast: 6.3789171917,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T08:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T08:00:00",
        base: 11.6174603175,
        forecast: 12.4999450939,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T08:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T08:15:00",
        base: 17.0956349206,
        forecast: 18.3942524454,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T08:15:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T08:15:00",
        base: 0.2142857143,
        forecast: 0.230563272,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T08:30:00",
        base: 27.8654761905,
        forecast: 29.9821917079,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T08:30:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T08:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T08:30:00",
        base: 0.4246031746,
        forecast: 0.4568568538,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T08:45:00",
        base: 35.3853174603,
        forecast: 38.0732546786,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T08:45:00",
        base: 0.4246031746,
        forecast: 0.4568568538,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T08:45:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T08:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T09:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T09:00:00",
        base: 43.5420634921,
        forecast: 46.8496029299,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T09:00:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T09:00:00",
        base: 1.0595238095,
        forecast: 1.1400072893,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T09:15:00",
        base: 84.0202380952,
        forecast: 90.4025780394,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T09:15:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T09:15:00",
        base: 1.9047619048,
        forecast: 2.0494513066,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T09:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T09:30:00",
        base: 2.2936507937,
        forecast: 2.4678809483,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T09:30:00",
        base: 91.5817460317,
        forecast: 98.5384727574,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T09:30:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T09:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T09:45:00",
        base: 0.1865079365,
        forecast: 0.2006754404,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T09:45:00",
        base: 2.9285714286,
        forecast: 3.1510313838,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T09:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T09:45:00",
        base: 102.2103174603,
        forecast: 109.974411048,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T10:00:00",
        base: 2.7142857143,
        forecast: 2.9204681118,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T10:00:00",
        base: 0.1507936508,
        forecast: 0.1622482284,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T10:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T10:00:00",
        base: 104.4900793651,
        forecast: 112.4273480805,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T10:15:00",
        base: 4.6726190476,
        forecast: 5.0275602364,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T10:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T10:15:00",
        base: 0.1507936508,
        forecast: 0.1622482284,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T10:15:00",
        base: 117.4126984127,
        forecast: 126.3315942884,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T10:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T10:30:00",
        base: 0.2103174603,
        forecast: 0.2262935818,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T10:30:00",
        base: 126.5793650794,
        forecast: 136.1945787013,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T10:30:00",
        base: 5.6329365079,
        forecast: 6.0608252701,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T10:45:00",
        base: 0.253968254,
        forecast: 0.2732601742,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T10:45:00",
        base: 144.8928571429,
        forecast: 155.8991990758,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T10:45:00",
        base: 0.0611111111,
        forecast: 0.0657532294,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T10:45:00",
        base: 5.5575396825,
        forecast: 5.9797011559,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T10:45:00",
        base: 1.3650793651,
        forecast: 1.4687734364,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T10:45:00",
        base: 2.0119047619,
        forecast: 2.1647329426,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T10:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:00:00",
        base: 0.3599206349,
        forecast: 0.3872609031,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T11:00:00",
        base: 4.9186507937,
        forecast: 5.2922810302,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T11:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:00:00",
        base: 0.253968254,
        forecast: 0.2732601742,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:00:00",
        base: 128.0079365079,
        forecast: 137.7316671812,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:00:00",
        base: 0.3492063492,
        forecast: 0.3757327395,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:00:00",
        base: 1.7619047619,
        forecast: 1.8957424586,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:00:00",
        base: 0.2801587302,
        forecast: 0.3014401297,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:00:00",
        base: 2.0119047619,
        forecast: 2.1647329426,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 1.5119047619,
        forecast: 1.6267519746,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 1.7619047619,
        forecast: 1.8957424586,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 4.2777777778,
        forecast: 4.6027260593,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 0.253968254,
        forecast: 0.2732601742,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 155.6848015873,
        forecast: 167.5109205128,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 4.8630952381,
        forecast: 5.2325053671,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 6.226984127,
        forecast: 6.6999978964,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 1.1095238095,
        forecast: 1.1938053861,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:15:00",
        base: 2.1706349206,
        forecast: 2.3355205514,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 8.996031746,
        forecast: 9.6793877333,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 178.4804365079,
        forecast: 192.0381559931,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 4.876984127,
        forecast: 5.2474492828,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 0.1388888889,
        forecast: 0.1494391578,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 5.3801587302,
        forecast: 5.788846003,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 19,
        forecast: 20.4432767829,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 2.1706349206,
        forecast: 2.3355205514,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 11.2182539683,
        forecast: 12.0704142576,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:30:00",
        base: 2.1071428571,
        forecast: 2.2672055079,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 2.4484126984,
        forecast: 2.634398867,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 0.1547619048,
        forecast: 0.1665179187,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 13.6984126984,
        forecast: 14.7389706463,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 2.623015873,
        forecast: 2.8222652367,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 202.6054365079,
        forecast: 217.9957376977,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 5.4206349206,
        forecast: 5.8323968432,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 17.726984127,
        forecast: 19.0735601597,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 25.7142857143,
        forecast: 27.6675926385,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T11:45:00",
        base: 8.0242063492,
        forecast: 8.6337405979,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 2.7063492063,
        forecast: 2.9119287314,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 3.6706349206,
        forecast: 3.9494634553,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 12.1238095238,
        forecast: 13.0447575662,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 4.5714285714,
        forecast: 4.9186831357,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 20.1111111111,
        forecast: 21.6387900451,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 25.1023809524,
        forecast: 27.0092064063,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 0.3571428571,
        forecast: 0.38427212,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 33.7777777778,
        forecast: 36.3436031696,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:00:00",
        base: 199.8491666667,
        forecast: 215.0300962633,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 33.6436507937,
        forecast: 36.1992876401,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 0.3611111111,
        forecast: 0.3885418102,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 17.4496031746,
        forecast: 18.7751088132,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 11.9623015873,
        forecast: 12.8709811742,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 4.871031746,
        forecast: 5.2410447475,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 3.6944444444,
        forecast: 3.9750815967,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 41.8293650794,
        forecast: 45.0068046301,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 227.1924206349,
        forecast: 244.450396738,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:15:00",
        base: 26.8611111111,
        forecast: 28.9015331127,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 33.880952381,
        forecast: 36.4546151154,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 21.3075396825,
        forecast: 22.926101647,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 50.0119047619,
        forecast: 53.8109058678,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 238.4999603175,
        forecast: 256.6168790256,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 5.9682539683,
        forecast: 6.4216140939,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 41.1075396825,
        forecast: 44.2301479787,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 0.6349206349,
        forecast: 0.6831504355,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 21.65,
        forecast: 23.2945759132,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 3.7817460317,
        forecast: 4.0690147816,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T12:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 6.8293650794,
        forecast: 7.3481368721,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 48.5718253968,
        forecast: 52.2614352863,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 0.6349206349,
        forecast: 0.6831504355,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 56.9563492063,
        forecast: 61.2828637563,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 25.8742063492,
        forecast: 27.8396611545,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 248.5317063492,
        forecast: 267.4106559068,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 42.8452380952,
        forecast: 46.0998453269,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 39.4702380952,
        forecast: 42.4684737931,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T12:45:00",
        base: 14.6686507937,
        forecast: 15.7829099056,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 49.230952381,
        forecast: 52.9706308321,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 30.8876984127,
        forecast: 33.233987781,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 63.1206349206,
        forecast: 67.9154005472,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 0.6865079365,
        forecast: 0.7386564084,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 63.7222222222,
        forecast: 68.5626855848,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 23.7976190476,
        forecast: 25.6053322613,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 241.2090929705,
        forecast: 259.5318026397,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 6.9345238095,
        forecast: 7.4612836629,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:00:00",
        base: 42.9742063492,
        forecast: 46.2386102591,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 0.9047619048,
        forecast: 0.9734893706,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 220.6157256236,
        forecast: 237.3741232415,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 52.3746031746,
        forecast: 56.353079426,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 30.4595238095,
        forecast: 32.773288206,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 52.6797619048,
        forecast: 56.6814186041,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 87.8345238095,
        forecast: 94.5066042807,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 6.4603174603,
        forecast: 6.9510556814,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 42.1091269841,
        forecast: 45.3078177907,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:15:00",
        base: 38.9964285714,
        forecast: 41.9586727806,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 50.3706349206,
        forecast: 54.1968858639,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 128.6492063492,
        forecast: 138.4216491211,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 31.6043650794,
        forecast: 34.0050938351,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 6.8968253968,
        forecast: 7.4207216058,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 40.7162698413,
        forecast: 43.8091565228,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 0.8888888889,
        forecast: 0.9564106097,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 223.9252494331,
        forecast: 240.9350448866,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 52.7428571429,
        forecast: 56.7493066786,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 66.7722222222,
        forecast: 71.8443694895,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T13:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 47.2357142857,
        forecast: 50.8238305885,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 96.1972222222,
        forecast: 103.5045494546,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 170.5142857143,
        forecast: 183.4668809631,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 7.2916666667,
        forecast: 7.8455557829,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 37.4742063492,
        forecast: 40.3208196114,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 233.1815192744,
        forecast: 250.8944389046,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 31.6928571429,
        forecast: 34.100307927,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 53.3126984127,
        forecast: 57.3624341945,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T13:45:00",
        base: 0.7579365079,
        forecast: 0.8155108324,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 230.6458049887,
        forecast: 248.1661068528,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 124.7091269841,
        forecast: 134.1822736996,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 33.5996031746,
        forecast: 36.1518940787,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 6.9067460317,
        forecast: 7.4313958314,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 52.825,
        forecast: 56.8376892662,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 0.753968254,
        forecast: 0.8112411422,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 33.5892857143,
        forecast: 36.1407928841,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 45.6642857143,
        forecast: 49.1330332606,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:00:00",
        base: 221.5182539683,
        forecast: 238.3452093865,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 0.4880952381,
        forecast: 0.5251718973,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 147.9115079365,
        forecast: 159.1471524277,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 6.8035714286,
        forecast: 7.3203838856,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 226.5696145125,
        forecast: 243.7802810568,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 29.5416666667,
        forecast: 31.7857088577,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 35.5920634921,
        forecast: 38.2957055391,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 54.8976190476,
        forecast: 59.0677484692,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 255.0218253968,
        forecast: 274.3937769618,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:15:00",
        base: 43.6841269841,
        forecast: 47.0024578398,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 35.6595238095,
        forecast: 38.3682902729,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 0.6547619048,
        forecast: 0.7044988866,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 278.0079365079,
        forecast: 299.1259575727,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 176.0265873016,
        forecast: 189.3979076505,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 56.844047619,
        forecast: 61.162031523,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 0.0416666667,
        forecast: 0.0448317473,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 227.1451814059,
        forecast: 244.3995691257,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 25.4880952381,
        forecast: 27.4242202959,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 5.8531746032,
        forecast: 6.2977930774,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:30:00",
        base: 39.7714285714,
        forecast: 42.7925432809,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 237.0383503401,
        forecast: 255.0442423248,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 319.0111111111,
        forecast: 343.2438126985,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 19.8888888889,
        forecast: 21.3996873926,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 0.6626984127,
        forecast: 0.7130382671,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 63.7337301587,
        forecast: 68.5750676865,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 34.6884920635,
        forecast: 37.3234970756,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 35.0555555556,
        forecast: 37.7184434211,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 0.0535714286,
        forecast: 0.057640818,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 220.4634920635,
        forecast: 237.2103257254,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 5.8055555556,
        forecast: 6.2465567948,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T14:45:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 69.9804365079,
        forecast: 75.2962859432,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 0.496031746,
        forecast: 0.5337112777,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 245.7892857143,
        forecast: 264.4599156913,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 264.9650510204,
        forecast: 285.0923092532,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 0.0892857143,
        forecast: 0.09606803,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 6.3888888889,
        forecast: 6.8742012574,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 336.7436507937,
        forecast: 362.3233504245,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 40.1047619048,
        forecast: 43.1511972596,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 30.9880952381,
        forecast: 33.3420109436,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:00:00",
        base: 17.6468253968,
        forecast: 18.9873124172,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 50.9373015873,
        forecast: 54.8065976276,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 0.8095238095,
        forecast: 0.8710168053,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 267.0367913832,
        forecast: 287.3214230248,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 16.0238095238,
        forecast: 17.2410091164,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 344.9603174603,
        forecast: 371.1641709982,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 280.5920351474,
        forecast: 301.9063493474,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 84.3402380952,
        forecast: 90.7468858589,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 28.6706349206,
        forecast: 30.8485118539,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 0.1011904762,
        forecast: 0.1088771007,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:15:00",
        base: 5.8373015873,
        forecast: 6.2807143166,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 306.3261621315,
        forecast: 329.5952904371,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 28.2420634921,
        forecast: 30.38738531,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 330.6587301587,
        forecast: 355.7762074381,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 15.4851190476,
        forecast: 16.6613986688,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 59.0349206349,
        forecast: 63.5193274946,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 98.5176984127,
        forecast: 106.0012935088,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 0.7738095238,
        forecast: 0.8325895933,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 0.1011904762,
        forecast: 0.1088771007,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 6.9761904762,
        forecast: 7.5061154103,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:30:00",
        base: 263.516553288,
        forecast: 283.5337808288,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 68.6392857143,
        forecast: 73.8532587389,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 255.8677437642,
        forecast: 275.3039529259,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 314.6755362812,
        forecast: 338.5788992111,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 22.9246031746,
        forecast: 24.6660004125,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 14.6795634921,
        forecast: 15.7946515537,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 7.3492063492,
        forecast: 7.9074662911,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 0.8055555556,
        forecast: 0.8667471151,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 116.5756349206,
        forecast: 125.430945833,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 292.751984127,
        forecast: 314.9899915925,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 0.1369047619,
        forecast: 0.1473043127,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T15:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 253.8833333333,
        forecast: 273.1688028371,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 237.2824263039,
        forecast: 255.3068587712,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 0.130952381,
        forecast: 0.1408997773,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 362.9665680272,
        forecast: 390.5382112172,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 83.195515873,
        forecast: 89.5152083205,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 7.9404761905,
        forecast: 8.5436501342,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 19.746031746,
        forecast: 21.2459785447,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 135.4311904762,
        forecast: 145.7188058919,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 0.8055555556,
        forecast: 0.8667471151,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:00:00",
        base: 13.5446428571,
        forecast: 14.5735201502,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 200.4669501134,
        forecast: 215.6948077366,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 387.8473503401,
        forecast: 417.3089859224,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 8.6051587302,
        forecast: 9.2588232464,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 156.3369047619,
        forecast: 168.212558707,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 208.9626984127,
        forecast: 224.8359095241,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 1.0793650794,
        forecast: 1.1613557404,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 96.993531746,
        forecast: 104.3613481914,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 20.0130385488,
        forecast: 21.533267701,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 0.0099206349,
        forecast: 0.0106742256,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 0.0952380952,
        forecast: 0.1024725653,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:15:00",
        base: 14.1478174603,
        forecast: 15.222513064,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 1.3492063492,
        forecast: 1.4516946755,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 175.2274603175,
        forecast: 188.5380774336,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 123.6573015873,
        forecast: 133.0505496094,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 147.0825396825,
        forecast: 158.2552141403,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 149.0994897959,
        forecast: 160.425375689,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 414.5503265306,
        forecast: 446.0403718798,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 0.0833333333,
        forecast: 0.0896634947,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 23.3741496599,
        forecast: 25.1496953191,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 8.4206349206,
        forecast: 9.0602826511,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:30:00",
        base: 14.814484127,
        forecast: 15.9398210213,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 33.0065759637,
        forecast: 35.5138193728,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 15.7152777778,
        forecast: 16.9090407017,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 197.3505952381,
        forecast: 212.3417285119,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 140.7871428571,
        forecast: 151.4816067845,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 9.8194444444,
        forecast: 10.5653484543,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 1.5833333333,
        forecast: 1.7036063986,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 104.8924603175,
        forecast: 112.860294669,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 435.7963582766,
        forecast: 468.9002933284,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T16:45:00",
        base: 109.198015873,
        forecast: 117.4929085599,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 164.6445464853,
        forecast: 177.1512649788,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 15.7787698413,
        forecast: 16.9773557452,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 54.6498866213,
        forecast: 58.8011978081,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 55.4523809524,
        forecast: 59.6646511622,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 60.3731292517,
        forecast: 64.9591890286,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 1.376984127,
        forecast: 1.481582507,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 211.5188492063,
        forecast: 227.5862304806,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 10.9365079365,
        forecast: 11.7672662518,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:00:00",
        base: 450.6542947846,
        forecast: 484.8868674576,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 1.4285714286,
        forecast: 1.5370884799,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 196.6794671202,
        forecast: 211.6196202029,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 77.2471655329,
        forecast: 83.1150097729,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 223.0461507937,
        forecast: 239.9891682126,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 9.755952381,
        forecast: 10.4970334108,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 17.6785714286,
        forecast: 19.021469939,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 35.7183673469,
        forecast: 38.4316036793,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 26.7857142857,
        forecast: 28.8204089985,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:15:00",
        base: 443.9836598639,
        forecast: 477.7095181944,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 10.2420634921,
        forecast: 11.020070463,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 111.6816893424,
        forecast: 120.1652466743,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 213.3604195011,
        forecast: 229.5676900201,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 18.7579365079,
        forecast: 20.1828256794,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 10.3015873016,
        forecast: 11.0841158163,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 449.3565079365,
        forecast: 483.4904982081,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 1.6547619048,
        forecast: 1.7804608226,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 15.2937641723,
        forecast: 16.4555081067,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:30:00",
        base: 227.7759920635,
        forecast: 245.078297382,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 20.2341269841,
        forecast: 21.771150442,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 128.9594671202,
        forecast: 138.7554779008,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 422.2666666667,
        forecast: 454.342860151,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 13.2420634921,
        forecast: 14.2479562708,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 1.9682539683,
        forecast: 2.1177663501,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 236.8117063492,
        forecast: 254.8003820175,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 234.9805782313,
        forecast: 252.8301578628,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 9.9563492063,
        forecast: 10.712652767,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T17:45:00",
        base: 9.8274943311,
        forecast: 10.5740098259,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 236.7500396825,
        forecast: 254.7340310315,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 148.0694444444,
        forecast: 159.3170860985,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 8.4761904762,
        forecast: 9.1200583142,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 410.4251133787,
        forecast: 441.6017995507,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 41.8591269841,
        forecast: 45.0388273067,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 1.75,
        forecast: 1.8829333879,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 23.4659863946,
        forecast: 25.2485081499,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 22.3988095238,
        forecast: 24.1002664581,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:00:00",
        base: 252.5924829932,
        forecast: 271.7798970061,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 85.6904761905,
        forecast: 92.1996906538,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 41.5949546485,
        forecast: 44.7545879291,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 382.9608276644,
        forecast: 412.0512735242,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 9.0396825397,
        forecast: 9.7263543257,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 259.5564909297,
        forecast: 279.2729044674,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 238.1721825397,
        forecast: 256.2642026132,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 1.5238095238,
        forecast: 1.6395610452,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 26.9345238095,
        forecast: 28.9805223818,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:15:00",
        base: 172.0218253968,
        forecast: 185.0889362785,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 8.8432539683,
        forecast: 9.5150046597,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 230.2219444444,
        forecast: 247.7100490411,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 29.5853174603,
        forecast: 31.8326754501,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 80.7716269841,
        forecast: 86.9071961391,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 168.95,
        forecast: 181.7837690776,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 1.5,
        forecast: 1.6139429039,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 270.2093877551,
        forecast: 290.735015959,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 346.7796031746,
        forecast: 373.1216531774,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:30:00",
        base: 194.1674603175,
        forecast: 208.9167965004,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 8.9444444444,
        forecast: 9.6238817604,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 305.7334920635,
        forecast: 328.9576000033,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 199.2869047619,
        forecast: 214.4251238558,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 1.6081746032,
        forecast: 1.7303346594,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 33.3035714286,
        forecast: 35.8333751881,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 220.8502380952,
        forecast: 237.6264497345,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 116.4867063492,
        forecast: 125.3352620751,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 228.0253968254,
        forecast: 245.3466474125,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T18:45:00",
        base: 264.6338095238,
        forecast: 284.7359060113,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 305.8996031746,
        forecast: 329.136329236,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 34.6435941043,
        forecast: 37.2751885805,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 1.5565873016,
        forecast: 1.6748286865,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 251.0392517007,
        forecast: 270.1086792576,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 0.005952381,
        forecast: 0.0064045353,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 280.608452381,
        forecast: 301.9240136659,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 9.1984126984,
        forecast: 9.8971419346,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 158.5918650794,
        forecast: 170.6388101756,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 212.3454761905,
        forecast: 228.4756496507,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 200.7469954649,
        forecast: 215.9961258752,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T19:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 244.8690136054,
        forecast: 263.4697379314,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 184.3243764172,
        forecast: 198.3260128914,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 37.3625,
        forecast: 40.2006278317,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 8.8392857143,
        forecast: 9.5107349695,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 0.0416666667,
        forecast: 0.0448317473,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 246.8367063492,
        forecast: 265.5869004254,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 371.2011904762,
        forecast: 399.3983515292,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 200.5970238095,
        forecast: 215.8347620826,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 1.2391269841,
        forecast: 1.3332534687,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 203.636468254,
        forecast: 219.1050886112,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T19:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 0.0416666667,
        forecast: 0.0448317473,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 234.4504421769,
        forecast: 252.2597516474,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 230.7279761905,
        forecast: 248.2545199382,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 208.4656746032,
        forecast: 224.3011308237,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 189.8412301587,
        forecast: 204.2619375234,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 0.874047619,
        forecast: 0.9404419683,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 421.5742063492,
        forecast: 453.5977992072,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 35.6918650794,
        forecast: 38.4030882482,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 10.0952380952,
        forecast: 10.8620919248,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:30:00",
        base: 178.5095238095,
        forecast: 192.0694528224,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 34.6343253968,
        forecast: 37.2652158041,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 174.9341269841,
        forecast: 188.2224619324,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 0.7629365079,
        forecast: 0.8208906421,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 266.839484127,
        forecast: 287.1091279274,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 462.3619047619,
        forecast: 497.483810154,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 9.8968253968,
        forecast: 10.6486074137,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 0.0535714286,
        forecast: 0.057640818,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 178.2457199546,
        forecast: 191.785609916,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 171.2507539683,
        forecast: 184.2592927714,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T19:45:00",
        base: 239.557585034,
        forecast: 257.7548429631,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 0.6597619048,
        forecast: 0.7098786963,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 151.2733730159,
        forecast: 162.7643912868,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 459.8182539683,
        forecast: 494.7469387217,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 148.4969897959,
        forecast: 159.7771086226,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 152.5577947846,
        forecast: 164.1463802197,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 30.3704365079,
        forecast: 32.6774336605,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 219.97861678,
        forecast: 236.6886183768,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 272.2482142857,
        forecast: 292.9287157,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T20:00:00",
        base: 9.9107142857,
        forecast: 10.6635513294,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 0.3978571429,
        forecast: 0.4280791417,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 25.2077380952,
        forecast: 27.1225666817,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 152.2241780045,
        forecast: 163.7874212631,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 209.0383390023,
        forecast: 224.9172959192,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 133.4282596372,
        forecast: 143.5637285488,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 128.7198809524,
        forecast: 138.4976923039,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 449.5273809524,
        forecast: 483.674351069,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 8.4801587302,
        forecast: 9.1243280044,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:15:00",
        base: 277.2035714286,
        forecast: 298.2604913647,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 121.6087585034,
        forecast: 130.846395227,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 138.4507653061,
        forecast: 148.9677534716,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 461.7738095238,
        forecast: 496.8510420631,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 19.739484127,
        forecast: 21.2389335558,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 0.4007936508,
        forecast: 0.4312387124,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 202.5646485261,
        forecast: 217.9518513818,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 115.9892063492,
        forecast: 124.799971012,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 7.9920634921,
        forecast: 8.5991561071,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:30:00",
        base: 290.5837301587,
        forecast: 312.6570328552,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 281.8936507937,
        forecast: 303.306838238,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 102.3325,
        forecast: 110.1058748099,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 110.714824263,
        forecast: 119.1249366517,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 186.2217063492,
        forecast: 200.3674676781,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 7.8670634921,
        forecast: 8.4646608651,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 14.185175737,
        forecast: 15.2627091476,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 108.3811791383,
        forecast: 116.6140233255,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 436.8722789116,
        forecast: 470.0579429776,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T20:45:00",
        base: 0.3650793651,
        forecast: 0.3928115004,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 85.7094047619,
        forecast: 92.2200570762,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 173.266547619,
        forecast: 186.4282100104,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 0.4206349206,
        forecast: 0.4525871635,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 97.2198696145,
        forecast: 104.6048791226,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 438.889739229,
        forecast: 472.2286534865,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 8.6527777778,
        forecast: 9.3100595291,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 92.1553004535,
        forecast: 99.1555954834,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 278.2888888889,
        forecast: 299.4282516404,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T21:00:00",
        base: 7.505952381,
        forecast: 8.0761190549,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 0.4206349206,
        forecast: 0.4525871635,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 7.0099206349,
        forecast: 7.5424077772,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 84.7666950113,
        forecast: 91.2057372679,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 152.6661564626,
        forecast: 164.2629732605,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 69.2768650794,
        forecast: 74.5392698669,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 5.4007936508,
        forecast: 5.8110483921,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 465.8655328798,
        forecast: 501.2535806466,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 77.6685657596,
        forecast: 83.5684203767,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:15:00",
        base: 302.4551587302,
        forecast: 325.4302381233,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 4.1111111111,
        forecast: 4.42339907,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 126.5439342404,
        forecast: 136.1564564671,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 3.4146825397,
        forecast: 3.674068436,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 59.616978458,
        forecast: 64.1455995568,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 52.2570238095,
        forecast: 56.2265685047,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 506.5460884354,
        forecast: 545.0243099574,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 328.2095238095,
        forecast: 353.1409546331,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 81.4524886621,
        forecast: 87.6397773883,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T21:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 59.1302154195,
        forecast: 63.6218610555,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 563.8306122449,
        forecast: 606.6602770951,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 121.5090136054,
        forecast: 130.7390735135,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 76.7734637188,
        forecast: 82.605324652,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 0.6428571429,
        forecast: 0.691689816,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 39.0295634921,
        forecast: 41.9943246939,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 0.0317460317,
        forecast: 0.0341575218,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 369.6182539683,
        forecast: 397.6951720997,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T21:45:00",
        base: 3.3273809524,
        forecast: 3.5801352511,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 74.4628628118,
        forecast: 80.1192060269,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 364.751984127,
        forecast: 392.4592509804,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 534.9254535147,
        forecast: 575.559426549,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 100.7526643991,
        forecast: 108.4060318383,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 2.7976190476,
        forecast: 3.0101316065,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 0.3095238095,
        forecast: 0.3330358373,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 0.6428571429,
        forecast: 0.691689816,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 27.2954365079,
        forecast: 29.3688507075,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 0.0317460317,
        forecast: 0.0341575218,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:00:00",
        base: 25.0111678005,
        forecast: 26.9110645268,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 509.0222789116,
        forecast: 547.6885966559,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 1.6388888889,
        forecast: 1.7633820617,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 44.1111111111,
        forecast: 47.4618765077,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 0.6428571429,
        forecast: 0.691689816,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 66.969744898,
        forecast: 72.05689637,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 0.0555555556,
        forecast: 0.0597756631,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 354.0904761905,
        forecast: 380.987874261,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 15.3948412698,
        forecast: 16.5642632162,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 92.1514455782,
        forecast: 99.1514477843,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T22:15:00",
        base: 0.0317460317,
        forecast: 0.0341575218,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 473.730952381,
        forecast: 509.7164726401,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 43.2896825397,
        forecast: 46.5780506318,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 0.3253968254,
        forecast: 0.3501145982,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 4.9206349206,
        forecast: 5.2944158753,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 339.1595238095,
        forecast: 364.9227378317,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 121.2016439909,
        forecast: 130.408355508,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 0.7380952381,
        forecast: 0.7941623813,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 0.0555555556,
        forecast: 0.0597756631,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 0.0555555556,
        forecast: 0.0597756631,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:30:00",
        base: 62.0013718821,
        forecast: 66.7111161214,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:45:00",
        base: 0.0992063492,
        forecast: 0.1067422555,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T22:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:45:00",
        base: 182.6425170068,
        forecast: 196.5163961842,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:45:00",
        base: 64.2896825397,
        forecast: 69.1732512866,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:45:00",
        base: 55.4053401361,
        forecast: 59.6140370344,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T22:45:00",
        base: 409.280952381,
        forecast: 440.3707258685,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T22:45:00",
        base: 313.275,
        forecast: 337.0719754826,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T22:45:00",
        base: 0.0555555556,
        forecast: 0.0597756631,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T23:00:00",
        base: 0.0555555556,
        forecast: 0.0597756631,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:00:00",
        base: 186.0370748299,
        forecast: 200.1688111912,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T23:00:00",
        base: 323.6464285714,
        forecast: 348.2312378468,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T23:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:00:00",
        base: 256.7178571429,
        forecast: 276.2186425626,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T23:00:00",
        base: 65.8259637188,
        forecast: 70.8262313582,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:00:00",
        base: 44.2259750567,
        forecast: 47.585465741,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:15:00",
        base: 192.4215986395,
        forecast: 207.0383157894,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T23:15:00",
        base: 99.4092970522,
        forecast: 106.960619707,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:15:00",
        base: 181.2134920635,
        forecast: 194.9788197397,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T23:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:15:00",
        base: 35.5159750567,
        forecast: 38.2138372789,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T23:15:00",
        base: 213.5611111111,
        forecast: 229.7836265533,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T23:15:00",
        base: 0.0436507937,
        forecast: 0.0469665924,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T23:30:00",
        base: 0.0238095238,
        forecast: 0.0256181413,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:30:00",
        base: 113.9099206349,
        forecast: 122.5627387295,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:30:00",
        base: 26.4438095238,
        forecast: 28.452532489,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T23:30:00",
        base: 91.9985827664,
        forecast: 98.9869732174,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T23:30:00",
        base: 114.9888888889,
        forecast: 123.7236675009,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:30:00",
        base: 178.5358843537,
        forecast: 192.0978157646,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T23:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:45:00",
        base: 17.7398412698,
        forecast: 19.087393956,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:45:00",
        base: 173.1910430839,
        forecast: 186.3469700046,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-01T23:45:00",
        base: 74.021031746,
        forecast: 79.643812618,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-01T23:45:00",
        base: 0.0238095238,
        forecast: 0.0256181413,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T23:45:00",
        base: 70.9253968254,
        forecast: 76.3130272758,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-01T23:45:00",
        base: 91.9985827664,
        forecast: 98.9869732174,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-01T23:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T00:00:00",
        base: 11.3242857143,
        forecast: 12.1845003803,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T00:00:00",
        base: 155.3626984127,
        forecast: 167.1643497575,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T00:00:00",
        base: 25.3996031746,
        forecast: 27.3290062039,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T00:00:00",
        base: 35.1111111111,
        forecast: 37.7782190842,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T00:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T00:00:00",
        base: 0.0238095238,
        forecast: 0.0256181413,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T00:00:00",
        base: 57.5164399093,
        forecast: 61.8855000334,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T00:15:00",
        base: 119.0722222222,
        forecast: 128.1171787393,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T00:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T00:15:00",
        base: 69.6592970522,
        forecast: 74.9507521127,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T00:15:00",
        base: 4.375,
        forecast: 4.7073334698,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T00:15:00",
        base: 15.119047619,
        forecast: 16.2675197458,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T00:15:00",
        base: 6.873015873,
        forecast: 7.3951034645,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T00:30:00",
        base: 43.2388888889,
        forecast: 46.5233985969,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T00:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T00:30:00",
        base: 48.0442176871,
        forecast: 51.6937494735,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T00:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T00:45:00",
        base: 28.4329365079,
        forecast: 30.5927574096,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T00:45:00",
        base: 46.5079365079,
        forecast: 50.0407694018,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T01:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T01:00:00",
        base: 7.1428571429,
        forecast: 7.6854423996,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T01:00:00",
        base: 12.1428571429,
        forecast: 13.0652520793,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T01:15:00",
        base: 7.1428571429,
        forecast: 7.6854423996,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T01:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T01:15:00",
        base: 12.1428571429,
        forecast: 13.0652520793,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T01:30:00",
        base: 12.1428571429,
        forecast: 13.0652520793,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T01:30:00",
        base: 7.1428571429,
        forecast: 7.6854423996,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T01:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T01:45:00",
        base: 12.1428571429,
        forecast: 13.0652520793,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T01:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T01:45:00",
        base: 7.1428571429,
        forecast: 7.6854423996,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T02:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T02:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T02:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T02:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T03:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T03:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T03:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T03:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T04:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T04:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T04:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T04:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T05:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T05:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T05:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T05:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T06:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T06:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T06:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T06:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T07:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T07:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T07:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T07:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T08:00:00",
        base: 1.1626984127,
        forecast: 1.251019235,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T08:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T08:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T08:15:00",
        base: 0.4246031746,
        forecast: 0.4568568538,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T08:15:00",
        base: 7.5694444444,
        forecast: 8.1444340985,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T08:30:00",
        base: 17.4384920635,
        forecast: 18.7631536806,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T08:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T08:30:00",
        base: 0.4246031746,
        forecast: 0.4568568538,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T08:45:00",
        base: 26.2142857143,
        forecast: 28.2055736065,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T08:45:00",
        base: 0.4246031746,
        forecast: 0.4568568538,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T08:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T09:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T09:00:00",
        base: 0.4246031746,
        forecast: 0.4568568538,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T09:00:00",
        base: 35.7023809524,
        forecast: 38.4144029273,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T09:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T09:15:00",
        base: 0.4047619048,
        forecast: 0.4355084026,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T09:15:00",
        base: 1.6865079365,
        forecast: 1.8146183443,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T09:15:00",
        base: 49.8948412698,
        forecast: 53.6849500063,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T09:30:00",
        base: 0.4047619048,
        forecast: 0.4355084026,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T09:30:00",
        base: 85.8670634921,
        forecast: 92.3896918687,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T09:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T09:30:00",
        base: 2.869047619,
        forecast: 3.0869860305,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T09:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T09:45:00",
        base: 3.0634920635,
        forecast: 3.2962008514,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T09:45:00",
        base: 113.2896825397,
        forecast: 121.8953861478,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T09:45:00",
        base: 0.4047619048,
        forecast: 0.4355084026,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T10:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T10:00:00",
        base: 141.2083333333,
        forecast: 151.9347917047,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T10:00:00",
        base: 0.5158730159,
        forecast: 0.5550597289,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T10:00:00",
        base: 6.1944444444,
        forecast: 6.6649864365,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T10:15:00",
        base: 6.6428571429,
        forecast: 7.1474614316,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T10:15:00",
        base: 0.8055555556,
        forecast: 0.8667471151,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T10:15:00",
        base: 160.8591269841,
        forecast: 173.078297684,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T10:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T10:30:00",
        base: 6.8134920635,
        forecast: 7.3310581112,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T10:30:00",
        base: 176.8472222222,
        forecast: 190.2808795884,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T10:30:00",
        base: 0.8055555556,
        forecast: 0.8667471151,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T10:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T10:45:00",
        base: 200.0600340136,
        forecast: 215.2569815022,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T10:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T10:45:00",
        base: 7.4523809524,
        forecast: 8.0184782369,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T10:45:00",
        base: 0.8055555556,
        forecast: 0.8667471151,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:00:00",
        base: 1.5396825397,
        forecast: 1.6566398061,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T11:00:00",
        base: 8.5357142857,
        forecast: 9.1841036675,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:00:00",
        base: 0.4035714286,
        forecast: 0.4342274956,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T11:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:00:00",
        base: 0.753968254,
        forecast: 0.8112411422,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:00:00",
        base: 1.2865079365,
        forecast: 1.38423357,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:00:00",
        base: 219.3695578231,
        forecast: 236.0332941224,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T11:15:00",
        base: 8.0158730159,
        forecast: 8.6247742484,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:15:00",
        base: 5.8501984127,
        forecast: 6.2945908098,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:15:00",
        base: 5.7892857143,
        forecast: 6.2290510649,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:15:00",
        base: 212.9905895692,
        forecast: 229.1697670905,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:15:00",
        base: 0.753968254,
        forecast: 0.8112411422,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:15:00",
        base: 5.630952381,
        forecast: 6.058690425,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:15:00",
        base: 2.6095238095,
        forecast: 2.80774829,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T11:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:30:00",
        base: 6.5777777778,
        forecast: 7.077438512,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:30:00",
        base: 0.753968254,
        forecast: 0.8112411422,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:30:00",
        base: 10.6988095238,
        forecast: 11.5115118075,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:30:00",
        base: 12.7380952381,
        forecast: 13.7057056126,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T11:30:00",
        base: 9.0912698413,
        forecast: 9.7818602986,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T11:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:30:00",
        base: 217.6909863946,
        forecast: 234.2272151585,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:30:00",
        base: 10.1115079365,
        forecast: 10.8795976547,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 17.5079365079,
        forecast: 18.8378732595,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 17.0615079365,
        forecast: 18.3575331095,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 5.9126984127,
        forecast: 6.3618384308,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 0.1626984127,
        forecast: 0.1750572991,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 12.3277777778,
        forecast: 13.2642196437,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 222.3199546485,
        forecast: 239.2078088024,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 27.4841269841,
        forecast: 29.5718744776,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 1.4642857143,
        forecast: 1.5755156919,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T11:45:00",
        base: 0.7896825397,
        forecast: 0.8496683542,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 1.1746031746,
        forecast: 1.2638283057,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 2.2420634921,
        forecast: 2.4123749754,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 39.6472222222,
        forecast: 42.658901977,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 0.9920634921,
        forecast: 1.0674225555,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 227.5322562358,
        forecast: 244.816046909,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 25.3111111111,
        forecast: 27.233792112,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 26.026984127,
        forecast: 28.004044228,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 6.1031746032,
        forecast: 6.5667835614,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:00:00",
        base: 19.7920634921,
        forecast: 21.2955069512,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 6.1468253968,
        forecast: 6.6137501539,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 233.2108276644,
        forecast: 250.9259736167,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 24.7817460317,
        forecast: 26.6642154364,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 53.7365079365,
        forecast: 57.8184371102,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 1.2261904762,
        forecast: 1.3193342786,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 35.8480952381,
        forecast: 38.5711859523,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 7.625,
        forecast: 8.2042097616,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 33.1027380952,
        forecast: 35.617286166,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:15:00",
        base: 2.9365079365,
        forecast: 3.1595707643,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 66.0420634921,
        forecast: 71.0587464886,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 11.5734126984,
        forecast: 12.4525515325,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 1.2261904762,
        forecast: 1.3193342786,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 33.1884920635,
        forecast: 35.7095541717,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 225.4841269841,
        forecast: 242.6123377943,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 4.4742063492,
        forecast: 4.8140757253,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 6.25,
        forecast: 6.7247620996,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 47.5351587302,
        forecast: 51.1460214127,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:30:00",
        base: 43.0657936508,
        forecast: 46.3371547094,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 10.9087301587,
        forecast: 11.7373784203,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 53.0558730159,
        forecast: 57.0860998433,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 1.253968254,
        forecast: 1.3492221102,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 225.869047619,
        forecast: 243.0264977459,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 42.6932539683,
        forecast: 45.9363161914,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 59.2284126984,
        forecast: 63.7275175898,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 4.9226190476,
        forecast: 5.2965507204,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 81.9436507937,
        forecast: 88.1682491462,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T12:45:00",
        base: 30.0035714286,
        forecast: 32.2827007995,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 45.6243197279,
        forecast: 49.0900313805,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 67.5006349206,
        forecast: 72.6281138266,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 4.6488095238,
        forecast: 5.0019420951,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 53.8547619048,
        forecast: 57.9456738788,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 1.2896825397,
        forecast: 1.3876493221,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 235.3214285714,
        forecast: 253.1968998546,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 58.2569444444,
        forecast: 62.6822547266,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 22.1726190476,
        forecast: 23.8568941154,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:00:00",
        base: 90.6619047619,
        forecast: 97.5487585639,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 74.1954761905,
        forecast: 79.8315082001,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 66.5507539683,
        forecast: 71.6060780782,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 92.5666666667,
        forecast: 99.5982098705,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 1.3253968254,
        forecast: 1.4260765341,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 3.9623015873,
        forecast: 4.2632856867,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 238.175,
        forecast: 256.2672340933,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 48.8985260771,
        forecast: 52.6129527827,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 94.2615079365,
        forecast: 101.4217945643,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:15:00",
        base: 40.5773809524,
        forecast: 43.659717365,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 53.7698412698,
        forecast: 57.8543025081,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 4.005952381,
        forecast: 4.3102522791,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 89.5785714286,
        forecast: 96.3831331333,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 129.4202380952,
        forecast: 139.2512499312,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 47.6842403628,
        forecast: 51.3064275748,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 80.0349603175,
        forecast: 86.1145708463,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 72.149223356,
        forecast: 77.6298180389,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 246.2186507937,
        forecast: 264.9218961733,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:30:00",
        base: 0.7063492063,
        forecast: 0.7600048595,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 72.9920634921,
        forecast: 78.5366819434,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 170.0095238095,
        forecast: 182.9237763669,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 83.2263888889,
        forecast: 89.5484265105,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 44.7358276644,
        forecast: 48.1340477398,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 3.9058730159,
        forecast: 4.2025706917,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 249.6002947846,
        forecast: 268.5604163884,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 0.873015873,
        forecast: 0.9393318488,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 86.5396825397,
        forecast: 93.1134043613,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T13:45:00",
        base: 76.31319161,
        forecast: 82.1100893827,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 3.8661904762,
        forecast: 4.1598737895,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 218.0148809524,
        forecast: 234.575713374,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 77.2837301587,
        forecast: 83.1543519185,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 82.8879138322,
        forecast: 89.1842402332,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 43.1628117914,
        forecast: 46.4415425358,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 1.0317460317,
        forecast: 1.1101194577,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 89.1685714286,
        forecast: 95.9419887396,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 254.4792630385,
        forecast: 273.8100005164,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 96.0293650794,
        forecast: 103.3239415582,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T14:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 130.0968253968,
        forecast: 139.979232114,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 70.501984127,
        forecast: 75.8574513291,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 280.8601190476,
        forecast: 302.1947974197,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 4.6915873016,
        forecast: 5.0479693557,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 268.421723356,
        forecast: 288.8115571114,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 38.3282879819,
        forecast: 41.2397789384,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 1.0873015873,
        forecast: 1.1698951208,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 87.0616836735,
        forecast: 93.6750577118,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:15:00",
        base: 92.5307142857,
        forecast: 99.5595264771,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 273.0368027211,
        forecast: 293.7772068395,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 64.8765873016,
        forecast: 69.8047384704,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 95.7864285714,
        forecast: 103.0625511228,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 4.384047619,
        forecast: 4.7170683635,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 313.2013888889,
        forecast: 336.992772729,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 0.7619047619,
        forecast: 0.8197805226,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 92.8394614512,
        forecast: 99.891726675,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 32.9425736961,
        forecast: 35.4449553691,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:30:00",
        base: 164.2198412698,
        forecast: 176.694298333,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 55.206547619,
        forecast: 59.4001438529,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 0.7817460317,
        forecast: 0.8411289737,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 0.1071428571,
        forecast: 0.115281636,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 98.5934977324,
        forecast: 106.0828506916,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 338.500085034,
        forecast: 364.2132068102,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 279.9574376417,
        forecast: 301.2235465867,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 30.7829365079,
        forecast: 33.1212679591,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 4.8106349206,
        forecast: 5.1760600623,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 182.9992063492,
        forecast: 196.9001803396,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T14:45:00",
        base: 97.8436904762,
        forecast: 105.2760866246,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 199.121031746,
        forecast: 214.2466508045,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 0.8015873016,
        forecast: 0.8624774248,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 345.7679421769,
        forecast: 372.0331444517,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 5.3721428571,
        forecast: 5.7802212287,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 114.9847222222,
        forecast: 123.7191843261,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 293.0423582766,
        forecast: 315.3024231247,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 26.9968253968,
        forecast: 29.0475565183,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 0.1071428571,
        forecast: 0.115281636,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 106.5713151927,
        forecast: 114.6666786108,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:00:00",
        base: 50.833531746,
        forecast: 54.6949452283,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 120.3484126984,
        forecast: 129.4903111147,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 306.2229931973,
        forecast: 329.4842845909,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 0.1071428571,
        forecast: 0.115281636,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 5.4336507937,
        forecast: 5.8464014272,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 116.9847505669,
        forecast: 125.8711386958,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 42.9446428571,
        forecast: 46.206801067,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 240.4067460317,
        forecast: 258.6685078741,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 0.7063492063,
        forecast: 0.7600048595,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 384.1786564626,
        forecast: 413.3616109556,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:15:00",
        base: 26.0246031746,
        forecast: 28.0014824139,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 317.403531746,
        forecast: 341.5141184927,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 125.3684013605,
        forecast: 134.891627834,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 24.1515873016,
        forecast: 25.9861886291,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 269.8388888889,
        forecast: 290.3363732817,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 49.2529761905,
        forecast: 52.9943276129,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 134.631547619,
        forecast: 144.8584206152,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 5.873015873,
        forecast: 6.3191415286,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 402.7639739229,
        forecast: 433.3587051103,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:30:00",
        base: 0.5396825397,
        forecast: 0.5806778702,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 133.7591950113,
        forecast: 143.9198024146,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 6.2242063492,
        forecast: 6.6970091132,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 141.0779761905,
        forecast: 151.7945323809,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 372.3197278912,
        forecast: 400.6018552116,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 338.8205952381,
        forecast: 364.5580635898,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 58.1192460317,
        forecast: 62.5340964759,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 267.8571428571,
        forecast: 288.2040899848,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 23.5166666667,
        forecast: 25.3030381936,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T15:45:00",
        base: 0.5912698413,
        forecast: 0.6361838431,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 6.5765079365,
        forecast: 7.0760722111,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 152.2120634921,
        forecast: 163.7743865088,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 237.7043650794,
        forecast: 255.760848833,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 312.7840136054,
        forecast: 336.543692811,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 57.9430555556,
        forecast: 62.34452223,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 22.1468253968,
        forecast: 23.829141129,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 146.9338378685,
        forecast: 158.0952166485,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 0.6468253968,
        forecast: 0.6959595062,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 356.8213888889,
        forecast: 383.9262323749,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T16:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 75.6148809524,
        forecast: 81.3587336956,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 372.3848809524,
        forecast: 400.6719574255,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 0.0714285714,
        forecast: 0.076854424,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 198.5992063492,
        forecast: 213.6851865403,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 7.5586507937,
        forecast: 8.1328205411,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 20.8650793651,
        forecast: 22.4500311873,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 158.651893424,
        forecast: 170.7033983896,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 176.6000623583,
        forecast: 190.0149449827,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 263.0919501134,
        forecast: 283.0769239751,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 0.5912698413,
        forecast: 0.6361838431,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T16:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 194.0272845805,
        forecast: 208.765972743,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 7.8165873016,
        forecast: 8.4103504055,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 213.9420634921,
        forecast: 230.1935168146,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 168.8714285714,
        forecast: 181.6992292112,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 87.9179138322,
        forecast: 94.596328771,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 1.4126984127,
        forecast: 1.520009719,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 172.5008730159,
        forecast: 185.6043732821,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 393.9027380952,
        forecast: 423.8243526543,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:30:00",
        base: 21.0198412698,
        forecast: 22.6165491059,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 97.5064058957,
        forecast: 104.9131812544,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 181.0570238095,
        forecast: 194.8104658542,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 7.266984127,
        forecast: 7.8189983097,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 203.3419274376,
        forecast: 218.7881739042,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 1.5714285714,
        forecast: 1.6907973279,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 412.7598809524,
        forecast: 444.1139205892,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 166.2674603175,
        forecast: 178.8974584875,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 21.0023809524,
        forecast: 22.5977624689,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T16:45:00",
        base: 134.3448412698,
        forecast: 144.5499354967,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 92.5353174603,
        forecast: 99.5644793177,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 2.0436507937,
        forecast: 2.1988904643,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 79.076984127,
        forecast: 85.0838249298,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 223.8886337868,
        forecast: 240.895647845,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 186.6201587302,
        forecast: 200.7961872733,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 21.5817460317,
        forecast: 23.2211372414,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 123.1123582766,
        forecast: 132.4642113499,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 432.2379761905,
        forecast: 465.0716096501,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T17:00:00",
        base: 7.3324603175,
        forecast: 7.8894481984,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 238.039744898,
        forecast: 256.1217047518,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 21.5023809524,
        forecast: 23.1357434369,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 136.6131519274,
        forecast: 146.9905514232,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 31.8392857143,
        forecast: 34.2578594962,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 195.2300793651,
        forecast: 210.060134148,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 2.4166666667,
        forecast: 2.6002413452,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 436.5587301587,
        forecast: 469.7205764545,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 37.0392857143,
        forecast: 39.8528615631,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T17:15:00",
        base: 7.4475396825,
        forecast: 8.0132692148,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 23.5833333333,
        forecast: 25.3747689893,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 168.1818027211,
        forecast: 180.9572180462,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 8.265,
        forecast: 8.8928254006,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 11.7468253968,
        forecast: 12.6391369952,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 199.5574206349,
        forecast: 214.7161886382,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 422.569047619,
        forecast: 454.6682105459,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 2.3333333333,
        forecast: 2.5105778505,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 23.0698412698,
        forecast: 24.8222710746,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:30:00",
        base: 261.7006972789,
        forecast: 281.5799888819,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 7.2123015873,
        forecast: 7.7601619785,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 25.6333333333,
        forecast: 27.580490958,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 194.1316043084,
        forecast: 208.8782167994,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 31.8115079365,
        forecast: 34.2279716646,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 296.472760771,
        forecast: 318.9934056336,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 202.5046825397,
        forecast: 217.887330263,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 404.0384920635,
        forecast: 434.7300381162,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 12.6734126984,
        forecast: 13.636109662,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T17:45:00",
        base: 2.2738095238,
        forecast: 2.4465324972,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 25.8,
        forecast: 27.7598179473,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 391.6488095238,
        forecast: 421.3992113051,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 2.5793650794,
        forecast: 2.7752986443,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 301.2603571429,
        forecast: 324.1446770944,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 198.693622449,
        forecast: 213.7867746698,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 28.2202380952,
        forecast: 30.3639020137,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 6.9404761905,
        forecast: 7.4676881983,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 64.6916666667,
        forecast: 69.6057709061,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 205.2804761905,
        forecast: 220.8739785733,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T18:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 364.7857142857,
        forecast: 392.4955433473,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 28.7642857143,
        forecast: 30.9492765432,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 215.8455498866,
        forecast: 232.2415957207,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 209.2820238095,
        forecast: 225.1794914962,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 1.8253968254,
        forecast: 1.9640575021,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 49.4152777778,
        forecast: 53.168957943,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 8.0238095238,
        forecast: 8.6333136289,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 114.7553571429,
        forecast: 123.4723962313,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:15:00",
        base: 318.0087698413,
        forecast: 342.1653316453,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 28.5436507937,
        forecast: 30.7118817668,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 331.3061791383,
        forecast: 356.4728378956,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 1.6111111111,
        forecast: 1.7334942301,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 88.3168650794,
        forecast: 95.0255851272,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 187.2097222222,
        forecast: 201.4305351496,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 231.9498469388,
        forecast: 249.569206354,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 210.0371825397,
        forecast: 225.9920135455,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 332.5100396825,
        forecast: 357.7681460174,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T18:30:00",
        base: 9.7757936508,
        forecast: 10.5183818619,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 212.9407142857,
        forecast: 229.116103184,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 1.0317460317,
        forecast: 1.1101194577,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 262.2990079365,
        forecast: 282.2237483754,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 128.0994047619,
        forecast: 137.8300835408,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 277.4206632653,
        forecast: 298.4940739176,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 10.5158730159,
        forecast: 11.3146790883,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 234.0296088435,
        forecast: 251.8069509993,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 342.9875396825,
        forecast: 369.0415372012,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 29.5476190476,
        forecast: 31.792113393,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T18:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 172.1648809524,
        forecast: 185.242858611,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 10.3115079365,
        forecast: 11.0947900419,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 0.7301587302,
        forecast: 0.7856230008,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 239.0113548753,
        forecast: 257.167120104,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 336.3902777778,
        forecast: 361.9431345103,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 32.691468254,
        forecast: 35.1747754714,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 361.2812698413,
        forecast: 388.7248945185,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 245.4423185941,
        forecast: 264.0865922769,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:00:00",
        base: 209.3446825397,
        forecast: 225.2469099048,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 359.3274603175,
        forecast: 386.6226698407,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 201.635515873,
        forecast: 216.9521400136,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 9.8313492063,
        forecast: 10.578157525,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 395.2593253968,
        forecast: 425.2839889536,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 34.4613095238,
        forecast: 37.0790573104,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 222.8085884354,
        forecast: 239.7335601577,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 203.7182142857,
        forecast: 219.1930442298,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 0.0198412698,
        forecast: 0.0213484511,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 0.7103174603,
        forecast: 0.7642745497,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:15:00",
        base: 209.9399263039,
        forecast: 225.8873695377,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 203.4573979592,
        forecast: 218.9124157901,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 452.3172619048,
        forecast: 486.6761567796,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 330.9745238095,
        forecast: 356.115989386,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 0.6944444444,
        forecast: 0.7471957888,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 34.8422619048,
        forecast: 37.4889475717,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 190.7475793651,
        forecast: 205.2371347701,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 190.9123469388,
        forecast: 205.4144184077,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 10.1865079365,
        forecast: 10.9602947999,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:30:00",
        base: 231.760515873,
        forecast: 249.3654933339,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 174.831207483,
        forecast: 188.1117244667,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 0.5317460317,
        forecast: 0.5721384897,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 257.1010770975,
        forecast: 276.6309726469,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 324.5051133787,
        forecast: 349.1551500144,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 9.8452380952,
        forecast: 10.5931014408,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 171.8665986395,
        forecast: 184.9219181961,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 174.9894444444,
        forecast: 188.2819814141,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 486.7240079365,
        forecast: 523.6965058494,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T19:45:00",
        base: 33.3303571429,
        forecast: 35.8621955971,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 141.7121598639,
        forecast: 152.4768898739,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 260.5885770975,
        forecast: 280.3833898985,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 154.1570238095,
        forecast: 165.8670897773,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 150.5467573696,
        forecast: 161.9825805094,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 28.7033730159,
        forecast: 30.8837367983,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 307.5476133787,
        forecast: 330.9095254857,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 0.5158730159,
        forecast: 0.5550597289,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 9.0714285714,
        forecast: 9.7605118475,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:00:00",
        base: 480.3150793651,
        forecast: 516.8007426564,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 138.4762698413,
        forecast: 148.9951953806,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 0.5158730159,
        forecast: 0.5550597289,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 285.9398356009,
        forecast: 307.6603790764,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 0.0119047619,
        forecast: 0.0128090707,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 8.2261904762,
        forecast: 8.8510678302,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 119.2710600907,
        forecast: 128.3311207172,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 256.6925453515,
        forecast: 276.1914080386,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 476.2154761905,
        forecast: 512.389725688,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 26.1319444444,
        forecast: 28.1169775344,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:15:00",
        base: 125.1793650794,
        forecast: 134.6882319909,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 103.9684807256,
        forecast: 111.8661277986,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 267.7572562358,
        forecast: 288.0966157824,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 0.6031746032,
        forecast: 0.6489929137,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 245.7611961451,
        forecast: 264.429692384,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 95.6867346939,
        forecast: 102.9552843053,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 449.723015873,
        forecast: 483.884846797,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 7.1091269841,
        forecast: 7.6491500327,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 118.5068253968,
        forecast: 127.5088332765,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:30:00",
        base: 20.9851190476,
        forecast: 22.5791893165,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 91.3833333333,
        forecast: 98.3249882463,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 15.2777777778,
        forecast: 16.4383073547,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 0.5674603175,
        forecast: 0.6105657017,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 236.5688038549,
        forecast: 254.5390281795,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 6.7757936508,
        forecast: 7.2904960541,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 103.5123809524,
        forecast: 111.3753818036,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 449.3246031746,
        forecast: 483.4561698987,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 253.27111678,
        forecast: 272.5100811292,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T20:45:00",
        base: 62.7002267574,
        forecast: 67.4630573659,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 90.3698412698,
        forecast: 97.2345093636,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 262.03361678,
        forecast: 281.9381975929,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 451.7444444444,
        forecast: 486.0598269961,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 231.2543820862,
        forecast: 248.8209126448,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 0.4801587302,
        forecast: 0.5166325169,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 9.1587301587,
        forecast: 9.8544450324,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 79.4964285714,
        forecast: 85.5351311863,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 66.7315759637,
        forecast: 71.8006356625,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T21:00:00",
        base: 6.8670634921,
        forecast: 7.3886989292,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 4.0119047619,
        forecast: 4.3166568144,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 0.0476190476,
        forecast: 0.0512362827,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 5.6448412698,
        forecast: 6.0736343408,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 69.5573696145,
        forecast: 74.8410820696,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 0.5238095238,
        forecast: 0.5635991093,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 82.4785714286,
        forecast: 88.7438033881,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 466.4876984127,
        forecast: 501.9230070778,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 62.8496031746,
        forecast: 67.623780705,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 238.5394217687,
        forecast: 256.659338045,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:15:00",
        base: 275.9721088435,
        forecast: 296.9354844976,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 0.380952381,
        forecast: 0.4098902613,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 74.4,
        forecast: 80.0515680342,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 2.5198412698,
        forecast: 2.711253291,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 217.8160544218,
        forecast: 234.3617835952,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 279.1775793651,
        forecast: 300.3844487656,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 4.5654761905,
        forecast: 4.9122786004,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 470.0321428571,
        forecast: 505.7366943841,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 59.6227891156,
        forecast: 64.1518516032,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:30:00",
        base: 49.8,
        forecast: 53.58290441,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 0.2738095238,
        forecast: 0.2946086253,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 68.25,
        forecast: 73.4344021281,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 316.4632936508,
        forecast: 340.5024580915,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 517.9884920635,
        forecast: 557.335900717,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 54.8839002268,
        forecast: 59.0529875401,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 42.298015873,
        forecast: 45.5110550453,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 3.1825396825,
        forecast: 3.424291558,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 217.05180839,
        forecast: 233.5394839553,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T21:45:00",
        base: 0.6468253968,
        forecast: 0.6959595062,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 30.1373015873,
        forecast: 32.42658936,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 207.7734750567,
        forecast: 223.5563504597,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 531.0226190476,
        forecast: 571.3601252201,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 58.7492063492,
        forecast: 63.2119097986,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 0.2738095238,
        forecast: 0.2946086253,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 0.5317460317,
        forecast: 0.5721384897,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 0.0357142857,
        forecast: 0.038427212,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 1.6805555556,
        forecast: 1.808213809,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 333.6668650794,
        forecast: 359.0128461109,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 54.3005668934,
        forecast: 58.4253430775,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T22:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 0.1865079365,
        forecast: 0.2006754404,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 215.6403004535,
        forecast: 232.0207551433,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 19.6686507937,
        forecast: 21.1627195853,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 1.5099206349,
        forecast: 1.6246171295,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 342.8077380952,
        forecast: 368.8480775373,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 0.5317460317,
        forecast: 0.5721384897,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 534.023015873,
        forecast: 574.588437997,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 51.6476190476,
        forecast: 55.5708721773,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:15:00",
        base: 73.4412131519,
        forecast: 79.019949881,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 301.0775793651,
        forecast: 323.9480151628,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 469.7507936508,
        forecast: 505.4339733474,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 10.6170634921,
        forecast: 11.423556189,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 0.1507936508,
        forecast: 0.1622482284,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 50.2908730159,
        forecast: 54.1110650904,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 0.9543650794,
        forecast: 1.0268604984,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 1.4285714286,
        forecast: 1.5370884799,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 243.3573639456,
        forecast: 261.8432604369,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:30:00",
        base: 98.5265306122,
        forecast: 106.0107966193,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:45:00",
        base: 398.9738095238,
        forecast: 429.2806324859,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:45:00",
        base: 1.4285714286,
        forecast: 1.5370884799,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T22:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:45:00",
        base: 259.9369047619,
        forecast: 279.6822152707,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:45:00",
        base: 2.3888888889,
        forecast: 2.5703535136,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:45:00",
        base: 43.2035714286,
        forecast: 46.4853983539,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T22:45:00",
        base: 245.6553174603,
        forecast: 264.3157709494,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:45:00",
        base: 0.0793650794,
        forecast: 0.0853938044,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T22:45:00",
        base: 86.5959750567,
        forecast: 93.1739729669,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:00:00",
        base: 86.3090702948,
        forecast: 92.8652743638,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:00:00",
        base: 318.0753968254,
        forecast: 342.2370197442,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:00:00",
        base: 212.0936507937,
        forecast: 228.2046951092,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:00:00",
        base: 33.8067460317,
        forecast: 36.3747719083,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T23:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:00:00",
        base: 1.4285714286,
        forecast: 1.5370884799,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:00:00",
        base: 218.9378968254,
        forecast: 235.5688433196,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:15:00",
        base: 166.5484126984,
        forecast: 179.1997525553,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:15:00",
        base: 273.7707936508,
        forecast: 294.5669531412,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:15:00",
        base: 1.4285714286,
        forecast: 1.5370884799,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T23:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:15:00",
        base: 111.569047619,
        forecast: 120.0440484675,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:15:00",
        base: 26.9781746032,
        forecast: 29.0274889742,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:15:00",
        base: 246.1646825397,
        forecast: 264.8638283863,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:30:00",
        base: 282.271547619,
        forecast: 303.7134408379,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:30:00",
        base: 128.9916666667,
        forecast: 138.7901233872,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:30:00",
        base: 19.7083333333,
        forecast: 21.2054164875,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T23:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:30:00",
        base: 104.2646825397,
        forecast: 112.1848296759,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:30:00",
        base: 1.4285714286,
        forecast: 1.5370884799,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:30:00",
        base: 154.5535714286,
        forecast: 166.2937599212,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:45:00",
        base: 52.0555555556,
        forecast: 56.0097963322,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:45:00",
        base: 262.5646031746,
        forecast: 282.5095187419,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:45:00",
        base: 126.944047619,
        forecast: 136.5869632327,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-02T23:45:00",
        base: 14.4583333333,
        forecast: 15.5566163238,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:45:00",
        base: 0.8968253968,
        forecast: 0.9649499902,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-02T23:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-02T23:45:00",
        base: 73.1646825397,
        forecast: 78.7224134681,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T00:00:00",
        base: 21.5674603175,
        forecast: 23.2057663566,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T00:00:00",
        base: 0.8968253968,
        forecast: 0.9649499902,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T00:00:00",
        base: 106.2373412698,
        forecast: 114.3073353822,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T00:00:00",
        base: 14.0634920635,
        forecast: 15.1317821468,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T00:00:00",
        base: 242.7751190476,
        forecast: 261.2167870893,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T00:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T00:00:00",
        base: 10.2416666667,
        forecast: 11.019643494,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T00:15:00",
        base: 2.5992063492,
        forecast: 2.7966470954,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T00:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T00:15:00",
        base: 187.3616269841,
        forecast: 201.5939788913,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T00:15:00",
        base: 1.2281746032,
        forecast: 1.3214691237,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T00:15:00",
        base: 6.3888888889,
        forecast: 6.8742012574,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T00:15:00",
        base: 80.223452381,
        forecast: 86.3173811319,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T00:30:00",
        base: 151.3489285714,
        forecast: 162.8456861887,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T00:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T00:30:00",
        base: 6.3888888889,
        forecast: 6.8742012574,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T00:30:00",
        base: 2.5992063492,
        forecast: 2.7966470954,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T00:30:00",
        base: 71.9258333333,
        forecast: 77.3894588777,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T00:45:00",
        base: 57.1171031746,
        forecast: 61.4558289072,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T00:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T00:45:00",
        base: 138.1788888889,
        forecast: 148.6752247954,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T01:00:00",
        base: 65.1276984127,
        forecast: 70.0749244677,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T01:00:00",
        base: 26.7877380952,
        forecast: 28.8225865405,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T01:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T01:15:00",
        base: 2.8294047619,
        forecast: 3.0443318252,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T01:15:00",
        base: 25.3407936508,
        forecast: 27.2657293948,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T01:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T01:30:00",
        base: 23.9598412698,
        forecast: 25.7798771976,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T01:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T01:30:00",
        base: 0.4960714286,
        forecast: 0.5337539747,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T01:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T01:45:00",
        base: 4.8876984127,
        forecast: 5.2589774464,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T02:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T02:00:00",
        base: 4.8876984127,
        forecast: 5.2589774464,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T02:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T02:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T02:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T03:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T03:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T03:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T03:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T04:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T04:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T04:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T04:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T05:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T05:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T05:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T05:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T06:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T06:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T06:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T06:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T07:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T07:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T07:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T07:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T08:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T08:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T08:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T08:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T08:45:00",
        base: 0.1795918367,
        forecast: 0.1932339803,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T09:00:00",
        base: 3.2653061224,
        forecast: 3.513345097,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T09:00:00",
        base: 0.0448979592,
        forecast: 0.0483084951,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T09:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T09:15:00",
        base: 15.7346938776,
        forecast: 16.929931686,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T09:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T09:15:00",
        base: 0.0448979592,
        forecast: 0.0483084951,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T09:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T09:30:00",
        base: 0.0448979592,
        forecast: 0.0483084951,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T09:30:00",
        base: 30.2714285714,
        forecast: 32.5709048895,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T09:45:00",
        base: 0.1836734694,
        forecast: 0.1976256617,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T09:45:00",
        base: 49.2755102041,
        forecast: 53.0185733538,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T09:45:00",
        base: 0.2,
        forecast: 0.2151923872,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T09:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T10:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:00:00",
        base: 0.1836734694,
        forecast: 0.1976256617,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:00:00",
        base: 76.1489795918,
        forecast: 81.9334035017,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:00:00",
        base: 0.4,
        forecast: 0.4303847744,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T10:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:15:00",
        base: 0.4,
        forecast: 0.4303847744,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:15:00",
        base: 0.3730612245,
        forecast: 0.4013996773,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T10:15:00",
        base: 1.0653061224,
        forecast: 1.1462288379,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:15:00",
        base: 0.306122449,
        forecast: 0.3293761028,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:15:00",
        base: 105.4673469388,
        forecast: 113.478850791,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:15:00",
        base: 0.0514285714,
        forecast: 0.0553351853,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:30:00",
        base: 0.4,
        forecast: 0.4303847744,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:30:00",
        base: 0.3730612245,
        forecast: 0.4013996773,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T10:30:00",
        base: 1.9204081633,
        forecast: 2.0662860851,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:30:00",
        base: 0.0514285714,
        forecast: 0.0553351853,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T10:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:30:00",
        base: 0.8088835534,
        forecast: 0.8703279141,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:30:00",
        base: 127.3755102041,
        forecast: 137.0512005509,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:30:00",
        base: 0.3551020408,
        forecast: 0.3820762793,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T10:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:45:00",
        base: 148.2020408163,
        forecast: 159.4597547475,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:45:00",
        base: 0.6220408163,
        forecast: 0.669292241,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T10:45:00",
        base: 2.1510204082,
        forecast: 2.3144160826,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:45:00",
        base: 0.2269387755,
        forecast: 0.2441774842,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:45:00",
        base: 0.4897959184,
        forecast: 0.5270017645,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:45:00",
        base: 0.1648979592,
        forecast: 0.1774239274,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T10:45:00",
        base: 0.3551020408,
        forecast: 0.3820762793,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T10:45:00",
        base: 0.8088835534,
        forecast: 0.8703279141,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T11:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:00:00",
        base: 0.8220408163,
        forecast: 0.8844846282,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:00:00",
        base: 1.0039591837,
        forecast: 1.0802218669,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:00:00",
        base: 0.3551020408,
        forecast: 0.3820762793,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:00:00",
        base: 0.8088835534,
        forecast: 0.8703279141,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:00:00",
        base: 0.5795918367,
        forecast: 0.6236187547,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:00:00",
        base: 150.1183673469,
        forecast: 161.5216491513,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T11:00:00",
        base: 2.1510204082,
        forecast: 2.3144160826,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:00:00",
        base: 0.4632653061,
        forecast: 0.4984558356,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 0.8220408163,
        forecast: 0.8844846282,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 159.3829531813,
        forecast: 171.4899908613,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 5.6027346939,
        forecast: 6.0283292678,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 6.2571428571,
        forecast: 6.732447542,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 1.3599039616,
        forecast: 1.4632048992,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 3.212244898,
        forecast: 3.4562532391,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 4.4595918367,
        forecast: 4.7983510662,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 4.9942857143,
        forecast: 5.3736613258,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 1.0489795918,
        forecast: 1.1286621124,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T11:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 3.6367346939,
        forecast: 3.9129881017,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 8.3363265306,
        forecast: 8.9695700325,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 1.7510204082,
        forecast: 1.8840313082,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 0.7648979592,
        forecast: 0.823001089,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 9.7602857143,
        forecast: 10.5016959125,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 163.9360144058,
        forecast: 176.3889114308,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 1.3599039616,
        forecast: 1.4632048992,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 9.9949795918,
        forecast: 10.7542175913,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:30:00",
        base: 16.7300840336,
        forecast: 18.0009336053,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 15.8445306122,
        forecast: 17.0481118317,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 159.6115246098,
        forecast: 171.7359250181,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 13.2824489796,
        forecast: 14.2914095181,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 5.0612244898,
        forecast: 5.4456849003,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 0.9689795918,
        forecast: 1.0425851575,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 1.7925570228,
        forecast: 1.9287231246,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 16.3026938776,
        forecast: 17.5410780656,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 28.4525330132,
        forecast: 30.6138425034,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T11:45:00",
        base: 1.7795918367,
        forecast: 1.9147730778,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 151.3054021609,
        forecast: 162.7988534277,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 20.798,
        forecast: 22.3778563437,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 18.7236734694,
        forecast: 20.1459599541,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 42.7900360144,
        forecast: 46.0404499891,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 2.0823529412,
        forecast: 2.2405325019,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 1.893877551,
        forecast: 2.0377401562,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 24.0947346939,
        forecast: 25.9250173873,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 4.7795918367,
        forecast: 5.1426588857,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:00:00",
        base: 0.8612244898,
        forecast: 0.9266447693,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 3.7665306122,
        forecast: 4.0526435693,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 1.1795918367,
        forecast: 1.2691959163,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 56.9075870348,
        forecast: 61.2303975158,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 148.7928307323,
        forecast: 160.0954222092,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 31.5945306122,
        forecast: 33.9945123228,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 4.7551020408,
        forecast: 5.1163087974,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 28.9976734694,
        forecast: 31.200392884,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 4.1040816327,
        forecast: 4.4158356187,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:15:00",
        base: 27.2012244898,
        forecast: 29.2674821621,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 4.6734693878,
        forecast: 5.02847517,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 17.8408163265,
        forecast: 19.1960392735,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 0.8857142857,
        forecast: 0.9529948575,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 42.0111428571,
        forecast: 45.2023905998,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 144.6218103241,
        forecast: 155.607563016,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 9.193877551,
        forecast: 9.8922622886,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 73.7104441777,
        forecast: 79.3096322166,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 35.3613469388,
        forecast: 38.0474633098,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:30:00",
        base: 29.8053061224,
        forecast: 32.0693748769,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 6.8285714286,
        forecast: 7.347282934,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 13.7269387755,
        forecast: 14.7696636195,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 30.633877551,
        forecast: 32.9608861952,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 44.2448979592,
        forecast: 47.6058260638,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 85.7292196879,
        forecast: 92.2413771823,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 0.9142857143,
        forecast: 0.9837366271,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 152.0136470588,
        forecast: 163.5608979792,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 35.9334693878,
        forecast: 38.6630452876,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T12:45:00",
        base: 51.5042040816,
        forecast: 55.4165631329,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 77.6210204082,
        forecast: 83.5172633883,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 95.1210564226,
        forecast: 102.3466360174,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 47.3501632653,
        forecast: 50.9469733342,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 0.012244898,
        forecast: 0.0131750441,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 0.9142857143,
        forecast: 0.9837366271,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 37.7391836735,
        forecast: 40.6059251262,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 6.9836734694,
        forecast: 7.5141668261,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 34.7895918367,
        forecast: 37.4322765833,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 58.1119591837,
        forecast: 62.5262561047,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:00:00",
        base: 149.708244898,
        forecast: 161.0803730071,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 64.0696926771,
        forecast: 68.9365505681,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 53.592,
        forecast: 57.6629520711,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 6.9918367347,
        forecast: 7.5229501889,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 159.0327346939,
        forecast: 171.1131690996,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 58.8842857143,
        forecast: 63.3572500538,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 130.1695918367,
        forecast: 140.0575260336,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 0.012244898,
        forecast: 0.0131750441,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 40.7065306122,
        forecast: 43.7986774831,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 103.9654621849,
        forecast: 111.8628799637,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:15:00",
        base: 0.906122449,
        forecast: 0.9749532644,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 1.1387755102,
        forecast: 1.2252791026,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 0.012244898,
        forecast: 0.0131750441,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 5.7428571429,
        forecast: 6.1790956893,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 57.9771020408,
        forecast: 62.3811549522,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 179.7508163265,
        forecast: 193.4050363221,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 68.4974885954,
        forecast: 73.7006904364,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 167.9613061224,
        forecast: 180.7199720991,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 41.2493877551,
        forecast: 44.3827711055,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 106.0797478992,
        forecast: 114.137770914,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:30:00",
        base: 83.6383673469,
        forecast: 89.9916996497,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 102.75,
        forecast: 110.5550889182,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 0.012244898,
        forecast: 0.0131750441,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 1.1142857143,
        forecast: 1.1989290143,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 5.6040816327,
        forecast: 6.0297785227,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 70.4209171669,
        forecast: 75.7702263658,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 40.9030612245,
        forecast: 44.0101369411,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 169.9168571429,
        forecast: 182.8240705608,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 110.2669387755,
        forecast: 118.6430289154,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 61.9566938776,
        forecast: 66.6630442891,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T13:45:00",
        base: 227.3708979592,
        forecast: 244.6424315453,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 66.0200816327,
        forecast: 71.0350948446,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 74.0935702281,
        forecast: 79.7218612636,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 0.012244898,
        forecast: 0.0131750441,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 277.6145714286,
        forecast: 298.7027117203,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 102.3810204082,
        forecast: 110.1580809222,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 0.8693877551,
        forecast: 0.9354281321,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 138.1628571429,
        forecast: 148.6579752469,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 36.793577431,
        forecast: 39.5884888029,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 170.0033877551,
        forecast: 182.9171742059,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:00:00",
        base: 5.4244897959,
        forecast: 5.8365445423,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 36.2433733493,
        forecast: 38.9964901541,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 0.012244898,
        forecast: 0.0131750441,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 175.0058367347,
        forecast: 188.2996188944,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 5.1959183673,
        forecast: 5.5906103855,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 70.243755102,
        forecast: 75.5796067275,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 98.7740816327,
        forecast: 106.2771520945,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 173.9885714286,
        forecast: 187.2050801463,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 74.6615294118,
        forecast: 80.3329637264,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 1.0326530612,
        forecast: 1.1110953869,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:15:00",
        base: 332.712122449,
        forecast: 357.985579382,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 74.0611212485,
        forecast: 79.6869473967,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 0.0081632653,
        forecast: 0.0087833627,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 94.2948019208,
        forecast: 101.4576176241,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 183.4588979592,
        forecast: 197.3947910142,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 0.012244898,
        forecast: 0.0131750441,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 0.0102040816,
        forecast: 0.0109792034,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 202.913877551,
        forecast: 218.3276085195,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 377.5949795918,
        forecast: 406.2778252441,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 32.9519447779,
        forecast: 35.4550382963,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 0.8040816327,
        forecast: 0.8651612301,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 3.6224489796,
        forecast: 3.8976172169,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:30:00",
        base: 71.4564081633,
        forecast: 76.8843752629,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 3.2448979592,
        forecast: 3.4913866901,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 0.0204081633,
        forecast: 0.0219584069,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 81.1367346939,
        forecast: 87.3000381373,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 91.3029651861,
        forecast: 98.238515179,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 74.0113253301,
        forecast: 79.6333688839,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 1.0612244898,
        forecast: 1.1418371565,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 30.1601080432,
        forecast: 32.4511282384,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 0.0081632653,
        forecast: 0.0087833627,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 406.2278367347,
        forecast: 437.0856896471,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 229.8312244898,
        forecast: 247.2896492423,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 186.7157959184,
        forecast: 200.8990892475,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T14:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 27.4854141657,
        forecast: 29.5732594359,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 0.0306122449,
        forecast: 0.0329376103,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 254.0667346939,
        forecast: 273.36613572,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 434.7588571429,
        forecast: 467.7839815999,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 1.1893877551,
        forecast: 1.2797359516,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 86.6580408163,
        forecast: 93.2407533618,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 186.5557959184,
        forecast: 200.7269353378,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 3.4734693878,
        forecast: 3.7373208469,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 82.8101080432,
        forecast: 89.1005241659,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 0.0183673469,
        forecast: 0.0197625662,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 76.4059591837,
        forecast: 82.2099037609,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:00:00",
        base: 0.0244897959,
        forecast: 0.0263500882,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 0.7526530612,
        forecast: 0.8098260448,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 23.6622208884,
        forecast: 25.4596489958,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 94.1786938776,
        forecast: 101.3326897891,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 0.0918367347,
        forecast: 0.0988128309,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 460.0776326531,
        forecast: 495.0260203136,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 0.0183673469,
        forecast: 0.0197625662,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 3.7306122449,
        forecast: 4.0139967733,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 78.6036182473,
        forecast: 84.5745012615,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 191.9183409364,
        forecast: 206.4968296569,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 278.7457142857,
        forecast: 299.9197783787,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 0.0306122449,
        forecast: 0.0329376103,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:15:00",
        base: 79.5835918367,
        forecast: 85.628915542,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 102.9860408163,
        forecast: 110.8090598519,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 3.9591836735,
        forecast: 4.2599309301,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 0.0183673469,
        forecast: 0.0197625662,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 520.253877551,
        forecast: 559.7733692718,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 0.1224489796,
        forecast: 0.1317504411,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 76.8130060024,
        forecast: 82.647870644,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 0.7485714286,
        forecast: 0.8054343635,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 0.0367346939,
        forecast: 0.0395251323,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 336.7502040816,
        forecast: 362.330401513,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 20.7003841537,
        forecast: 22.2728254087,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 83.5648571429,
        forecast: 89.9126054683,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:30:00",
        base: 198.7893205282,
        forecast: 213.8897421604,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 19.0343577431,
        forecast: 20.4802444067,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 0.0183673469,
        forecast: 0.0197625662,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 0.0367346939,
        forecast: 0.0395251323,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 0.1224489796,
        forecast: 0.1317504411,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 483.237214886,
        forecast: 519.9448492485,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 4.7346938776,
        forecast: 5.0943503906,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 111.9586938776,
        forecast: 120.4632930102,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 321.8139255702,
        forecast: 346.25953437,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 0.7893877551,
        forecast: 0.8493511772,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 69.5338223289,
        forecast: 74.8157460866,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 209.3255654262,
        forecast: 225.2263406184,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T15:45:00",
        base: 85.3182040816,
        forecast: 91.7991400349,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 426.6131332533,
        forecast: 459.019492754,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 0.0367346939,
        forecast: 0.0395251323,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 288.9527010804,
        forecast: 310.9021076505,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 0.6832653061,
        forecast: 0.7351674615,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 0.1224489796,
        forecast: 0.1317504411,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 0.0183673469,
        forecast: 0.0197625662,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 85.2173877551,
        forecast: 91.690665505,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 122.4548163265,
        forecast: 131.7567212403,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 5.2673469388,
        forecast: 5.6674648095,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 217.0517286915,
        forecast: 233.5393982027,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 18.5682352941,
        forecast: 19.9787143941,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:00:00",
        base: 74.1501488595,
        forecast: 79.7827377174,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 17.4784393758,
        forecast: 18.806135468,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 5.6653061224,
        forecast: 6.0956537432,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 0.6832653061,
        forecast: 0.7351674615,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 390.3412965186,
        forecast: 419.9923770808,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 0.0367346939,
        forecast: 0.0395251323,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 0.0102040816,
        forecast: 0.0109792034,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 73.1845306122,
        forecast: 78.7437692386,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 90.1503265306,
        forecast: 96.9983198598,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 0.1224489796,
        forecast: 0.1317504411,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 134.6309387755,
        forecast: 144.8577655228,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 221.7149939976,
        forecast: 238.5568941693,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:15:00",
        base: 264.1816806723,
        forecast: 284.2494325769,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 0.0367346939,
        forecast: 0.0395251323,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 138.9345306122,
        forecast: 149.4882665269,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 0.0102040816,
        forecast: 0.0109792034,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 0.112244898,
        forecast: 0.1207712377,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 316.8437454982,
        forecast: 340.9118097978,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 5.5326530612,
        forecast: 5.9529240987,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 15.6417046819,
        forecast: 16.829878851,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 0.5118367347,
        forecast: 0.5507168439,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 79.3506530612,
        forecast: 85.3782822861,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 105.8919183673,
        forecast: 113.9356734873,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 219.7445378151,
        forecast: 236.4367583205,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:30:00",
        base: 229.0642184874,
        forecast: 246.464379979,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 15.1153781513,
        forecast: 16.2635715381,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 81.2390204082,
        forecast: 87.4100936725,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 107.6739591837,
        forecast: 115.8530815739,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 0.1020408163,
        forecast: 0.1097920343,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 0.012244898,
        forecast: 0.0131750441,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 142.5351836735,
        forecast: 153.3624321653,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 5.0469387755,
        forecast: 5.4303140155,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 224.1629939976,
        forecast: 241.1908489885,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 235.7641536615,
        forecast: 253.6732551996,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 170.7122929172,
        forecast: 183.6799291765,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T16:45:00",
        base: 0.3102040816,
        forecast: 0.3337677842,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 86.6453061224,
        forecast: 93.2270513159,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 147.5963265306,
        forecast: 158.808029232,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 121.2506602641,
        forecast: 130.4610951522,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 160.4315006002,
        forecast: 172.6181879721,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 0.4040816327,
        forecast: 0.4347764557,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 0.006122449,
        forecast: 0.0065875221,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 16.6251020408,
        forecast: 17.8879769771,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 217.4106530612,
        forecast: 233.9255871624,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 0.0408163265,
        forecast: 0.0439168137,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 6.0387755102,
        forecast: 6.4974925887,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:00:00",
        base: 113.0646530612,
        forecast: 121.6532629945,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 5.3816326531,
        forecast: 5.7904318879,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 53.3535414166,
        forecast: 57.406379712,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 150.8223673469,
        forecast: 162.2791263542,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 17.2675510204,
        forecast: 18.5792276249,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 91.4926530612,
        forecast: 98.4426121123,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 0.4326530612,
        forecast: 0.4655182253,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 215.4033061224,
        forecast: 231.7657582641,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 0.0102040816,
        forecast: 0.0109792034,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 114.6066434574,
        forecast: 123.3123859664,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:15:00",
        base: 36.5849459784,
        forecast: 39.3640093013,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 0.0102040816,
        forecast: 0.0109792034,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 5.4673469388,
        forecast: 5.8826571967,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 22.7526530612,
        forecast: 24.4809886356,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 205.9298367347,
        forecast: 221.5726658015,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 145.8691716687,
        forecast: 156.9496763431,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 18.5924489796,
        forecast: 20.004767398,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 115.6369387755,
        forecast: 124.4209445115,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 41.7102040816,
        forecast: 44.8785919323,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 0.3183673469,
        forecast: 0.342551147,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:30:00",
        base: 118.6561536615,
        forecast: 127.6695048052,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 123.7782761104,
        forecast: 133.1807135915,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 0.0102040816,
        forecast: 0.0109792034,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 123.3210564226,
        forecast: 132.688762611,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 19.9608163265,
        forecast: 21.4770785777,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 20.6195918367,
        forecast: 22.185895951,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 0.2612244898,
        forecast: 0.2810676078,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 140.8019471789,
        forecast: 151.4975356712,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 6.0163265306,
        forecast: 6.4733383411,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 43.3224489796,
        forecast: 46.6133060739,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T17:45:00",
        base: 192.3110204082,
        forecast: 206.9193378216,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 0.9918367347,
        forecast: 1.0671785732,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 139.6716686675,
        forecast: 150.2813990159,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 21.1514285714,
        forecast: 22.7581320337,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 177.9967346939,
        forecast: 191.5177112528,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 5.6040816327,
        forecast: 6.0297785227,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 20.7787755102,
        forecast: 22.3571715245,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 46.7763265306,
        forecast: 50.3295468502,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 131.8665186074,
        forecast: 141.883354647,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:00:00",
        base: 122.8424393758,
        forecast: 132.1737888867,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 108.4023985594,
        forecast: 116.6368546149,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 21.6173109244,
        forecast: 23.2594037121,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 156.2946938776,
        forecast: 168.1671414021,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 26.7857142857,
        forecast: 28.8204089985,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 69.4253061224,
        forecast: 74.698986779,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 5.506122449,
        forecast: 5.9243781697,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 121.2302737095,
        forecast: 130.4391599954,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 139.5816206483,
        forecast: 150.1845107748,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 0.906122449,
        forecast: 0.9749532644,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T18:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 109.6546002401,
        forecast: 117.9841759594,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 106.3559183673,
        forecast: 114.4349198256,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 0.9428571429,
        forecast: 1.0144783967,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 5.7306122449,
        forecast: 6.1659206452,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 0.006122449,
        forecast: 0.0065875221,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 141.8865306122,
        forecast: 152.6645061618,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 41.9673469388,
        forecast: 45.1552678586,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 20.7415966387,
        forecast: 22.3171684739,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 136.5228451381,
        forecast: 146.8933847552,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:30:00",
        base: 102.0391740696,
        forecast: 109.790267274,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 120.2008163265,
        forecast: 129.3315030367,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 131.7520288115,
        forecast: 141.7601679845,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 4.6571428571,
        forecast: 5.0109084445,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 89.7254189676,
        forecast: 96.5411354957,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 60.3469387755,
        forecast: 64.9310090731,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 148.9742857143,
        forecast: 160.2906608629,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 0.8489795918,
        forecast: 0.9134697252,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 94.2719063625,
        forecast: 101.4329828749,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 18.7211884754,
        forecast: 20.1432861951,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T18:45:00",
        base: 0.006122449,
        forecast: 0.0065875221,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 0.7673469388,
        forecast: 0.8256360978,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 84.510122449,
        forecast: 90.929674957,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 77.6942328932,
        forecast: 83.5960372353,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 17.2142496999,
        forecast: 18.5218774329,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 127.937334934,
        forecast: 137.655702575,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 0.0183673469,
        forecast: 0.0197625662,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 186.3579591837,
        forecast: 200.5140705417,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 99.0755102041,
        forecast: 106.6014777637,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 83.1102040816,
        forecast: 89.4234160803,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T19:00:00",
        base: 5.3775510204,
        forecast: 5.7860402066,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 15.1791476591,
        forecast: 16.3321851012,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 72.8446938776,
        forecast: 78.3781178477,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 105.3869267707,
        forecast: 113.3923217513,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 65.6235510204,
        forecast: 70.6084429994,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 238.8379591837,
        forecast: 256.98055294,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 111.0624489796,
        forecast: 119.4989676147,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 0.0551020408,
        forecast: 0.0592876985,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 4.6959183673,
        forecast: 5.0526294176,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 86.2346938776,
        forecast: 92.78524817,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:15:00",
        base: 0.9102040816,
        forecast: 0.9793449458,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 0.0551020408,
        forecast: 0.0592876985,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 76.4816326531,
        forecast: 82.2913255335,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 54.258,
        forecast: 58.3795427204,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 0.9102040816,
        forecast: 0.9793449458,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 3.5612244898,
        forecast: 3.8317419964,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 64.8452653061,
        forecast: 69.7710371955,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 85.746482593,
        forecast: 92.2599514111,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 12.6703721489,
        forecast: 13.6328381464,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 155.6269387755,
        forecast: 167.4486623298,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:30:00",
        base: 312.4816326531,
        forecast: 336.2183424161,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 63.3224489796,
        forecast: 68.1325447927,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 67.5801560624,
        forecast: 72.7136755483,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 56.6417142857,
        forecast: 60.944328558,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 0.4408163265,
        forecast: 0.4743015881,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 45.6655510204,
        forecast: 49.1343946818,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 3.3897959184,
        forecast: 3.6472913788,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 12.0254741897,
        forecast: 12.9389524898,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 191.0628571429,
        forecast: 205.5763616583,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 0.0551020408,
        forecast: 0.0592876985,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 353.5306122449,
        forecast: 380.3854819662,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T19:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 48.5832653061,
        forecast: 52.2737441932,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 9.9093877551,
        forecast: 10.662124033,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 0.1285714286,
        forecast: 0.1383379632,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 218.5151020408,
        forecast: 235.1139322247,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 388.2979591837,
        forecast: 417.7938238861,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 2.7816326531,
        forecast: 2.9929308545,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 57.9816326531,
        forecast: 62.3860297185,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 52.7023265306,
        forecast: 56.7056972826,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 0.5510204082,
        forecast: 0.5928769851,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 36.7734285714,
        forecast: 39.566809397,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T20:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 42.9053877551,
        forecast: 46.1645640714,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 41.996244898,
        forecast: 45.1863609628,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 7.3746938776,
        forecast: 7.9348899015,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 0.1224489796,
        forecast: 0.1317504411,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 234.0534693878,
        forecast: 251.8326240367,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 410.8436734694,
        forecast: 442.0521542762,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 0.5142857143,
        forecast: 0.5533518528,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 2.3816326531,
        forecast: 2.5625460801,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 30.2605306122,
        forecast: 32.5591791002,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:15:00",
        base: 45.0224489796,
        forecast: 48.442441365,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 2.0306122449,
        forecast: 2.1848614822,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 37.5826938776,
        forecast: 40.4375480625,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 0.5142857143,
        forecast: 0.5533518528,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 6.2195918367,
        forecast: 6.6920440734,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 0.1224489796,
        forecast: 0.1317504411,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 252.1667346939,
        forecast: 271.3218080417,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 33.1284489796,
        forecast: 35.6449500989,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 26.0610612245,
        forecast: 28.0407098878,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 435.2310204082,
        forecast: 468.2920113009,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:30:00",
        base: 36.6204081633,
        forecast: 39.4021652624,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 4.9510204082,
        forecast: 5.3271095033,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 29.2347755102,
        forecast: 31.4555056548,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 38.0992653061,
        forecast: 40.9933592568,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 0.5142857143,
        forecast: 0.5533518528,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 22.5351428571,
        forecast: 24.2469559353,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 0.1285714286,
        forecast: 0.1383379632,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 1.093877551,
        forecast: 1.1769706075,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 29.9142857143,
        forecast: 32.1866327695,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 278.8553061224,
        forecast: 300.0376950236,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T20:45:00",
        base: 475.326122449,
        forecast: 511.4328149146,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 0.0918367347,
        forecast: 0.0988128309,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 2.9591836735,
        forecast: 3.1839689941,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 268.7246938776,
        forecast: 289.1375418602,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 0.3714285714,
        forecast: 0.3996430048,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 0.9142857143,
        forecast: 0.9837366271,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 54.6031428571,
        forecast: 58.7509032972,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 61.9148979592,
        forecast: 66.6180734719,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 22.0306122449,
        forecast: 23.704100201,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 18.7355510204,
        forecast: 20.1587397469,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:00:00",
        base: 453.6706122449,
        forecast: 488.1323102316,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 0.3714285714,
        forecast: 0.3996430048,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 0.3959183673,
        forecast: 0.425993093,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 378.467755102,
        forecast: 407.2168984717,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 12.9326530612,
        forecast: 13.9150424246,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 13.9199591837,
        forecast: 14.9773462315,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 227.8091836735,
        forecast: 245.114010291,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 1.8530612245,
        forecast: 1.9938233425,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 53.7329387755,
        forecast: 57.8145968288,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 0.0918367347,
        forecast: 0.0988128309,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:15:00",
        base: 48.6574285714,
        forecast: 52.3535410437,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 182.4953061224,
        forecast: 196.3580028761,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 0.1102040816,
        forecast: 0.118575397,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 312.5608163265,
        forecast: 336.3035410347,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 0.5918367347,
        forecast: 0.6367937988,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 5.512244898,
        forecast: 5.9309656918,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 0.0918367347,
        forecast: 0.0988128309,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 56.0757142857,
        forecast: 60.3353341023,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 8.3364489796,
        forecast: 8.969701783,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:30:00",
        base: 43.8094693878,
        forecast: 47.1373214951,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:45:00",
        base: 51.7436326531,
        forecast: 55.6741791621,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T21:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:45:00",
        base: 145.1720408163,
        forecast: 156.1995900816,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:45:00",
        base: 252.4710204082,
        forecast: 271.6492078879,
    },
    {
        mapped_area: "Bottleshop",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:45:00",
        base: 0.4530612245,
        forecast: 0.4874766322,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T21:45:00",
        base: 6.1768571429,
        forecast: 6.6460631695,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:45:00",
        base: 40.5336734694,
        forecast: 43.612689777,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T21:45:00",
        base: 0.0183673469,
        forecast: 0.0197625662,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T21:45:00",
        base: 0.2326530612,
        forecast: 0.2503258382,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:00:00",
        base: 49.7934693878,
        forecast: 53.5758777198,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:00:00",
        base: 114.4520408163,
        forecast: 123.1460394094,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:00:00",
        base: 4.5093877551,
        forecast: 4.8519295789,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T22:00:00",
        base: 40.4765306122,
        forecast: 43.5512062378,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T22:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T22:00:00",
        base: 0.0183673469,
        forecast: 0.0197625662,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T22:00:00",
        base: 192.9946938776,
        forecast: 207.6549444513,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T22:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:15:00",
        base: 46.982244898,
        forecast: 50.5511071754,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Miscellaneous",
        relative_timestamp: "2022-07-03T22:15:00",
        base: 0.0183673469,
        forecast: 0.0197625662,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T22:15:00",
        base: 125.9889795918,
        forecast: 135.5593463891,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:15:00",
        base: 77.9591836735,
        forecast: 83.8811141899,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:15:00",
        base: 1.2751020408,
        forecast: 1.3719612604,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T22:15:00",
        base: 39.787755102,
        forecast: 42.8101100064,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T22:30:00",
        base: 33.4316326531,
        forecast: 35.9711641911,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:30:00",
        base: 28.4257142857,
        forecast: 30.5849865734,
    },
    {
        mapped_area: "Front Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:30:00",
        base: 0.412244898,
        forecast: 0.4435598185,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:30:00",
        base: 36.3908163265,
        forecast: 39.1551331852,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T22:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T22:30:00",
        base: 47.1959183673,
        forecast: 50.7810116952,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:45:00",
        base: 9.2489795918,
        forecast: 9.9515499871,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T22:45:00",
        base: 7.2653061224,
        forecast: 7.8171928407,
    },
    {
        mapped_area: "Bistro Moncur",
        mapped_class: "Food",
        relative_timestamp: "2022-07-03T22:45:00",
        base: 11.612244898,
        forecast: 12.4943335011,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T22:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "Upstairs Bar",
        mapped_class: "Beverage",
        relative_timestamp: "2022-07-03T23:00:00",
        base: 7.2653061224,
        forecast: 7.8171928407,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T23:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T23:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T23:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-03T23:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T00:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T00:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T00:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T00:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T01:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T01:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T01:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T01:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T02:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T02:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T02:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T02:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T03:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T03:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T03:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T03:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T04:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T04:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T04:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T04:45:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T05:00:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T05:15:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T05:30:00",
        base: 0,
        forecast: 0,
    },
    {
        mapped_area: "",
        mapped_class: "",
        relative_timestamp: "2022-07-04T05:45:00",
        base: 0,
        forecast: 0,
    },
];
