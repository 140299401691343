import { SettingOutlined } from "@ant-design/icons";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import "./userAvatarDropdown.scss";
import React, { Dispatch, ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "../Router/Routes";
import { Context, State } from "../../State/store";
import { Action, LogOutAction } from "../../State/actions";
import { Link } from "react-router-dom";
import { clearCubeJsToken } from "Api/backend";
import { queryClient } from "index";
import { useResetFilter } from "Store/filterStore";

export default function UserAvatarDropdown(): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = React.useContext(Context);
    const resetFilter = useResetFilter();
    const [currentUserEmail, setCurrentUserEmail] = useState<null | string>(null);
    const [isAdminUser, setIsAdminUser] = useState(false);
    const router = useHistory();

    const logout = async () => {
        // Clears any token in storage that are related to the current user's session.
        clearCubeJsToken();
        localStorage.clear();
        const action: LogOutAction = {
            type: "LOG_OUT_CLEAR_STATE",
        };
        dispatch(action);
        queryClient.removeQueries();
        queryClient.clear();
        resetFilter();
        router.push(RoutePaths.LOGIN);
    };

    useEffect(() => {
        if (state.groupData?.user) {
            if (state.groupData.user.email)
                setCurrentUserEmail(state.groupData.user.email);

            if (state.groupData.user.is_admin === true) setIsAdminUser(true);
        }
    }, [state.groupData?.user]);

    const userMenu = (
        <Menu>
            {currentUserEmail && (
                <Menu.Item key="0">
                    <a rel="noreferrer">{currentUserEmail}</a>
                </Menu.Item>
            )}
            {currentUserEmail && <Menu.Divider />}
            {isAdminUser && (
                <Menu.Item key="1">
                    <Link to={RoutePaths.USERS} rel="noreferrer">
                        User Management
                    </Link>
                </Menu.Item>
            )}
            {/* <Menu.Item key="2">
                <Link to={RoutePaths.PROFILE} rel="noreferrer">
                    Change Password
                </Link>
            </Menu.Item> */}
            <Menu.Item key="3">
                <a rel="noreferrer" onClick={logout}>
                    Log Out
                </a>
            </Menu.Item>
        </Menu>
    );
    return (
        <Dropdown.Button
            className="dropdown-btn"
            overlay={userMenu}
            buttonsRender={(buttons) => [null, buttons[1]]}
            icon={
                <SettingOutlined
                    style={{
                        fontSize: "28px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                    }}
                />
            }
        />
    );
}
