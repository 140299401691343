import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ENV } from "./Utils/constants";
import { QueryClient, QueryClientProvider } from "react-query";
import { SECONDS_IN_MINUTE } from "Utils/date-utils";
import "./Utils/string-utils";
import "react-dates/initialize";
import "./styles/reactDates/datepicker.css";

Sentry.init({
    dsn: "https://7766a931612b47e59420cbe50bfa3f47@o934474.ingest.sentry.io/5890255",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: ENV,
});

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: SECONDS_IN_MINUTE * 15 * 1000,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
