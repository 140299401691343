/**
 *  TODO: Tech Debt
 *  Consolidate the methods both here and in backend.tsx together.
 *  Also define a standard for what the methods should return. (e.g. Request object or the Data only)
 *
 */

import { View } from "../../Components/ViewSelect";
import { GroupData, User } from "../backend";

import { BACKEND_API_URL } from "../../Utils/constants";
import { Auth0Result } from "auth0-js";
import { getAuthTokens, getRefreshToken } from "Api/auth0";
import { BASE_URL_AUTH } from "./types";
import { setSession } from "./auth";
import { ExtendedUser } from "../../@types/UserTable";

export interface UserWithView extends User {
    views: View[];
}

/**
 * Fetches the users from "/v2/group/data" endpoint.
 *
 * Appends "views.views" object onto each returned user.
 *
 * @returns {UserWithView[]}
 */
export const fetchUsers = async (): Promise<UserWithView[]> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    if (access_token === null) {
        throw new Error("No token");
        // return [];
    } else {
        // Make API request for users
        return fetch(`${BACKEND_API_URL}/v2/group/data`, {
            headers: { authorization: `bearer ${access_token}` },
        })
            .then(async (response) => {
                if (!response.ok) return [];

                // handle success
                const groupData: GroupData = await response.json();
                const users: UserWithView[] = [];
                groupData.users.forEach(function (user) {
                    const TEMP_USER_OBJ = user;
                    TEMP_USER_OBJ.views = groupData.views.views;
                    users.push(TEMP_USER_OBJ);
                });
                return users;
            })
            .catch(() =>
                // handle error
                []
            );
    }
};

/**
 * Returns false on failure events.
 * Returns true on successful user deletion.
 *
 * @param id
 * @returns
 */
export const deleteUser = async (id: string): Promise<boolean> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    // Make a request to delete user
    if (access_token === null) {
        throw new Error("no Token");
    } else {
        return await fetch(`${BACKEND_API_URL}/group/users/${id}`, {
            headers: {
                authorization: `bearer ${access_token!}`,
            },
            method: "DELETE",
        })
            .then((response) => response.ok)
            .catch(
                () =>
                    // handle error
                    false
            );
    }
};

/**
 * Create new user.
 *
 * Returns True on successful creation.
 * Returns False in failure cases.
 */
export const createUser = async ({
    first_name,
    last_name,
    username,
    is_admin,
    email,
    defaultViewId,
}: Partial<ExtendedUser>): Promise<boolean> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    if (!access_token) return false;

    // const canInvite = is_admin ? true : false;

    // TODO allow send_email to be defined from the frontend (From wranglr-portal)

    const requestData = {
        first_name,
        last_name,
        username,
        email,
        is_admin,
        settings: defaultViewId ? { defaultViewId: defaultViewId } : {},
    };
    // Make a request to create user
    return await fetch(`${BACKEND_API_URL}/group/users`, {
        body: JSON.stringify(requestData),
        method: "POST",
        headers: { authorization: `bearer ${access_token!}` },
    })
        .then((response) => response.ok)
        .catch(
            () =>
                // handle error
                false
        );
};

export const modifyUser = async ({
    first_name,
    last_name,
    user_id,
    is_admin,
    is_active,
    defaultViewId,
}: Partial<ExtendedUser>): Promise<boolean> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    if (!access_token) return false;

    const requestData = {
        first_name,
        last_name,
        is_active,
        is_admin,
        settings: defaultViewId ? { defaultViewId: defaultViewId } : {},
    };

    // modifyUser via API
    return await fetch(`${BACKEND_API_URL}/group/users/${user_id}`, {
        body: JSON.stringify(requestData),
        method: "PUT",
        headers: { authorization: `bearer ${access_token!}` },
    })
        .then((response) => response.ok)
        .catch(
            () =>
                // handle error
                false
        );
};

export const refreshAuthToken = async (): Promise<Auth0Result | null> => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        return null;
    }
    const requestData = {
        client_id: process.env.REACT_APP_AUTH0_CLIENTID,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
    };

    return await fetch(`${BASE_URL_AUTH}/oauth/token`, {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: { "content-type": "application/json" },
    })
        .then((response) => response.json())
        .then((response) => {
            if (response) {
                setSession(response);
            }
            return response;
        })
        .catch((error) => error);
};

export const sendPasswordResetEmail = async (email: string): Promise<void> => {
    const requestData = {
        client_id: process.env.REACT_APP_APPLICATION_AUTH0_CLIENTID,
        email: email,
        connection: process.env.REACT_APP_AUTH0_REALM,
    };
    return await fetch(`${BASE_URL_AUTH}/dbconnections/change_password`, {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: { "content-type": "application/json" },
    })
        .then((response) => response.json())
        .catch((error) => error);
};
