declare global {
    interface StringConstructor {
        capitalizeFirstLetterOfEveryWordInASentence(value: string): string;
    }
}

String.capitalizeFirstLetterOfEveryWordInASentence = (sentence: string): string =>
    sentence
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

export {};
