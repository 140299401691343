import { Context, State } from "../../State/store";
import React, { Dispatch, ReactElement, SetStateAction, useCallback } from "react";
import CheckboxWithDrawers from "./index";
import { Action, AddVenuesToFilterAction } from "../../State/actions";
import { drawerType } from "../SideNavigation/CheckboxManager";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { usePreviewMode } from "Store/viewSettingsStore";
import { useVenuesFilter } from "Store/filterStore";
import { chain } from "lodash";

interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}

export default function VenuesCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = React.useContext(Context);
    const { data: mappingRule } = useListMappingRules();
    const { previewMode } = usePreviewMode();
    const { selectedVenues: selectedVenueIds, updateVenuesFilter } =
        useVenuesFilter();

    const venueNameById = chain(mappingRule)
        .keyBy("id")
        .mapValues("segmentName")
        .value();

    const venueIdByName = chain(mappingRule)
        .keyBy("segmentName")
        .mapValues("id")
        .value();

    const venues = previewMode
        ? mappingRule
              ?.filter(({ segment, exclude }) => segment === "venue" && !exclude)
              .map(({ segmentName }) => segmentName) ?? []
        : state.groupData!.segments.venues.map((venue) => venue.primary_id);

    const selectedVenues =
        selectedVenueIds?.map((venueId) => venueNameById[venueId] ?? venueId) ?? [];

    // const selectedVenues = previewMode
    //     ? selectedVenueIds?.map((venueId) => venueNameById[venueId]) ?? []
    //     : state.filters.selectedVenues.map((venue) => venue.primary_id);

    const dispatchAction = useCallback(
        (selectedVenues: string[]) => {
            const action: AddVenuesToFilterAction = {
                type: "ADD_VENUES_TO_FILTER",
                payload: {
                    selectedVenues: selectedVenues.map((venue) => ({
                        primary_id: venue,
                    })),
                },
            };
            if (mappingRule) {
                updateVenuesFilter(
                    mappingRule
                        .filter(
                            ({ segmentName, segment }) =>
                                segment === "venue" &&
                                selectedVenues.includes(segmentName)
                        )
                        .map(({ id }) => id)
                );
            }
            dispatch(action);
        },
        [dispatch, mappingRule, updateVenuesFilter]
    );

    // TODO Test this as this seems like a pointless addition
    // useEffect(() => {
    //     dispatchAction([]);
    // }, [previewMode]);

    return (
        <>
            <CheckboxWithDrawers
                options={venues.sort()}
                selectedOptions={selectedVenues}
                dispatchAction={dispatchAction}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Venues"}
                title={"Venues"}
            />
        </>
    );
}
