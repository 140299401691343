import { Auth, AuthState } from "Api/auth/auth";
import { useEffect, useMemo, useState } from "react";

const useAuth = () => {
    const auth = Auth.getInstance();
    const [authState, setAuthState] = useState(auth.getAuthState());

    useEffect(() => {
        const handleAuthChange = (state: AuthState) => {
            setAuthState(state);
        };

        auth.subscribe(handleAuthChange);

        return () => {
            auth.unsubscribe(handleAuthChange);
        };
    }, [auth]);

    return useMemo(() => {
        return {
            isLoggedIn: authState === "authenticated",
            isExpired: authState === "expired",
            auth,
        };
    }, [authState, auth]);
};

export { useAuth };
