import { Context, State } from "../../State/store";
import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
} from "react";
import CheckboxWithDrawers from "./index";
import { drawerType } from "../SideNavigation/CheckboxManager";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { useAreasFilter } from "Store/filterStore";
import { chain } from "lodash";
interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}
export default function AreasCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const { data: mappingRule } = useListMappingRules();
    const { selectedAreas: selectedAreaIds, updateAreasFilter } = useAreasFilter();

    const areaNameById = chain(mappingRule)
        .keyBy("id")
        .mapValues("segmentName")
        .value();

    const areas =
        mappingRule
            ?.filter(({ segment, exclude }) => segment === "area" && !exclude)
            .map(({ segmentName }) => segmentName) ?? [];

    const selectedAreas =
        selectedAreaIds?.map((areaId) => areaNameById[areaId] ?? areaId) ?? [];

    const dispatchAction = useCallback(
        (selectedAreas: string[]): void => {
            if (mappingRule) {
                updateAreasFilter(
                    mappingRule
                        .filter(
                            ({ segmentName, segment }) =>
                                segment === "area" &&
                                selectedAreas.includes(segmentName)
                        )
                        .map(({ id }) => id)
                );
            }
        },
        [mappingRule, updateAreasFilter]
    );

    useEffect(() => {
        dispatchAction([]);
    }, []);

    return (
        <>
            <CheckboxWithDrawers
                options={areas.sort()}
                dispatchAction={dispatchAction}
                selectedOptions={selectedAreas}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Areas"}
                title={"Areas"}
            />
        </>
    );
}
