import { DateRange, DEFAULT_DATE_FORMAT, parseToMoment } from "Utils/date-utils";
import create from "zustand";

export enum ComparisonFilter {
    PreviousPeriod = "Previous Period",
    SamePeriodLastYear = "Same Period Last Year",
    SameDayLastWeek = "Same Day Last Week",
    Forecast = "Forecast",
}

export enum AggregateFilter {
    AverageDay = "Average Day",
    AverageWeek = "Average Week",
}

interface FilterStoreState {
    selectedDateRange: DateRange<string>;
    selectedComparison?: ComparisonFilter;
    selectedAggregate?: AggregateFilter;
    selectedHours?: number[];
    selectedDays?: string[];
    selectedVenues?: string[];
    selectedAreas?: string[];
    selectedClasses?: string[];
    selectedViewId?: number;
    isStaffHrZero?: boolean;
    isSalesZero?: boolean;
}

interface FilterStoreAction {
    initializeFilter: (filter: FilterStoreState) => void;
    updateFilter: (filter: Partial<FilterStoreState>) => void;
    resetStore: () => void;
}

export type FilterStore = FilterStoreState & FilterStoreAction;

export const useFilterStore = create<FilterStore>((set) => {
    const today = parseToMoment().format(DEFAULT_DATE_FORMAT);
    const defaultSelectedDateRange = {
        start: today,
        end: today,
    };
    return {
        selectedDateRange: defaultSelectedDateRange,
        selectedComparison: ComparisonFilter.SameDayLastWeek,
        initializeFilter: (filter) => set({ ...filter }),
        updateFilter: (filter) => set((state) => ({ ...state, ...filter })),
        resetStore: () =>
            set({
                selectedDateRange: { ...defaultSelectedDateRange },
                selectedComparison: ComparisonFilter.SameDayLastWeek,
                selectedAggregate: undefined,
                selectedAreas: undefined,
                selectedHours: undefined,
                selectedDays: undefined,
                selectedVenues: undefined,
                selectedClasses: undefined,
                selectedViewId: undefined,
            }),
    };
});

export const useInitializeFilter = () =>
    useFilterStore(({ initializeFilter }) => initializeFilter);

export const useUpdateFilter = () =>
    useFilterStore(({ updateFilter }) => updateFilter);

export const useResetFilter = () => useFilterStore(({ resetStore }) => resetStore);

export const useDateRangeFilter = () => {
    const selectedDateRange = useFilterStore(
        ({ selectedDateRange }) => selectedDateRange
    );
    const updateFilter = useUpdateFilter();

    const updateDateRangeFilter = (selectedDateRange: DateRange<string>) => {
        updateFilter({ selectedDateRange });
    };

    return { selectedDateRange, updateDateRangeFilter };
};

export const useSelectedViewId = () => {
    const selectedViewId = useFilterStore(({ selectedViewId }) => selectedViewId);
    const updateFilter = useUpdateFilter();

    const updateSelectedViewId = (selectedViewId?: number) => {
        updateFilter({ selectedViewId });
    };

    return { selectedViewId, updateSelectedViewId };
};

export const useAggregateFilter = () => {
    const selectedAggregate = useFilterStore(
        ({ selectedAggregate }) => selectedAggregate
    );
    const updateFilter = useUpdateFilter();

    const updateAggregateFilter = (selectedAggregate?: AggregateFilter) => {
        updateFilter({ selectedAggregate });
    };

    return { selectedAggregate, updateAggregateFilter };
};

export const useComparisonFilter = () => {
    const selectedComparison = useFilterStore(
        ({ selectedComparison }) => selectedComparison
    );
    const updateFilter = useUpdateFilter();

    const updateComparisonFilter = (selectedComparison?: ComparisonFilter) => {
        updateFilter({ selectedComparison });
    };

    return { selectedComparison, updateComparisonFilter };
};

export const useHoursFilter = () => {
    const selectedHours = useFilterStore(({ selectedHours }) => selectedHours);
    const updateFilter = useUpdateFilter();

    const updateHoursFilter = (selectedHours: number[]) => {
        updateFilter({ selectedHours });
    };
    return { selectedHours, updateHoursFilter };
};

export const useDaysFilter = () => {
    const selectedDays = useFilterStore(({ selectedDays }) => selectedDays);
    const updateFilter = useUpdateFilter();

    const updateDaysFilter = (selectedDays: string[]) => {
        updateFilter({ selectedDays });
    };

    return { selectedDays, updateDaysFilter };
};

export const useVenuesFilter = () => {
    const selectedVenues = useFilterStore(({ selectedVenues }) => selectedVenues);
    const updateFilter = useUpdateFilter();

    const updateVenuesFilter = (selectedVenues: string[]) => {
        updateFilter({ selectedVenues });
    };

    return { selectedVenues, updateVenuesFilter };
};

export const useAreasFilter = () => {
    const selectedAreas = useFilterStore(({ selectedAreas }) => selectedAreas);
    const updateFilter = useUpdateFilter();

    const updateAreasFilter = (selectedAreas: string[]) => {
        updateFilter({ selectedAreas });
    };

    return { selectedAreas, updateAreasFilter };
};

export const useClassesFilter = () => {
    const selectedClasses = useFilterStore(({ selectedClasses }) => selectedClasses);
    const updateFilter = useUpdateFilter();

    const updateClassesFilter = (selectedClasses: string[]) => {
        updateFilter({ selectedClasses });
    };

    return { selectedClasses, updateClassesFilter };
};

export const useSegmentsFilter = () => ({
    ...useClassesFilter(),
    ...useVenuesFilter(),
    ...useAreasFilter(),
});
