import React, { ReactElement } from "react";
import { Col, Row } from "antd";
import { RoundedCard } from "./index";
import { SegmentTable } from "../SegmentTable/SegmentTable";
import { usePreviewMode } from "Store/viewSettingsStore";
import { SegmentTable as PreviewSegmentTable } from "Pages/Dashboard/Components/SegmentTable/SegmentTable";

interface Props {
    children: ReactElement;
}

const ReducedPaddingCard = (props: Props) => (
    <RoundedCard
        {...props}
        style={{ minHeight: "380px" }}
        bodyStyle={{ padding: "10px" }}
    >
        {props.children}
    </RoundedCard>
);

export default function TableWidget(): ReactElement {
    const { previewMode } = usePreviewMode();
    return (
        <Row gutter={16}>
            <Col span={8}>
                <ReducedPaddingCard>
                    {previewMode ? (
                        <PreviewSegmentTable segment="Venue" />
                    ) : (
                        <SegmentTable segment="Venue" />
                    )}
                </ReducedPaddingCard>
            </Col>
            <Col span={8}>
                <ReducedPaddingCard>
                    {previewMode ? (
                        <PreviewSegmentTable segment="Area" />
                    ) : (
                        <SegmentTable segment="Area" />
                    )}
                </ReducedPaddingCard>
            </Col>
            <Col span={8}>
                <ReducedPaddingCard>
                    {previewMode ? (
                        <PreviewSegmentTable segment="Class" />
                    ) : (
                        <SegmentTable segment="Class" />
                    )}
                </ReducedPaddingCard>
            </Col>
        </Row>
    );
}
