import { DEFAULT_DATE_FORMAT, parseToMoment } from "Utils/date-utils";
import { Maybe } from "types";
import { useDateRangeFilter } from "Store/filterStore";
import { CubeQueryBuilder } from "Utils/cubejs-utils";
import { usePreviewMode } from "Store/viewSettingsStore";
import { useCubeQuery } from "@cubejs-client/react";
import { useMemo } from "react";

export const useGetLastCutOffTime = (): {
    cutOffTime: Maybe<{
        hours: number;
        minutes: number;
    }>;
    isLoading: boolean;
} => {
    const { previewMode } = usePreviewMode();

    const { selectedDateRange } = useDateRangeFilter();
    const transactionTotalKey = previewMode
        ? "actualsUnmappedNoPreAgg.transactionTotal"
        : "actualsNoPreAgg.transactionTotal";
    const periodKey = previewMode
        ? "actualsUnmappedNoPreAgg.period"
        : "actualsNoPreAgg.period";

    const today = parseToMoment();
    const todaySelected = parseToMoment(selectedDateRange.end).isSame(
        parseToMoment(),
        "date"
    );

    const query = CubeQueryBuilder()
        .addMeasures([transactionTotalKey])
        .addTimeDimensions([
            {
                dimension: periodKey,
                granularity: "minute",
                dateRange: [
                    today.format(DEFAULT_DATE_FORMAT),
                    today.clone().add(1, "day").format(DEFAULT_DATE_FORMAT),
                ],
            },
        ])
        .addOrder({
            [periodKey]: "desc",
        })
        .addLimit(1)
        .getResult();

    const { resultSet, isLoading, error, progress, refetch } = useCubeQuery<any>(
        query,
        { skip: !todaySelected }
    );

    const result = useMemo(() => {
        const rawData = resultSet?.rawData();
        if (!rawData) return parseToMoment().subtract(30, "minutes");
        if (!rawData[0]) return parseToMoment().subtract(30, "minutes");
        return parseToMoment(resultSet?.rawData()[0][periodKey]);
    }, [resultSet]);

    // const result = useQuery(
    //     ["last-cut-off-time", query],
    //     () => cubejsApi.load(query),
    //     {
    //         enabled: todaySelected,
    //         select: (result) => {
    //             const rawData = result.rawData();
    //             if (!rawData[0]) return parseToMoment().subtract(30, "minutes");
    //             return parseToMoment(result.rawData()[0][periodKey]);
    //         },
    //         staleTime: 1 * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND,
    //         refetchOnWindowFocus: true,
    //         refetchInterval: 1 * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND,
    //         refetchIntervalInBackground: true,
    //     }
    // );

    return {
        isLoading: isLoading,
        cutOffTime:
            result && todaySelected
                ? {
                      hours: result.get("hours"),
                      minutes: result.get("minutes"),
                  }
                : undefined,
    };
};
