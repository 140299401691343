import { Col, Row } from "antd";
import DayCheckbox from "../CheckboxWithDrawers/DayCheckbox";
import VenuesCheckbox from "../CheckboxWithDrawers/VenuesCheckbox";
import AreasCheckbox from "../CheckboxWithDrawers/AreasCheckbox";
import ClassesCheckbox from "../CheckboxWithDrawers/ClassCheckbox";
import React, { ReactElement, useState } from "react";
import { ReducedMarginDivider, SideNavFilterTitle } from "./SideNavFilters";

export type drawerType = "Day" | "Venues" | "Areas" | "Classes" | undefined;

export default function CheckboxManager(): ReactElement {
    const [openDrawerState, setOpenDrawerState] = useState<drawerType>(undefined);

    return (
        <>
            <Row>
                <Col span={24}>
                    <SideNavFilterTitle level={5}>Days</SideNavFilterTitle>
                    <DayCheckbox
                        openDrawerState={openDrawerState}
                        setOpenDrawerState={setOpenDrawerState}
                    />
                </Col>
            </Row>
            <ReducedMarginDivider />
            <Row>
                <Col span={24}>
                    <SideNavFilterTitle level={5}>Venues</SideNavFilterTitle>
                    <VenuesCheckbox
                        openDrawerState={openDrawerState}
                        setOpenDrawerState={setOpenDrawerState}
                    />
                </Col>
            </Row>
            <ReducedMarginDivider />
            <Row>
                <Col span={24}>
                    <SideNavFilterTitle level={5}>Areas</SideNavFilterTitle>
                    <AreasCheckbox
                        openDrawerState={openDrawerState}
                        setOpenDrawerState={setOpenDrawerState}
                    />
                </Col>
            </Row>
            <ReducedMarginDivider />
            <Row>
                <Col span={24}>
                    <SideNavFilterTitle level={5}>Classes</SideNavFilterTitle>
                    <ClassesCheckbox
                        openDrawerState={openDrawerState}
                        setOpenDrawerState={setOpenDrawerState}
                    />
                </Col>
            </Row>
        </>
    );
}
