import React from "react";
import { Collapse } from "antd";
import "./tabulatedGraphs.scss";
import { SegmentTable } from "Components/SegmentTable/SegmentTable";
import { SegmentTable as PreviewSegmentTable } from "Pages/Dashboard/Components/SegmentTable/SegmentTable";
import { usePreviewMode } from "Store/viewSettingsStore";

export const TabulatedTables = () => {
    const { previewMode } = usePreviewMode();
    return (
        <div className={"tabulated-tables"}>
            <Collapse defaultActiveKey={["1"]} ghost={true}>
                <Collapse.Panel header="Venues" key="1">
                    {previewMode ? (
                        <PreviewSegmentTable omitTitle={true} segment={"Venue"} />
                    ) : (
                        <SegmentTable omitTitle={true} segment={"Venue"} />
                    )}
                </Collapse.Panel>
                <Collapse.Panel header="Areas" key="2">
                    {previewMode ? (
                        <PreviewSegmentTable omitTitle={true} segment={"Area"} />
                    ) : (
                        <SegmentTable omitTitle={true} segment={"Area"} />
                    )}
                </Collapse.Panel>
                <Collapse.Panel header="Classes" key="3">
                    {previewMode ? (
                        <PreviewSegmentTable omitTitle={true} segment={"Class"} />
                    ) : (
                        <SegmentTable omitTitle={true} segment={"Class"} />
                    )}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};
