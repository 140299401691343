import React, { useMemo } from "react";
import { baseVsForecast } from "../../mock-data";
import { chain } from "lodash";
import { toQTCDate } from "Utils/date-utils";

interface ForecastPreviewFilter {
    selectedAreas: string[];
    selectedClasses: string[];
    selectedDays: string[];
}

export const useForecastingSlides = ({
    filters,
}: {
    filters: ForecastPreviewFilter;
}) => {
    const dataGroupedByWeekDay = useMemo(
        () =>
            chain(
                baseVsForecast.filter(
                    ({ mapped_area, mapped_class, relative_timestamp }) => {
                        return (
                            (filters.selectedDays.length === 0 ||
                                filters.selectedDays.includes(
                                    toQTCDate(relative_timestamp).format("dddd")
                                )) &&
                            (filters.selectedClasses.length === 0 ||
                                filters.selectedClasses.includes(mapped_class)) &&
                            (filters.selectedAreas.length === 0 ||
                                filters.selectedAreas.includes(mapped_area))
                        );
                    }
                )
            )
                .groupBy(({ relative_timestamp }) =>
                    toQTCDate(relative_timestamp).format("dddd")
                )
                .value(),
        [filters]
    );

    const dataGroupedByWeekDayWithoutFilters = useMemo(
        () =>
            chain(baseVsForecast)
                .groupBy(({ relative_timestamp }) =>
                    toQTCDate(relative_timestamp).format("dddd")
                )
                .value(),
        [filters]
    );

    const weekData = baseVsForecast.filter(
        ({ mapped_area, mapped_class, relative_timestamp }) => {
            return (
                (filters.selectedDays.length === 0 ||
                    filters.selectedDays.includes(
                        toQTCDate(relative_timestamp).format("dddd")
                    )) &&
                (filters.selectedClasses.length === 0 ||
                    filters.selectedClasses.includes(mapped_class)) &&
                (filters.selectedAreas.length === 0 ||
                    filters.selectedAreas.includes(mapped_area))
            );
        }
    );
    // const dataTotaledByWeekDay = chain(baseVsForecast)
    //     .groupBy(({ relative_timestamp }) =>
    //         toQTCDate(relative_timestamp).format("dddd")
    //     )
    //     .map((data, weekDay) => {
    //         const filteredData = data.filter(({ mapped_area, mapped_class }) => {
    //             return (
    //                 (filters.selectedAreas.length === 0 ||
    //                     filters.selectedAreas.includes(mapped_area)) &&
    //                 (filters.selectedClasses.length === 0 ||
    //                     filters.selectedClasses.includes(mapped_class))
    //             );
    //         });
    //
    //         return {
    //             name: weekDay,
    //             base: sumBy(filteredData, "base"),
    //             forecast: sumBy(filteredData, "forecast"),
    //         };
    //     })
    //     .value();

    return useMemo(() => {
        return {
            dataGroupedByWeekDay,
            dataGroupedByWeekDayWithoutFilters,
            weekData,
        };
    }, [
        dataGroupedByWeekDay,
        dataGroupedByWeekDayWithoutFilters,
        filters,
        weekData,
    ]);

    // return baseVsForecast.filter(
    //     ({
    //         mapped_area,
    //         mapped_class,
    //         relative_timestamp,
    //     }) => {
    //         // TODO FOR MOBILE VIEW SPLIT BASED ON DAYS !!!
    //         // TODO GRANULARITY GROUPING
    //         return (
    //             (filters.selectedDays.length === 0 ||
    //                 filters.selectedDays.includes(
    //                     toQTCDate(relative_timestamp).format(
    //                         "dddd"
    //                     )
    //                 )) &&
    //             (filters.selectedClasses.length === 0 ||
    //                 filters.selectedClasses.includes(
    //                     mapped_class
    //                 )) &&
    //             (filters.selectedAreas.length === 0 ||
    //                 filters.selectedAreas.includes(
    //                     mapped_area
    //                 ))
    //         );
    //     }
    // )
};
