import React from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

interface Props {
    tooltipText: JSX.Element | string;
}

const InfoTooltip: React.FC<Props> = ({ tooltipText, children }) => {
    return (
        <Tooltip title={tooltipText}>
            <span style={{ marginRight: 5 }}>{children}</span>
            <InfoCircleOutlined />
        </Tooltip>
    );
};
export default InfoTooltip;
