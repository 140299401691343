import React, { ReactElement, useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

interface Props {
    options: ApexOptions;
    series: Array<any>;
    height: number;
    type:
        | "line"
        | "area"
        | "bar"
        | "histogram"
        | "pie"
        | "donut"
        | "radialBar"
        | "scatter"
        | "bubble"
        | "heatmap"
        | "treemap"
        | "boxPlot"
        | "candlestick"
        | "radar"
        | "polarArea"
        | "rangeBar"
        | undefined;
}

export default function ApexChartHandler({
    options,
    series,
    height,
    type,
}: Props): ReactElement {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (options !== {} && series.length) {
            setVisible(true);
        }
    }, [options, series]);

    return (
        <>
            {visible && (
                <ReactApexChart
                    options={options}
                    series={series}
                    type={type}
                    width="100%"
                    height={height}
                />
            )}
        </>
    );
}
