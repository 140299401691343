import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import { Divider, Spin, Typography } from "antd";
import { CenteredStatistic } from "Components/HomeDashboardDesktop/components/SharedStyledComponnets";
import { Metric } from "Components/HomeDashboardDesktop/TotalSalesMetric";
import { useGetSalesAndStaffHours } from "Pages/Dashboard/Hooks/useGetSalesAndStaffHours";
import React, { memo, useMemo } from "react";
import { useAggregateFilter } from "Store/filterStore";
import styled from "styled-components";
import {
    DARK_GREEN,
    DARK_RED,
    MOBILE_VIEWPORT_WIDTH,
    WRANGLR_DARK_BLUE,
} from "Utils/constants";
import { calculatePercentageDifference, currencyFormatter } from "Utils/utils";

interface Props {
    level: 1 | 2 | 3 | 4 | 5;
}

export const WranglrDarkBlueTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
`;

let ManHrMetricPreview: React.FC<Props> = ({ level }) => {
    const isMobile = useMemo(
        () => window.innerWidth <= MOBILE_VIEWPORT_WIDTH,
        [window.innerWidth]
    );
    const { selectedAggregate } = useAggregateFilter();
    const {
        isLoading,
        totalSalesAndStaffHours,
        totalSalesAndStaffHoursForComparison,
    } = useGetSalesAndStaffHours();

    const staffEfficiency = totalSalesAndStaffHours
        ? totalSalesAndStaffHours.totalSales /
          totalSalesAndStaffHours.totalStaffHours
        : undefined;

    const staffEfficiencyForComparison = totalSalesAndStaffHoursForComparison
        ? totalSalesAndStaffHoursForComparison.totalSales /
          totalSalesAndStaffHoursForComparison.totalStaffHours
        : undefined;

    const percentageDifference =
        staffEfficiency != undefined &&
        staffEfficiencyForComparison != undefined &&
        !selectedAggregate
            ? calculatePercentageDifference(
                  staffEfficiency,
                  staffEfficiencyForComparison
              )
            : undefined;

    return (
        <Spin spinning={isLoading} size="small">
            <Metric level={level}>
                {staffEfficiency != undefined
                    ? isFinite(staffEfficiency)
                        ? currencyFormatter(Math.ceil(staffEfficiency), 0)
                        : "$0"
                    : "-"}
            </Metric>
            {percentageDifference != undefined && percentageDifference !== 0 && (
                <>
                    <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                    <CenteredStatistic
                        value={percentageDifference ? percentageDifference : 0}
                        precision={0}
                        valueStyle={
                            percentageDifference >= 0
                                ? {
                                      color: DARK_GREEN,
                                      fontSize: isMobile ? "16px" : "22px",
                                  }
                                : {
                                      color: DARK_RED,
                                      fontSize: isMobile ? "16px" : "22px",
                                  }
                        }
                        prefix={
                            percentageDifference >= 0 ? (
                                <CaretUpFilled />
                            ) : (
                                <CaretDownFilled />
                            )
                        }
                        suffix="%"
                    />
                </>
            )}
        </Spin>
    );
};

ManHrMetricPreview = memo(ManHrMetricPreview);

export { ManHrMetricPreview };
