import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { Divider, Spin, Typography } from "antd";
import { CenteredStatistic } from "Components/HomeDashboardDesktop/components/SharedStyledComponnets";
import { useGetSalesAndStaffHours } from "Pages/Dashboard/Hooks/useGetSalesAndStaffHours";
import React, { memo, useMemo } from "react";
import styled from "styled-components";
import {
    DARK_GREEN,
    DARK_RED,
    MOBILE_VIEWPORT_WIDTH,
    QUANTACO_DARK_ORANGE,
} from "Utils/constants";
import {
    calculatePercentageDifference,
    currencyFormatter,
    isNumber,
} from "Utils/utils";

const Metric = styled(Typography.Title)`
    color: ${QUANTACO_DARK_ORANGE} !important;
    text-align: center;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
`;

interface Props {
    level: 1 | 2 | 3 | 4 | 5;
}

let TotalSalesMetricPreview: React.FC<Props> = ({ level }) => {
    const isMobile = useMemo(
        () => window.innerWidth <= MOBILE_VIEWPORT_WIDTH,
        [window.innerWidth]
    );
    const {
        isLoading,
        totalSalesAndStaffHours,
        totalSalesAndStaffHoursForComparison,
        forecastSales,
        averageSalesAndStaffHours,
    } = useGetSalesAndStaffHours();

    const totalSales = averageSalesAndStaffHours
        ? currencyFormatter(averageSalesAndStaffHours.totalSales)
        : totalSalesAndStaffHours
        ? currencyFormatter(totalSalesAndStaffHours.totalSales)
        : "-";

    const percentage =
        totalSalesAndStaffHours &&
        (totalSalesAndStaffHoursForComparison || forecastSales) &&
        !averageSalesAndStaffHours
            ? calculatePercentageDifference(
                  totalSalesAndStaffHours.totalSales,
                  forecastSales ??
                      totalSalesAndStaffHoursForComparison?.totalSales ??
                      0
              )
            : null;

    return (
        <Spin spinning={isLoading} size={"small"}>
            <Metric level={level} type="secondary">
                {totalSales}
            </Metric>
            {percentage ? (
                <>
                    {percentage && isNumber(percentage) && (
                        <>
                            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                            <CenteredStatistic
                                value={percentage}
                                precision={1}
                                valueStyle={
                                    percentage >= 0
                                        ? {
                                              color: DARK_GREEN,
                                              fontSize: isMobile ? "16px" : "22px",
                                          }
                                        : {
                                              color: DARK_RED,
                                              fontSize: isMobile ? "16px" : "22px",
                                          }
                                }
                                prefix={
                                    percentage >= 0 ? (
                                        <CaretUpFilled />
                                    ) : (
                                        <CaretDownFilled />
                                    )
                                }
                                suffix="%"
                            />
                        </>
                    )}
                </>
            ) : null}
        </Spin>
    );
};

TotalSalesMetricPreview = memo(TotalSalesMetricPreview);

export { TotalSalesMetricPreview };
