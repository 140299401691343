import React, { ReactElement } from "react";
import { Image, Affix } from "antd";
import quantacoSmallLogo from "../../quantaco-small-logo.png";
import { animated, config, useSpring } from "react-spring";
import { RoutePaths } from "../Router/Routes";
import { Link } from "react-router-dom";

const AnimatedDiv = animated.div;

export default function ForecastingSideNavigation({
    navigatingFromHome,
}: {
    navigatingFromHome: boolean;
}): ReactElement {
    const styles = useSpring({
        to: { opacity: 1 },
        from: { opacity: navigatingFromHome ? 0 : 1 },
        delay: 100,
        config: config.molasses,
    });

    return (
        <Affix>
            <AnimatedDiv style={styles}>
                <Link to={RoutePaths.HOME}>
                    <Image src={quantacoSmallLogo} width={"100%"} preview={false} />
                </Link>
            </AnimatedDiv>
        </Affix>
    );
}
