import React, { useState } from "react";
import { QTCSpace } from "styles/QTCSpace";
import { ForecastEventAction } from "./ForecastEventAction";
import { ForecastEventState, SalesAffectedEvent } from "../ForecastModel";
import { toQTCDate } from "Utils/date-utils";
import { ForecastEventEditor } from "./ForecastEventEditor";
import { v4 as uuid } from "uuid";
import { ForecastEventTableMobile } from "./ForecastEventTableMobile";
import { ForecastEventEditorMobile } from "./ForecastEventEditorMobile";

export const ForecastEventMobile = () => {
    const [state, setState] = useState<ForecastEventState>(ForecastEventState.IDLE);
    const [editingEvent, setEditingEvent] = useState<SalesAffectedEvent>();

    const [events, setEvents] = useState<SalesAffectedEvent[]>([
        {
            id: uuid(),
            start: toQTCDate("2022-08-18T10:00:00"),
            end: toQTCDate("2022-08-18T15:00:00"),
            name: "Wedding",
            expectedSalesAmount: 10000,
            areaIds: [
                "9baa8bbb-89ab-4cde-b012-3181fd2a0444",
                "88a98aba-89ab-4cde-b012-3181fd2d82b9",
            ],
            classIds: [
                "99a89aab-4567-489a-bcde-f181fd38172f",
                "8999ba9a-cdef-4012-b456-7181fd3b6270",
            ],
            venueId: "bb988889-0123-4456-b89a-b181a29c467f",
        },
    ]);

    const saveEvent = (event: SalesAffectedEvent) => {
        setState(ForecastEventState.SAVING_EVENT);
        if (state === ForecastEventState.CREATING_EVENT) {
            // Save me to the DB
            setEvents((prevEvents) => [
                ...prevEvents,
                {
                    ...event,
                    venueId: "bb988889-0123-4456-b89a-b181a29c467f",
                    id: uuid(),
                },
            ]);
        } else if (state === ForecastEventState.EDITING_EVENT) {
            setEvents((prevEvents) => [
                ...prevEvents.filter(({ id }) => id !== event.id),
                { ...event, venueId: "bb988889-0123-4456-b89a-b181a29c467f" },
            ]);
            // update me in the DB
        }

        setEditingEvent(undefined);
        setState(ForecastEventState.IDLE);
    };

    return (
        <QTCSpace $fluid direction="vertical" size={"middle"}>
            <ForecastEventTableMobile
                onEditEvent={(event) => {
                    setEditingEvent(event);
                    setState(ForecastEventState.EDITING_EVENT);
                }}
                events={events}
            />
            <ForecastEventAction
                onAdd={() => setState(ForecastEventState.CREATING_EVENT)}
                onApply={() => null}
            />

            {[
                ForecastEventState.CREATING_EVENT,
                ForecastEventState.EDITING_EVENT,
                ForecastEventState.SAVING_EVENT,
            ].includes(state) ? (
                <ForecastEventEditorMobile
                    onCloseEditor={() => {
                        setState(ForecastEventState.IDLE);
                        setEditingEvent(undefined);
                    }}
                    onSaveEvent={saveEvent}
                    existingEvent={editingEvent}
                    loading={state === ForecastEventState.SAVING_EVENT}
                />
            ) : null}
        </QTCSpace>
    );
};
