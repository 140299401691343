import React, { Dispatch, ReactElement, useContext, useState } from "react";
import { Context, State } from "../../State/store";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, Modal, Tooltip, Typography } from "antd";
import { deleteView, getViews } from "../../Api/backend";
import {
    Action,
    ReplaceFiltersAction,
    UpdateViewsAction,
} from "../../State/actions";
import { EMPTY_FILTERS } from "./index";
import { useResetFilter, useSelectedViewId } from "Store/filterStore";

export default function DeleteViewButton(): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = useContext(Context);

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { selectedViewId } = useSelectedViewId();
    const resetFilters = useResetFilter();

    const handleOk = () => {
        deleteView(selectedViewId!).then(() => {
            getViews()
                .then((views) => {
                    const action: UpdateViewsAction = {
                        type: "UPDATE_VIEWS",
                        payload: {
                            views: views,
                        },
                    };
                    dispatch(action);
                    const updateSelectedViewIdAction: ReplaceFiltersAction = {
                        type: "REPLACE_FILTERS",
                        payload: {
                            filters: {
                                ...EMPTY_FILTERS,
                                datasetName: state.filters.datasetName,
                                selectedViewId: undefined,
                            },
                        },
                    };
                    resetFilters();
                    dispatch(updateSelectedViewIdAction);
                })
                .then(() => message.success("View sucessfully deleted"));
        });
        setIsModalVisible(false);
    };

    return (
        <>
            <Modal
                title="Confirm"
                visible={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                footer={[
                    <Button
                        key="Cancel"
                        onClick={() => {
                            setIsModalVisible(false);
                        }}
                    >
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Confirm
                    </Button>,
                ]}
            >
                <Typography.Text>
                    Are you sure you want to delete this view?
                </Typography.Text>
                <br />
                <Typography.Text type={"secondary"}>
                    This will also clear applied filters.
                </Typography.Text>
            </Modal>
            <Tooltip
                placement={"bottomRight"}
                title={"Delete currently selected view"}
            >
                <div className={"delete-view-button"}>
                    {" "}
                    <Button
                        disabled={!selectedViewId}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            setIsModalVisible(true);
                        }}
                    />
                </div>
            </Tooltip>
        </>
    );
}
