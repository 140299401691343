import moment, { Moment } from "moment";

export const DEFAULT_GRANULARITY_IN_MINUTES = 15;
export const MINUTES_IN_HOURS = 60;
export const SECONDS_IN_MINUTE = 60;
export const MILLISECONDS_IN_SECOND = 1000;
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const HOUR_MINUTE_FORMAT = "HH:mm";
export const DATE_WITH_HOURS_AND_MINUTES_FORMAT = "YYYY-MM-DDTHH:mm:00";
export const WEEK_AND_YEAR_FORMAT = "wo [Week] (YYYY)";

export const MomentFormat = {
    dateTimeDisplay: "DD/MM/YYYY HH:mm",
    dateOnly: "DD/MM/YY",
    dateMonthYearWithDashSeparated: "DD-MM-YYYY",
};

interface ShiftTimeInput {
    start: Moment;
    end: Moment;
    addDay?: boolean;
}

export type QTCDate = Moment;
export interface QTCRange {
    start: QTCDate;
    end: QTCDate;
}
export type DateRange<T> = {
    start: T;
    end: T;
};
export interface Time {
    hours: number;
    minutes: number;
}

const weekDayMapper = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    0: "Sunday",
};

type DateFormat =
    | "HH:mm"
    | "YYYY-MM-DD"
    | "YYYY-MM-DDTHH:mm:ss"
    | "ddd, HH:mm"
    | "DD-MM-YYYY";

export const toQTCDate = (dateInString?: string): QTCDate => moment(dateInString);

export const parseToMoment = (
    dateInString?: string | number,
    dateOnly?: boolean
): Moment => (dateOnly ? moment(dateInString).startOf("day") : moment(dateInString));

export const formatForDisplay = (
    dateInStringOrUnix?: string | number,
    dateFormate?: DateFormat
): string => parseToMoment(dateInStringOrUnix).format(dateFormate);

export const countNumberOfDaysBetweenDates = (start: string, end: string): number =>
    Math.floor(parseToMoment(end).diff(parseToMoment(start), "days")) + 1;

export const calculateNumberOfDaysAndWeeksSelectedInRange = (
    fromDate: string,
    toDate: string,
    selectedDays?: string[]
): {
    numberOfDaysSelected: number;
    numberOfWeeksSelected: number;
} => {
    const numberOfDaysSelected = countNumberOfDaysBetweenDates(fromDate, toDate);
    const numberOfWeeksSelected = Math.floor(numberOfDaysSelected / 7);

    if (selectedDays && selectedDays.length) {
        const weekDayCounter = countWeekDaysInRange({
            start: fromDate,
            end: toDate,
        });
        const numberOfWeekDayInRange = selectedDays.reduce((result, day) => {
            result += weekDayCounter[day];
            return result;
        }, 0);

        return {
            numberOfDaysSelected: numberOfWeekDayInRange,
            numberOfWeeksSelected: numberOfWeekDayInRange,
        };
    }
    return { numberOfDaysSelected, numberOfWeeksSelected };
};

export const countWeekDaysInRange = ({ start, end }: DateRange<string>) => {
    const startMoment = moment(start).startOf("day");
    const endMoment = moment(end).startOf("day");

    const weekDayCount = {
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
        Saturday: 0,
        Sunday: 0,
    };

    const currentDate = startMoment.clone();
    while (currentDate.isSameOrBefore(endMoment)) {
        weekDayCount[weekDayMapper[currentDate.weekday()]] += 1;
        currentDate.add(1, "day");
    }

    return weekDayCount;
};

export const shiftToSamePeriodLastYear = ({
    start,
    end,
    addDay = false,
}: ShiftTimeInput): DateRange<string> => ({
    start: start.subtract(1, "year").format(DATE_TIME_FORMAT),
    end: end
        .subtract(1, "year")
        .add(addDay ? 1 : 0, "days")
        .format(DATE_TIME_FORMAT),
});

export const shiftToSameDayLastWeek = ({
    start,
    end,
    addDay = false,
}: ShiftTimeInput): DateRange<string> => ({
    start: start.subtract(7, "days").format(DATE_TIME_FORMAT),
    end: end
        .subtract(7, "days")
        .add(addDay ? 1 : 0, "days")
        .format(DATE_TIME_FORMAT),
});

export const shiftToPreviousPeriod = ({
    start,
    end,
    addDay = false,
}: ShiftTimeInput): DateRange<string> => {
    const daysDifference = end.diff(start, "days");
    return {
        start: start.subtract(daysDifference + 1, "day").format(DATE_TIME_FORMAT),
        end: end
            .subtract(daysDifference + 1, "day")
            .add(addDay ? 1 : 0, "days")
            .format(DATE_TIME_FORMAT),
    };
};
