import { useGetLastCutOffTime } from "Hooks/useGetLastCutOffTime";
import { useMemo } from "react";
import {
    AggregateFilter,
    ComparisonFilter,
    useAggregateFilter,
    useComparisonFilter,
    useDateRangeFilter,
    useDaysFilter,
} from "Store/filterStore";
import { Maybe } from "types";
import {
    calculateNumberOfDaysAndWeeksSelectedInRange,
    DATE_TIME_FORMAT,
    parseToMoment,
} from "Utils/date-utils";
import { TotalSalesAndStaffHours } from "../dashboard-model";
import { getComparisonPeriod } from "../dashboard-utils";
import { useGetForecastSalesByDates } from "./useGetForecastSalesByDates";
import { useGetSalesAndStaffHoursByDates } from "./useGetSalesAndStaffHoursByDates";

export const useGetSalesAndStaffHours = () => {
    const {
        selectedDateRange: { start, end },
    } = useDateRangeFilter();
    const { selectedDays } = useDaysFilter();

    const { selectedComparison } = useComparisonFilter();
    const { selectedAggregate } = useAggregateFilter();
    const forecastComparison = selectedComparison === ComparisonFilter.Forecast;
    const { cutOffTime, isLoading: gettingLastCutoffTime } = useGetLastCutOffTime();

    const range = {
        start: parseToMoment(start).add(6, "hours").format(DATE_TIME_FORMAT),
        end: parseToMoment(end)
            .add(cutOffTime ? 0 : 1, "day")
            .add(cutOffTime?.hours ?? 5, "hours")
            .add(cutOffTime?.minutes ?? 45, "minutes")
            .format(DATE_TIME_FORMAT),
    };

    const {
        isLoading: gettingTotalSalesAndStaffHours,
        totalSalesAndStaffHours,
        totalSalesAndStaffHoursByMappingRule,
        query,
    } = useGetSalesAndStaffHoursByDates({
        range,
    });

    const comparisonPeriod = getComparisonPeriod({
        start,
        end,
        selectedComparison,
        cutOffTime,
    });

    const { numberOfWeeksSelected, numberOfDaysSelected } = useMemo(() => {
        return calculateNumberOfDaysAndWeeksSelectedInRange(
            start,
            end,
            selectedDays
        );
    }, [end, selectedDays, start]);

    const {
        isLoading: gettingForecastedSales,
        forecastSales,
        forecastSalesByMappingRules,
        query: forecastQuery,
    } = useGetForecastSalesByDates({
        range,
        enabled: Boolean(forecastComparison) && !selectedAggregate,
    });

    const {
        isLoading: gettingTotalSalesAndStaffHoursForComparison,
        totalSalesAndStaffHours: totalSalesAndStaffHoursForComparison,
        totalSalesAndStaffHoursByMappingRule:
            totalSalesAndStaffHoursByMappingRuleForComparison,
        query: comparisonQuery,
    } = useGetSalesAndStaffHoursByDates({
        range: comparisonPeriod ?? range,
        enabled:
            Boolean(comparisonPeriod) && !forecastComparison && !selectedAggregate,
    });

    const averageSalesAndStaffHours: Maybe<TotalSalesAndStaffHours> = useMemo(() => {
        if (!selectedAggregate || !totalSalesAndStaffHours) return;

        const { totalSales, totalStaffHours } = totalSalesAndStaffHours;
        if (selectedAggregate === AggregateFilter.AverageDay) {
            return {
                totalSales: totalSales / numberOfDaysSelected,
                totalStaffHours: totalStaffHours / numberOfDaysSelected,
            };
        }

        return {
            totalSales: totalSales / (numberOfWeeksSelected || 1),
            totalStaffHours: totalStaffHours / (numberOfWeeksSelected || 1),
        };
    }, [
        numberOfDaysSelected,
        numberOfWeeksSelected,
        selectedAggregate,
        totalSalesAndStaffHours,
    ]);

    const averageSalesAndStaffHoursByMappingRule = useMemo(() => {
        if (!selectedAggregate || !totalSalesAndStaffHoursByMappingRule) return;

        const denominator =
            selectedAggregate === AggregateFilter.AverageDay
                ? numberOfDaysSelected
                : numberOfWeeksSelected || 1;

        return Object.keys(totalSalesAndStaffHoursByMappingRule).reduce<
            Record<string, TotalSalesAndStaffHours>
        >((result, mappingRule) => {
            const { totalSales, totalStaffHours } =
                totalSalesAndStaffHoursByMappingRule[mappingRule];
            result[mappingRule] = {
                totalSales: totalSales / denominator,
                totalStaffHours: totalStaffHours / denominator,
            };

            return result;
        }, {});
    }, [
        numberOfDaysSelected,
        numberOfWeeksSelected,
        selectedAggregate,
        totalSalesAndStaffHoursByMappingRule,
    ]);

    const isLoading =
        gettingTotalSalesAndStaffHours ||
        gettingTotalSalesAndStaffHoursForComparison ||
        gettingForecastedSales ||
        gettingLastCutoffTime;

    return {
        isLoading,
        query,
        totalSalesAndStaffHours,
        totalSalesAndStaffHoursByMappingRule,
        comparisonQuery,
        totalSalesAndStaffHoursForComparison,
        totalSalesAndStaffHoursByMappingRuleForComparison,
        averageSalesAndStaffHours,
        averageSalesAndStaffHoursByMappingRule,
        forecastQuery,
        forecastSales,
        forecastSalesByMappingRules,
    };
};
