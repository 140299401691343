import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import { Context } from "../../State/store";
import styled from "styled-components";
import { Select, Tooltip } from "antd";
import { formatForDisplay, parseToMoment } from "Utils/date-utils";
import {
    useAggregateFilter,
    useDateRangeFilter,
    AggregateFilter as AggregateFilterEnum,
} from "Store/filterStore";
import { usePreviewMode } from "Store/viewSettingsStore";

export enum Aggregate {
    AVERAGE_DAY = "Average Day",
    AVERAGE_WEEK = "Average Week",
    MEDIAN_DAY = "Median Day",
    MEDIAN_WEEK = "Median Week",
}

const supportedAggregates = [
    Aggregate.AVERAGE_DAY,
    Aggregate.AVERAGE_WEEK,
    // Aggregate.MEDIAN_DAY,
    // Aggregate.MEDIAN_WEEK,
];
const supportedAggregatesPreview = [
    AggregateFilterEnum.AverageDay,
    AggregateFilterEnum.AverageWeek,
];

const StyledSelect = styled(Select)`
    width: 100%;
`;

const TooltipWrapper = ({
    isSameDay,
    isCurrentDay,
    children,
}: {
    isSameDay: boolean;
    isCurrentDay: boolean;
    children: ReactElement;
}) => {
    if (isSameDay) {
        return (
            <Tooltip title="Please select 2 or more days to use this feature">
                {children}
            </Tooltip>
        );
    } else if (isCurrentDay) {
        return (
            <Tooltip title="Please ensure the current day isn't selected">
                {children}
            </Tooltip>
        );
    } else {
        return children;
    }
};

export const AggregateFilterPreview: React.FC = () => {
    const { selectedAggregate, updateAggregateFilter } = useAggregateFilter();
    const {
        selectedDateRange: { start, end },
    } = useDateRangeFilter();

    const todaySelected = React.useMemo(
        () => parseToMoment(end).isSame(parseToMoment(), "date"),
        [end]
    );

    const isSameDay = React.useMemo(() => start === end, [end, start]);
    const numberOfDaySelectedLessThanTwoWeek =
        parseToMoment(end).diff(parseToMoment(start), "days") < 13;

    return (
        <TooltipWrapper isSameDay={isSameDay} isCurrentDay={todaySelected}>
            <StyledSelect
                placeholder="Select Aggregate Period"
                showArrow={true}
                onChange={(selectedAggregate: any) =>
                    updateAggregateFilter(selectedAggregate)
                }
                allowClear={true}
                value={selectedAggregate ? selectedAggregate : undefined}
                disabled={todaySelected || isSameDay}
            >
                {supportedAggregatesPreview.map((supportedAggregate) => {
                    const averageWeekDisabled =
                        supportedAggregate === AggregateFilterEnum.AverageWeek &&
                        numberOfDaySelectedLessThanTwoWeek;
                    return (
                        <Select.Option
                            value={supportedAggregate}
                            key={supportedAggregate}
                            disabled={averageWeekDisabled}
                        >
                            {averageWeekDisabled ? (
                                <Tooltip title="Please select at least 2 weeks">
                                    {supportedAggregate}
                                </Tooltip>
                            ) : (
                                supportedAggregate
                            )}
                        </Select.Option>
                    );
                })}
            </StyledSelect>
        </TooltipWrapper>
    );
};

export default function AggregateFilter(): ReactElement {
    const [
        {
            filters: { selectedAggregate, selectedDates },
        },
    ] = useContext(Context);
    const { updateAggregateFilter } = useAggregateFilter();

    // const { previewMode } = usePreviewMode();
    //
    // const onChange = (selectedAggregate) => {
    //     const postsData = {
    //         selectedAggregate,
    //     };
    //
    //     dispatch({ type: "ADD_AGGREGATE_TO_FILTER", payload: postsData });
    // };

    const isCurrentDateSelected = useMemo(() => {
        const currentDate = formatForDisplay(new Date().toString(), "YYYY-MM-DD");
        return (
            currentDate === selectedDates.fromDate ||
            currentDate === selectedDates.toDate
        );
    }, [selectedDates.fromDate, selectedDates.toDate]);

    const isDisabled = useMemo(
        () =>
            isCurrentDateSelected || selectedDates.fromDate === selectedDates.toDate,
        [isCurrentDateSelected, selectedDates.fromDate, selectedDates.toDate]
    );

    useEffect(() => {
        if (isDisabled) {
            // We disable the aggregate option as there would not be enough data to properly use this transformation.
            // dispatch({
            //     type: "ADD_AGGREGATE_TO_FILTER",
            //     payload: {
            //         selectedAggregate: null,
            //     },
            // });
            updateAggregateFilter();
        }
    }, [isDisabled]);

    // if (previewMode) {
    /**
     * PreviewMode no more it's all live now
     */
    return <AggregateFilterPreview />;
    // }
    // return (
    //     <TooltipWrapper
    //         isSameDay={selectedDates.fromDate === selectedDates.toDate}
    //         isCurrentDay={isCurrentDateSelected}
    //     >
    //         <StyledSelect
    //             placeholder="Select Aggregate Period"
    //             showArrow={true}
    //             onChange={onChange}
    //             allowClear={true}
    //             value={selectedAggregate ? selectedAggregate : undefined}
    //             disabled={isDisabled}
    //         >
    //             {supportedAggregates.map((supportedAggregate) => (
    //                 <Select.Option
    //                     value={supportedAggregate}
    //                     key={supportedAggregate}
    //                 >
    //                     {supportedAggregate}
    //                 </Select.Option>
    //             ))}
    //         </StyledSelect>
    //     </TooltipWrapper>
    // );
}
