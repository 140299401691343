import React from "react";
import { Card, Col, Row, Typography } from "antd";
import styled from "styled-components";
import { WRANGLR_DARK_BLUE } from "../../Utils/constants";
import TotalSalesMetric from "./TotalSalesMetric";
import TabulatedGraphs from "./TabulatedGraphs";
import TableWidget from "./TableWidget";
import "./segmentTable.scss";
import { TotalSalesMetricPreview } from "Pages/Dashboard/Components/TotalSalesMetric/TotalSalesMetric";
import { ManHrMetricPreview } from "Pages/Dashboard/Components/ManHrMetric/ManHrMetric";

const FullHeightDiv = styled.div`
    height: 100%;
`;

export const RoundedCard = styled(Card)`
    border-radius: 15px;
    border: 1px solid #d9d9d9;
`;

const MarginBottomRow = styled(Row)`
    margin-bottom: 20px;
`;

export const WranglrDarkBlueTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
`;

export const HomeDashboardDesktop: React.FC = () => {
    return (
        <FullHeightDiv>
            <MarginBottomRow gutter={20}>
                <Col span={24}>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Row style={{ paddingBottom: "20px" }}>
                                <RoundedCard
                                    style={{ width: "100%", height: "50%" }}
                                >
                                    <WranglrDarkBlueTitle level={4}>
                                        Sales
                                    </WranglrDarkBlueTitle>
                                    <TotalSalesMetricPreview level={3} />
                                </RoundedCard>
                            </Row>
                            <Row>
                                <RoundedCard style={{ width: "100%" }}>
                                    <WranglrDarkBlueTitle level={4}>
                                        /StaffHr
                                    </WranglrDarkBlueTitle>
                                    <ManHrMetricPreview level={3} />
                                </RoundedCard>
                            </Row>
                        </Col>
                        <Col span={20}>
                            <RoundedCard>
                                <TabulatedGraphs height={350} />
                            </RoundedCard>
                        </Col>
                    </Row>
                </Col>
            </MarginBottomRow>
            <Row>
                <Col span={24}>{<TableWidget />}</Col>
            </Row>
        </FullHeightDiv>
    );
};
