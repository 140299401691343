import { Tag, Typography } from "antd";
import React from "react";

export const BASELINE_CARD_TOOLTIP_TEXT =
    "Please select at least 1 week period. Selecting multiple periods will return an average of all periods as the sales forecast.";

export const DRIVERS_CARD_TOOLTIP_TEXT =
    "Drivers can raise or lower the sales forecast of your selected baseline based on factors that may affect your sales, such as sports games, bookings or public holidays.";

export const BASELINE_AND_FORECAST_CARD_TOOLTIP_TEXT =
    "You can use these charts to compare your adjusted forecast to the original baseline.";
export const BASELINE_AND_AUTOGEN_CARD_TOOLTIP_TEXT =
    "You can use these charts to compare your autogen forecast to the baseline.";
export const AUTOGEN_AND_FORECAST_CARD_TOOLTIP_TEXT =
    "You can use these charts to compare your adjusted forecast to the original autogen.";

export const DRIVER_MODAL_NAME_FIELD_TOOLTIP_TEXT =
    "Description for current driver, e.g. Sports Game, Bookings.";
export const DRIVER_MODAL_TIME_PERIOD_FIELD_TOOLTIP_TEXT =
    "Select the period of time that your driver applies to.";
export const DRIVER_MODAL_AREAS_FIELD_TOOLTIP_TEXT =
    "Select the area(s) that your driver applies to, leave blank to select all.";
export const DRIVER_MODAL_CLASSES_FIELD_TOOLTIP_TEXT =
    "Select the class(es) that your driver applies to, leave blank to select all.";
export const DRIVER_MODAL_VALUE_TYPE_FIELD_TOOLTIP_TEXT =
    "Select whether your driver is percentage-based or dollar-based. \n" +
    "Dollar-based values raise or lower a period by a dollar figure, Percentage-based values raise or lower a period by a percentage.\n";
export const DRIVER_MODAL_VALUE_FIELD_TOOLTIP_TEXT =
    "Enter a value for your driver.";
export const DRIVER_MODAL_SPREAD_FIELD_TOOLTIP_TEXT = (
    <Typography.Text style={{ color: "#fff" }}>
        Spread applies to dollar-based drivers and determines how your entered value
        will be dispersed across the selected time period. <Tag>Even</Tag>will spread
        your figure equally across the 15-minutely segments of your forecast,
        <Tag>Proportional</Tag>will spread your figure across the sales trend of the
        selected period.
    </Typography.Text>
);
export const DRIVER_MODAL_OVERRIDE_FIELD_TOOLTIP_TEXT =
    "This option only applies to dollar-based drivers and will replace the baseline sales total within your selected period with your own dollar-based value figure that other drivers can then raise or lower.";
