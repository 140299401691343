import { getUserToken } from "Api/auth/auth";
import { AuthTokens } from "Api/auth/types";
import { useEffect } from "react";

export const useGtag = ({
    groupId,
    groupName,
    userId,
    userName,
}: {
    groupId?: string;
    groupName?: string;
    userId?: string;
    userName?: string;
}) => {
    const auth0UserId = getUserToken(AuthTokens.userId);
    const email = getUserToken(AuthTokens.email);

    useEffect(() => {
        if (userId && groupId && groupName && userId) {
            //@ts-ginore
            if (window?.gtag) {
                //@ts-ignore
                window?.gtag?.("set", "user_properties", {
                    internal_user_id: userId,
                    // TODO Rename this property both here and in the GA dashboard
                    firebase_user_id: auth0UserId,
                    group_id: groupId,
                    group_name: groupName,
                    userEmail: email,
                    userName,
                });
            }
        }
    }, [userId, groupId, groupName, userId]);
};
