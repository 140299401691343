import create from "zustand";

interface ViewSettingsStore {
    previewMode: true;
    setPreviewMode: (previewMode?: boolean) => void;
}

export const useViewSettingsStore = create<ViewSettingsStore>((set) => ({
    previewMode: true,
    setPreviewMode: () => set({ previewMode: true }),
}));

export const usePreviewMode = () => {
    const previewMode = useViewSettingsStore((state) => state.previewMode);
    const setPreviewMode = useViewSettingsStore((state) => state.setPreviewMode);

    return { previewMode, setPreviewMode };
};
