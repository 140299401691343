import { Col, DatePicker, Row, Select, Skeleton, Spin, Typography } from "antd";
import CustomLoader from "Components/CustomLoader";
import { RoundedCard } from "Components/HomeDashboardDesktop";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import BaseDesktopPage from "Pages/BaseDesktopPage";
import React, { useEffect, useMemo, useState } from "react";
import { QTCSpace } from "styles/QTCSpace";
import { MomentFormat } from "Utils/date-utils";
import { ForecastEvent } from "./Components/ForecastEvent";
import { ForecastPreview } from "./Components/ForecastPreview";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const ForecastPage: React.FC = () => {
    const { data: mappingRules, isLoading } = useListMappingRules();
    const [selectedMappingRuleId, setSelectedMappingRuleId] = useState<string>();

    const venuesMappingRule = useMemo(
        () => mappingRules?.filter(({ segment }) => segment === "venue") ?? [],
        [mappingRules]
    );

    useEffect(() => {
        if (venuesMappingRule.length && !selectedMappingRuleId) {
            setSelectedMappingRuleId(venuesMappingRule[0].id);
        }
    }, [selectedMappingRuleId, venuesMappingRule, venuesMappingRule.length]);

    if (isLoading || !mappingRules) {
        return (
            <Spin indicator={<CustomLoader />}>
                <Row gutter={[8, 20]}>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                </Row>
            </Spin>
        );
    }

    return (
        <BaseDesktopPage page={"forecast"}>
            <QTCSpace $fluid direction="vertical" size="large">
                <Row gutter={[16, 0]} align="middle">
                    <Col>
                        <Typography.Title level={5} style={{ marginBottom: 0 }}>
                            Venue
                        </Typography.Title>
                    </Col>
                    <Col span={8}>
                        <Select
                            onChange={(mappingRuleId) =>
                                setSelectedMappingRuleId(mappingRuleId)
                            }
                            value={selectedMappingRuleId}
                        >
                            {venuesMappingRule.map(({ id, segmentName }) => (
                                <Option key={id} value={id}>
                                    {segmentName}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Row gutter={[16, 0]} align="middle">
                    <Col>
                        <Typography.Title level={5} style={{ marginBottom: 0 }}>
                            Week
                        </Typography.Title>
                    </Col>
                    <Col span={8}>
                        <RangePicker
                            format={MomentFormat.dateMonthYearWithDashSeparated}
                        />
                    </Col>
                </Row>

                <Row>
                    <ForecastPreview />
                </Row>
                <Row>
                    <Col span={24}>
                        <ForecastEvent />
                    </Col>
                </Row>
            </QTCSpace>
        </BaseDesktopPage>
    );
};
