import moment from "moment";
import { Filters } from "../../State/store";
import { Aggregate } from "../../Components/AggregateFilter";
import {
    Filter,
    Query,
    TimeDimension,
    TQueryOrderObject,
} from "@cubejs-client/core";
import { ComparisonPeriod, Granularity, SalesType, SeriesRow } from "./types";
import { DAYS_OF_WEEK_NUM_VALUE } from "../../Utils/utils";
import { DAY_OF_WEEK_NUM_VALUES, DAYS_OF_WEEK } from "../../Utils/types";
import {
    DateRange,
    DATE_TIME_FORMAT,
    DEFAULT_DATE_FORMAT,
    parseToMoment,
    shiftToPreviousPeriod,
    shiftToSameDayLastWeek,
    shiftToSamePeriodLastYear,
} from "Utils/date-utils";
import { Nullable } from "types";
import { presetRanges } from "Components/DateFilter";
import memoizeOne from "memoize-one";
import { DEFAULT_TIMEZONE } from "../../Utils/constants";

enum ComparisonEnums {
    PREVIOUS_PERIOD = "Previous Period",
    SAME_PERIOD_LAST_YEAR = "Same Period Last Year",
    SAME_DAY_LAST_WEEK = "Same Day Last Week",
    ALL_FORECAST = "Forecast",
}

export const getDateGranularity = (filters: Filters): Granularity => {
    let dateGranularity;
    const { fromDate, toDate } = filters.selectedDates;
    const start = moment(fromDate);
    const end = moment(toDate);
    const daysDifference = end.diff(start, "days");

    switch (true) {
        case (filters.selectedAggregate as string) === Aggregate.MEDIAN_DAY:
            dateGranularity = "minute";
            break;
        case (filters.selectedAggregate as string) === Aggregate.MEDIAN_WEEK:
            dateGranularity = "hour";
            break;
        case daysDifference < 1:
            dateGranularity = "minute";
            break;
        case daysDifference <= 31:
            dateGranularity = "hour";
            break;
        // case daysDifference <= 31:
        //     dateGranularity = "day";
        //     break;
        case daysDifference <= 366:
            dateGranularity = "week";
            break;
        default:
            dateGranularity = "month";
    }

    return dateGranularity;
};

export const getFormattingGranularity = (filters: Filters): Granularity => {
    const { fromDate, toDate } = filters.selectedDates;
    const start = moment(fromDate);
    const end = moment(toDate);
    const daysDifference =
        filters.selectedAggregate === Aggregate.MEDIAN_WEEK
            ? 7
            : end.diff(start, "days");
    if (daysDifference <= 7 || daysDifference > 31) {
        return getDateGranularity(filters);
    }
    return "day";
};

/**
 * Granularity utility for Preview mode / Mapped data
 * @param param0
 * @returns
 */
export const getGranularityForDateRange = ({
    start,
    end,
}: DateRange<string>): Granularity => {
    const startMoment = parseToMoment(start, true);
    const endMoment = parseToMoment(end, true);

    const daysDifference = endMoment.diff(startMoment, "days");
    if (daysDifference <= 1) {
        return "minute";
    } else if (daysDifference <= 7) {
        return "hour";
    } else if (daysDifference <= 31) {
        return "day";
    } else if (daysDifference <= 366) {
        return "week";
    } else {
        return "month";
    }
};

export const getStaffHoursDimension = ({
    range,
    cubeName,
}: {
    range: DateRange<string>;
    cubeName: string;
}): string =>
    getGranularityForDateRange(range) === "minute"
        ? `${cubeName}.activeStaff`
        : `${cubeName}.activeStaffHourly`;
// `${CUBE_NAME}.activeStaff`,

export const sumByDayIfRequired = (
    dataSeries: SeriesRow[],
    filters: Filters
): SeriesRow[] => {
    const { fromDate, toDate } = filters.selectedDates;
    const start = moment(fromDate);
    const end = moment(toDate);
    const daysDifference = end.diff(start, "days");
    if (
        getDateGranularity(filters) !== "hour" ||
        daysDifference <= 7 ||
        daysDifference > 31
    ) {
        return dataSeries;
    }
    const dayMappings: any = {};
    const summedDataSeries: any[] = [];
    dataSeries.forEach((row) => {
        const date = moment(row.x).subtract(6, "hours").format("YYYY-MM-DD");
        const category = `${date}T00:00.000`;
        if (!dayMappings[category]) {
            dayMappings[category] = 0;
        }
        dayMappings[category] += row.value;
    });
    const keys = Object.keys(dayMappings);

    keys.forEach((key) => {
        summedDataSeries.push({
            x: key,
            category: key,
            value: dayMappings[key],
        });
    });
    return summedDataSeries;
};

// @deprecated
export const getComparisonPeriod = (
    fromDate: string,
    toDate: string,
    selectedComparison: any | null,
    addDay = false
): ComparisonPeriod => {
    const start = moment(fromDate);
    const end = moment(toDate);

    const getSamePeriodLastYear = () => {
        const temp1 = start.subtract(1, "year");
        const temp2 = end.subtract(1, "year");
        return [
            temp1.format("YYYY-MM-DD"),
            temp2.add(addDay ? 1 : 0, "days").format("YYYY-MM-DD"),
        ];
    };

    const getSameDayLastWeek = () => {
        const temp1 = start.subtract(7, "day");
        const temp2 = end.subtract(7, "day");
        return [
            temp1.format("YYYY-MM-DD"),
            temp2.add(addDay ? 1 : 0, "days").format("YYYY-MM-DD"),
        ];
    };

    const getPreviousPeriod = () => {
        const daysDifference = end.diff(start, "days");
        const temp1 = start.subtract(daysDifference, "day");
        const temp2 = end.subtract(daysDifference, "day");
        return [
            temp1.format("YYYY-MM-DD"),
            temp2.add(addDay ? 1 : 0, "days").format("YYYY-MM-DD"),
        ];
    };

    switch (selectedComparison) {
        case ComparisonEnums.SAME_PERIOD_LAST_YEAR:
            if (addDay) {
                return [
                    [fromDate, moment(toDate).add(1, "days").format("YYYY-MM-DD")],
                    getSamePeriodLastYear(),
                ];
            } else {
                return [[fromDate, toDate], getSamePeriodLastYear()];
            }
        case ComparisonEnums.SAME_DAY_LAST_WEEK:
            if (addDay) {
                return [
                    [fromDate, moment(toDate).add(1, "days").format("YYYY-MM-DD")],
                    getSameDayLastWeek(),
                ];
            } else {
                return [[fromDate, toDate], getSameDayLastWeek()];
            }
        case ComparisonEnums.PREVIOUS_PERIOD:
            if (addDay) {
                return [
                    [fromDate, moment(toDate).add(1, "days").format("YYYY-MM-DD")],
                    getPreviousPeriod(),
                ];
            } else {
                return [[fromDate, toDate], getPreviousPeriod()];
            }
        default:
            return [[fromDate, toDate], []];
    }
};

/**
 * This function is responsible for filtering a data series to form a 'shift series', which means that the start time of the series will be 6am, and the end time will be 6am as well.
 *
 * @param dataSeries
 * @param filters
 * @param removeDataPastCurrentTime
 * @param dateKey
 * @param isCompareSeries
 */
export const filterByShiftIfRequired = ({
    dataSeries,
    filters,
    dateKey = "x",
    cutOffTime,
}: {
    dataSeries: SeriesRow[];
    filters: Filters;
    dateKey?: string;
    isCompareSeries?: boolean;
    cutOffTime?: { hours: number; minutes: number };
}): SeriesRow[] => {
    const granularity = getDateGranularity(filters);
    const { selectedDates } = filters;
    const { fromDate, toDate } = selectedDates;

    if (granularity !== "minute" && granularity !== "hour") {
        return dataSeries.filter((row) => {
            return row[dateKey] >= fromDate && row[dateKey] <= toDate;
        });
    }

    const shiftFromDate = moment(fromDate)
        .add(6, "hours")
        .format("YYYY-MM-DDTHH:mm:ss");
    const shiftToDate = moment(toDate)
        .add(1, "days")
        .add(6, "hours")
        .format("YYYY-MM-DDTHH:mm:ss");

    const curTime = cutOffTime
        ? moment()
              .hours(cutOffTime?.hours)
              .minutes(cutOffTime?.minutes)
              .format("YYYY-MM-DDTHH:mm:ss")
        : undefined;

    return dataSeries.filter((row) => {
        return (
            row[dateKey] >= shiftFromDate &&
            row[dateKey] < shiftToDate &&
            (!curTime || row[dateKey] <= curTime)
        );
    });
};

export const removeDataPastCurrentTime = ({
    dataSeries,
    dataKey = "x",
    cutOffTime,
}: {
    dataSeries: SeriesRow[];
    dataKey?: string;
    cutOffTime?: { hours: number; minutes: number };
}): SeriesRow[] => {
    if (!cutOffTime) {
        return dataSeries;
    }
    const curTime = moment()
        .hours(cutOffTime.hours)
        .minutes(cutOffTime.minutes)
        .format("YYYY-MM-DDTHH:mm:ss");

    return dataSeries.filter((row) => row[dataKey] <= curTime);
};

export const nullifyValuesPastCurrentTime = (
    dataSeries: SeriesRow[],
    dateKey = "x"
): SeriesRow[] => {
    const curTime = moment().subtract(30, "minute").format("YYYY-MM-DDTHH:mm:ss");
    return dataSeries.map((row) => {
        if (row[dateKey] <= curTime) return row;
        return { ...row, value: undefined };
    });
};

export const filterToFifteenMinuteTimeBlocks = (
    dataSeries: SeriesRow[],
    dateKey = "x"
): SeriesRow[] =>
    dataSeries.filter((row) => {
        const MINUTE_SEGMENTS = ["00", "15", "30", "45"];
        return MINUTE_SEGMENTS.includes(row[dateKey].slice(-9, -7));
    });

/**
 * Helper function to convert the string representation of a Day of the week into a NUM value (What Cube.JS expects)
 *
 * @param {"Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"| "Sunday"} day
 * @returns {"1" | "2" | "3" | "4" | "5" | "6" | "7"}
 */
export const DayToNum = (day: DAYS_OF_WEEK): DAY_OF_WEEK_NUM_VALUES => {
    return DAYS_OF_WEEK_NUM_VALUE[day];
};

interface DateByWeekDay {
    Monday: string[];
    Tuesday: string[];
    Wednesday: string[];
    Thursday: string[];
    Friday: string[];
    Saturday: string[];
    Sunday: string[];
}

export const getAllDatesByWeekDayInRange = memoizeOne(
    (start: string, end: string) => {
        const startMoment = parseToMoment(start);
        const endMoment = parseToMoment(end);
        const result: DateByWeekDay = {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
        };
        let currentDate = startMoment.clone();
        while (currentDate.isSameOrBefore(endMoment, "date")) {
            switch (currentDate.get("day")) {
                case 0:
                    result.Sunday.push(currentDate.format(DEFAULT_DATE_FORMAT));
                    break;
                case 1:
                    result.Monday.push(currentDate.format(DEFAULT_DATE_FORMAT));
                    break;
                case 2:
                    result.Tuesday.push(currentDate.format(DEFAULT_DATE_FORMAT));
                    break;
                case 3:
                    result.Wednesday.push(currentDate.format(DEFAULT_DATE_FORMAT));
                    break;
                case 4:
                    result.Thursday.push(currentDate.format(DEFAULT_DATE_FORMAT));
                    break;
                case 5:
                    result.Friday.push(currentDate.format(DEFAULT_DATE_FORMAT));
                    break;
                case 6:
                    result.Saturday.push(currentDate.format(DEFAULT_DATE_FORMAT));
                    break;
                default:
                    break;
            }
            currentDate = currentDate.clone().add(1, "day");
        }

        return result;
    }
);

export const getFilterSet = (params: {
    filters: Filters;
    cubeName: string;
}): Filter[] => {
    const { filters, cubeName } = params;
    const filterSet: Filter[] = [];
    const { selectedHours, selectedVenues, selectedAreas, selectedClasses } =
        filters;

    if (filters.selectedDays.length > 0) {
        // NUM value and associated Day of week.
        // 1 - Monday (6am - 5am)
        // 2 - Tuesday
        // 3 - Wednesday
        // 4 - Thursday
        // 5 - Friday
        // 6 - Saturday
        // 7 - Sunday
        const selectedDaysToNum = filters.selectedDays.flatMap((day) => {
            const dayOfWeek = Number(DayToNum(day as DAYS_OF_WEEK));
            return [String(dayOfWeek)];
        });

        filterSet.push({
            member: `${cubeName}.shiftDay`,
            operator: "equals",
            values: selectedDaysToNum,
        });
    }

    if (selectedHours.length > 0) {
        filterSet.push({
            member: `${cubeName}.hour`,
            operator: "equals",
            values: selectedHours.map((hour) => hour.toString()),
        });
    }

    if (selectedVenues.length > 0) {
        filterSet.push({
            member: `${cubeName}.mappedVenue`,
            operator: "equals",
            values: selectedVenues.map((venue) => venue.primary_id),
        });
    }

    if (selectedAreas.length > 0) {
        filterSet.push({
            member: `${cubeName}.mappedArea`,
            operator: "equals",
            values: selectedAreas.map((area) => area.primary_id),
        });
    }

    if (selectedClasses.length > 0) {
        filterSet.push({
            member: `${cubeName}.mappedClass`,
            operator: "equals",
            values: selectedClasses.map((classLabel) => classLabel.primary_id),
        });
    }

    return filterSet;
};

export const getMedianColumnName = (selectedAggregate: Aggregate): string => {
    switch (selectedAggregate) {
        case Aggregate.AVERAGE_DAY:
            return "sortablePeriodMedianDaily";
        case Aggregate.AVERAGE_WEEK:
            return "sortablePeriodMedianWeekly";
    }
    return "period";
};

export const getTimeDimension = (params: {
    filters: Filters;
    skipComparison?: boolean;
    cubeName: string;
}): TimeDimension[] => {
    const { filters, skipComparison = false, cubeName = "actuals" } = params;
    const { selectedComparison, mode, selectedDates, selectedAggregate } = filters;
    const { fromDate, toDate } = selectedDates;

    const shiftFromDate = moment(fromDate).format(DEFAULT_DATE_FORMAT);

    const shiftToDate = moment(toDate).add(1, "days").format(DEFAULT_DATE_FORMAT);

    const averageAggregateSelected =
        selectedAggregate === Aggregate.AVERAGE_DAY ||
        selectedAggregate === Aggregate.AVERAGE_WEEK;

    if (averageAggregateSelected) {
        return [
            {
                dimension: `${cubeName}.${getMedianColumnName(selectedAggregate!)}`,
                granularity:
                    selectedAggregate === Aggregate.AVERAGE_DAY ? "minute" : "hour",
            },
        ];
    }
    const timeDimension: TimeDimension[] = [
        {
            dimension: `${cubeName}.period`,
            granularity: getDateGranularity(filters),
            dateRange: [shiftFromDate, shiftToDate],
        },
    ];
    if (selectedComparison && mode !== "forecast" && !skipComparison) {
        const comparisonPeriods = getComparisonPeriod(
            shiftFromDate,
            shiftToDate,
            selectedComparison
        );

        timeDimension[0]["compareDateRange"] = comparisonPeriods;
    }

    return timeDimension;
};

export const getTimeFilter = ({
    start,
    end,
    useExactDateAndTime,
    cubeName,
}: {
    start: string;
    end: string;
    useExactDateAndTime?: boolean;
    cubeName: string;
}): Filter => {
    if (useExactDateAndTime) {
        return {
            member: `${cubeName}.period`,
            values: [start, end],
            operator: "inDateRange",
        };
    }
    const startDate = parseToMoment(start).add(6, "hours").format(DATE_TIME_FORMAT);
    const endDate = parseToMoment(end);
    const now = parseToMoment();

    const [foreverStart, foreverEnd] = presetRanges
        .find(({ label }) => label === "Forever")!
        .range();

    if (
        foreverStart.isSame(startDate, "date") &&
        foreverEnd.isSame(endDate, "date")
    ) {
        return {
            member: `${cubeName}.period`,
            values: [
                foreverStart.clone().format(DEFAULT_DATE_FORMAT),
                foreverEnd
                    .clone()
                    .add(5, "hours")
                    .add(45, "minutes")
                    .format(DEFAULT_DATE_FORMAT),
            ],
            operator: "inDateRange",
        };
    }

    if (endDate.isSame(now, "date")) {
        return {
            member: `${cubeName}.period`,
            values: [
                startDate,
                now
                    .subtract(30, "minutes")
                    .minutes(30)
                    .second(0)
                    .format(DATE_TIME_FORMAT),
            ],
            operator: "inDateRange",
        };
    }

    return {
        member: `${cubeName}.period`,
        values: [
            startDate,
            endDate
                .add(1, "day")
                .add(5, "hours")
                .add(45, "minutes")
                .format(DATE_TIME_FORMAT),
        ],
        operator: "inDateRange",
    };
};

export const getComparisonTimeFilter = ({
    selectedDates,
    selectedComparison,
}: Filters): Nullable<Filter> => {
    if (!selectedComparison || selectedComparison === ComparisonEnums.ALL_FORECAST)
        return null;

    const { fromDate, toDate } = selectedDates;

    const start = parseToMoment(fromDate);
    const end = parseToMoment(toDate);

    const shiftedPeriod =
        selectedComparison === ComparisonEnums.PREVIOUS_PERIOD
            ? shiftToPreviousPeriod({ start, end })
            : selectedComparison === ComparisonEnums.SAME_DAY_LAST_WEEK
            ? shiftToSameDayLastWeek({ start, end })
            : shiftToSamePeriodLastYear({ start, end });

    return getTimeFilter({ ...shiftedPeriod, cubeName: "actuals" });
};

export const getMeasures = (params: {
    filters: Filters;
    addActiveStaff?: boolean;
    ignoreGranularityForActiveStaff?: boolean;
    salesType?: SalesType;
    cubeName: string;
}): string[] => {
    const {
        filters,
        addActiveStaff = false,
        ignoreGranularityForActiveStaff = false,
        salesType = "actual",
        cubeName,
    } = params;
    const transactionTotalField =
        salesType === "actual"
            ? `${cubeName}.transactionTotal`
            : "forecasts.forecastTransactionTotal";

    if (addActiveStaff) {
        if (
            (getDateGranularity(filters) === "minute" ||
                filters.selectedAggregate === Aggregate.AVERAGE_DAY) &&
            !ignoreGranularityForActiveStaff
        ) {
            return [transactionTotalField, `${cubeName}.activeStaff`];
        } else {
            return [transactionTotalField, `${cubeName}.activeStaffHourly`];
        }
    } else {
        return [transactionTotalField];
    }
};

export const getOrdering = ({
    selectedAggregate,
    cubeName,
}: Filters & { cubeName: string }): TQueryOrderObject =>
    selectedAggregate
        ? {
              [`${cubeName}.${getMedianColumnName(selectedAggregate)}`]: "asc",
          }
        : {
              [`${cubeName}.period`]: "asc",
          };

export const cumSum = (dataSeries: SeriesRow[]): SeriesRow[] => {
    const dataSeriesClone: SeriesRow[] = [...dataSeries];

    dataSeriesClone.forEach(({ value = 0 }, index) => {
        if (value && index > 0) {
            dataSeriesClone[index].value = dataSeriesClone[index - 1].value! + value;
        }
    });
    return dataSeriesClone;
};

export const CubeQueryBuilder = (query: Query = {}) => ({
    getResult: (
        { ignoreTimeZone }: { ignoreTimeZone: boolean } = { ignoreTimeZone: false }
    ) =>
        ignoreTimeZone
            ? query
            : {
                  ...query,
                  timezone: DEFAULT_TIMEZONE,
              },
    addMeasures: (measures: string[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  measures: [...(query.measures ?? []), ...measures],
              })
            : CubeQueryBuilder(query),
    addOrder: (order: TQueryOrderObject, condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  order,
              })
            : CubeQueryBuilder(query),
    addFilters: (filters: any, condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  filters: [...(query.filters ?? []), ...filters],
              })
            : CubeQueryBuilder(query),
    addTimeDimensions: (timeDimensions: TimeDimension[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  timeDimensions: [
                      ...(query.timeDimensions ?? []),
                      ...timeDimensions,
                  ],
              })
            : CubeQueryBuilder(query),
    addDimensions: (dimensions: string[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  dimensions: [...(query.dimensions ?? []), ...dimensions],
              })
            : CubeQueryBuilder(query),
    addLimit: (limit: number) =>
        CubeQueryBuilder({
            ...query,
            limit,
        }),
});

export const todayIncludedInSelectedDateRange = ({
    selectedDates: { toDate },
}: Filters): boolean => parseToMoment(toDate).isSame(parseToMoment(), "date");

export const getCubeName = memoizeOne((start: string, end: string, today: string) =>
    start === end && today === start ? "actualsNoPreAgg" : "actuals"
);
