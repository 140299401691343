import notification from "antd/lib/notification";

// TODO Consider extending this component to be mobile friendly (for mobile screen use the Antd "Message" instead of "Notification")
/**
 * Ant design notification component.
 *
 * See https://ant.design/components/notification/ for docs
 *
 */
export const openNotificationWithIcon = ({
    type,
    title,
    description,
    key,
}: {
    type: "success" | "info" | "error" | "warning";
    title: string;
    description?: string;
    key?: string;
}): void => {
    if (key) {
        notification[type]({
            key,
            message: title,
            description: description,
        });
        return;
    }
    notification[type]({
        message: title,
        description: description,
    });
};
