import { useGetMedianSalesDates } from "./useGetMedianSalesDates";
import {
    GetSaleAndStaffDataByDatesParams,
    useGetTotalSalesAndStaffHoursByDates,
} from "./useGetTotalSalesAndStaffHoursByDates";

export const TOTAL_SALES_BY_DATE_QUERY_KEY = "SalesByDate";

export const useGetMedianSalesAndStaff = (
    params: GetSaleAndStaffDataByDatesParams = {}
) => {
    const { isLoading: gettingDateWithMedianSales, periodsWithMedianSales } =
        useGetMedianSalesDates();

    const {
        totalSalesAndStaff,
        totalSalesAndStaffBySegment,
        isLoading: gettingDataForMedianDateSales,
    } = useGetTotalSalesAndStaffHoursByDates({
        ...params,
        enabled: Boolean(periodsWithMedianSales),
        dateRange: periodsWithMedianSales,
    });

    return {
        isLoading: gettingDataForMedianDateSales || gettingDateWithMedianSales,
        medianSalesAndStaffInPeriods: totalSalesAndStaff,
        medianSalesAndStaffInPeriodsBySegment: totalSalesAndStaffBySegment,
    };
};
