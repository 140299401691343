import { ColumnType } from "antd/lib/table";
import React, { CSSProperties, useMemo } from "react";
import { SEGMENT_TYPE } from "Utils/types";
import { WRANGLR_DARK_BLUE } from "Utils/constants";
import { calculatePercentageDifference, stringSort } from "Utils/utils";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import {
    ComparisonFilter,
    useComparisonFilter,
    useSegmentsFilter,
} from "Store/filterStore";
import { SegmentTableRow } from "./SegmentTableRow";
import { useGetSalesAndStaffHours } from "Pages/Dashboard/Hooks/useGetSalesAndStaffHours";
import { Dictionary, chain } from "lodash";
export interface SegmentTableProps {
    segment: SEGMENT_TYPE;
    omitTitle?: boolean;
}
export interface SegmentTableRowRecord {
    segment: string;
    sales: RowValue;
    staffHours: RowValue;
    id: string;
}

export interface RowValue {
    total: number | string;
    change?: number;
}

const columnClassName = "segment-table-column";

const onHeaderCell = (): { style: CSSProperties } => ({
    style: {
        color: WRANGLR_DARK_BLUE,
        fontWeight: "bold" as const,
    },
});

const getColumns = ({
    comparisonSelected,
    isForecast = false,
}: {
    comparisonSelected: boolean;
    isForecast: boolean;
}): ColumnType<SegmentTableRowRecord>[] => [
    {
        title: `Name`,
        dataIndex: "segment",
        key: "segment",
        className: columnClassName,
        sortDirections: ["descend", "ascend"],
        sorter: {
            compare: (a, b) => stringSort(a.segment, b.segment),
            multiple: 1,
        },
        onHeaderCell,
    },
    {
        title: "Sales",
        dataIndex: "sales",
        key: "sales",
        className: columnClassName,
        sortDirections: ["ascend", "descend"],
        defaultSortOrder: "descend",
        sorter: {
            compare: (a, b) =>
                parseInt(a.sales.total.toString()) -
                parseInt(b.sales.total.toString()),
            multiple: 5,
        },
        render: (rowValues: RowValue) => (
            <SegmentTableRow
                rowValue={rowValues}
                displayChange={comparisonSelected || isForecast}
            />
        ),
        onHeaderCell,
    },
    {
        title: "/StaffHr",
        dataIndex: "staffHours",
        key: "staffHours",
        className: columnClassName,
        sortDirections: ["descend", "ascend"],
        sorter: {
            compare: (a, b) =>
                // It is safe to assume "manHour" is a number in this context.
                parseInt(a.staffHours.total.toString()) -
                parseInt(b.staffHours.total.toString()),
            multiple: 4,
        },
        render: (rowValue: RowValue) => (
            <SegmentTableRow
                rowValue={rowValue}
                displayChange={comparisonSelected && !isForecast}
                roundNumberWhenFormatting
            />
        ),
        onHeaderCell,
    },
];

const getComparisonFigures = (
    salesCurrentPeriod,
    manHrCurrentPeriod,
    salesPreviousPeriod,
    manHrPreviousPeriod
) => {
    const salesChange = calculatePercentageDifference(
        salesCurrentPeriod,
        salesPreviousPeriod
    );
    const manHourChange = calculatePercentageDifference(
        manHrCurrentPeriod,
        manHrPreviousPeriod
    );

    return {
        salesChange: salesChange === Number.POSITIVE_INFINITY ? 100 : salesChange,
        manHourChange:
            manHourChange === Number.POSITIVE_INFINITY ? 100 : manHourChange,
    };
};

// This is just to normalise all the values to be Id based
// If it's already an Id then we are good otherwise we cast all the Names to their Ids
// THIS DOES CARRY A RISK OF COLLISION BUT AT THIS POINT THE RISK OF COLLISIONS WITH MAPPING CONFIGURATOR IS A JOKE
export const resolveIdAndNameInconsistancy = (
    venueIdByName: Dictionary<string>,
    currentValue: string // Either a name or an Id
) => {
    if (currentValue in venueIdByName) {
        return venueIdByName[currentValue];
    }
    return currentValue;
};

export const useSegmentTable = ({ segment }: SegmentTableProps) => {
    const {
        selectedAreas,
        selectedClasses,
        selectedVenues,
        updateAreasFilter,
        updateClassesFilter,
        updateVenuesFilter,
    } = useSegmentsFilter();

    const { data: mappingRules } = useListMappingRules();
    const { selectedComparison } = useComparisonFilter();
    const columns = useMemo(
        () =>
            getColumns({
                comparisonSelected: Boolean(selectedComparison),
                isForecast: selectedComparison === ComparisonFilter.Forecast,
            }),
        [selectedComparison]
    );

    // Here in case it is ever needed

    // const venueIdByName = useMemo(
    //     () => chain(mappingRules).keyBy("segmentName").mapValues("id").value(),
    //     [mappingRules]
    // );

    // const venueNameById = useMemo(
    //     () => chain(mappingRules).keyBy("id").mapValues("segmentName").value(),
    //     [mappingRules]
    // );

    const {
        totalSalesAndStaffHoursByMappingRule,
        averageSalesAndStaffHoursByMappingRule,
        totalSalesAndStaffHoursByMappingRuleForComparison,
        forecastSalesByMappingRules,
        isLoading,
    } = useGetSalesAndStaffHours();

    const dataSource = useMemo(() => {
        const mappingRulesForSegment = mappingRules?.filter(
            ({ exclude, segment: ruleSegment }: any) =>
                !exclude && ruleSegment.toUpperCase() === segment.toUpperCase()
        );

        if (!mappingRulesForSegment || !totalSalesAndStaffHoursByMappingRule) {
            return [];
        }

        // A quick hack for including unmapped figure to segment table
        // TODO - revisit ASAP
        const unmappedMappingRule: any =
            segment === "Area"
                ? { segmentName: "unmappedArea", segment: "area" }
                : segment === "Class"
                ? { segmentName: "unmappedClass", segment: "class" }
                : { segmentName: "unmappedVenue", segment: "venue" };

        return [...mappingRulesForSegment, unmappedMappingRule]
            .filter(({ segmentName }) =>
                segmentName.includes("unmapped")
                    ? Boolean(
                          totalSalesAndStaffHoursByMappingRule[segmentName]
                              ?.totalSales
                      )
                    : true
            )
            .map(({ segmentName, id }) => {
                const key = segmentName.includes("unmapped") ? segmentName : id;

                const { totalSales, totalStaffHours } =
                    averageSalesAndStaffHoursByMappingRule?.[key] ??
                        totalSalesAndStaffHoursByMappingRule[key] ?? {
                            totalSales: 0,
                            totalStaffHours: 0,
                        };
                const rowName =
                    segmentName === "unmappedArea"
                        ? "Unmapped Areas"
                        : segmentName === "unmappedClass"
                        ? "Unmapped Classes"
                        : segmentName === "unmappedVenue"
                        ? "Unmapped Venues"
                        : segmentName;

                const {
                    totalSales: totalSalesForComparison,
                    totalStaffHours: totalStaffHoursForComparison,
                } =
                    totalSalesAndStaffHoursByMappingRuleForComparison &&
                    totalSalesAndStaffHoursByMappingRuleForComparison[key]
                        ? totalSalesAndStaffHoursByMappingRuleForComparison[key]
                        : {
                              totalSales: forecastSalesByMappingRules?.[segmentName],
                              totalStaffHours: undefined,
                          };

                const manHr = isFinite(totalSales / totalStaffHours)
                    ? totalSales / totalStaffHours
                    : 0;
                const { salesChange, manHourChange } =
                    totalSalesForComparison != null ||
                    totalStaffHoursForComparison != null
                        ? getComparisonFigures(
                              totalSales,
                              manHr,
                              totalSalesForComparison,
                              totalSalesForComparison /
                                  (totalStaffHoursForComparison ?? 0)
                          )
                        : { salesChange: undefined, manHourChange: undefined };

                return {
                    key: rowName,
                    segment: rowName,
                    id,
                    sales: {
                        total: totalSales,
                        change: salesChange,
                    },
                    staffHours: {
                        total: manHr,
                        change: manHourChange,
                    },
                };
            });
    }, [
        mappingRules,
        totalSalesAndStaffHoursByMappingRule,
        segment,
        averageSalesAndStaffHoursByMappingRule,
        totalSalesAndStaffHoursByMappingRuleForComparison,
        forecastSalesByMappingRules,
    ]);

    const onRowClick = (record: SegmentTableRowRecord) => () => {
        const { segment: segmentName, id } = record;
        if (segmentName.includes("Unmapped")) {
            return;
        }

        if (segment === "Area") {
            if (
                !selectedAreas ||
                selectedAreas.length === 0 ||
                !selectedAreas.includes(id)
            ) {
                updateAreasFilter([...(selectedAreas ?? []), id]);
            } else {
                updateAreasFilter(
                    selectedAreas.filter((selectedArea) => selectedArea !== id)
                );
            }
        } else if (segment === "Venue") {
            if (
                !selectedVenues ||
                selectedVenues.length === 0 ||
                !selectedVenues.includes(id)
            ) {
                updateVenuesFilter([...(selectedVenues ?? []), id]);
            } else {
                updateVenuesFilter(
                    selectedVenues.filter((selectedArea) => selectedArea !== id)
                );
            }
        } else {
            if (
                !selectedClasses ||
                selectedClasses.length === 0 ||
                !selectedClasses.includes(id)
            ) {
                updateClassesFilter([...(selectedClasses ?? []), id]);
            } else {
                updateClassesFilter(
                    selectedClasses.filter((selectedArea) => selectedArea !== id)
                );
            }
        }
    };

    const getRowClassName = (record: SegmentTableRowRecord) => {
        const { id: uncastedId } = record;

        return selectedAreas?.includes(uncastedId) ||
            selectedVenues?.includes(uncastedId) ||
            selectedClasses?.includes(uncastedId)
            ? "selected-row"
            : "";
    };

    return {
        columns,
        dataSource,
        getRowClassName,
        onRowClick,
        isLoading,
    };
};
