import { GroupDataInstance } from "Api/GroupDataManager";
import { GroupData } from "Api/backend";
import { useEffect, useState } from "react";

export const useGroupData = () => {
    const [groupData, setGroupData] = useState<GroupData | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    const fetchData = () => {
        setIsLoading(true);
        GroupDataInstance.fetchGroupData()
            .catch((err) => {
                console.error("Error fetching group data:", err);
                setError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const handleDataUpdate = (data) => {
            setGroupData(data);
            setIsLoading(false);
        };

        GroupDataInstance.subscribe(handleDataUpdate);

        // Fetch the data when the component is mounted
        fetchData();

        // Unsubscribe when the component is unmounted
        return () => {
            GroupDataInstance.unsubscribe(handleDataUpdate);
        };
    }, []);

    return { groupData, fetchData, isLoading, error };
};
