import { Empty, Spin } from "antd";
import { ApexOptions } from "apexcharts";
import { getGranularityForDateRange } from "Api/Cube/utils";
import {
    useGetSalesAndStaffHoursSeries,
    useGetAverageSalesAndStaffHoursSeries,
} from "Hooks/useGetSalesAndStaffHours";
import React, { useEffect, useState } from "react";
import {
    useDateRangeFilter,
    useAggregateFilter,
    AggregateFilter,
} from "Store/filterStore";
import styled from "styled-components";
import { parseToMoment, DATE_TIME_FORMAT, formatForDisplay } from "Utils/date-utils";
import {
    getSalesOverTimeLineGraphOptions,
    formatTooltipTimestampBasedOnGranularity,
} from "Utils/utils";
import Charts from "./ApexChartHandler";

interface Props {
    height: number;
}

const StyledCharts = styled(Charts)`
    * {
        font-weight: 400 !important;
    }
`;

export const SalesOverTimeLineChart: React.FC<Props> = ({ height }) => {
    const {
        selectedDateRange: { start, end },
    } = useDateRangeFilter();
    const { selectedAggregate } = useAggregateFilter();

    const averageAggregateSelected =
        selectedAggregate === AggregateFilter.AverageWeek ||
        selectedAggregate === AggregateFilter.AverageDay;

    const shiftStart = parseToMoment(start).add(6, "hours").format(DATE_TIME_FORMAT);
    const shiftEnd = parseToMoment(end)
        .add(1, "day")
        .add(5, "hours")
        .add(45, "minutes")
        .format(DATE_TIME_FORMAT);

    const range = {
        start: shiftStart,
        end: shiftEnd,
    };

    const { isLoading: loadingTotalSeries, saleAndStaffHoursSeries } =
        useGetSalesAndStaffHoursSeries({
            range,
            enabled: !averageAggregateSelected,
        });

    const { isLoading: loadingAverageSeries, averageSaleAndStaffHoursSeries } =
        useGetAverageSalesAndStaffHoursSeries({
            range: {
                start,
                end,
            },
            enabled: averageAggregateSelected,
            doNotApplyMovingAverage: true,
        });

    const isLoading = loadingTotalSeries || loadingAverageSeries;

    const [chartData, setChartData] = useState<{
        options: ApexOptions;
        series: any[];
    }>({ options: {}, series: [] });

    const data = averageSaleAndStaffHoursSeries ?? saleAndStaffHoursSeries;

    useEffect(() => {
        const series = data?.length
            ? data.map((d) => {
                  const isTransactionalSeries = d.key.includes("transactionTotal");
                  return {
                      name: isTransactionalSeries ? "Sales" : "Active Staff",
                      type: isTransactionalSeries ? "line" : "column",
                      data: d.series.map((d) => d.value),
                  };
              })
            : [];

        const granularity = getGranularityForDateRange({
            start: shiftStart,
            end: shiftEnd,
        });

        const options =
            data && data.length
                ? getSalesOverTimeLineGraphOptions(
                      data[0].series.map((d) => d.x),
                      granularity,
                      end
                  )
                : {};

        if (data?.length && averageAggregateSelected) {
            const displayFormat =
                selectedAggregate === AggregateFilter.AverageWeek
                    ? "ddd, HH:mm"
                    : "HH:mm";

            const lineThickness =
                selectedAggregate === AggregateFilter.AverageWeek
                    ? [3, 3, 3]
                    : [5, 5, 3];

            options.xaxis = {
                type: "datetime",
                categories: data[0].series.map((d) => d.x),
                labels: {
                    datetimeUTC: false,
                    formatter: (val) => formatForDisplay(val, displayFormat),
                },
            };
            options.tooltip = {
                x: {
                    formatter: (val) => {
                        return formatTooltipTimestampBasedOnGranularity(
                            selectedAggregate === AggregateFilter.AverageWeek
                                ? "hour"
                                : "minute",
                            val,
                            true,
                            end
                        );
                    },
                },
            };
            options.stroke = {
                ...options.stroke,
                width: lineThickness,
            };
        }

        setChartData({
            options,
            series,
        });
    }, [
        data,
        averageAggregateSelected,
        selectedAggregate,
        start,
        end,
        shiftStart,
        shiftEnd,
    ]);

    const ChartWrapper = () => {
        if (chartData.series.length === 0) return <Empty />;
        return <StyledCharts {...chartData} height={height} type={"line"} />;
    };

    return (
        <div style={{ height }}>
            <Spin style={{ height }} spinning={isLoading}>
                {!isLoading && <ChartWrapper />}
            </Spin>
        </div>
    );
};
