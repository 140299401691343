import useEmblaCarousel from "embla-carousel-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NextButton, PrevButton } from "./Buttons";
import "./Styles/carousel.css";
import "./Styles/base.css";
import "./Styles/reset.css";
import "./Styles/header.css";
import "./Styles/footer.css";
import { Slide } from "./MobileCarouselSlide";
import { useForecastingSlides } from "./ForecastPreviewSlides";
import { Switch, Col, Collapse, Row, Select, Typography } from "antd";
import { chain, sumBy } from "lodash";
import styled from "styled-components";
import { toQTCDate } from "Utils/date-utils";
import { ForecastPreviewFilter } from "../ForecastPreview";
import { ForecastSegmentTable } from "../ForecastSegmentTable";
import Title from "antd/lib/typography/Title";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { ForecastChartMobile } from "../ForecastChartMobile";
import { ForecastEventMobile } from "../ForecastEventMobile";

const { Option } = Select;

const StyledCollapsedPanel = styled(Collapse.Panel)`
    .ant-collapse-content-box {
        overflow: auto;
    }
`;

const ArrayToDayMapping = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

const ForecastMobile = () => {
    const [isWeeklyChecked, setViewChecked] = useState(false);
    const [selectedMappingRuleId, setSelectedMappingRuleId] = useState<string>();
    // TODO LOADING STATE
    const { data: mappingRules, isLoading } = useListMappingRules();
    const venuesMappingRule = useMemo(
        () => mappingRules?.filter(({ segment }) => segment === "venue") ?? [],
        [mappingRules]
    );

    const currentScreen = useMemo(() => {
        if (isWeeklyChecked) {
            return <WeeklyView />;
        }
        return <DailyView />;
    }, [isWeeklyChecked]);

    return (
        <div
            style={{
                display: "flex",
                gap: 12,
                flexDirection: "column",
                paddingBottom: 120,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    padding: "12px 4px",
                }}
            >
                <Row gutter={[16, 0]} align="middle">
                    <Col>
                        <Typography.Title level={5} style={{ marginBottom: 0 }}>
                            Venue
                        </Typography.Title>
                    </Col>
                    <Col>
                        <Select
                            onChange={(mappingRuleId) =>
                                setSelectedMappingRuleId(mappingRuleId)
                            }
                            value={selectedMappingRuleId}
                            // TODO Check to see if there is better resize method
                            style={{ width: 160 }}
                        >
                            {venuesMappingRule.map(({ id, segmentName }) => (
                                <Option key={id} value={id}>
                                    {segmentName}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                    <p>{isWeeklyChecked ? "Weekly View" : "Daily View"}</p>
                    <Switch
                        checked={isWeeklyChecked}
                        onChange={(checked) => setViewChecked(checked)}
                    />
                </div>
            </div>
            {currentScreen}
        </div>
    );
};

const WeeklyView = () => {
    const [filters, setFilters] = useState<ForecastPreviewFilter>({
        selectedAreas: [],
        selectedClasses: [],
        selectedDays: [], // TODO REMOVE THIS AS THIS IS USELESS FOR THE DAILY VIEW
    });

    const { weekData } = useForecastingSlides({ filters });

    const dataGroupedByArea = useMemo(
        () =>
            chain(weekData)
                .groupBy("mapped_area")
                .map((data, area) => {
                    const filteredData = data.filter(
                        ({ relative_timestamp, mapped_class }) => {
                            return (
                                (filters.selectedDays.length === 0 ||
                                    filters.selectedDays.includes(
                                        toQTCDate(relative_timestamp).format("dddd")
                                    )) &&
                                (filters.selectedClasses.length === 0 ||
                                    filters.selectedClasses.includes(mapped_class))
                            );
                        }
                    );
                    return {
                        name: area,
                        base: sumBy(filteredData, "base"),
                        forecast: sumBy(filteredData, "forecast"),
                    };
                })
                .filter(({ name }) => Boolean(name))
                .value(),
        [filters.selectedAreas, weekData]
    );

    const dataGroupedByClass = useMemo(
        () =>
            chain(weekData)
                .groupBy("mapped_class")
                .map((data, className) => {
                    const filteredData = data.filter(
                        ({ relative_timestamp, mapped_area }) => {
                            return (
                                (filters.selectedDays.length === 0 ||
                                    filters.selectedDays.includes(
                                        toQTCDate(relative_timestamp).format("dddd")
                                    )) &&
                                (filters.selectedAreas.length === 0 ||
                                    filters.selectedAreas.includes(mapped_area))
                            );
                        }
                    );
                    return {
                        name: className,
                        base: sumBy(filteredData, "base"),
                        forecast: sumBy(filteredData, "forecast"),
                    };
                })
                .filter(({ name }) => Boolean(name))
                .value(),
        [weekData, filters.selectedClasses]
    );

    const onRowClick = (key: keyof ForecastPreviewFilter, recordName: string) => {
        if (filters[key].includes(recordName)) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: prevFilters[key].filter(
                    (selectedRecord) => selectedRecord !== recordName
                ),
            }));
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: [...prevFilters[key], recordName],
            }));
        }
    };

    return (
        <div style={{ display: "flex", gap: 12, flexDirection: "column" }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    padding: 12,
                }}
            >
                <h1 style={{ textAlign: "center" }}>{"Week View"}</h1>
                <ForecastChartMobile baselineData={weekData} loading={false} />
            </div>

            <Collapse
                ghost
                style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                }}
                destroyInactivePanel
                defaultActiveKey={["1"]}
            >
                <StyledCollapsedPanel
                    header={
                        <Title level={3} style={{ margin: "0px" }}>
                            {"Areas"}
                        </Title>
                    }
                    key={"1"}
                    showArrow={false}
                >
                    <ForecastSegmentTable
                        onRowClick={({ name }) =>
                            () => {
                                onRowClick("selectedAreas", name);
                            }}
                        records={dataGroupedByArea}
                        selectedRecords={filters.selectedAreas}
                    />
                </StyledCollapsedPanel>
            </Collapse>
            <Collapse
                ghost
                style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                }}
                destroyInactivePanel
                defaultActiveKey={["1"]}
            >
                <StyledCollapsedPanel
                    header={
                        <Title level={3} style={{ margin: "0px" }}>
                            {"Classes"}
                        </Title>
                    }
                    key={"1"}
                    showArrow={false}
                >
                    <ForecastSegmentTable
                        onRowClick={({ name }) =>
                            () => {
                                onRowClick("selectedClasses", name);
                            }}
                        records={dataGroupedByClass}
                        selectedRecords={filters.selectedClasses}
                    />
                </StyledCollapsedPanel>
            </Collapse>

            <Collapse
                ghost
                style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                }}
                destroyInactivePanel
                defaultActiveKey={["1"]}
            >
                <StyledCollapsedPanel
                    header={
                        <Title level={3} style={{ margin: "0px" }}>
                            {"Events"}
                        </Title>
                    }
                    key={"1"}
                    showArrow={false}
                >
                    <ForecastEventMobile />
                </StyledCollapsedPanel>
            </Collapse>
        </div>
    );
};

const DailyView = () => {
    const [filters, setFilters] = useState<ForecastPreviewFilter>({
        selectedAreas: [],
        selectedClasses: [],
        selectedDays: [], // TODO REMOVE THIS AS THIS IS USELESS FOR THE DAILY VIEW
    });
    const [slidesInView, setSlidesInView] = useState<number[]>([]);
    const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
        if (!embla) return;

        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);

    const { dataGroupedByWeekDay, dataGroupedByWeekDayWithoutFilters } =
        useForecastingSlides({ filters });

    const findSlidesInView = useCallback(() => {
        if (!embla) return;

        setSlidesInView((slidesInView) => {
            if (slidesInView.length === embla.slideNodes().length) {
                embla.off("select", findSlidesInView);
            }
            const inView = embla
                .slidesInView(true)
                .filter((index) => slidesInView.indexOf(index) === -1);
            return inView;
        });
    }, [embla, setSlidesInView]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        findSlidesInView();
        embla.on("select", onSelect);
        embla.on("select", findSlidesInView);
    }, [embla, onSelect, findSlidesInView]);

    const currentData = useMemo(() => {
        return dataGroupedByWeekDayWithoutFilters[
            ArrayToDayMapping[slidesInView[0]]
        ];
    }, [slidesInView]);

    const dataGroupedByArea = useMemo(
        () =>
            chain(currentData)
                .groupBy("mapped_area")
                .map((data, area) => {
                    const filteredData = data.filter(
                        ({ relative_timestamp, mapped_class }) => {
                            return (
                                (filters.selectedDays.length === 0 ||
                                    filters.selectedDays.includes(
                                        toQTCDate(relative_timestamp).format("dddd")
                                    )) &&
                                (filters.selectedClasses.length === 0 ||
                                    filters.selectedClasses.includes(mapped_class))
                            );
                        }
                    );
                    return {
                        name: area,
                        base: sumBy(filteredData, "base"),
                        forecast: sumBy(filteredData, "forecast"),
                    };
                })
                .filter(({ name }) => Boolean(name))
                .value(),
        [filters.selectedAreas, currentData]
    );

    const dataGroupedByClass = useMemo(
        () =>
            chain(currentData)
                .groupBy("mapped_class")
                .map((data, className) => {
                    const filteredData = data.filter(
                        ({ relative_timestamp, mapped_area }) => {
                            return (
                                (filters.selectedDays.length === 0 ||
                                    filters.selectedDays.includes(
                                        toQTCDate(relative_timestamp).format("dddd")
                                    )) &&
                                (filters.selectedAreas.length === 0 ||
                                    filters.selectedAreas.includes(mapped_area))
                            );
                        }
                    );
                    return {
                        name: className,
                        base: sumBy(filteredData, "base"),
                        forecast: sumBy(filteredData, "forecast"),
                    };
                })
                .filter(({ name }) => Boolean(name))
                .value(),
        [currentData, filters.selectedClasses]
    );

    const onRowClick = (key: keyof ForecastPreviewFilter, recordName: string) => {
        if (filters[key].includes(recordName)) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: prevFilters[key].filter(
                    (selectedRecord) => selectedRecord !== recordName
                ),
            }));
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: [...prevFilters[key], recordName],
            }));
        }
    };

    return (
        <div style={{ display: "flex", gap: 12, flexDirection: "column" }}>
            <div className="embla">
                <div className="embla__viewport" ref={viewportRef}>
                    <div className="embla__container">
                        {Object.keys(dataGroupedByWeekDay).map(
                            (currentDay, index) => (
                                <Slide
                                    key={index}
                                    component={
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <h1 style={{ textAlign: "center" }}>
                                                {currentDay}
                                            </h1>
                                            <ForecastChartMobile
                                                baselineData={
                                                    dataGroupedByWeekDay[currentDay]
                                                }
                                                loading={false}
                                                isDaily
                                            />
                                        </div>
                                    }
                                />
                            )
                        )}
                    </div>
                </div>
                <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
            </div>
            <Collapse
                ghost
                style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                }}
                destroyInactivePanel
                defaultActiveKey={["1"]}
            >
                <StyledCollapsedPanel
                    header={
                        <Title level={3} style={{ margin: "0px" }}>
                            {"Areas"}
                        </Title>
                    }
                    key={"1"}
                    showArrow={false}
                >
                    <ForecastSegmentTable
                        onRowClick={({ name }) =>
                            () => {
                                onRowClick("selectedAreas", name);
                            }}
                        records={dataGroupedByArea}
                        selectedRecords={filters.selectedAreas}
                    />
                </StyledCollapsedPanel>
            </Collapse>
            <Collapse
                ghost
                style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                }}
                destroyInactivePanel
                defaultActiveKey={["1"]}
            >
                <StyledCollapsedPanel
                    header={
                        <Title level={3} style={{ margin: "0px" }}>
                            {"Classes"}
                        </Title>
                    }
                    key={"1"}
                    showArrow={false}
                >
                    <ForecastSegmentTable
                        onRowClick={({ name }) =>
                            () => {
                                onRowClick("selectedClasses", name);
                            }}
                        records={dataGroupedByClass}
                        selectedRecords={filters.selectedClasses}
                    />
                </StyledCollapsedPanel>
            </Collapse>

            <Collapse
                ghost
                style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                }}
                destroyInactivePanel
                defaultActiveKey={["1"]}
            >
                <StyledCollapsedPanel
                    header={
                        <Title level={3} style={{ margin: "0px" }}>
                            {"Events"}
                        </Title>
                    }
                    key={"1"}
                    showArrow={false}
                >
                    <ForecastEventMobile />
                </StyledCollapsedPanel>
            </Collapse>
        </div>
    );
};

export { ForecastMobile };
