import React, { useState } from "react";
import { Card, Col, Row, Typography } from "antd";
import styled from "styled-components";
import { QUANTACO_DARK_BLUE } from "Utils/constants";
import { ForecastChart } from "./ForecastChart";
import { baseVsForecast } from "../mock-data";
import { ForecastSegmentTable } from "./ForecastSegmentTable";
import { chain, sumBy } from "lodash";
import { toQTCDate } from "Utils/date-utils";
import { QTCSpace } from "styles/QTCSpace";

export interface ForecastPreviewFilter {
    selectedAreas: string[];
    selectedClasses: string[];
    selectedDays: string[];
}

export const ForecastPreview = () => {
    const [filters, setFilters] = useState<ForecastPreviewFilter>({
        selectedAreas: [],
        selectedClasses: [],
        selectedDays: [],
    });
    const dataGroupedByWeekDay = chain(baseVsForecast)
        .groupBy(({ relative_timestamp }) =>
            toQTCDate(relative_timestamp).format("dddd")
        )
        .map((data, weekDay) => {
            const filteredData = data.filter(({ mapped_area, mapped_class }) => {
                return (
                    (filters.selectedAreas.length === 0 ||
                        filters.selectedAreas.includes(mapped_area)) &&
                    (filters.selectedClasses.length === 0 ||
                        filters.selectedClasses.includes(mapped_class))
                );
            });

            return {
                name: weekDay,
                base: sumBy(filteredData, "base"),
                forecast: sumBy(filteredData, "forecast"),
            };
        })
        .value();

    const dataGroupedByArea = chain(baseVsForecast)
        .groupBy("mapped_area")
        .map((data, area) => {
            const filteredData = data.filter(
                ({ relative_timestamp, mapped_class }) => {
                    return (
                        (filters.selectedDays.length === 0 ||
                            filters.selectedDays.includes(
                                toQTCDate(relative_timestamp).format("dddd")
                            )) &&
                        (filters.selectedClasses.length === 0 ||
                            filters.selectedClasses.includes(mapped_class))
                    );
                }
            );
            return {
                name: area,
                base: sumBy(filteredData, "base"),
                forecast: sumBy(filteredData, "forecast"),
            };
        })
        .filter(({ name }) => Boolean(name))
        .value();

    const dataGroupedByClass = chain(baseVsForecast)
        .groupBy("mapped_class")
        .map((data, className) => {
            const filteredData = data.filter(
                ({ relative_timestamp, mapped_area }) => {
                    return (
                        (filters.selectedDays.length === 0 ||
                            filters.selectedDays.includes(
                                toQTCDate(relative_timestamp).format("dddd")
                            )) &&
                        (filters.selectedAreas.length === 0 ||
                            filters.selectedAreas.includes(mapped_area))
                    );
                }
            );
            return {
                name: className,
                base: sumBy(filteredData, "base"),
                forecast: sumBy(filteredData, "forecast"),
            };
        })
        .filter(({ name }) => Boolean(name))
        .value();

    const onRowClick = (key: keyof ForecastPreviewFilter, recordName: string) => {
        if (filters[key].includes(recordName)) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: prevFilters[key].filter(
                    (selectedRecord) => selectedRecord !== recordName
                ),
            }));
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: [...prevFilters[key], recordName],
            }));
        }
    };
    return (
        <>
            <QTCSpace $fluid direction="vertical" size="large">
                <Col span={24}>
                    <RoundedCard>
                        <Typography.Title
                            level={4}
                            style={{ color: QUANTACO_DARK_BLUE }}
                        >
                            Baseline & Forecast
                        </Typography.Title>
                        <ForecastChart
                            baselineData={baseVsForecast.filter(
                                ({
                                    mapped_area,
                                    mapped_class,
                                    relative_timestamp,
                                }) => {
                                    return (
                                        (filters.selectedDays.length === 0 ||
                                            filters.selectedDays.includes(
                                                toQTCDate(relative_timestamp).format(
                                                    "dddd"
                                                )
                                            )) &&
                                        (filters.selectedClasses.length === 0 ||
                                            filters.selectedClasses.includes(
                                                mapped_class
                                            )) &&
                                        (filters.selectedAreas.length === 0 ||
                                            filters.selectedAreas.includes(
                                                mapped_area
                                            ))
                                    );
                                }
                            )}
                            loading={false}
                        />
                    </RoundedCard>
                </Col>
                <Col span={24}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <RoundedCard bodyStyle={{ padding: 10, height: 490 }}>
                                <SegmentTableTitle level={4}>Days</SegmentTableTitle>
                                <ForecastSegmentTable
                                    records={dataGroupedByWeekDay}
                                    selectedRecords={filters.selectedDays}
                                    onRowClick={({ name }) =>
                                        () => {
                                            onRowClick("selectedDays", name);
                                        }}
                                />
                            </RoundedCard>
                        </Col>
                        <Col span={8}>
                            <RoundedCard bodyStyle={{ padding: 10, height: 490 }}>
                                <SegmentTableTitle level={4}>
                                    Areas
                                </SegmentTableTitle>
                                <ForecastSegmentTable
                                    onRowClick={({ name }) =>
                                        () => {
                                            onRowClick("selectedAreas", name);
                                        }}
                                    records={dataGroupedByArea}
                                    selectedRecords={filters.selectedAreas}
                                />
                            </RoundedCard>
                        </Col>
                        <Col span={8}>
                            <RoundedCard bodyStyle={{ padding: 10, height: 490 }}>
                                <SegmentTableTitle level={4}>
                                    Classes
                                </SegmentTableTitle>
                                <ForecastSegmentTable
                                    onRowClick={({ name }) =>
                                        () => {
                                            onRowClick("selectedClasses", name);
                                        }}
                                    records={dataGroupedByClass}
                                    selectedRecords={filters.selectedClasses}
                                />
                            </RoundedCard>
                        </Col>
                    </Row>
                </Col>
            </QTCSpace>
        </>
    );
};

const RoundedCard = styled(Card)`
    border-radius: 15px;
    border: 1px solid #d9d9d9;
`;

const SegmentTableTitle = styled(Typography.Title)`
    color: ${QUANTACO_DARK_BLUE} !important;
    padding-left: 5px;
    padding-top: 5px;
`;
