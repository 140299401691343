import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BaselineRawRow } from "Components/ForecastingChartsDataManager/BaselineChartForAutogen";

interface BaselineDataState {
    baseline?: BaselineRawRow[];
}

const initialState = { baseline: undefined } as BaselineDataState;

const currentForecastSlice = createSlice({
    name: "currentForecastSlice",
    initialState,
    reducers: {
        clearBaseline(state) {
            state.baseline = undefined;
        },
        setCurrentBaseline(state, action: PayloadAction<BaselineDataState>) {
            state.baseline = action.payload.baseline;
        },
    },
});

export const { clearBaseline, setCurrentBaseline } = currentForecastSlice.actions;
export default currentForecastSlice.reducer;
