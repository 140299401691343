import { Button, Drawer, Row, Checkbox } from "antd";
import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { useSpring, animated } from "react-spring";
import { ArrowRightOutlined } from "@ant-design/icons";
import "./checkboxWithDrawers.scss";
import styled from "styled-components";
import { drawerType } from "../SideNavigation/CheckboxManager";
import { SideNavFilterTitle } from "../SideNavigation/SideNavFilters";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface Props {
    options: string[];
    dispatchAction: (selectedAreas: string[]) => void;
    selectedOptions: string[];
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
    drawerType: drawerType;
    title: string;
}

export const ColumnCheckbox = styled(Checkbox.Group)`
    .ant-checkbox-group-item {
        display: block !important;
        margin-right: 0 !important;
    }
`;

export default function CheckboxWithDrawers({
    options,
    dispatchAction,
    selectedOptions,
    openDrawerState: currentlyOpenDrawer,
    setOpenDrawerState: setCurrentlyOpenDrawer,
    drawerType,
    title,
}: Props): ReactElement {
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const selectAllText = `Select All (${
        selectedOptions.length !== 0 ? selectedOptions.length : options.length
    } out of ${options.length})`;

    const filteredList = options.slice(0, 3);

    const onChange = (e: CheckboxChangeEvent) => {
        const item = e.target.value;
        let length;
        if (selectedOptions.find((checked) => checked === item)) {
            dispatchAction(selectedOptions.filter((checked) => checked !== item));
            length = selectedOptions.length - 1;
        } else {
            dispatchAction(selectedOptions.concat(item));
            length = selectedOptions.length + 1;
        }
        setIndeterminate(!!length && length < options.length);
        setCheckAll(length === options.length);
    };

    const onCheckAllChange = (e) => {
        dispatchAction(e.target.checked ? options : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onOpenDrawer = () => {
        setCurrentlyOpenDrawer(drawerType);
        setIsDrawerVisible(!isDrawerVisible);
    };

    const onCloseDrawer = () => {
        setIsDrawerVisible(false);
    };

    const styles = useSpring({
        left: isDrawerVisible && currentlyOpenDrawer === drawerType ? 300 : 0,
        opacity: isDrawerVisible && currentlyOpenDrawer === drawerType ? 1 : 0,
    });

    const AnimatedDrawer = animated(Drawer);

    useEffect(() => {
        if (currentlyOpenDrawer !== drawerType) {
            setIsDrawerVisible(false);
        }
    }, [currentlyOpenDrawer]);

    useEffect(() => {
        setIndeterminate(
            selectedOptions.length < options.length && selectedOptions.length > 0
        );
        setCheckAll(selectedOptions.length === options.length);
    }, [options.length, selectedOptions.length]);

    return (
        <>
            <Row>
                <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                >
                    {selectAllText}
                </Checkbox>
            </Row>
            {filteredList.map((option, index) => (
                <Row key={index}>
                    <Checkbox
                        onChange={onChange}
                        value={option}
                        checked={
                            selectedOptions.find((checked) => checked === option) !==
                            undefined
                        }
                    >
                        {option}
                    </Checkbox>
                </Row>
            ))}
            {options.length >= 4 && (
                <Row>
                    <Button type={"link"} onClick={onOpenDrawer}>
                        More <ArrowRightOutlined />
                    </Button>
                </Row>
            )}
            <AnimatedDrawer
                style={styles}
                title={<SideNavFilterTitle level={5}>{title}</SideNavFilterTitle>}
                placement={"left"}
                closable={true}
                onClose={onCloseDrawer}
                visible={isDrawerVisible && currentlyOpenDrawer === drawerType}
                key={"left"}
            >
                <Row>
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                    >
                        {selectAllText}
                    </Checkbox>
                </Row>
                {options.map((option, index) => (
                    <Row key={index}>
                        <Checkbox
                            onChange={onChange}
                            value={option}
                            checked={
                                selectedOptions.find(
                                    (checked) => checked === option
                                ) !== undefined
                            }
                        >
                            {option}
                        </Checkbox>
                    </Row>
                ))}
            </AnimatedDrawer>
        </>
    );
}
