import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { sumBy } from "lodash";
import React, { CSSProperties, useMemo } from "react";
import { QUANTACO_DARK_BLUE } from "Utils/constants";
import { currencyFormatter, stringSort } from "Utils/utils";

interface ForecastSegmentTableRecord {
    name: string;
    base: number;
    forecast?: number;
}

interface Props {
    records: ForecastSegmentTableRecord[];
    onRowClick: (record: ForecastSegmentTableRecord) => () => void;
    selectedRecords: string[];
}

const onHeaderCell = (): { style: CSSProperties } => ({
    style: {
        color: QUANTACO_DARK_BLUE,
        fontWeight: "bold",
    },
});

export const ForecastSegmentTable: React.FC<Props> = ({
    records,
    onRowClick,
    selectedRecords,
}) => {
    const columns = useMemo(() => {
        const result: ColumnsType<ForecastSegmentTableRecord> = [
            {
                key: "name",
                title: `Name`,
                dataIndex: "name",
                className: "segment-table-column",
                sortDirections: ["descend", "ascend"],
                onHeaderCell,
                sorter: (
                    record: ForecastSegmentTableRecord,
                    secondRecord: ForecastSegmentTableRecord
                ) => stringSort(record.name, secondRecord.name),
            },
            {
                key: "base",
                title: `Base`,
                dataIndex: "base",
                className: "segment-table-column",
                render: (val) => currencyFormatter(val, 1),
                sortDirections: ["descend", "ascend"],
                onHeaderCell,
                sorter: (
                    record: ForecastSegmentTableRecord,
                    secondRecord: ForecastSegmentTableRecord
                ) => (record.base > secondRecord.base ? -1 : 1),
            },
        ];

        if (records.some(({ forecast }) => forecast)) {
            result.push({
                key: "forecast",
                title: `Forecast`,
                dataIndex: "forecast",
                className: "segment-table-column",
                render: (val) => currencyFormatter(val, 1),
                sortDirections: ["descend", "ascend"],
                onHeaderCell,
                sorter: (
                    record: ForecastSegmentTableRecord,
                    secondRecord: ForecastSegmentTableRecord
                ) =>
                    (record.forecast ?? 0) > (secondRecord.forecast ?? 0) ? -1 : 1,
            });
        }
        return result;
    }, [records]);

    const getRowClassName = ({ name }: ForecastSegmentTableRecord) => {
        return selectedRecords.includes(name) ? "selected-row" : "";
    };
    const renderSummaryRow = (data: readonly ForecastSegmentTableRecord[]) => {
        const totalForecast = sumBy(data, "forecast");
        const totalBase = sumBy(data, "base");
        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                    <Typography.Text strong>Totals</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                    <Typography.Text strong>
                        {currencyFormatter(totalBase)}
                    </Typography.Text>
                </Table.Summary.Cell>
                {totalForecast && (
                    <Table.Summary.Cell index={2}>
                        <Typography.Text strong>
                            {currencyFormatter(totalForecast)}
                        </Typography.Text>
                    </Table.Summary.Cell>
                )}
            </Table.Summary.Row>
        );
    };

    return (
        <Table
            columns={columns}
            className={"segment-table"}
            dataSource={records}
            pagination={{ pageSize: 7, size: "small", hideOnSinglePage: true }}
            tableLayout={"auto"}
            showSorterTooltip={false}
            size={"small"}
            onRow={(record: ForecastSegmentTableRecord) => ({
                onClick: onRowClick(record),
            })}
            rowClassName={getRowClassName}
            summary={renderSummaryRow}
        />
    );
};
