import List from "antd-mobile/es/components/list";
import CascadePicker, {
    CascadePickerOption,
} from "antd-mobile/es/components/cascade-picker";
import { ListItem } from "antd-mobile/es/components/list/list-item";

import React, { ReactElement, useEffect, useState } from "react";
import { View } from "./index";
import styled from "styled-components";
interface Props {
    className?: string;
    views: View[];
    onSelectView;
    selectedViewId: number | undefined;
    defaultViewId: number | null;
}

const StyledCascadePicker = styled(CascadePicker)`
    .adm-picker-header {
        user-select: contain !important;
    }
`;

const StyledNoSelectedDivTag = styled.div`
    user-select: none;
`;

const StyledListItem = styled(ListItem)`
    .adm-list-item-content-extra {
        user-select: none !important;
    }
    .adm-list-item-content-main {
        user-select: none !important;
    }
`;

export default function ViewSelectMobile({
    className,
    views,
    onSelectView,
    selectedViewId,
    defaultViewId,
}: Props): ReactElement {
    const [pickerData, setPickerData] = useState<CascadePickerOption[]>([]);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setPickerData(
            views.map((view) => ({
                value: view.view_id.toString(),
                label:
                    view.view_id === defaultViewId
                        ? `${view.view_label} (default)`
                        : view.view_label,
            }))
        );
    }, [views]);

    const onChange = (value) => {
        const viewId = value[0];
        const view: View = views.find((view) => view.view_id === parseInt(viewId))!;
        if (view === undefined) {
            //apply no filters if view not detected
            setIsVisible(false);
            return;
        }
        onSelectView(view);
        setIsVisible(false);
    };

    return (
        <>
            <List style={{ width: "100%" }}>
                <StyledListItem
                    onClick={() => setIsVisible(true)}
                    arrow
                    extra="Select"
                >
                    View Select
                </StyledListItem>
            </List>
            <StyledCascadePicker
                confirmText={
                    <StyledNoSelectedDivTag>{"Confirm"}</StyledNoSelectedDivTag>
                }
                cancelText={
                    <StyledNoSelectedDivTag>{"Cancel"}</StyledNoSelectedDivTag>
                }
                className={className}
                onConfirm={(val, extend) => onChange(val)}
                onCancel={() => setIsVisible(false)}
                options={pickerData}
                visible={isVisible}
            />
        </>
    );
}
