import moment from "moment";
import React, { ReactElement, useCallback, useContext, useState } from "react";
import { Button, DatePicker, Tag } from "antd";
import { Context, Filters, SelectedDates } from "../../State/store";
import styled from "styled-components";
import { UpdateFiltersAction } from "../../State/actions";
import { formatForDisplay } from "Utils/date-utils";
import { Nullable } from "types";
import { useDateRangeFilter } from "Store/filterStore";
import { usePreviewMode } from "Store/viewSettingsStore";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./index.css";

const { RangePicker } = DatePicker;

export const FullWidthDatePicker = styled(RangePicker)`
    width: auto;
`;

const DatePickerList = styled.ul`
    margin-bottom: 0;
    padding: 4px 12px;
    overflow: hidden;
    line-height: 34px;
    text-align: left;
    list-style: none;
`;

const DatePickerListItem = styled.li`
    display: inline-block;
`;

const DatePickerTag = styled(Tag)`
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    cursor: pointer;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const convertSelectedDatesIntoMoment = (
    selectedDates: SelectedDates
): [moment.Moment, moment.Moment] => {
    if (selectedDates.isPresetDateRange) {
        const selectedRange = presetRanges.find(
            (range) => range.label === selectedDates.presetDateRangeLabel
        )!;

        return selectedRange.range();
    }

    return [moment(selectedDates.fromDate), moment(selectedDates.toDate)];
};

interface PresetRange {
    label: string;
    range: () => [moment.Moment, moment.Moment];
    changeUnit?: { unit: string; period?: string };
}

export const presetRanges: PresetRange[] = [
    {
        label: "Today",
        range: () => [moment(), moment()],
        changeUnit: { unit: "days" },
    },
    {
        label: "Yesterday",
        range: () => [moment().subtract(1, "d"), moment().subtract(1, "d")],
        changeUnit: { unit: "days" },
    },
    {
        label: "This Week",
        range: () => [moment().startOf("isoWeek"), moment()],
        changeUnit: { unit: "w", period: "isoWeek" },
    },
    {
        label: "Last Week",
        range: () => [
            moment().subtract(1, "w").startOf("isoWeek"),
            moment().subtract(1, "w").endOf("isoWeek"),
        ],
        changeUnit: { unit: "w", period: "isoWeek" },
    },
    {
        label: "This Month",
        range: () => [moment().startOf("month"), moment()],
        changeUnit: { unit: "month", period: "month" },
    },
    {
        label: "Last Month",
        range: () => [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
        ],
        changeUnit: { unit: "month", period: "month" },
    },
    {
        label: "This Year",
        range: () => [moment().startOf("year"), moment()],
        changeUnit: { unit: "year", period: "year" },
    },
    {
        label: "Last Year",
        range: () => [
            moment().subtract(1, "y").startOf("year"),
            moment().subtract(1, "y").endOf("year"),
        ],
        changeUnit: { unit: "year", period: "year" },
    },
    {
        label: "Forever",
        range: () => [moment("2018-01-01"), moment()],
    },
];
const initialPresetValue = {
    isPresetOn: false,
    label: "",
};

// ! This being kept here for when we re-enable react-dates on Desktop view

// const StyledReactDatesWrapper = styled.div`
//     .DateRangePickerInput,
//     .DateRangePicker {
//         .DateInput {
//             width: 108px !important;
//         }
//         .DateInput_input {
//             font-size: 14px !important;
//             line-height: 12px !important;
//         }
//         .DateInput_input__focused {
//             border-bottom: 2px solid ${WRANGLR_LIGHT_BLUE} !important;
//         }
//     }
// `;

export default function DateFilter(): ReactElement {
    const [state, dispatch] = useContext(Context);
    const { filters } = state;
    const { selectedDates }: Filters = filters;
    const { previewMode } = usePreviewMode();

    const [currentMode, setCurrentMode] = useState<any>(["date", "date"]);

    const [open, setOpen] = useState(false);

    const [presetRangesDetails, setPresetRangesDetails] =
        useState<{ isPresetOn: boolean; label: string }>(initialPresetValue);

    const { selectedComparison, mode } = filters;
    const { selectedDateRange, updateDateRangeFilter } = useDateRangeFilter();

    const disabledDate = (current) => {
        return (
            current &&
            (current > moment().endOf("day") || current < moment("2018-01-01"))
        );
    };

    const onPanelChange = (value, mode) => {
        if (mode[0] === "decade") {
            mode[0] = "year";
        }

        setCurrentMode(mode);
    };

    const onChange = (dates: Nullable<[moment.Moment, moment.Moment]>) => {
        const today = formatForDisplay(undefined, "YYYY-MM-DD");

        const fromDate = dates
            ? formatForDisplay(dates[0].toString(), "YYYY-MM-DD")
            : today;
        const toDate = dates
            ? formatForDisplay(dates[1].toString(), "YYYY-MM-DD")
            : today;

        const action: UpdateFiltersAction = {
            type: "UPDATE_FILTERS",
            payload: {
                filters: {
                    ...state.filters,
                    selectedDates: {
                        fromDate,
                        toDate,
                        isPresetDateRange: false,
                    },
                    mode:
                        selectedComparison !== null && mode === "forecast"
                            ? mode
                            : "actual",
                } as Filters,
            },
        };
        dispatch(action);
        setCurrentMode(["date", "date"]);
        updateDateRangeFilter({ start: fromDate, end: toDate });

        setPresetRangesDetails(initialPresetValue);
    };

    const onClickArrow = useCallback(
        (isLeft?: boolean) => {
            const [start, end] = previewMode
                ? [moment(selectedDateRange.start), moment(selectedDateRange.end)]
                : convertSelectedDatesIntoMoment(selectedDates);
            const durationDays = end.diff(start, "days");
            let updatedStart: moment.Moment, updatedEnd: moment.Moment;
            if (isLeft) {
                if (presetRangesDetails.isPresetOn && presetRangesDetails.label) {
                    const selectedRange = presetRanges.find(
                        (range) => range.label === presetRangesDetails.label
                    )!;
                    //@ts-ignore
                    updatedEnd = end
                        .clone()
                        .subtract(1, selectedRange.changeUnit?.unit)
                        //@ts-ignore
                        .endOf(selectedRange.changeUnit?.period);
                    //@ts-ignore
                    updatedStart = start
                        .clone()
                        .subtract(1, selectedRange.changeUnit?.unit)
                        //@ts-ignore
                        .startOf(selectedRange.changeUnit?.period);
                } else {
                    updatedEnd = start.clone().subtract(1, "days");
                    updatedStart = start.clone().subtract(durationDays + 1, "days");
                }
            } else {
                if (presetRangesDetails.isPresetOn && presetRangesDetails.label) {
                    const selectedRange = presetRanges.find(
                        (range) => range.label === presetRangesDetails.label
                    )!;
                    //@ts-ignore
                    updatedEnd = end
                        .clone()
                        .add(1, selectedRange.changeUnit?.unit)
                        //@ts-ignore
                        .endOf(selectedRange.changeUnit?.period);
                    //@ts-ignore
                    updatedStart = start
                        .clone()
                        .add(1, selectedRange.changeUnit?.unit)
                        //@ts-ignore
                        .startOf(selectedRange.changeUnit?.period);
                } else {
                    updatedStart = end.clone().add(1, "days");
                    updatedEnd = end.clone().add(durationDays + 1, "days");
                }
            }
            if (updatedStart < moment("2018-01-01").startOf("day")) {
                updatedStart = moment("2018-01-01");
            }
            if (updatedEnd > moment().endOf("day")) {
                updatedEnd = moment();
            }
            const fromDate = updatedStart.format("YYYY-MM-DD");
            const toDate = updatedEnd.format("YYYY-MM-DD");
            const action: UpdateFiltersAction =
                presetRangesDetails.isPresetOn && presetRangesDetails.label
                    ? {
                          type: "UPDATE_FILTERS",
                          payload: {
                              filters: {
                                  ...state.filters,
                                  selectedDates: {
                                      fromDate,
                                      toDate,
                                      isPresetDateRange: true,
                                      presetDateRangeLabel:
                                          presetRangesDetails.label,
                                  },
                                  mode:
                                      selectedComparison !== null &&
                                      mode === "forecast"
                                          ? mode
                                          : "actual",
                              } as Filters,
                          },
                      }
                    : {
                          type: "UPDATE_FILTERS",
                          payload: {
                              filters: {
                                  ...state.filters,
                                  selectedDates: {
                                      fromDate,
                                      toDate,
                                      isPresetDateRange: false,
                                  },
                                  mode:
                                      selectedComparison !== null &&
                                      mode === "forecast"
                                          ? mode
                                          : "actual",
                              } as Filters,
                          },
                      };
            updateDateRangeFilter({ start: fromDate, end: toDate });
            dispatch(action);
            setOpen(false);
            setCurrentMode(["date", "date"]);
        },
        [
            dispatch,
            mode,
            presetRangesDetails.isPresetOn,
            presetRangesDetails.label,
            previewMode,
            selectedComparison,
            selectedDateRange.end,
            selectedDateRange.start,
            selectedDates,
            state.filters,
            updateDateRangeFilter,
        ]
    );

    const onClickPresetRange = (e) => {
        const target = e.target as HTMLElement;
        const rangeLabel = target.innerText;
        const selectedRange = presetRanges.find(
            (range) => range.label === rangeLabel
        )!;
        const [fromDateObj, toDateObj] = selectedRange.range();
        const fromDate = fromDateObj.format("YYYY-MM-DD");
        const toDate = toDateObj.format("YYYY-MM-DD");
        const action: UpdateFiltersAction = {
            type: "UPDATE_FILTERS",
            payload: {
                filters: {
                    ...state.filters,
                    selectedDates: {
                        fromDate,
                        toDate,
                        isPresetDateRange: true,
                        presetDateRangeLabel: selectedRange.label,
                    },
                    mode:
                        selectedComparison !== null && mode === "forecast"
                            ? mode
                            : "actual",
                } as Filters,
            },
        };
        updateDateRangeFilter({ start: fromDate, end: toDate });
        dispatch(action);
        setOpen(false);
        setPresetRangesDetails({ isPresetOn: true, label: rangeLabel });
        setCurrentMode(["date", "date"]);
    };

    return (
        <StyledDiv>
            <Button
                type="ghost"
                disabled={
                    (previewMode
                        ? [
                              moment(selectedDateRange.start),
                              moment(selectedDateRange.end),
                          ]
                        : convertSelectedDatesIntoMoment(selectedDates))[0] <=
                        moment("2018-01-01") ||
                    (presetRangesDetails.isPresetOn &&
                        presetRangesDetails.label === "Forever")
                }
                className={`custom-button ${
                    (previewMode
                        ? [
                              moment(selectedDateRange.start),
                              moment(selectedDateRange.end),
                          ]
                        : convertSelectedDatesIntoMoment(selectedDates))[0] <=
                    moment("2018-01-01")
                        ? "disabled"
                        : ""
                }`}
                icon={<LeftOutlined />}
                onClick={() => onClickArrow(true)}
            />
            <FullWidthDatePicker
                mode={currentMode}
                disabledDate={disabledDate}
                onPanelChange={onPanelChange}
                onChange={onChange as any}
                open={open}
                onOpenChange={() => setOpen(!open)}
                value={
                    previewMode
                        ? [
                              moment(selectedDateRange.start),
                              moment(selectedDateRange.end),
                          ]
                        : convertSelectedDatesIntoMoment(selectedDates)
                }
                format={["DD-MM-YYYY", "DD-MM-YYYY"]}
                renderExtraFooter={() => DatePickerFooter(onClickPresetRange)}
            />
            <Button
                type="ghost"
                icon={<RightOutlined />}
                disabled={
                    (previewMode
                        ? [
                              moment(selectedDateRange.start),
                              moment(selectedDateRange.end),
                          ]
                        : convertSelectedDatesIntoMoment(selectedDates))[1] >=
                        moment().startOf("day") ||
                    (presetRangesDetails.isPresetOn &&
                        presetRangesDetails.label === "Forever")
                }
                className={`custom-button ${
                    (previewMode
                        ? [
                              moment(selectedDateRange.start),
                              moment(selectedDateRange.end),
                          ]
                        : convertSelectedDatesIntoMoment(selectedDates))[1] >=
                        moment().startOf("day") ||
                    (presetRangesDetails.isPresetOn &&
                        presetRangesDetails.label === "Forever")
                        ? "disabled"
                        : ""
                }`}
                onClick={() => onClickArrow(false)}
            />
        </StyledDiv>
    );
}

function DatePickerFooter(onClickPresetRange: (e: any) => void) {
    return (
        <>
            <DatePickerList>
                {presetRanges.map(({ label }) => (
                    <DatePickerListItem key={label} onClick={onClickPresetRange}>
                        <DatePickerTag>{label}</DatePickerTag>
                    </DatePickerListItem>
                ))}
            </DatePickerList>
        </>
    );
}
