import { MappingRuleResult, MappingSegment } from "Api/mappingConfigurator";
import cloneDeep from "lodash/cloneDeep";
import { JsonGroup } from "react-awesome-query-builder";

export const parseMappingRules = (
    filteredMappingRules: {
        rules: JsonGroup;
        segment: MappingSegment;
        segmentName: string;
        priority?: number | undefined;
        result: MappingRuleResult[];
        exclude?: boolean | undefined;
        key: string;
        id: string;
    }[],
    id: string
): {
    area: {};
    class: {
        class: {};
        area: {};
    };
    venue: {};
} => {
    const matchingRuleSetIndex = filteredMappingRules.findIndex(
        (currentMappingRule) => currentMappingRule.id === id
    );
    const mappingRulesDeepClone = cloneDeep(filteredMappingRules);

    if (matchingRuleSetIndex !== -1)
        mappingRulesDeepClone.splice(matchingRuleSetIndex, 1);

    const integrationSourceMap = {
        area: {},
        class: { class: {}, area: {} },
        venue: {},
    };

    const updateMappingObject = (mappingObject, integrationSource, tag, itemId) => {
        const key = `${integrationSource}_${tag}`;

        if (mappingObject[key]) {
            mappingObject[key].push(itemId);
        } else {
            mappingObject[key] = [itemId];
        }
    };

    const updateIntegrationSourceMap = (
        segment: string,
        integrationSource: string,
        mappingObject: any
    ) => {
        if (integrationSourceMap[segment][integrationSource]) {
            integrationSourceMap[segment][integrationSource].push(
                ...mappingObject[integrationSource]
            );
        } else {
            integrationSourceMap[segment][integrationSource] = [
                ...mappingObject[integrationSource],
            ];
        }
    };

    const processMappingResult = (
        segment: string,
        currentParsedMapping: MappingRuleResult,
        tag: string
    ) => {
        const mappingObject = {};

        currentParsedMapping[`${tag}Ids`]?.forEach((itemId) => {
            updateMappingObject(
                mappingObject,
                currentParsedMapping.integrationSource,
                tag,
                itemId
            );
        });

        if (Object.keys(mappingObject).length > 0) {
            Object.keys(mappingObject).forEach((currentIntegrationSource) => {
                updateIntegrationSourceMap(
                    segment,
                    currentIntegrationSource,
                    mappingObject
                );
            });
        }
    };

    mappingRulesDeepClone.forEach((currentRule) => {
        const { segment, result } = currentRule;

        if (!["venue", "area", "class"].includes(segment)) {
            console.error("Unknown segment type!!!");
            return;
        }

        if (segment === "class") {
            result.forEach((currentParsedMapping) => {
                processMappingResult("class", currentParsedMapping, "area");
                processMappingResult("class", currentParsedMapping, "class");
            });
        } else {
            result.forEach((currentParsedMapping) => {
                processMappingResult(segment, currentParsedMapping, segment);
            });
        }
    });

    return integrationSourceMap;
};
