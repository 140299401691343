import { CubeContext } from "@cubejs-client/react";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { cloneDeep, sum } from "lodash";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { useSegmentsFilter, useDaysFilter, useHoursFilter } from "Store/filterStore";
import { CubeQueryBuilder } from "Utils/cubejs-utils";
import { DateRange } from "Utils/date-utils";
import { formatNumberToFixed } from "Utils/number-utils";
import { CubeName, QueryKey } from "../dashboard-utils";

interface GetForecastSales {
    range: DateRange<string>;
    enabled?: boolean;
}

const dayMapper = {
    Monday: "1",
    Tuesday: "2",
    Wednesday: "3",
    Thursday: "4",
    Friday: "5",
    Saturday: "6",
    Sunday: "7",
};

export const useGetForecastSalesByDates = (params: GetForecastSales) => {
    const {
        range: { start, end },
        enabled = true,
    } = params;

    const { cubejsApi } = useContext(CubeContext);

    const {
        data: mappingRules,
        isLoading: loadingMappingRules,
        mappingRuleById,
        mappingRuleBySegmentName,
    } = useListMappingRules();

    const { selectedAreas, selectedClasses, selectedVenues } = useSegmentsFilter();
    const { selectedDays } = useDaysFilter();
    const { selectedHours } = useHoursFilter();

    const selectedDaysToNum = useMemo(
        () => selectedDays?.map((day) => dayMapper[day]),
        [selectedDays]
    );

    // Create a helper function to pick up when the venueId and mappingRuleById are undefined
    const safeMappingHelper = (venueId: string, mappingRuleById: any) => {
        if (venueId && mappingRuleById && mappingRuleById[venueId]?.segmentName) {
            return mappingRuleById[venueId].segmentName;
        } else {
            return mappingRuleBySegmentName[venueId]?.segmentName;
        }
    };

    const selectedVenueNames = selectedVenues?.map((venueId) =>
        safeMappingHelper(venueId, mappingRuleById)
    );

    const selectedAreaNames = selectedAreas?.map((areaId) =>
        safeMappingHelper(areaId, mappingRuleById)
    );

    const selectedClassNames = selectedClasses?.map((classId) =>
        safeMappingHelper(classId, mappingRuleById)
    );

    const initialSalesByMappingRule = useMemo(
        () =>
            mappingRules?.reduce((result, { segmentName }) => {
                result[segmentName] = 0;
                return result;
            }, {}),
        [mappingRules]
    );

    const query = enabled
        ? CubeQueryBuilder({
              measures: [`${CubeName.forecast}.forecastTransactionTotal`],
              order: { [`${CubeName.forecast}.localisedPeriod`]: "asc" },
              dimensions: ["mappedVenue", "mappedArea", "mappedClass"].map(
                  (dimension) => `${CubeName.forecast}.${dimension}`
              ),
              timeDimensions: [
                  {
                      dimension: `${CubeName.forecast}.localisedPeriod`,
                      dateRange: [start, end],
                      granularity: "year",
                  },
              ],
          })
              .addFilters(
                  [
                      {
                          member: `${CubeName.forecast}.shiftDay`,
                          operator: "equals",
                          values: selectedDaysToNum,
                      },
                  ],
                  selectedDaysToNum !== undefined &&
                      selectedDaysToNum.length !== 0 &&
                      selectedDaysToNum.length !== 7
              )
              .addFilters(
                  [
                      {
                          member: `${CubeName.forecast}.hour`,
                          operator: "equals",
                          values: selectedHours?.map(String),
                      },
                  ],
                  selectedHours != undefined &&
                      selectedHours.length !== 0 &&
                      selectedHours.length !== 24
              )
              .getResult({ ignoreTimeZone: true })
        : null;

    const { data, isLoading: loadingData } = useQuery(
        [QueryKey.forecastSales, query],
        () => cubejsApi.load(query!),
        {
            enabled: Boolean(query),
            select: (result) =>
                result.rawData().map((d) => ({
                    venueName: d[`${CubeName.forecast}.mappedVenue`],
                    className: d[`${CubeName.forecast}.mappedClass`],
                    areaName: d[`${CubeName.forecast}.mappedArea`],
                    forecastTransactionTotal: formatNumberToFixed(
                        d[`${CubeName.forecast}.forecastTransactionTotal`]
                    ),
                })),
            refetchOnWindowFocus: "always",
            refetchOnMount: "always",
            refetchOnReconnect: "always",
        }
    );

    const forecastSalesByMappingRules = useMemo(() => {
        if (!data || !initialSalesByMappingRule) return;

        return data.reduce((result, d) => {
            const includeVenue =
                !selectedVenueNames ||
                selectedVenueNames.length === 0 ||
                selectedVenueNames.includes(d.venueName);

            const includeArea =
                !selectedAreaNames ||
                selectedAreaNames.length === 0 ||
                selectedAreaNames.includes(d.areaName);

            const includeClass =
                !selectedClassNames ||
                selectedClassNames.length === 0 ||
                selectedClassNames.includes(d.className);

            if (result[d.venueName] != null && includeArea && includeClass) {
                result[d.venueName] = formatNumberToFixed(
                    result[d.venueName] + d.forecastTransactionTotal
                );
            }

            if (result[d.areaName] != null && includeVenue && includeClass) {
                result[d.areaName] = formatNumberToFixed(
                    result[d.areaName] + d.forecastTransactionTotal
                );
            }

            if (result[d.className] != null && includeArea && includeVenue) {
                result[d.className] = formatNumberToFixed(
                    result[d.className] + d.forecastTransactionTotal
                );
            }

            return result;
        }, cloneDeep(initialSalesByMappingRule));
    }, [
        data,
        initialSalesByMappingRule,
        selectedAreaNames,
        selectedClassNames,
        selectedVenueNames,
    ]);

    const forecastSales = useMemo(() => {
        if (!forecastSalesByMappingRules || !mappingRules) return;

        return sum(
            mappingRules
                .filter(
                    ({ segment, segmentName }) =>
                        segment === "venue" &&
                        (!selectedVenueNames ||
                            selectedVenueNames.length === 0 ||
                            selectedVenueNames.includes(segmentName))
                )
                .map(({ segmentName }) => forecastSalesByMappingRules[segmentName])
        );
    }, [forecastSalesByMappingRules, mappingRules, selectedVenueNames]);

    const isLoading = loadingMappingRules || loadingData;

    return { forecastSalesByMappingRules, forecastSales, isLoading, query };
};
