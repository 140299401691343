import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Skeleton, Typography } from "antd";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import { ApexChartsSeries } from "../../Api/Cube/types";
import {
    QUANTACO_CHART_DARK_BLUE,
    QUANTACO_DARK_ORANGE,
} from "../../Utils/constants";
import { FilteredSegments } from "./index";
import {
    DEFAULT_GRANULARITY_IN_MINUTES,
    MINUTES_IN_HOURS,
} from "../../Utils/date-utils";
import { currencyFormatter } from "Utils/utils";

export interface BaselineRawRow {
    mapped_area: string;
    mapped_class: string;
    relative_timestamp: string;
    base: number;
    forecast?: number;
}

interface BasicMappedRow {
    x: string;
}

export interface BaselineMappedRow extends BasicMappedRow {
    [base: string]: number | string;
}

interface BaselineSeries {
    series: ApexChartsSeries;
}
const getOptions = ({
    xAxisData,
    mode,
    baseName,
    max,
}: {
    xAxisData: string[];
    mode: "baseline" | "drivers" | "drivers_autogen" | "autogen";
    baseName: string;
    max: number;
}): ChartOptions => {
    const options: ApexOptions = {
        chart: {
            type: "line",
            id: "baselineChart",
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            title: {
                text: "Day & Time",
                style: {
                    fontSize: "16px",
                    fontWeight: "600 !important",
                    color: "#00255d",
                },
                offsetX: -30,
            },
            type: "datetime",
            tickAmount: 7,
            categories: xAxisData,
            labels: {
                datetimeUTC: false,
                formatter: (val) => moment(val).format("ddd HH:mm (D/M)"),
            },
        },
        colors:
            mode === "drivers" || mode === "drivers_autogen"
                ? [QUANTACO_CHART_DARK_BLUE, QUANTACO_DARK_ORANGE]
                : [QUANTACO_DARK_ORANGE, QUANTACO_CHART_DARK_BLUE],

        yaxis: {
            title: {
                text: "Amount $",
                style: {
                    fontSize: "16px",
                    fontWeight: "600 !important",
                    color: "#00255d",
                },
            },
            tickAmount: 2,
            min: 0,
            labels: {
                formatter: (num) => {
                    return currencyFormatter(num);
                },
            },
            forceNiceScale: true,
            showForNullSeries: true,
            max: max,
        },
        grid: {
            show: true,
        },
        stroke: {
            width: [3, 3],
            dashArray:
                mode === "drivers" || mode === "drivers_autogen" ? [4, 0] : [0, 4],
            curve: ["smooth", "smooth"],
        },
        fill: {
            opacity: [1, 0.7],
            type: "solid",
            gradient: {
                opacityFrom: 1,
                opacityTo: 1,
            },
        },
    };
    return { options: options };
};

export const groupDataByField =
    (resultArr: BaselineMappedRow[], targetField: string): any =>
    (res: {}, value: BaselineRawRow) => {
        const { base, forecast } = value;
        const targetMapping = value[targetField];

        if (!res[targetMapping]) {
            res[targetMapping] = {
                x: targetMapping,
                base: 0,
                forecast: 0,
            };
            resultArr.push(res[targetMapping]);
        }
        res[targetMapping].base += base;
        if (forecast) {
            res[targetMapping].forecast += forecast;
        }
        return res;
    };

interface ChartOptions {
    options?: ApexOptions;
    brushChartOptions?: ApexOptions;
}

interface Props {
    baselineData: BaselineRawRow[];
    forecastData: BaselineRawRow[];
    loading: boolean;
    mode: "baseline" | "drivers" | "drivers_autogen" | "autogen";
    filteredSegments: FilteredSegments;
}

const BaselineChartForAutogen = ({
    baselineData,
    forecastData,
    loading,
    mode,
    filteredSegments,
}: Props): ReactElement => {
    const [options, setOptions] = useState<ChartOptions>({});
    const [series, setSeries] = useState<BaselineSeries>({ series: [] });
    const baseName: string = useMemo(() => {
        return mode === "baseline" || mode === "drivers"
            ? "Baseline"
            : mode === "autogen" || mode === "drivers_autogen"
            ? "Autogen"
            : "";
    }, [mode]);

    const forecastName: string = useMemo(() => {
        return mode === "baseline"
            ? "Autogen"
            : mode === "drivers"
            ? "Baseline Drivers"
            : mode === "autogen"
            ? "Baseline"
            : mode === "drivers_autogen"
            ? "Autogen Drivers"
            : "";
    }, [mode]);

    useEffect(() => {
        if (!loading && baselineData.length) {
            const result: BaselineMappedRow[] = [];
            baselineData.reduce(groupDataByField(result, "relative_timestamp"), {});

            // Filter out first 6 hours of the next Monday.
            const removedDuplicatedMondayResult = filteredSegments.days.includes(
                "Monday"
            )
                ? result.slice(
                      0,
                      -((MINUTES_IN_HOURS * 6) / DEFAULT_GRANULARITY_IN_MINUTES)
                  )
                : result;

            const resultForecast: BaselineMappedRow[] = [];
            forecastData.reduce(
                groupDataByField(resultForecast, "relative_timestamp"),
                {}
            );

            // Filter out first 6 hours of the next Monday.
            const removedDuplicatedMondayResultForecast =
                filteredSegments.days.includes("Monday")
                    ? resultForecast.slice(
                          0,
                          -((MINUTES_IN_HOURS * 6) / DEFAULT_GRANULARITY_IN_MINUTES)
                      )
                    : resultForecast;

            const s: {
                series: Array<{ name: string; type: string; data: Array<number> }>;
            } = {
                series: [
                    {
                        name: baseName,
                        type: "line",
                        data: removedDuplicatedMondayResult.map(
                            (r) => r.base
                        ) as number[],
                    },
                ],
            };
            if (mode === "drivers" || mode === "drivers_autogen") {
                s.series.push({
                    name: forecastName,
                    type: "line",
                    data: removedDuplicatedMondayResult.map(
                        (r) => r.forecast
                    ) as number[],
                });
            }
            if (mode === "baseline" || mode === "autogen") {
                s.series.push({
                    name: forecastName,
                    type: "line",
                    data: removedDuplicatedMondayResultForecast.map(
                        (r) => r.base
                    ) as number[],
                });
            }
            let data: Array<number> = [];
            s.series.forEach((a) => (data = [...data, ...a.data]));
            const max = Math.max(...data);
            setOptions(
                getOptions({
                    xAxisData: removedDuplicatedMondayResult.map((row) => row.x),
                    mode,
                    baseName: forecastName,
                    max: max,
                })
            );
            setSeries(s);
        }
    }, [loading, baselineData, baseName, mode, forecastData, forecastName]);

    if (loading) {
        return (
            <>
                <Skeleton active={true} />
                <Skeleton active={true} />
            </>
        );
    }

    return (
        <>
            {baselineData.length > 0 ? (
                <>
                    <ReactApexChart
                        options={options.options ? options.options : {}}
                        series={series.series ? series.series : []}
                        type={"area"}
                        width="100%"
                        height={350}
                    />
                </>
            ) : (
                <>
                    <Typography.Text type={"secondary"}>
                        {`Missing ${baseName.toLowerCase()} forecast data`}
                    </Typography.Text>
                </>
            )}
        </>
    );
};

export default BaselineChartForAutogen;
