import React, { useEffect, useMemo, useState } from "react";
import { Modal, Select } from "antd";
import omit from "lodash/omit";
import { calcTextWidth, mapListValues } from "./SelectWidget";
import { openNotificationWithIcon } from "Components/Notification";
import { ExclamationCircleOutlined } from "@ant-design/icons"; // Add this line

export type ListValue = {
    value: string;
    title: string;
    disabled?: boolean;
    warning?: boolean;
    count?: number;
};

function hasDuplicateValues(arr: ListValue[]): boolean {
    const values: string[] = [];
    for (const item of arr) {
        if (values.includes(item.value)) {
            return true;
        }
        values.push(item.value);
    }

    return false;
}

const Option = Select.Option;

const SELECT_WIDTH_OFFSET_RIGHT = 200;

const MultiSelectWidget = ({
    setValue,
    config,
    value,
    field,
    placeholder,
    customProps,
    fieldDefinition,
    readonly,
    listValues,
    allowCustomValues,
}: {
    setValue: (value: any) => void;
    config: any;
    value: any[];
    field: any;
    placeholder: string;
    customProps: any;
    fieldDefinition: any;
    readonly: boolean;
    // from fieldSettings:
    listValues: ListValue[];
    allowCustomValues: boolean;
}) => {
    const [options, setOptions] = useState<React.ReactNode>(undefined);
    const [optionsMaxWidth, setOptionsMaxWidth] = useState(0);

    useEffect(() => {
        let currentOptionsMaxWidth = 0;

        setOptions(
            mapListValues(
                listValues,
                ({ title, value, warning, disabled, count }) => {
                    const displayTitle =
                        count !== undefined ? `[${count}] ${title}` : title;
                    currentOptionsMaxWidth = Math.max(
                        currentOptionsMaxWidth,
                        calcTextWidth(displayTitle)
                    );

                    return (
                        <Option key={value} value={value} disabled={disabled}>
                            {warning && (
                                <ExclamationCircleOutlined
                                    style={{ marginRight: 5, color: "#ff4d4f" }}
                                />
                            )}{" "}
                            {displayTitle}
                        </Option>
                    );
                }
            )
        );

        setOptionsMaxWidth(currentOptionsMaxWidth);
    }, [listValues]);

    const handleChange = (val) => {
        if (val && !val.length) val = undefined; // not allow []
        setValue(val);
    };

    const filterOption = (input, option) => {
        let dataForFilter = option.children || option.value;

        if (Array.isArray(dataForFilter)) {
            dataForFilter = dataForFilter.join(" ");
        }
        return dataForFilter?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const { renderSize } = config.settings;
    const placeholderWidth = calcTextWidth(placeholder);
    const aValue = value && value.length ? value : undefined;
    const width = aValue ? null : placeholderWidth + SELECT_WIDTH_OFFSET_RIGHT;
    const dropdownWidth = optionsMaxWidth + SELECT_WIDTH_OFFSET_RIGHT;
    const customSelectProps = omit(customProps, ["showCheckboxes"]);

    const listValuesObject = useMemo(
        () =>
            listValues.reduce(
                (obj, currentItem) => ({
                    ...obj,
                    [currentItem.value]: currentItem.title,
                }),
                {}
            ),
        [listValues]
    );

    const sortedValues = useMemo(
        () =>
            aValue?.sort((itemA, itemB) => {
                const titleA = listValuesObject[itemA];
                const titleB = listValuesObject[itemB];
                return titleA.localeCompare(titleB);
            }),
        [aValue, listValues]
    );

    // useEffect(() => {
    //     console.log({ listValues });
    // }, [sortedValues]);

    if (hasDuplicateValues(listValues)) {
        openNotificationWithIcon({
            title: `The field "${fieldDefinition.label}" has duplicate values in its field settings. Please fix this before continuing.`,
            type: "error",
            key: `duplicate-values-found ${fieldDefinition.label}`,
        });
    }

    return (
        <Select
            disabled={readonly}
            mode={allowCustomValues ? "tags" : "multiple"}
            style={{
                minWidth: width ?? 350,
                width: width ?? 350,
            }}
            dropdownStyle={{
                width: dropdownWidth,
            }}
            key={"widget-multiselect"}
            dropdownMatchSelectWidth={false}
            placeholder={placeholder}
            size={renderSize}
            value={sortedValues}
            onChange={handleChange}
            filterOption={filterOption}
            onSelect={(val) => {
                console.log("onSelect");
                console.log({ val });
            }}
            {...customSelectProps}
        >
            {options}
        </Select>
    );
};

export { MultiSelectWidget };
