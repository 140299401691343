import { Row, Col, Button, Popover, Divider, Modal, Table } from "antd";
import { DraggableTable } from "Components/DraggableTable/DraggableTable";
import { LoadingCard } from "Components/LoadingCard/LoadingCard";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { TitleWithQuantacoColor } from "styles/TitleWithQuantacoColor";
import { Utils as QbUtils } from "react-awesome-query-builder";
import { useHistory } from "react-router-dom";
import {
    useDeleteMappingRule,
    useListIntegrationSources,
    useListMappingRules,
    useListSegmentsByIntegrationSource,
} from "Hooks/mappingConfigurator";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { MappingRule, MappingRuleResult } from "Api/mappingConfigurator";
import { stringSort, toTitleCase } from "Utils/utils";
import { getColumnSearchProps } from "Components/UserTable/TableColumnComponents";
import { chain } from "lodash";
import { getFieldSettings } from "Utils/mapping-configurator-utils";
import styled from "styled-components";
import { TitleValue } from "@types";
import { UnmappedItemsTable } from "./UnmappedItemsTable";
import { useModalVisibility } from "../../@hooks/useModalVisibility";
import { useExportMappingItems } from "./MappingConfiguratorEditorPage";

export const removeIntegrationSource = (str: string) => {
    const stringSplit = str.split("_");
    return stringSplit[1] + "_" + stringSplit[2];
};

const StyledRuleSummary = styled.div`
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    gap: 8px;
    overflow-x: auto;
`;

const IntegrationSummary = styled.div`
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #f9f9f9;
`;

const SummaryTitle = styled.p`
    margin-bottom: 0;
`;

const SummaryDivider = styled(Divider)`
    width: 100%;
    margin: 4px 0;
`;

const renderContent = (property, currentItem, titleAndValueMap) => {
    if (property === "classIds") {
        return <b>{currentItem.split("_")[1]}</b>;
    }
    return (
        titleAndValueMap.find(
            (currentTitleAndValueMap) =>
                currentTitleAndValueMap.value === currentItem
        )?.title || currentItem.split("_")[1]
    );
};

export const popoverRuleSummary = (
    result: MappingRuleResult[],
    titleAndValueMap: TitleValue[]
) => (
    <StyledRuleSummary>
        {result.map((currentRule, index) => {
            const { integrationSource } = currentRule;
            return (
                <IntegrationSummary key={index}>
                    <SummaryTitle>
                        {`Source:`}{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {toTitleCase(integrationSource)}
                        </span>
                    </SummaryTitle>
                    <SummaryDivider />
                    {["venueIds", "areaIds", "classIds"].map(
                        (property) =>
                            currentRule[property] && (
                                <>
                                    {currentRule[property].map(
                                        (currentItem, currentItemID) => (
                                            <p key={currentItemID}>
                                                {renderContent(
                                                    property,
                                                    currentItem,
                                                    titleAndValueMap
                                                )}
                                            </p>
                                        )
                                    )}
                                </>
                            )
                    )}
                </IntegrationSummary>
            );
        })}
    </StyledRuleSummary>
);

const columns = (titleAndValueMap: TitleValue[]) => [
    {
        title: "Segment",
        dataIndex: "segment",
        key: "segmentType",
        render: (segmentName: string) => `${toTitleCase(segmentName)}`,
        width: "25%",
        sorter: (a, b) => stringSort(a.segment, b.segment),
        filters: [
            { text: "Area", value: "area" },
            { text: "Class", value: "class" },
            { text: "Venue", value: "venue" },
        ],
        onFilter: (value, record) => record.segment === value,
    },
    {
        title: "Name",
        dataIndex: "segmentName",
        key: "segmentName",
        width: "30%",
        ...getColumnSearchProps("segmentName"),
        sorter: (a, b) => stringSort(a.segmentName, b.segmentName),
    },

    {
        title: "Exclude",
        key: "exclude",
        width: "15%",
        filters: [
            { text: "True", value: true },
            { text: "False", value: false },
        ],
        onFilter: (value, record) => (record.exclude ?? false) === value,
        filterMultiple: false,
        render: (rowValue: any) =>
            toTitleCase((rowValue.exclude ? rowValue.exclude : false).toString()),
    },
    {
        title: "Rule summary",
        render: (text: any) => {
            const result: Array<MappingRuleResult> =
                text?.result as Array<MappingRuleResult>;
            const PopoverContent = popoverRuleSummary(result, titleAndValueMap);
            return (
                <Popover content={PopoverContent} placement="left">
                    <Button type="primary">{`${result.length} Mapped sources`}</Button>
                </Popover>
            );
        },
    },
];

export type ToggleModal = () => void;

export const MappingRuleList: React.FC = () => {
    const {
        isLoading: isListMappingRulesLoading,
        data: mappingRules,
        refetch,
    } = useListMappingRules();
    const [visible, showModal, closeModal] = useModalVisibility();
    const { exportToCsv } = useExportMappingItems();

    const {
        data: integrationSources,
        isLoading: isListSegmentsIntegrationSourcesLoading,
    } = useListIntegrationSources();

    const { data: segments, isLoading: isListSegmentsIntegrationSourceLoading } =
        useListSegmentsByIntegrationSource(
            integrationSources
                ?.map(({ integrationName }) => integrationName)
                .join(",")
        );

    const isLoading =
        isListSegmentsIntegrationSourcesLoading ||
        isListSegmentsIntegrationSourceLoading ||
        isListMappingRulesLoading;

    const venueNameById = useMemo(
        () =>
            segments
                ? chain(segments)
                      .filter(({ segmentType }) => segmentType === "venue")
                      .keyBy(
                          ({ integrationName, segmentId }) =>
                              `${integrationName}_${segmentId}`
                      )
                      .mapValues("segmentName")
                      .value()
                : undefined,
        [segments]
    );

    const fieldSettings = useMemo(
        () =>
            segments && venueNameById
                ? getFieldSettings({ segments, venueNameById })
                : undefined,
        [venueNameById, segments]
    );

    const titleAndValueMap = useMemo(() => {
        let titleAndValuePairs: TitleValue[] = [];
        fieldSettings
            ? Object.keys(fieldSettings).forEach((currentKey) => {
                  titleAndValuePairs = [
                      ...titleAndValuePairs,
                      ...fieldSettings[currentKey].fieldSettings.listValues.map(
                          (currentListValue) => {
                              return {
                                  ...currentListValue,
                                  value: removeIntegrationSource(
                                      currentListValue.value
                                  ),
                              };
                          }
                      ),
                  ];
              })
            : [];
        return titleAndValuePairs;
    }, [fieldSettings]);

    const filteredMappingRules = useMemo(
        () =>
            mappingRules
                ?.filter(({ rules }) => Boolean(rules))
                .map(({ id, ...rest }) => ({
                    key: id,
                    id,
                    ...rest,
                })),
        [mappingRules]
    );

    const history = useHistory();

    useEffect(() => {
        refetch();
    }, [refetch]);

    const createNewMappingRule = () => {
        history.push(`/mapping/${QbUtils.uuid()}`);
    };

    const moveToRowEditorPage = (_, clickedMappingRule: MappingRule) => {
        history.push(`/mapping/${clickedMappingRule.id}`);
    };

    const tableColumns = useMemo(
        () => columns(titleAndValueMap),
        [titleAndValueMap]
    );

    if (!filteredMappingRules || isLoading) {
        return <QuantacoLoader size={135} />;
    }

    if (filteredMappingRules.length === 0) {
        return (
            <LoadingCard
                message="You don't have any mapping rule"
                onCreate={createNewMappingRule}
            />
        );
    }

    return (
        <>
            <Row style={{ height: "100%" }}>
                <Col flex={"auto"}>
                    <TitleWithQuantacoColor level={3}>
                        Mapping Rules
                    </TitleWithQuantacoColor>
                </Col>
            </Row>
            <DraggableTable
                columns={tableColumns}
                dataSource={filteredMappingRules}
                onRowMoved={() => null}
                isLoading={isLoading}
                onRowClicked={moveToRowEditorPage}
            />
            <Row style={{ marginTop: 20 }} justify={"space-between"}>
                <Button onClick={showModal}>Show Unmapped</Button>
                <Button onClick={exportToCsv}>Export Mapping CSV</Button>

                <Col span={8}>
                    <Link to={`/mapping/${QbUtils.uuid()}`}>
                        <Button type={"primary"} block={true}>
                            Create New Rule
                        </Button>
                    </Link>
                </Col>
            </Row>
            <UnmappedItemsTable
                visible={visible}
                onOk={closeModal}
                onCancel={closeModal}
            />
        </>
    );
};
