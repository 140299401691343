import {
    Button,
    Empty,
    Form,
    message,
    Popover,
    Select,
    Modal,
    Input,
    Checkbox,
} from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { ImmutableTree } from "react-awesome-query-builder";
import { MappingConfiguratorLayout } from "./MappingConfiguratorLayout";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import BaseDesktopPage from "Pages/BaseDesktopPage";
import { RuleBuilder } from "./RuleBuilder";
import { useMappingConfiguratorEditorPage } from "./MappingConfiguratorEditorPage.logic";
import { popoverRuleSummary, removeIntegrationSource } from "./MappingRuleList";
import { getFieldSettings } from "Utils/mapping-configurator-utils";
import { TitleValue } from "@types";
import {
    useDeleteMappingRule,
    useListMappingRules,
} from "Hooks/mappingConfigurator";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { RoutePaths } from "Components/Router/Routes";
import { parseMappingRules } from "./parseMappingRules";
import { UnmappedItemsTable } from "./UnmappedItemsTable";
import { useModalVisibility } from "@hooks/useModalVisibility";
import styled from "styled-components";
import { MappingRule, listMappingRules } from "Api/mappingConfigurator";
const { Option } = Select;

const { confirm, success } = Modal;

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
`;

// interface MappingRule {
//   id: string;
//   rules: JsonGroup;
//   segment: MappingSegment;
//   segmentName: string;
//   priority?: number;
//   result: MappingRuleResult[];
//   exclude?: boolean;
// }

// interface MappingRule {
//   id: string;
//   rules: JsonGroup;
//   segment: MappingSegment;
//   segmentName: string;
//   priority?: number;
//   result: MappingRuleResult[];
//   exclude?: boolean;
// }

interface CsvEntry {
    segmentName: string;
    venueId?: string;
    classId?: string;
    areaId?: string;
    segment: string;
}

// The function takes an array of MappingRule objects and returns a flattened array of CsvEntry objects.
function parseResultToCsv(original: MappingRule[]): CsvEntry[] {
    return original.flatMap(({ rules, result, segmentName, segment }) =>
        result.flatMap(({ venueIds, classIds, areaIds }): CsvEntry[] => {
            const result: CsvEntry[] = [];

            if (venueIds) {
                result.push(
                    ...venueIds.flatMap((id) => ({
                        segmentName,
                        segment,
                        venueId: id,
                    }))
                );
            }
            if (classIds) {
                result.push(
                    ...classIds.flatMap((id) => ({
                        segmentName,
                        segment,

                        classId: id,
                    }))
                );
            }
            if (areaIds) {
                result.push(
                    ...areaIds.flatMap((id) => ({
                        segmentName,
                        segment,

                        areaId: id,
                    }))
                );
            }
            return result;
        })
    );
}

const useExportMappingItems = () => {
    const [mappingRules, setMappingRules] = useState<CsvEntry[]>([]);

    // Fetch the list of mapping rules from the server
    const fetchMappingRules = async () => {
        try {
            const res = await listMappingRules(); // Replace this with your implementation
            setMappingRules(parseResultToCsv(res));
        } catch (error) {
            console.error("Error fetching mapping rules:", error);
        }
    };

    // Export the mapping rules to a CSV file
    const exportToCsv = () => {
        const header = "segmentName,classId,areaId,venueId,segment";
        const rows = mappingRules.map(
            (rule) =>
                `${rule.segmentName},${rule.classId ?? ""},${rule.areaId ?? ""},${
                    rule.venueId ?? ""
                },${rule.segment}`
        );
        const csvContent = [header, ...rows].join("\n");
        const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "mapping_rules.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Fetch mapping rules on component mount
    useEffect(() => {
        fetchMappingRules();
    }, []);

    return { mappingRules, exportToCsv };
};

export { useExportMappingItems };

// MappedSegmentTypes: A type representing mapped segment types, with three properties:
// 1. area: an object with keys as strings and values as arrays of strings.
// 2. class: an object with two properties:
//    a. class: an object with keys as strings and values as arrays of strings.
//    b. area: an object with keys as strings and values as arrays of strings.
// 3. venue: an object with keys as strings and values as arrays of strings.
export type MappedSegmentTypes = {
    area: { [key: string]: string[] };
    class: { class: { [key: string]: string[] }; area: { [key: string]: string[] } };
    venue: { [key: string]: string[] };
};
export const MappingConfiguratorEditorPage: React.FC<
    RouteComponentProps<{ id: string }>
> = ({
    match: {
        params: { id },
    },
}) => {
    const {
        fetchingExistingMappingRule,
        creatingMappingRule,
        updatingMappingRule,
        config,
        mappingRule,
        setMappingRule,
        ruleBuilder,
        setRuleBuilder,
        mappingRuleDirty,
        saveMappingRule,
        mappingRuleValid,
        segments,
        venueNameById,
        mappingRuleParsingResult,
        setParsedMappingRules,
        parsedMappingRules,
    } = useMappingConfiguratorEditorPage(id);

    const history = useHistory();

    const [visible, showModal, closeModal] = useModalVisibility();

    const { data: mappingRules } = useListMappingRules();

    const { mutate: deleteMappingRule, isLoading: deleteMappingRuleLoading } =
        useDeleteMappingRule();

    const successCountdown = () => {
        let secondsToGo = 3;
        const modal = success({
            title: "Rule deleted.",
            content: `You will be redirect to the home screen in ${secondsToGo} ${
                secondsToGo === 1 ? "second" : "seconds"
            }.`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                content: `You will be redirect to the home screen in ${secondsToGo} ${
                    secondsToGo === 1 ? "second" : "seconds"
                }.`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
            history.push(RoutePaths.MAPPING);
        }, secondsToGo * 1000);
    };
    const deleteMappingObject = () => {
        confirm({
            title: "Are you sure you wish to delete this rule?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                deleteMappingRule(id);
                successCountdown();
            },
            onCancel() {
                return;
            },
        });
    };

    const filteredMappingRules = useMemo(
        () =>
            mappingRules
                ?.filter(({ rules }) => Boolean(rules))
                .map(({ id, ...rest }) => ({
                    key: id,
                    id,
                    ...rest,
                })),
        [mappingRules]
    );

    useEffect(() => {
        if (filteredMappingRules) {
            setParsedMappingRules(parseMappingRules(filteredMappingRules, id));
        }
    }, [filteredMappingRules]);

    const fieldSettings = useMemo(() => {
        return segments && venueNameById
            ? getFieldSettings({
                  segments,
                  venueNameById,
                  knownMappedRules: parsedMappingRules,
                  selectedSegment: mappingRule["segment"],
              })
            : undefined;
    }, [venueNameById, segments, parsedMappingRules, mappingRule]);

    const titleAndValueMap = useMemo(() => {
        let titleAndValuePairs: TitleValue[] = [];
        fieldSettings
            ? Object.keys(fieldSettings).forEach((currentKey) => {
                  titleAndValuePairs = [
                      ...titleAndValuePairs,
                      ...fieldSettings[currentKey].fieldSettings.listValues.map(
                          (currentListValue) => {
                              return {
                                  ...currentListValue,
                                  value: removeIntegrationSource(
                                      currentListValue.value
                                  ),
                              };
                          }
                      ),
                  ];
              })
            : [];
        return titleAndValuePairs;
    }, [fieldSettings]);

    const popOverContent = useMemo(
        () =>
            mappingRuleParsingResult ? (
                popoverRuleSummary(mappingRuleParsingResult, titleAndValueMap)
            ) : (
                <div>No rules have been set.</div>
            ),
        [mappingRuleParsingResult, titleAndValueMap]
    );

    if (fetchingExistingMappingRule || !config || !ruleBuilder || !venueNameById) {
        return <QuantacoLoader size={135} />;
    }

    const mappingRuleNameDuplicated = Boolean(
        mappingRule.segmentName &&
            mappingRule.segment &&
            mappingRules
                ?.filter((mappingRule) => mappingRule.id !== id)
                .some(
                    ({ segment, segmentName }) =>
                        segment === mappingRule.segment &&
                        mappingRule.segmentName === segmentName
                )
    );

    return (
        <BaseDesktopPage page="mapping">
            <MappingConfiguratorLayout>
                <Form layout="vertical">
                    <div
                        style={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <Form.Item
                            style={{ width: "45%" }}
                            label="Segment"
                            required
                            requiredMark
                        >
                            <Select
                                showSearch
                                placeholder="Segment"
                                value={mappingRule.segment}
                                onChange={(segment) =>
                                    setMappingRule((prevMappingRule) => ({
                                        ...prevMappingRule,
                                        segment,
                                    }))
                                }
                            >
                                <Option value="venue">Venue</Option>
                                <Option value="area">Area</Option>
                                <Option value="class">Class</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Segment Name"
                            required
                            requiredMark
                            style={{ width: "45%" }}
                            validateStatus={
                                mappingRuleNameDuplicated ? "error" : undefined
                            }
                            help={
                                mappingRuleNameDuplicated
                                    ? "Segment Name already exists"
                                    : undefined
                            }
                        >
                            <Input
                                value={mappingRule.segmentName}
                                onChange={({ target: { value } }) =>
                                    setMappingRule((prevMappingRule) => ({
                                        ...prevMappingRule,
                                        segmentName: value,
                                    }))
                                }
                                placeholder="Segment Name"
                            />
                        </Form.Item>
                    </div>
                    <Form.Item style={{ width: "45%" }}>
                        <Checkbox
                            checked={mappingRule.exclude}
                            onChange={({ target: { checked } }) => {
                                setMappingRule((prevMappingRule) => ({
                                    ...prevMappingRule,
                                    exclude: checked,
                                }));
                            }}
                        >
                            Exclude
                        </Checkbox>{" "}
                    </Form.Item>
                    <Form.Item label="Mapping Rules" required style={{ padding: 0 }}>
                        {mappingRule["segment"] === undefined ? (
                            <Empty description={"Please select a Segment!"} />
                        ) : (
                            <RuleBuilder
                                onRulesChanged={(immutableTree: ImmutableTree) => {
                                    setRuleBuilder(immutableTree);
                                }}
                                rules={ruleBuilder}
                                config={config}
                                segments={segments}
                                venueNameById={venueNameById}
                                knownMappedRules={parsedMappingRules}
                                currentSelectedSegmentType={mappingRule["segment"]}
                            />
                        )}
                    </Form.Item>
                    <StyledRow>
                        <Button
                            disabled={
                                !mappingRuleValid ||
                                !mappingRuleDirty ||
                                creatingMappingRule ||
                                updatingMappingRule ||
                                mappingRuleNameDuplicated
                            }
                            loading={creatingMappingRule || updatingMappingRule}
                            type="primary"
                            onClick={() => {
                                message.success("Mapping Rule Saved");
                                saveMappingRule();
                            }}
                        >
                            Save
                        </Button>
                        <Popover
                            content={popOverContent}
                            placement="top"
                            destroyTooltipOnHide
                        >
                            <Button>Summary</Button>
                        </Popover>
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={deleteMappingObject}
                            type="primary"
                            disabled={
                                !mappingRuleValid ||
                                creatingMappingRule ||
                                updatingMappingRule ||
                                mappingRuleNameDuplicated
                            }
                        >
                            Delete
                        </Button>
                        <Button onClick={showModal}>Show Unmapped</Button>
                    </StyledRow>
                </Form>
            </MappingConfiguratorLayout>
            <UnmappedItemsTable
                visible={visible}
                onOk={closeModal}
                onCancel={closeModal}
            />
        </BaseDesktopPage>
    );
};
