import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import React, { useState } from "react";
import { MomentFormat } from "Utils/date-utils";
import { SalesAffectedEvent, Spread } from "../ForecastModel";

const { Option } = Select;

interface Props {
    existingEvent?: SalesAffectedEvent;
    onSaveEvent: (event: SalesAffectedEvent) => void;
    onCloseEditor: () => void;
    loading: boolean;
}

const SaleUnit = {
    amount: "amount",
    percent: "percent",
};

export const ForecastEventEditor: React.FC<Props> = ({
    existingEvent,
    onSaveEvent,
    onCloseEditor,
    loading,
}) => {
    const { data: mappingRules } = useListMappingRules();
    const [saleUnit, setSaleUnit] = useState(
        !existingEvent || existingEvent.expectedSalesAmount
            ? SaleUnit.amount
            : SaleUnit.percent
    );
    const [form] = Form.useForm<SalesAffectedEvent>();
    const modalTitle = existingEvent ? "Edit Event" : "Create Event";

    const saveEvent = async () => {
        const event = await form.validateFields();
        onSaveEvent({ ...event, id: existingEvent?.id ?? "" });
    };

    const expectedSalesUnit = (
        <Select
            value={saleUnit}
            className="select-before"
            onChange={(updatedSaleUnit) => {
                setSaleUnit(updatedSaleUnit);
                if (updatedSaleUnit === SaleUnit.amount) {
                    form.setFieldsValue({
                        expectedSalesPercent: undefined,
                    });
                } else {
                    form.setFieldsValue({
                        expectedSalesAmount: undefined,
                        spread: undefined,
                    });
                }
            }}
        >
            <Option value="amount">$</Option>
            <Option value="percent">%</Option>
        </Select>
    );

    return (
        <Modal
            title={modalTitle}
            onOk={saveEvent}
            onCancel={() => {
                if (!loading) {
                    onCloseEditor();
                }
            }}
            visible={true}
            width={750}
            okText="Save"
            cancelButtonProps={{ disabled: loading }}
            confirmLoading={loading}
        >
            <Form.Provider>
                <Form
                    initialValues={{ ...existingEvent }}
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name={"name"}
                                label={"Name"}
                                rules={[
                                    {
                                        required: true,
                                        max: 20,
                                        message: "Event name is required",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name={"start"}
                                label={"Start"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Event start time is required",
                                    },
                                ]}
                            >
                                <DatePicker
                                    format={MomentFormat.dateTimeDisplay}
                                    style={{ width: "100%" }}
                                    showTime
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"end"}
                                label={"End"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Event end time is required",
                                    },
                                ]}
                            >
                                <DatePicker
                                    format={MomentFormat.dateTimeDisplay}
                                    style={{ width: "100%" }}
                                    showTime
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name={"areaIds"}
                                label={"Area"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Areas required",
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    allowClear
                                    mode="multiple"
                                    maxTagCount={2}
                                >
                                    {mappingRules
                                        ?.filter(({ segment }) => segment === "area")
                                        ?.map(({ id, segmentName }) => (
                                            <Option key={id} value={id}>
                                                {segmentName}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"classIds"}
                                label={"Class"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Classes required",
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    allowClear
                                    mode="multiple"
                                    maxTagCount={2}
                                >
                                    {mappingRules
                                        ?.filter(
                                            ({ segment }) => segment === "class"
                                        )
                                        ?.map(({ id, segmentName }) => (
                                            <Option key={id} value={id}>
                                                {segmentName}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name={
                                    saleUnit === SaleUnit.amount
                                        ? "expectedSalesAmount"
                                        : "expectedSalesPercent"
                                }
                                label="Expected Sales"
                                rules={[
                                    {
                                        required: true,
                                        message: "Expected Sales required.",
                                    },
                                ]}
                            >
                                <Input
                                    addonBefore={expectedSalesUnit}
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                        {saleUnit === SaleUnit.amount && (
                            <Col span={12}>
                                <Form.Item
                                    name={"spread"}
                                    label="Spread"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Spread is required",
                                        },
                                    ]}
                                >
                                    <Select>
                                        <Option key={"even"} value={Spread.Even}>
                                            Even
                                        </Option>
                                        <Option
                                            key={"proportional"}
                                            value={Spread.Proportional}
                                        >
                                            Proportional
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </Form.Provider>
        </Modal>
    );
};
